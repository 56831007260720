import { DependencyList, useMemo, useEffect, useState } from "react";
import { windowIsDefined } from "common/utils";
import { Breakpoint, BreakpointKey, BreakpointCallback } from "types";

export const useScreenMedia = (breakpoint: BreakpointKey) =>
  useMemo(
    () =>
      windowIsDefined()
        ? window.matchMedia(`(min-width: ${Breakpoint[breakpoint]})`)
        : null,
    [breakpoint]
  );

const useScreenMediaQuery = (
  breakpoint: BreakpointKey,
  callback?: BreakpointCallback,
  deps?: DependencyList
): [matches: boolean, media: MediaQueryList | null] => {
  const media = useScreenMedia(breakpoint);
  const [matches, setMatches] = useState(media?.matches ?? false);

  useEffect(() => {
    const handleMatch = (event: MediaQueryListEvent) => {
      callback?.(event.matches);
      setMatches(event.matches);
    };

    media?.addEventListener("change", handleMatch);
    const matches = media?.matches ?? false;

    callback?.(matches);
    setMatches(matches);

    return () => {
      media?.removeEventListener("change", handleMatch);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [media, ...(deps ?? [])]);

  return [matches, media];
};

export default useScreenMediaQuery;
