import { Form, Formik, FormikProps } from "formik";
import { useRouter } from "next/router";
import { FunctionComponent, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";
import Switch from "base-components/Switch";
import AnchorLink from "components/AnchorLink";
import { useCookieConsentContext } from "contexts/CookieConsentContext";
import styles from "./CookieBanner.module.scss";

type FormProps = {
  marketingCookieConsent: boolean;
};

const CookieBanner: FunctionComponent = () => {
  const [updateConsent, consentState] = useCookieConsentContext();
  const { asPath } = useRouter();

  const dialogRef = useRef<HTMLDialogElement>(null);

  const initialValues: FormProps = {
    marketingCookieConsent: consentState?.["fb-pixel"]
      ? consentState["fb-pixel"] === "granted"
      : false,
  };

  const handleConsent = ({ marketingCookieConsent }: FormProps) => {
    if (marketingCookieConsent) {
      updateConsent({
        "gtag.ad": "granted",
        "gtag.analytics": "granted",
        "fb-pixel": "granted",
      });
    } else {
      updateConsent({
        "gtag.ad": "denied",
        "gtag.analytics": "denied",
        "fb-pixel": "denied",
      });
    }
  };

  useEffect(() => {
    dialogRef.current?.toggleAttribute(
      "open",
      !consentState && !asPath.includes("/gdpr")
    );
  }, [consentState, asPath]);

  return (
    <dialog className={styles.backdrop} ref={dialogRef}>
      <Formik initialValues={initialValues} onSubmit={handleConsent}>
        {({ values }: FormikProps<FormProps>) => (
          <Form className={styles.banner} id="consent-form">
            <div className={styles.text}>
              <b>
                <FormattedMessage defaultMessage="This website uses cookies" />
              </b>
              <br />
              <p>
                <FormattedMessage defaultMessage="We use cookies to increase the efficiency of your visits to our website and for the purpose of remembering browsing preferences, collecting analytical information." />
              </p>
              <p>
                <FormattedMessage
                  defaultMessage="More information can be found on the <gdpr-link> cookies</gdpr-link> page."
                  values={{
                    "gdpr-link": (parts: string) => (
                      <AnchorLink href="/gdpr">{parts}</AnchorLink>
                    ),
                  }}
                />
              </p>
              <Switch className={styles.switch} name="marketingCookieConsent">
                <FormattedMessage defaultMessage="Marketing cookies" />
              </Switch>
            </div>
            <Button
              type="info"
              className={styles.button}
              onClick={() => {
                handleConsent(values);
              }}
            >
              <FormattedMessage defaultMessage="Accept cookies" />
            </Button>
          </Form>
        )}
      </Formik>
    </dialog>
  );
};

export default CookieBanner;
