import { captureException } from "@sentry/nextjs";
import { camelCase, get } from "lodash";
import moment from "moment";
import {
  YOUTUBE_VIDEO_ID_REGEX,
  FILE_API_URL,
  HTML_ESCAPE_CHARACTERS,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "common/constants";
import { LOCALE_VALUES } from "i18n";
import {
  Primitive,
  PrimitiveValuesRecord,
  FormFieldRequired,
  FormatTimeParams,
  ThemeColor,
  UserNameField,
  UserNamePickFromList as UserNamePickList,
  NestedProperty,
  LeafProperty,
  BorderRadius,
} from "types";

export const getFilePath = (path?: string | null, defaultValue = "") => {
  if (!path) return defaultValue;
  if (path.startsWith("http")) return path;

  return `${FILE_API_URL}/${path}`;
};

export const convertObjectToCSV = <T extends {}>(
  object: T,
  fields: (keyof T)[]
) =>
  `${fields
    .map((field) => {
      if (!object[field] || object[field] === null) {
        return "";
      }

      if (Array.isArray(object[field])) {
        return (object[field] as unknown as any[]).join(" ");
      }

      if (typeof object[field] === "object") {
        return Object.keys(object[field]).map(
          (key) => object[field][key as keyof T[typeof field]]
        );
      }

      return object[field];
    })
    .flat()
    .join(",")}`;

export const convertObjectsToCSV = <T extends {}>(
  objects: T[],
  fields: (keyof T)[],
  headers: string[]
) =>
  `${headers.join(",")}\n${objects
    .map((object) => convertObjectToCSV(object, fields))
    .join("\n")}`;

export const normalizeString = (string = "") =>
  string?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "") ?? "";

export const limitString = (string: string, limit = 25, lead = "...") => {
  if (!string) return "";
  return `${string.slice(0, limit)}${string.length > limit ? lead : ""}`;
};

export const whiteSpaceAgnosticStringSort = (stringA = "", stringB = "") =>
  stringA.replace(/\s/g, "") <= stringB.replace(/\s/g, "") ? -1 : 1;

export const composeUserId = (id = 0) => `YP-${`000000000${id}`.slice(-9)}`;

export const getFileName = (file: string | File) =>
  typeof file === "string" ? file : file?.name ?? "";

export const getFileOriginalName = (file?: { originalName?: string }) =>
  file?.originalName ?? "File";

export const abbreviate = (string: string, separator = "") =>
  string
    ?.split(" ")
    .map((portion) => portion.charAt(0).toUpperCase())
    .join(separator);

export const prependSlash = (path: string) =>
  path?.startsWith("/") ? path : `/${path}`;

export const appendSlash = (path: string) =>
  path?.endsWith("/") ? path : `${path}/`;

export const getUserDisplayName = (
  user?: {
    name?: string | null;
    surname?: string | null;
    email: string | null;
  } | null,
  fallback = "Unknown"
) =>
  !user?.name && !user?.surname
    ? fallback ?? user?.email
    : `${user?.name ?? ""} ${user?.surname ?? ""}`.trim();

export const getYouTubeVideoId = (url: string) => {
  const urlMatch = url?.match(YOUTUBE_VIDEO_ID_REGEX) ?? [];
  return urlMatch?.[7]?.length === 11 ? urlMatch[7] : null;
};

export const generatePathRegex = (...paths: string[]) =>
  new RegExp(`^\/((${LOCALE_VALUES.join("|")})\/)?(${paths.join("|")})`);

export const replaceHtmlEscapeCharacters = (string: string) =>
  string
    ? Object.entries(HTML_ESCAPE_CHARACTERS).reduce(
        (replacedString, [escape, character]) =>
          replacedString.replace(new RegExp(escape, "g"), character),
        string
      )
    : string;

export const windowIsDefined = () => typeof window !== "undefined";

export const getUrlHash = (url: string, includeHash = false) =>
  url.substring(url.lastIndexOf("#") + Number(!includeHash));

export const requiredFieldsFilter = <T>(
  object: T,
  requiredFields: (keyof T)[]
) =>
  (Object.keys(object) as (keyof T)[])
    .filter((key) => requiredFields.includes(key))
    .filter((key) => Boolean(object[key])).length === requiredFields.length;

export const getPrimitiveValues = <T extends PrimitiveValuesRecord>(
  object: T
): Primitive[] =>
  Object.values(object)
    .map((item) => {
      if (typeof item === "object" && item !== null) {
        return getPrimitiveValues(item);
      }

      return item;
    })
    .flat(10);

export const getFormFieldRequiredAttribute = (required?: FormFieldRequired) =>
  typeof required === "boolean" ? required : undefined;

export const formatDateTime = (params: FormatTimeParams | string | number) => {
  if (!params) return "";

  if (typeof params === "string" || typeof params === "number") {
    return moment(params).format(DEFAULT_DATE_TIME_FORMAT);
  }

  const {
    date,
    dateFormat = DEFAULT_DATE_FORMAT,
    time,
    timeFormat = DEFAULT_TIME_FORMAT,
  } = params;

  const formattedDate = date ? `${moment(date).format(dateFormat)} ` : "";
  const formattedTime = time ? moment(time, "HH:mm:ss").format(timeFormat) : "";

  return `${formattedDate}${formattedTime}`;
};

export const padTimeUnit = (unit: number, maxLength = 2) =>
  unit.toString().padStart(maxLength, "0");

export const formatReadingTime = (durationMilliseconds: number) => {
  const duration = moment.duration(durationMilliseconds);

  return `${padTimeUnit(duration.minutes())}:${padTimeUnit(
    duration.seconds()
  )}`;
};

export const themeColor = (color: ThemeColor, opacity?: number) =>
  `hsl(var(--color-${color})${opacity ? ` / ${opacity}` : ""})`;

export const borderRadius = (radius: BorderRadius) =>
  `var(--border-radius-${radius})`;

export const userName = (
  user?: UserNameField | null,
  pick?: UserNamePickList
): string => {
  if (pick) {
    return pick
      .reduce(
        (nameFields, pickField) => [...nameFields, user?.[pickField]],
        [] as (string | null | undefined)[]
      )
      .filter(Boolean)
      .join(" ");
  }

  const fullName = [user?.name, user?.surname].filter(Boolean).join(" ");

  if (!fullName) {
    return user?.nickname || user?.email || "";
  }

  return fullName;
};

/**
 * *Timeout trick* - refers to usage of `setTimeout` with 0 delay in order to
 * push back an execution of a callback to the end of the event loop. This is
 * useful for async operations, such as setting state using `useState` setter
 * function or updating attributes of DOM nodes.
 *
 * This is only for in browser usage. In the context of React, this means usage
 * within other callbacks and inside of the `useEffect` hook.
 */
export const timeoutTrick = (callback: () => void) =>
  windowIsDefined() ? window.setTimeout(callback) : null;

export const getTagString = <TagType>(
  tag: TagType,
  searchProperty?: NestedProperty<TagType, 4>
) => (typeof tag === "string" ? tag : get(tag, searchProperty ?? ""));

export const flattenObject = <T extends Record<string, unknown>>(
  object: T,
  currentKey?: string
): Record<LeafProperty<T, 5>, string> =>
  Object.keys(object).reduce((currentObject, key) => {
    const value = object[key];
    const newKey = currentKey
      ? Array.isArray(object)
        ? `${currentKey}[${key}]`
        : `${currentKey}.${key}`
      : key;

    if (typeof value === "object") {
      return {
        ...currentObject,
        ...flattenObject(value as Record<string, unknown>, newKey),
      };
    }

    return { ...currentObject, [newKey]: value };
  }, {} as Record<LeafProperty<T>, string>);

export const generateProjectSectionAnchorLink = (
  name: string,
  omitHash = false
) => `${omitHash ? "" : "#"}${camelCase(normalizeString(name || ""))}`;

export const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; Max-Age=-1;`;
};

const onLoadError = (error: Event | string) => {
  if (typeof error === "string") {
    console.error(error);
    captureException(error);
  } else {
    const errorMessage = `Couldnt load script from: ${
      (error.target as any)?.src ?? ""
    }`;
    console.error(errorMessage);
    captureException(errorMessage);
  }
};

export const addCustomScriptToHead = (
  url: string,
  id: string,
  onLoad: () => void
) => {
  if (windowIsDefined()) {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const newScript = document.createElement("script");
      newScript.id = id;
      newScript.onerror = onLoadError;
      newScript.onload = onLoad;
      document.head.appendChild(newScript);
      newScript.src = url;
    } else {
      onLoad();
    }
  }
};
