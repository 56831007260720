import { FC, OptionHTMLAttributes, useCallback } from "react";
import { defineMessage, useIntl } from "react-intl";

export const EMPTY_OPTION_VALUE = "NO_SELECTION_OPTION";

export const EMPTY_OPTION_DEFAULT_CONTENT = defineMessage({
  defaultMessage: "-- No selection --",
  description: "No option selected message",
});

export const EmptyOption: FC<OptionHTMLAttributes<HTMLOptionElement>> = ({
  children,
  ...props
}) => {
  const intl = useIntl();

  return (
    <option {...props}>
      {children || intl.formatMessage(EMPTY_OPTION_DEFAULT_CONTENT)}
    </option>
  );
};

export const useEmptySelectOption = ({ selectRef, ...optionProps }: any) => {
  const select = useCallback(() => {
    const selectElement = selectRef?.current;
    if (selectElement) selectElement.value = EMPTY_OPTION_VALUE;
  }, [selectRef]);

  const intl = useIntl();

  return {
    props: {
      children: intl.formatMessage(EMPTY_OPTION_DEFAULT_CONTENT),
      disabled: true,
      value: EMPTY_OPTION_VALUE,
      ...optionProps,
    },
    select,
  };
};
