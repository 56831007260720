import { noop } from "lodash";
import { createContext, FC, useContext, useState } from "react";
import { windowIsDefined } from "common/utils";

export const AUTH_TOKEN_LOCAL_STORAGE_KEY = "auth-token";

export type AuthTokenType = string | null;

export type AuthTokenContextType = {
  token: AuthTokenType;
  setToken: (token: AuthTokenType) => void;
};

export const AuthTokenContext = createContext<AuthTokenContextType>({
  token: "",
  setToken: (value) => noop(value),
});

export const useAuthToken = () => useContext(AuthTokenContext);

export const AuthTokenProvider: FC = ({ children }) => {
  const [token, setToken] = useState<AuthTokenType>(
    windowIsDefined()
      ? localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY) ?? null
      : null
  );

  const setTokenValue = (newToken: AuthTokenType) => {
    if (newToken === null) {
      localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, newToken);
    }

    setToken(newToken);
  };

  return (
    <AuthTokenContext.Provider value={{ token, setToken: setTokenValue }}>
      {children}
    </AuthTokenContext.Provider>
  );
};
