import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { forwardRef, useMemo, ReactNode, LabelHTMLAttributes } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "base-components/Button";
import FormFileField, {
  FormFileFieldProps,
} from "base-components/FormFileField";
import IntrinsicElementWrapper from "base-components/IntrinsicElementWrapper";
import { getFormFieldRequiredAttribute } from "common/utils";
import { FormFieldElementAttributes, FormFieldElementType, Size } from "types";
import styles from "./FormField.module.scss";

export type FormFieldProps = Omit<
  FormFieldElementAttributes & FormFileFieldProps,
  "value" | "size"
> & {
  as?: "input" | "select" | "textarea";
  errorMessage?: ReactNode;
  fieldClassName?: string;
  fieldWrapperClassName?: string;
  label?: ReactNode;
  note?: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  name?: string;
  value?: any;
  size?: Size;
};

export const FormField = forwardRef<FormFieldElementType, FormFieldProps>(
  (
    {
      as = "input",
      "aria-label": ariaLabel,
      children,
      className = "",
      disabled = false,
      errorMessage,
      fieldClassName = "",
      fieldWrapperClassName = "",
      id,
      label,
      labelProps = {},
      name,
      note,
      required = false,
      type,
      value,
      size = "md",
      ...props
    },
    ref
  ) => {
    const inputId = useMemo(
      () => id || `${name || uuidv4()}-field`,
      [id, name]
    );

    const labelValue: ReactNode = label ?? ariaLabel;

    const labelTitleValue: string =
      labelProps["aria-label"] ??
      labelProps.title ??
      typeof labelValue === "string"
        ? (labelValue as string)
        : typeof labelValue === "object"
        ? (labelValue as any).props?.children || ""
        : "";

    if (type === "file") {
      return (
        <FormFileField
          aria-label={ariaLabel}
          ref={ref}
          className={className}
          disabled={disabled}
          errorMessage={errorMessage}
          id={id}
          label={label}
          labelProps={labelProps}
          name={name}
          required={required}
          value={value}
          {...props}
        />
      );
    }

    if (type === "reset" || type === "submit") {
      return (
        <Button
          ref={ref}
          className={className}
          disabled={disabled}
          htmlType={type}
        >
          {children}
        </Button>
      );
    }

    return (
      <label
        className={classNames(styles.field, className)}
        data-as={as}
        data-disabled={Boolean(disabled)}
        data-required={required}
        data-type={type}
        data-size={size}
        htmlFor={inputId}
        {...labelProps}
        title={labelTitleValue}
      >
        {(label || (type === "radio" && value)) && (
          <span className={styles.label}>
            {label ? label : type === "radio" ? value : null}
          </span>
        )}
        <div
          className={`${as === "select" ? fieldWrapperClassName : ""} ${
            styles.fieldWrapper
          }`}
        >
          {type === "search" && (
            <span className={styles.searchIcon}>
              <FontAwesomeIcon icon="search" />
            </span>
          )}
          <IntrinsicElementWrapper
            aria-label={ariaLabel}
            ref={ref}
            as={as}
            className={fieldClassName}
            disabled={disabled}
            id={inputId}
            name={name}
            required={getFormFieldRequiredAttribute(required)}
            type={type}
            value={value}
            placeholder={`${labelTitleValue}...`}
            {...props}
          >
            {children}
          </IntrinsicElementWrapper>
          {as === "select" && (
            <span className={styles.selectIcon}>
              <FontAwesomeIcon icon="chevron-down" />
            </span>
          )}
        </div>
        <div className={styles.errorMessage}>{errorMessage}</div>
        {note && <small className={styles.note}>{note}</small>}
      </label>
    );
  }
);

export default FormField;
