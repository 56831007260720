import { ComponentProps, ForwardedRef, forwardRef } from "react";
import { IntrinsicElementName } from "types";

declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type IntrinsicElementWrapperProps<T extends IntrinsicElementName> =
  ComponentProps<T> & {
    as?: T;
  };

const IntrinsicElementWrapper = <T extends IntrinsicElementName>(
  { as, children, ...props }: IntrinsicElementWrapperProps<T>,
  ref: ForwardedRef<any>
) => {
  const IntrinsicElement = as as any;

  return (
    <IntrinsicElement ref={ref} {...props}>
      {children}
    </IntrinsicElement>
  );
};

export default forwardRef(IntrinsicElementWrapper);
