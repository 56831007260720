import { gql } from "@apollo/client";

export const USERS_QUERY = gql`
  query Users($where: UserWhereInput, $offset: Int, $limit: Int) {
    users(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        email
        nickname
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      email
      surname
      nickname
      address
      city
      zipcode
      phone
      fblink
      discord
      roles {
        role
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UserInput!) {
    updateUser(data: $data) {
      id
    }
  }
`;

export const SET_USER_ROLES_MUTATION = gql`
  mutation SetUserRoles($userId: Int!, $roles: [Role!]!) {
    setUserRoles(userId: $userId, roles: $roles) {
      id
    }
  }
`;
