import { useLazyQuery } from "@apollo/client";
import { random, range } from "lodash";
import Link from "next/link";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import Button from "base-components/Button";
import LocaleSelect from "base-components/LocaleSelect";
import LoginButton from "components/LoginButton";
import ProfileLinksTooltip, {
  ProfileLinks,
} from "components/ProfileLinksTooltip/ProfileLinksTooltip";
import SocialLinks from "components/SocialLinks/SocialLinks";
import { HEADER_LINK_GROUPS_QUERY } from "graphql/header";
import useDelayedLoading from "hooks/useDelayedLoading";
import useMatchingPath from "hooks/useMatchingPath";
import useScreenMediaQuery from "hooks/useScreenMediaQuery";
import useUser from "hooks/useUser";
import Logo from "svg/Logo";
import {
  HeaderLinkGroups,
  HeaderLinkGroupsVariables,
  HeaderLinkGroups_headerLinkGroups_data,
  SortOrder,
} from "types/graphql";
import styles from "./Header.module.scss";

const Header = () => {
  const [navToggled, setNavToggled] = useState(false);

  const [linkGroups, setLinkGroups] = useState<
    HeaderLinkGroups_headerLinkGroups_data[]
  >([]);

  const { authToken } = useUser();

  const [matchingLinkGroup, delocalizedPathname] = useMatchingPath(linkGroups);

  const isOnHomePage = delocalizedPathname === "/";

  const [
    fetchHeaderLinkGroups,
    { data, error, loading: loadingHeaderLinkGroups },
  ] = useLazyQuery<HeaderLinkGroups, HeaderLinkGroupsVariables>(
    HEADER_LINK_GROUPS_QUERY,
    {
      variables: {
        linksOrderBy: [{ order: SortOrder.asc }],
      },
    }
  );

  const loading = useDelayedLoading(loadingHeaderLinkGroups);

  // const [visible] = usePartnerLogoVisibility();

  useEffect(() => {
    fetchHeaderLinkGroups();
  }, [fetchHeaderLinkGroups]);

  useEffect(() => {
    if (data) {
      setLinkGroups(
        [...(data.headerLinkGroups.data ?? [])].sort(
          (groupA, groupB) => groupB.pathMatch.length - groupA.pathMatch.length
        )
      );
    }
  }, [data]);

  useEffect(() => {
    setNavToggled(false);
  }, [delocalizedPathname]);

  useEffect(() => {
    if (navToggled) {
      document.body.style.setProperty("overflow-y", "hidden");
    } else {
      document.body.style.removeProperty("overflow-y");
    }
  }, [navToggled]);

  const [breakpointMatches] = useScreenMediaQuery(
    "md",
    (breakpointMatches) => {
      if (breakpointMatches && navToggled) {
        setNavToggled(false);
      }
    },
    [navToggled]
  );

  const logos = (
    <div className={styles.logos}>
      <Link href="/">
        <a>
          <Logo className={styles.mainLogo} />
        </a>
      </Link>
      {/* {visible && (
        <a
          href="https://www.tipos.sk/"
          target="_blank"
          title="Tipos"
          rel="noopener"
        >
          <Image
            alt="Tipos"
            src="/img/tipos-logo.svg"
            height={32}
            width={114}
          />
        </a>
      )} */}
    </div>
  );

  return (
    <header className={styles.header} data-toggled={navToggled}>
      <nav className={styles.topNav}>
        {!isOnHomePage && logos}
        <SocialLinks className={styles.socialLinks} />
        <span className={styles.separator} />
        {authToken ? (
          breakpointMatches ? (
            <ProfileLinksTooltip className={styles.profileLinks} />
          ) : (
            <div className={styles.profileLinks}>
              <ProfileLinks />
            </div>
          )
        ) : breakpointMatches ? (
          <LoginButton />
        ) : (
          <div className={styles.profileLinks}>
            <LoginButton />
          </div>
        )}
        <span className={styles.separator} />
        <LocaleSelect className={styles.localeSelect} />
      </nav>
      <nav className={styles.bottomNav}>
        {isOnHomePage ? (
          logos
        ) : (
          <h2 className={styles.currentLinkGroupName}>
            {matchingLinkGroup?.name}
          </h2>
        )}
        <ul className={styles.links}>
          {error ? (
            <small>
              <FormattedMessage defaultMessage="Failed to load header data" />
            </small>
          ) : loading ? (
            range(5).map((index) => (
              <li key={`header-link-loading-${index}`}>
                <Skeleton width={`${random(6, 10)}ch`} count={1} />
              </li>
            ))
          ) : (
            matchingLinkGroup?.links?.map(
              ({ id, groupId, path, text, backgroundColor, icon }: any) => (
                <li
                  key={`header-link-group-${groupId}-link-${id}`}
                  data-button={Boolean(backgroundColor || icon)}
                >
                  <Link href={path}>
                    <a>
                      {backgroundColor || icon ? (
                        <Button
                          className={styles.linkButton}
                          icon={icon}
                          backgroundColor={backgroundColor}
                          htmlType="button"
                        >
                          {text}
                        </Button>
                      ) : (
                        text
                      )}
                    </a>
                  </Link>
                </li>
              )
            )
          )}
        </ul>
      </nav>
      <button
        className={styles.toggleButton}
        data-toggled={navToggled}
        onClick={() => setNavToggled(!navToggled)}
        type="button"
      >
        <div className={styles.toggleButtonLine1} />
        <div className={styles.toggleButtonLine2} />
        <div className={styles.toggleButtonLine3} />
      </button>
    </header>
  );
};

export default Header;
