import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { compact } from "lodash";
import {
  useMemo,
  ReactNode,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  forwardRef,
} from "react";
import FilePreview from "base-components/FilePreview/FilePreview";
import { getFormFieldRequiredAttribute } from "common/utils";
import { ClassNameArgument, FormFieldRequired, Size } from "types";
import styles from "./FormFileField.module.scss";

export type FormFileFieldProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "required" | "size"
> & {
  className?: ClassNameArgument;
  errorMessage?: ReactNode;
  inputText?: ReactNode;
  inputClassName?: ClassNameArgument;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  label?: ReactNode;
  required?: FormFieldRequired;
  onFileRemove?: (file: File) => void;
  size?: Size;
};

export const FormFileField = forwardRef<HTMLInputElement, FormFileFieldProps>(
  (
    {
      "aria-label": ariaLabel,
      className,
      children,
      errorMessage,
      inputText,
      inputClassName,
      label,
      multiple = false,
      id,
      name,
      onChange,
      onFileRemove,
      required = false,
      value = [],
      labelProps = {},
      size = "md",
      ...props
    },
    ref
  ) => {
    const files = useMemo(() => compact([value].flat()), [value]);

    const handleRemove = (file: any) => {
      if (onFileRemove) {
        onFileRemove(typeof file === "string" ? { name: file } : file);
      }
    };

    const labelValue = ariaLabel ?? label;
    const inputTextValue = inputText ?? labelValue;

    const htmlForId = id ?? name;
    const hasSingleFile = files?.length === 1;

    const cornerIcon = (
      <FontAwesomeIcon size="1x" icon={onFileRemove ? "times" : "file"} />
    );

    return (
      <div
        className={classNames(className, styles.fileField)}
        data-required={required}
        data-size={size}
        data-single-file={hasSingleFile}
        role="group"
      >
        <label className={styles.label} htmlFor={htmlForId} {...labelProps}>
          {labelValue && <span className={styles.labelText}>{labelValue}</span>}
          <input
            ref={ref}
            aria-label={ariaLabel}
            id={htmlForId}
            multiple={multiple}
            name={name}
            onChange={onChange}
            type="file"
            required={getFormFieldRequiredAttribute(required)}
            {...props}
          />
          <div className={classNames(inputClassName, styles.customInput)}>
            {hasSingleFile ? (
              <FilePreview
                key={`file-${name}-first`}
                as="div"
                file={files[0]}
                onRemove={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleRemove(files[0]);
                }}
              >
                {cornerIcon}
              </FilePreview>
            ) : (
              <span>+{inputTextValue}</span>
            )}
            {children}
          </div>
        </label>
        {errorMessage}
        {Array.isArray(files) && files?.length > 1 && (
          <ul>
            {files.map((file: any, index) => (
              <FilePreview
                key={`file-${name}-${file.name ?? file ?? index}`}
                as="li"
                file={file}
                onRemove={() => handleRemove(file)}
              >
                {cornerIcon}
              </FilePreview>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

export default FormFileField;
