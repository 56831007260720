import { useQuery } from "@apollo/client";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import { PermissionGuard } from "components/PermissionGuard/PermissionGuard";
import GamerIcon from "components/Vector/Icons/GamerIcon";
import { USER_INVITATIONS_QUERY } from "graphql/team";
import useAuth from "hooks/useAuth";
import styles from "./ProfileLinksTooltip.module.scss";

const NotificationsCount = () => {
  const [notifCount, setNotifCount] = useState(0);
  const { error, loading } = useQuery(USER_INVITATIONS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => setNotifCount(data?.userInvitations?.length ?? 0),
  });

  if (notifCount === 0) return null;

  return (
    <div className={styles.notification}>
      {loading ? <LoadingIndicatorInline error={error} /> : notifCount}
    </div>
  );
};

export const ProfileLinks = () => {
  const { logout } = useAuth();
  return (
    <>
      <PermissionGuard roles={["ADMIN", "EDITOR", "REDACTOR"]}>
        <Link href="/admin">
          <a>
            <Button
              backgroundColor="cool-gray-900"
              type="control"
              icon="unlock"
              htmlType="button"
            >
              Admin
            </Button>
          </a>
        </Link>
      </PermissionGuard>
      <Link href="/profile">
        <a>
          <Button
            backgroundColor="cool-gray-900"
            type="control"
            icon="user"
            htmlType="button"
          >
            Profil
            <NotificationsCount />
          </Button>
        </a>
      </Link>
      <Button
        backgroundColor="cool-gray-900"
        className={styles.hideOnDesktop}
        type="control"
        icon="user-astronaut"
        onClick={logout}
        htmlType="button"
      >
        <FormattedMessage defaultMessage="Logout" />
      </Button>
    </>
  );
};

const ProfileLinksTooltip = ({ className = "" }) => {
  const [toggled, setToggled] = useState(false);
  const wrapperRef = useRef(null);

  const router = useRouter();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        toggled &&
        (!event.key || event.key === "Escape")
      ) {
        setToggled(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    document.addEventListener("keyup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
      document.removeEventListener("keyup", handleClickOutside);
    };
  }, [wrapperRef, toggled, setToggled]);

  useEffect(() => {
    setToggled(false);
  }, [router.asPath]);

  return (
    <div ref={wrapperRef} className={`${styles.tooltipTarget} ${className}`}>
      <button
        className={styles.profile}
        onClick={() => setToggled(!toggled)}
        type="button"
      >
        <GamerIcon color="hsl(var(--color-cool-gray-900))" size={24} />
        <NotificationsCount />
      </button>
      <dialog className={styles.tooltip} data-toggled={toggled}>
        <ProfileLinks />
      </dialog>
    </div>
  );
};

ProfileLinksTooltip.propTypes = {
  className: PropTypes.string,
  onLogout: PropTypes.func,
};

export default ProfileLinksTooltip;
