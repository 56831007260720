import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import ContentWrapper from "base-components/ContentWrapper";
import SocialLinks from "components/SocialLinks/SocialLinks";
import Logo from "svg/Logo";
import styles from "./Footer.module.scss";

const Footer = () => {
  // const [visible] = usePartnerLogoVisibility();

  return (
    <footer className={styles.footer}>
      <ContentWrapper contentClassName={styles.content}>
        <section className={styles.logos}>
          <Logo className={styles.mainLogo} />
          {/* {visible && (
            <a
              className={styles.tiposLogo}
              href="https://www.tipos.sk/"
              title="Tipos"
              target="_blank"
              rel="noopener"
            >
              <small>Generálny partner</small>
              <Image
                alt="Tipos"
                src="/img/tipos-logo.svg"
                height={40}
                width={148}
              />
            </a>
          )} */}
        </section>
        <hr />
        <section className={styles.linksSection}>
          <h3 className={styles.linksSectionTitle}>Menu</h3>
          <ul className={styles.linksSectionLinks}>
            <li>
              <Link href="/articles?category=1">
                <a>Ešport</a>
              </Link>
            </li>
            <li>
              <Link href="/articles?category=2">
                <a>Hry</a>
              </Link>
            </li>
            <li>
              <Link href="/articles?category=3">
                <a>Tech</a>
              </Link>
            </li>
            <li>
              <Link href="/articles?category=4">
                <a>Iné</a>
              </Link>
            </li>
            <li>
              <Link href="/tournaments">
                <a>Herná zóna</a>
              </Link>
            </li>
          </ul>
        </section>
        <section className={styles.linksSection}>
          <h3 className={styles.linksSectionTitle}>Informácie</h3>
          <ul className={styles.linksSectionLinks}>
            <li>
              <Link href="/gdpr">
                <a>GDPR</a>
              </Link>
            </li>
            <li>
              <Link href="/rules">
                <a>Všeobecné pravidlá hier</a>
              </Link>
            </li>
            <li>
              <Link href="/career">
                <a>Kariéra</a>
              </Link>
            </li>
            <li>
              <Link href="/fps-testing">
                <a>FPS Testing</a>
              </Link>
            </li>
          </ul>
        </section>
        <section className={styles.linksSection}>
          <h3 className={styles.linksSectionTitle}>Kontakty</h3>
          <ul className={styles.linksSectionLinks}>
            <li>
              <a href="mailto:info@ygames.sk">
                <FontAwesomeIcon icon="envelope" /> info@ygames.sk
              </a>
            </li>
            <li>
              <a href="mailto:media@ygames.sk">
                <FontAwesomeIcon icon="envelope" /> media@ygames.sk
              </a>
            </li>
          </ul>
        </section>
        <hr />
        <SocialLinks className={styles.socialLinks} />
        <hr />
        <section className={styles.linksSection}>
          <small>Copyright &copy; {new Date().getUTCFullYear()} YZONE.SK</small>
          <a
            className={styles.webglobeLogo}
            href="https://wy.sk/"
            target="__blank"
            title="Webglobe"
          >
            <small>Hosted by</small>
            <Image
              alt="Webglobe"
              src="/img/webglobe-logo.svg"
              height={46}
              width={186}
            />
          </a>
          <small>Developed by UNIIT</small>
        </section>
      </ContentWrapper>
    </footer>
  );
};

export default Footer;
