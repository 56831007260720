import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useDefaultConsentScriptContent } from "@uniit/cookie-consent";
import { noop } from "lodash";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { FunctionComponent, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CookieBanner from "base-components/CookieBanner";
import { DEFAULT_RICH_TEXT_ELEMENTS } from "common/constants";
import { generatePathRegex, windowIsDefined } from "common/utils";
import Banner from "components/Banner";
import Footer from "components/Footer/Footer";
import Header from "components/Header";
import StatusMessages from "components/StatusMessages";
import {
  ApolloClientProvider,
  getApolloClientInstance,
} from "contexts/ApolloClientProvider";
import { AuthTokenProvider } from "contexts/AuthTokenContext";
import { FingerprintAgentProvider } from "contexts/FingerprintAgentContext";
import { PartnerLogoVisibilityProvider } from "contexts/PartnerLogoVisibility";
import { StatusMessagesProvider } from "contexts/StatusMessages";
import { VerificationEmailProvider } from "contexts/VerificationEmail";
import { REDIRECTS_QUERY } from "graphql/redirect";
import { DEFAULT_LOCALE_KEY, useLocaleKeyByValue, useMessages } from "i18n";
// eslint-disable-next-line import/order
import "@fortawesome/fontawesome-svg-core/styles.css";
import "styles/globals.scss";
import { Redirects } from "types/graphql";
// eslint-disable-next-line import/order
import { CookieConsentProvider } from "contexts/CookieConsentContext";

config.autoAddCss = false;
library.add(fab, fas, far);

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  const { asPath, locale } = useRouter();
  const consentScript = useDefaultConsentScriptContent();
  useEffect(() => {
    if (!windowIsDefined()) return;

    const storage = window?.sessionStorage;
    if (!storage) return;

    const prevPath = storage.getItem("currentPath") ?? "";
    storage.setItem("prevPath", prevPath);
    storage.setItem("currentPath", window.location.pathname);
  }, [asPath]);

  const localeKey = useLocaleKeyByValue(locale);
  const messages = useMessages(localeKey);

  /* Check and execute redirects*/
  useEffect(() => {
    const fetchRedirects = async () => {
      const apolloClient = getApolloClientInstance({
        locale: localeKey,
      });

      const response = await apolloClient.query<Redirects>({
        query: REDIRECTS_QUERY,
        fetchPolicy: "network-only",
      });

      const pathWithoutParams = asPath.split("?")[0];
      response.data.redirects.data.forEach((redirect) => {
        if (pathWithoutParams.toLowerCase() === redirect.url.toLowerCase()) {
          Router.push(redirect.redirectUrl);
        }
      });
    };

    fetchRedirects().catch(console.error);
  }, [asPath, localeKey]);

  const isIframePage = generatePathRegex("iframe").test(asPath);
  const isAdminPage = generatePathRegex("admin").test(asPath);

  const hideHeaderAndFooter = generatePathRegex(
    "change-password",
    "login",
    "registration",
    "reset-password",
    "verification",
    "tournaments/groupings"
  ).test(asPath);
  return (
    <IntlProvider
      locale={localeKey}
      messages={messages}
      onError={noop}
      defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
      defaultLocale={DEFAULT_LOCALE_KEY}
    >
      <StatusMessagesProvider>
        <AuthTokenProvider>
          <ApolloClientProvider>
            <FingerprintAgentProvider>
              <PartnerLogoVisibilityProvider initialValue={true}>
                <VerificationEmailProvider>
                  <CookieConsentProvider>
                    <SkeletonTheme
                      baseColor="hsl(var(--color-cool-gray-800))"
                      highlightColor="hsl(var(--color-cool-gray-900))"
                    >
                      {!isIframePage && !hideHeaderAndFooter && <Header />}
                      {!isIframePage && <Banner withWrapper />}
                      <Component {...pageProps} />
                      {!isIframePage &&
                        !isAdminPage &&
                        !hideHeaderAndFooter && <Footer />}
                      <Script id="cookie-consent">{consentScript}</Script>
                      <Script
                        id="gtag-source"
                        src="https://www.googletagmanager.com/gtag/js?id=G-EPFNN45FZJ"
                        async
                      />
                      <Script id="gtag">{`
                        window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-EPFNN45FZJ')
                      `}</Script>
                      <Script id="fbq-script">{`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?           
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};             
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';          
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '1472779280221462');
                        fbq('track', 'PageView');
                      `}</Script>
                      <CookieBanner />
                      <StatusMessages />
                    </SkeletonTheme>
                  </CookieConsentProvider>
                </VerificationEmailProvider>
              </PartnerLogoVisibilityProvider>
            </FingerprintAgentProvider>
          </ApolloClientProvider>
        </AuthTokenProvider>
      </StatusMessagesProvider>
    </IntlProvider>
  );
};

export default App;
