import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { AUTH_API_URL } from "common/constants";
import { useAuthToken } from "contexts/AuthTokenContext";
import { fetchWrapper } from "helpers";
import { useLocale } from "i18n";
import {
  LoginRequestBody,
  LoginResponseBody,
  RegisterRequestBody,
  RegisterResponseBody,
  VerifyEmailRequestBody,
  VerifyEmailResponseBody,
  ChangePasswordRequestBody,
  ChangePasswordResponseBody,
  ResendEmailVerificationEmailRequestBody,
  ResendEmailVerificationEmailResponseBody,
  ResetPasswordRequestBody,
  ResetPasswordResponseBody,
  VerifyPasswordResetRequestBody,
  VerifyPasswordResetResponseBody,
} from "types/auth-api";
import useUser from "./useUser";

const useAuth = () => {
  const { setToken } = useAuthToken();
  const [, locale] = useLocale();

  const { setSentryUser } = useUser();

  const router = useRouter();

  const headers = useMemo(
    () => ({
      "Accept-Language": locale,
      "Content-Type": "application/json",
    }),
    [locale]
  );

  const changePassword = useCallback(
    async (requestBody: ChangePasswordRequestBody) =>
      fetchWrapper.post<ChangePasswordRequestBody, ChangePasswordResponseBody>(
        `${AUTH_API_URL}/change-password`,
        requestBody,
        { headers }
      ),
    [headers]
  );

  const login = useCallback(
    async (requestBody: LoginRequestBody) => {
      const response = await fetchWrapper.post<
        LoginRequestBody,
        LoginResponseBody
      >(`${AUTH_API_URL}/login`, requestBody, { headers });

      if (response.token) {
        setToken(response.token);
      }

      return response;
    },
    [setToken, headers]
  );

  const logout = useCallback(() => {
    setToken(null);
    setSentryUser(null);

    router.push("/login");
  }, [setToken, setSentryUser, router]);

  const register = useCallback(
    async (requestBody: RegisterRequestBody) =>
      fetchWrapper.post<RegisterRequestBody, RegisterResponseBody>(
        `${AUTH_API_URL}/register`,
        requestBody,
        { headers }
      ),
    [headers]
  );

  const resendEmailVerificationEmail = useCallback(
    async (requestBody: ResendEmailVerificationEmailRequestBody) =>
      fetchWrapper.post<
        ResendEmailVerificationEmailRequestBody,
        ResendEmailVerificationEmailResponseBody
      >(`${AUTH_API_URL}/resend-email-verification-email`, requestBody, {
        headers,
      }),
    [headers]
  );

  const resetPassword = useCallback(
    async (requestBody: ResetPasswordRequestBody) =>
      fetchWrapper.post<ResetPasswordRequestBody, ResetPasswordResponseBody>(
        `${AUTH_API_URL}/reset-password`,
        requestBody,
        { headers }
      ),
    [headers]
  );

  const verifyEmail = useCallback(
    async (requestBody: VerifyEmailRequestBody) =>
      fetchWrapper.post<VerifyEmailRequestBody, VerifyEmailResponseBody>(
        `${AUTH_API_URL}/verify-email`,
        requestBody,
        { headers }
      ),
    [headers]
  );

  const verifyPasswordReset = useCallback(
    async (requestBody: VerifyPasswordResetRequestBody) =>
      fetchWrapper.post<
        VerifyPasswordResetRequestBody,
        VerifyPasswordResetResponseBody
      >(`${AUTH_API_URL}/verify-password-reset`, requestBody, { headers }),
    [headers]
  );

  return {
    changePassword,
    login,
    logout,
    register,
    resendEmailVerificationEmail,
    resetPassword,
    verifyEmail,
    verifyPasswordReset,
  };
};

export default useAuth;
