import { useMemo } from "react";
import { MessageFormatElement, useIntl } from "react-intl";
import { LocaleKey, LocaleValue } from "types";
import cs from "./compiled-messages/cs.json";
import en from "./compiled-messages/en.json";
import sk from "./compiled-messages/sk.json";

export type Messages = Record<string, MessageFormatElement[]>;

export const MESSAGES: Record<LocaleKey, Messages> = Object.freeze({
  [LocaleKey.cs]: cs,
  [LocaleKey.en]: en,
  [LocaleKey.sk]: sk,
});

/**
 * ISO standard defines locale for Czech as `cs`, and `react-intl` expects a
 * coinciding locale code. But in real world `cz` is more common code and has
 * stronger association with the Czech language. This is why we need to map ISO
 * format to the "common" format.
 */
export const LOCALE: Record<LocaleKey, LocaleValue> = Object.freeze({
  [LocaleKey.sk]: LocaleValue.sk,
  [LocaleKey.cs]: LocaleValue.cz,
  [LocaleKey.en]: LocaleValue.en,
});

export const LOCALE_EMOJI_FLAG: Readonly<Record<LocaleKey, string>> =
  Object.freeze({
    sk: "🇸🇰",
    cs: "🇨🇿",
    en: "🇬🇧",
  });

export const LOCALE_FULL_NAME: Readonly<Record<LocaleKey, string>> =
  Object.freeze({
    sk: "Slovenčina",
    cs: "Čeština",
    en: "English",
  });

export const LOCALE_KEYS = Object.keys(LOCALE) as LocaleKey[];
export const LOCALE_VALUES = Object.values(LOCALE);

export const DEFAULT_LOCALE: LocaleValue = LocaleValue.sk;
export const DEFAULT_LOCALE_KEY: LocaleKey = LocaleKey.sk;

export const LOCALE_LOCAL_STORAGE_KEY = "preferred-locale";

export const getLocaleKeyByValue = (locale: string) => {
  const localeIndex = LOCALE_VALUES.indexOf(locale as LocaleValue);
  return localeIndex > 0 ? LOCALE_KEYS[localeIndex] : DEFAULT_LOCALE_KEY;
};

export const getLocaleValueByKey = (locale: string) => {
  const localeIndex = LOCALE_KEYS.indexOf(locale as LocaleKey);
  return localeIndex > 0 ? LOCALE_VALUES[localeIndex] : DEFAULT_LOCALE;
};

export const useMessages = (locale: LocaleKey) => {
  const messages = useMemo(() => MESSAGES[locale], [locale]);
  return messages;
};

export const useLocaleKeyByValue = (locale?: string) =>
  useMemo(() => getLocaleKeyByValue(locale || DEFAULT_LOCALE), [locale]);

export const useLocale = (): [LocaleKey, LocaleValue] => {
  const { locale } = useIntl();
  return [locale as LocaleKey, LOCALE[locale as LocaleKey]];
};

export const useLocaleEmojiFlag = () => {
  const { locale } = useIntl();
  return LOCALE_EMOJI_FLAG[locale as LocaleKey];
};

export const useLocaleFullName = () => {
  const { locale } = useIntl();
  return LOCALE_FULL_NAME[locale as LocaleKey];
};
