import path from "path";
import mimeMatch from "mime-match";
import mime from "mime-types";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { FileIcon } from "react-file-icon";
import IntrinsicElementWrapper, {
  intrinsicElementsList,
} from "base-components/IntrinsicElementWrapper";
import { getFileName, getFilePath } from "common/utils";
import styles from "./FilePreview.module.scss";

const FilePreview = ({ as = "div", children, file, onRemove }) => {
  const fileName = useMemo(() => getFileName(file), [file]);

  const extension = useMemo(() => path.extname(fileName), [fileName]);
  const mimeType = useMemo(() => mime.lookup(fileName), [fileName]);

  const isImage = useMemo(() => mimeMatch(mimeType, "image/*"), [mimeType]);

  return (
    <IntrinsicElementWrapper as={as} className={styles.filePreview}>
      {typeof file === "string" && isImage && (
        <img alt="" src={getFilePath(file)} />
      )}
      <div className={styles.fileIcon}>
        <FileIcon extension={extension} />
      </div>
      <button className={styles.removeButton} onClick={onRemove} type="button">
        {children}
      </button>
      <span className={styles.fileName}>{fileName}</span>
    </IntrinsicElementWrapper>
  );
};

FilePreview.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList),
  children: PropTypes.node,
  file: PropTypes.any,
  onRemove: PropTypes.func,
};

export default FilePreview;
