import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ADMIN_MODULES } from "common/constants";
import { hasAccess } from "components/PermissionGuard";
import useUser from "hooks/useUser";

const useAdminRedirect = () => {
  const { authToken, user } = useUser();
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    setLoading(true);

    if (!authToken) router.push("/");
    if (!user) return;

    const { asPath } = router;

    const isInAdmin = asPath.startsWith(`/admin`);

    const currentModule = ADMIN_MODULES.find((module) =>
      asPath.startsWith(`/admin/${module.id}`)
    );

    if (
      isInAdmin &&
      (isEmpty(user?.roles) ||
        (currentModule && !hasAccess(user, currentModule.roles)))
    ) {
      router.push("/");
    }

    setLoading(false);
  }, [authToken, user, router, loading]);

  return loading;
};

export default useAdminRedirect;
