import { useRouter } from "next/router";
import { useMemo } from "react";

const useMatchingPath = <T extends { pathMatch: string; exact?: boolean }>(
  paths: T[]
): [match: T | undefined, path: string] => {
  const { locale, asPath } = useRouter();

  const delocalizedPathname = useMemo(
    () => asPath.replace(locale as string, "").replace("//", "/"),
    [locale, asPath]
  );

  const match = useMemo(
    () =>
      paths.find(({ pathMatch, exact }) =>
        new RegExp(`${exact ? "^" : ""}${pathMatch}${exact ? "$" : ""}`).test(
          delocalizedPathname
        )
      ),
    [paths, delocalizedPathname]
  );

  return [match, delocalizedPathname];
};

export default useMatchingPath;
