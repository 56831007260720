import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FunctionComponent, useEffect } from "react";
import { themeColor } from "common/utils";
import { StatusMessageType } from "contexts/StatusMessages";
import { ClassNameArgument, FontAwesomeIconName } from "types";
import styles from "./StatusMessage.module.scss";

const ICON_PROPS: Record<
  StatusMessageType,
  { icon: FontAwesomeIconName; color: string }
> = Object.freeze({
  error: {
    color: themeColor("lava-300"),
    icon: "triangle-exclamation",
  },
  success: {
    color: themeColor("india-green-300"),
    icon: "check",
  },
  warning: {
    color: themeColor("citrine-300"),
    icon: "triangle-exclamation",
  },
  info: {
    color: themeColor("blue-300"),
    icon: "circle-info",
  },
});

export type StatusMessageProps = {
  autoClose?: boolean;
  autoCloseTimeout?: number;
  className?: ClassNameArgument;
  open?: boolean;
  onClose?: () => void;
  type?: StatusMessageType;
};

const StatusMessage: FunctionComponent<StatusMessageProps> = ({
  autoClose = true,
  autoCloseTimeout = 4000,
  children,
  className,
  open: isOpen = false,
  onClose,
  type = "success",
}) => {
  useEffect(() => {
    if (autoClose) {
      setTimeout(
        () => onClose?.(),
        autoCloseTimeout + (type === "error" ? 2000 : 0)
      );
    }
  }, [autoClose, autoCloseTimeout, onClose, type]);

  return (
    <dialog
      className={classNames(className, styles.statusMessage)}
      data-type={type}
      open={isOpen}
      onClick={onClose}
    >
      <FontAwesomeIcon
        className={styles.statusMessageIcon}
        {...ICON_PROPS[type]}
      />
      {children}
    </dialog>
  );
};

export default StatusMessage;
