import * as Sentry from "@sentry/nextjs";
import {
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,
} from "./sentry.env";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  sampleRate: SENTRY_SAMPLE_RATE,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  initialScope: {
    user: null,
  },
});
