import {
  Agent as FingerprintAgent,
  load as loadFingerprintAgent,
} from "@fingerprintjs/fingerprintjs";
import { captureException } from "@sentry/nextjs";
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

export const FingerprintAgentContext = createContext<
  FingerprintAgent | undefined
>(undefined);

export const useFingerprintAgent = () => useContext(FingerprintAgentContext);

export const FingerprintAgentProvider: FunctionComponent = ({ children }) => {
  const [fingerprintAgent, setFingerprintAgent] = useState<FingerprintAgent>();

  useEffect(() => {
    loadFingerprintAgent({ monitoring: false })
      .then(setFingerprintAgent)
      .catch(captureException);
  }, []);

  return (
    <FingerprintAgentContext.Provider value={fingerprintAgent}>
      {children}
    </FingerprintAgentContext.Provider>
  );
};
