import classNames from "classnames";
import { useRouter } from "next/router";
import { ChangeEventHandler } from "react";
import FormField from "base-components/FormField";
import useScreenMediaQuery from "hooks/useScreenMediaQuery";
import {
  LOCALE,
  LOCALE_KEYS,
  LOCALE_EMOJI_FLAG,
  LOCALE_FULL_NAME,
  useLocale,
} from "i18n";
import { ClassNameArgument, FormFieldElementType, LocaleKey } from "types";
import styles from "./LocaleSelect.module.scss";

export type LocaleSelectProps = {
  className?: ClassNameArgument;
};

const LocaleSelect = ({ className }: LocaleSelectProps) => {
  const [currentLocaleKey] = useLocale();
  const [breakpointMatches] = useScreenMediaQuery("md");

  const router = useRouter();

  const handleChange: ChangeEventHandler<FormFieldElementType> = (event) => {
    const locale = event.target.value as LocaleKey;
    router.push(router.asPath, router.asPath, { locale: LOCALE[locale] });
  };

  return (
    <FormField
      as="select"
      id="locale-select-global"
      className={classNames(className, styles.select)}
      fieldWrapperClassName={styles.selectWrapper}
      onChange={handleChange}
      defaultValue={currentLocaleKey}
    >
      {LOCALE_KEYS.map((localeKey) => (
        <option key={`locale-${localeKey}`} value={localeKey}>
          {breakpointMatches
            ? LOCALE[localeKey].toUpperCase()
            : `${LOCALE_EMOJI_FLAG[localeKey]} ${LOCALE_FULL_NAME[localeKey]}`}
        </option>
      ))}
    </FormField>
  );
};

export default LocaleSelect;
