import { useQuery } from "@apollo/client";
import { setUser } from "@sentry/nextjs";
import { useCallback, useEffect } from "react";
import { getUserDisplayName } from "common/utils";
import { useAuthToken } from "contexts/AuthTokenContext";
import { CURRENT_USER_QUERY } from "graphql/user";
import { Role, CurrentUser, CurrentUser_currentUser } from "types/graphql";

const useUser = () => {
  const { token, setToken } = useAuthToken();

  const setSentryUser = useCallback((user: CurrentUser_currentUser | null) => {
    if (!user) {
      setUser(null);
    } else {
      setUser({
        id: user.id.toString(),
        email: user.email,
        username: getUserDisplayName(user, "Unknown"),
      });
    }
  }, []);

  const {
    loading,
    called,
    data,
    refetch: refetchUser,
  } = useQuery<CurrentUser>(CURRENT_USER_QUERY, {
    onError: () => {
      setToken(null);
    },
    onCompleted: (data) => {
      setSentryUser(data.currentUser);
    },
  });

  const hasRoles = useCallback(
    (roles: Role[]) =>
      data?.currentUser?.roles?.some(
        (role) => role?.role && roles.includes(role.role)
      ),
    [data?.currentUser?.roles]
  );

  useEffect(() => {
    if (called && !loading) {
      refetchUser();
    }
  }, [token, refetchUser, called, loading]);

  return {
    loading,
    hasRoles,
    user: data?.currentUser ?? null,
    authToken: token,
    setSentryUser,
  };
};

export default useUser;
