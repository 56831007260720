import { useEffect, useState, useRef } from "react";
import { SetState } from "types";

const DEFAULT_DELAY = 300;

const useDelayedLoading = (
  loading: boolean,
  delay = DEFAULT_DELAY,
  initialState = false
) => {
  const [delayedLoading, setDelayedLoading] = useState(initialState);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    const currentTimeout = timeoutRef.current;

    if (loading === false) {
      setDelayedLoading(false);
      window.clearTimeout(currentTimeout);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setDelayedLoading(loading);
      }, delay);
    }

    return () => {
      window.clearTimeout(currentTimeout);
    };
  }, [delay, loading]);

  return delayedLoading;
};

export const useDelayedLoadingState = (
  initialValue = false,
  delay = DEFAULT_DELAY
): [loading: boolean, setLoading: SetState<boolean>] => {
  const [loading, setLoading] = useState(initialValue);
  const delayedLoading = useDelayedLoading(loading, delay);

  return [delayedLoading, setLoading];
};

export default useDelayedLoading;
