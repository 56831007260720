import { noop } from "lodash";
import {
  FunctionComponent,
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useMemo,
} from "react";
import { useIntl } from "react-intl";

export type StatusMessageType = "success" | "error" | "warning" | "info";

export type StatusMessage = {
  id: string;
  content?: ReactNode;
  type: StatusMessageType;
  error?: any;
};

export type StatusErrorMessage = {
  id: string;
  content?: ReactNode;
  error: any;
  preferErrorMessage?: boolean;
};

export type StatusMessageDispatcher = (message: StatusMessage) => void;
export type StatusMessageErrorDispatcher = (
  message: StatusErrorMessage
) => void;

export type StatusMessagesContextType = {
  messages: StatusMessage[];
  dispatchMessage: StatusMessageDispatcher;
  dispatchError: StatusMessageErrorDispatcher;
  dispatchGenericError: (messageId: string) => void;
  removeMessage: StatusMessageDispatcher;
};

export type StatusMessageActionType = "add" | "remove" | "reset";

export type StatusMessageAction = {
  type: StatusMessageActionType;
  message: StatusMessage;
};

export type StatusMessagesReducer = (
  messages: StatusMessage[],
  action: StatusMessageAction
) => StatusMessage[];

export const StatusMessagesContext = createContext<StatusMessagesContextType>({
  messages: [],
  dispatchMessage: (FormattedMessage) => noop(FormattedMessage),
  dispatchError: (FormattedMessage) => noop(FormattedMessage),
  dispatchGenericError: (messageId) => noop(messageId),
  removeMessage: (FormattedMessage) => noop(FormattedMessage),
});

export const useStatusMessages = () => useContext(StatusMessagesContext);

export const StatusMessagesProvider: FunctionComponent = ({ children }) => {
  const [messages, dispatch] = useReducer<StatusMessagesReducer>(
    (messages, action) => {
      switch (action.type) {
        case "add": {
          if (messages.some((message) => message.id === action.message.id)) {
            return messages;
          }

          return [...messages, action.message];
        }

        case "remove": {
          return messages.filter((message) => message.id !== action.message.id);
        }

        case "reset": {
          return [];
        }

        default:
          throw Error("Unknown FormattedMessage action");
      }
    },
    []
  );

  const dispatchMessage: StatusMessageDispatcher = (message) => {
    dispatch({
      type: "add",
      message,
    });
  };

  const removeMessage: StatusMessageDispatcher = (message) => {
    dispatch({ type: "remove", message });
  };

  const intl = useIntl();

  const genericErrorMessage = useMemo(
    () =>
      intl.formatMessage({
        defaultMessage: "An error occurred. Try again later",
      }),
    [intl]
  );

  const dispatchError: StatusMessageErrorDispatcher = ({
    content,
    error,
    id,
    preferErrorMessage,
  }) => {
    const errorMessage: string | null =
      typeof error === "string"
        ? error
        : typeof error?.message === "string"
        ? error.message
        : null;

    const contentMessage = content ?? null;

    dispatch({
      type: "add",
      message: {
        id,
        type: "error",
        content: preferErrorMessage
          ? errorMessage ?? contentMessage ?? genericErrorMessage
          : contentMessage ?? errorMessage ?? genericErrorMessage,
      },
    });
  };

  const dispatchGenericError = (messageId: string) => {
    dispatchMessage({
      id: messageId,
      content: genericErrorMessage,
      type: "error",
    });
  };

  return (
    <StatusMessagesContext.Provider
      value={{
        messages,
        dispatchMessage,
        dispatchError,
        dispatchGenericError,
        removeMessage,
      }}
    >
      {children}
    </StatusMessagesContext.Provider>
  );
};
