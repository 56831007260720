import { kebabCase } from "lodash";
import { ReactNode } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import IntrinsicElementWrapper from "base-components/IntrinsicElementWrapper";
import {
  FontAwesomeIconName,
  IntrinsicElementName,
  RequiredEntityTranslations,
  Status,
  ThemeColor,
  ArticlePreviewPick,
} from "types";
import { Role, TournamentStatus } from "types/graphql";

export const FILE_API_URL = process.env.NEXT_PUBLIC_FILE_API_URL ?? "";
export const GRAPHQL_API_URL = process.env.NEXT_PUBLIC_GRAPHQL_API_URL ?? "";
export const AUTH_API_URL = process.env.NEXT_PUBLIC_AUTH_API_URL ?? "";
export const OEMBED_API_URL = process.env.NEXT_PUBLIC_OEMBED_API_URL ?? "";
export const FONT_AWESOME_API_URL = "https://api.fontawesome.com";

export const DEFAULT_DATE_FORMAT = "DD.MM.YY";

export const DEFAULT_TIME_FORMAT = "HH:mm";

export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

export const NA_TEXT = "N/A";

export const HTML_ESCAPE_CHARACTERS = Object.freeze({
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&apos;": "'",
  "&nbsp;": " ",
});

export const IMAGE_SIZE = Object.freeze({
  small: 400,
  medium: 720,
  large: 1280,
});

export const YOUTUBE_VIDEO_ID_REGEX =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const FILE_EXT_REGEX = /\.([-a-zA-Z0-9]+)$/;

export const ADMIN_MODULES: Readonly<
  {
    id: string;
    icon: FontAwesomeIconName;
    name: ReactNode;
    roles: Role[];
  }[]
> = Object.freeze([
  {
    id: "articles",
    icon: "newspaper",
    name: (
      <FormattedMessage
        defaultMessage="Articles"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN, Role.EDITOR, Role.REDACTOR],
  },
  {
    id: "tournaments",
    icon: "trophy",
    name: (
      <FormattedMessage
        defaultMessage="Tournaments"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "tournaments/groups",
    icon: "object-group",
    name: (
      <FormattedMessage
        defaultMessage="Tournament Groups"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "matchbook",
    icon: "book",
    name: (
      <FormattedMessage
        defaultMessage="Matchbook"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "currency",
    icon: "money-bill",
    name: (
      <FormattedMessage
        defaultMessage="Currencies"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "header",
    icon: "bars",
    name: (
      <FormattedMessage
        defaultMessage="Header"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "home-articles",
    icon: "thumbtack",
    name: (
      <FormattedMessage
        defaultMessage="Home Articles"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "home-events",
    icon: "location-pin",
    name: (
      <FormattedMessage
        defaultMessage="Home Events"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "projects",
    icon: "magic",
    name: (
      <FormattedMessage
        defaultMessage="Projects"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "custom-pages",
    icon: "columns",
    name: (
      <FormattedMessage
        defaultMessage="Custom Pages"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "general-rules",
    icon: "book",
    name: (
      <FormattedMessage
        defaultMessage="General Rules"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "media-gallery",
    icon: "images",
    name: (
      <FormattedMessage
        defaultMessage="Media Gallery"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "game-ids",
    icon: "gamepad",
    name: (
      <FormattedMessage
        defaultMessage="Game Ids"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN],
  },
  {
    id: "games",
    icon: "gamepad",
    name: (
      <FormattedMessage
        defaultMessage="Games"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "redirects",
    icon: "compass",
    name: (
      <FormattedMessage
        defaultMessage="Redirects"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "banners",
    icon: "minus-square",
    name: (
      <FormattedMessage
        defaultMessage="Banners"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "partners",
    icon: "building",
    name: (
      <FormattedMessage
        defaultMessage="Partners"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN],
  },
  {
    id: "roles",
    icon: "user-shield",
    name: (
      <FormattedMessage
        defaultMessage="User roles"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN],
  },
  {
    id: "color-palette",
    icon: "paint-roller",
    name: (
      <FormattedMessage
        defaultMessage="Color Palette"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN, Role.EDITOR, Role.REDACTOR],
  },
  {
    id: "tags",
    icon: "tags",
    name: (
      <FormattedMessage
        defaultMessage="Tags"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN, Role.EDITOR, Role.REDACTOR],
  },
  {
    id: "guests",
    icon: "user-tie",
    name: (
      <FormattedMessage
        defaultMessage="Guests"
        description="Admin panel navigation item"
      />
    ),
    roles: [Role.SUPERADMIN, Role.ADMIN, Role.EDITOR, Role.REDACTOR],
  },
]);

export const buildArticleURL = (article?: ArticlePreviewPick) =>
  `/article/${article?.id}-${kebabCase(article?.title || "")}`;

export const FONT_AWESOME_HINT = (
  <FormattedMessage
    defaultMessage="Font Awesome icon name. Learn more <a>here</a>."
    values={{
      a: (parts: string) => (
        <a
          href="https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free"
          rel="noopener"
        >
          {parts}
        </a>
      ),
    }}
  />
);

export const EXACT_MATCH_HINT = (
  <FormattedMessage
    defaultMessage="You can also use regular expressions to define a match rule. For example, <code>/(articles|projects)</code> would match any path that begins with <code>/articles</code> or <code>/projects</code>. You can read more about regular expression syntax <a>here</a>."
    values={{
      a: (parts: string) => (
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#writing_a_regular_expression_pattern"
          target="_blank"
          rel="noopener"
        >
          {parts}
        </a>
      ),
    }}
  />
);

type DefaultRichTextElements = IntlProvider["props"]["defaultRichTextElements"];

export const DEFAULT_RICH_TEXT_ELEMENTS: DefaultRichTextElements =
  Object.freeze({
    ...[
      "a",
      "b",
      "code",
      "em",
      "i",
      "mark",
      "u",
      "small",
      "strong",
      "sub",
      "sup",
    ].reduce(
      (tags, tag) => ({
        ...tags,
        [tag]: (parts) => (
          <IntrinsicElementWrapper as={tag as IntrinsicElementName}>
            {parts}
          </IntrinsicElementWrapper>
        ),
      }),
      {} as DefaultRichTextElements
    ),
    ...["lowercase", "uppercase", "capitalize"].reduce(
      (accumulatedCases, textCase) => ({
        ...accumulatedCases,
        [textCase]: (parts) => (
          <span {...{ [`data-${textCase}`]: true }}>{parts}</span>
        ),
      }),
      {} as DefaultRichTextElements
    ),
  });

export const TOURNAMENT_STATUS_COLORS: Record<TournamentStatus, ThemeColor> =
  Object.freeze({
    [TournamentStatus.REGISTRATION_OPEN]: "sea-green",
    [TournamentStatus.REGISTRATION_CLOSED]: "lava-500",
    [TournamentStatus.RUNNING]: "blue-500",
    [TournamentStatus.FINISHED]: "cool-gray-600",
    [TournamentStatus.LOCKED]: "orange-300",
  });

export const ROLE_STATUS: Record<Role, Status> = Object.freeze({
  ADMIN: "hazard",
  SUPERADMIN: "danger",
  EDITOR: "info",
  REDACTOR: "warning",
  TOURNAMENT: "success",
});

export const DEFAULT_ENTITY_TRANSLATIONS: RequiredEntityTranslations = {
  en: {
    DEFAULT: "Unknown entity",
    Article: "Article",
    Banner: "Banner",
    CareerApplication: "Career application",
    CareerPosition: "Career position",
    Currency: "Currency",
    File: "File",
    Game: "Game",
    GameId: "Game Id",
    GameMap: "Game map",
    GeneralRule: "General rule",
    HeaderLink: "Header link",
    HeaderLinkGroup: "Header link group",
    HomeArticle: "Home article",
    HomeEvent: "Home event",
    Laptop: "Laptop",
    Merchant: "Merchant",
    Partner: "Partner",
    Project: "Project",
    ProjectTranslation: "Project translation",
    ProjectSections: "Project Sections",
    ProjectVotingSectionVote: "Project Voting Section vote",
    ProjectCompetitionSectionSubmission:
      "Project Competition Section submission",
    Redirect: "Redirect",
    Team: "Team",
    Tournament: "Tournament",
    TournamentGroup: "Tournament group",
    TournamentGrouping: "Tournament grouping",
    TournamentGroupingValue: "Tournament grouping value",
    TournamentMatch: "Match",
    TournamentPrize: "Prize",
    TournamentStage: "Stage",
    TournamentTeam: "Tournament team",
    Matchbook: "Matchbook",
    User: "User",
    Tag: "Tag",
    Guest: "Guest",
    UserConsent: "User consent",
  },
  sk: {
    DEFAULT: "Neznáma entita",
    Article: "Článok",
    Banner: "Banner",
    CareerApplication: "Žiadosť o kariérnu pozíciu",
    CareerPosition: "Kariérna pozícia",
    Currency: "Mena",
    File: "Súbor",
    Game: "Hra",
    GameId: "Herné id",
    GameMap: "Herná mapa",
    GeneralRule: "Všeobecné pravidlo",
    HeaderLink: "Odkaz v záhlaví",
    HeaderLinkGroup: "Skupina odkazov hlavičky",
    HomeArticle: "Domovský článok",
    HomeEvent: "Domáca udalosť",
    Laptop: "Laptop",
    Merchant: "Obchodník",
    Partner: "Partner",
    Project: "Projekt",
    ProjectTranslation: "Preklad projektu",
    ProjectSections: "Projektové sekcie",
    ProjectVotingSectionVote: "Hlas projektovej sekcie",
    ProjectCompetitionSectionSubmission: "Podanie do projektovej súťaže",
    Redirect: "Presmerovanie",
    Team: "Tím",
    Tournament: "Turnaj",
    TournamentGroup: "Turnajová skupina",
    TournamentGrouping: "Turnajové zoskupenie",
    TournamentGroupingValue: "Hodnota zoskupenia turnajov",
    TournamentMatch: "Zápas",
    TournamentPrize: "Cena",
    TournamentStage: "Stage",
    TournamentTeam: "Turnajový tím",
    Matchbook: "Zápasovník",
    User: "Užívateľ",
    Tag: "Tag",
    Guest: "Hosť",
    UserConsent: "Nastavenia cookies",
  },
  cs: {
    DEFAULT: "Neznámá entita",
    Article: "Článek",
    Banner: "Banner",
    CareerApplication: "Žádost o kariérní pozici",
    CareerPosition: "Kariérní pozice",
    Currency: "Měna",
    File: "Soubor",
    Game: "Hra",
    GameId: "Herní Id",
    GameMap: "Herní mapa",
    GeneralRule: "Obecné pravidlo",
    HeaderLink: "Odkaz v záhlaví",
    HeaderLinkGroup: "Skupina odkazů záhlaví",
    HomeArticle: "Domovský článek",
    HomeEvent: "Domácí událost",
    Laptop: "Laptop",
    Merchant: "Obchodník",
    Partner: "Partner",
    Project: "Projekt",
    ProjectTranslation: "Překlad projektu",
    ProjectSections: "Projektové sekce",
    ProjectVotingSectionVote: "Hlas projektové sekce",
    ProjectCompetitionSectionSubmission: "Podání do projektové soutěže",
    Redirect: "Přesměrování",
    Team: "Tým",
    Tournament: "Turnaj",
    TournamentGroup: "Turnajová skupina",
    TournamentGrouping: "Turnajové seskupení",
    TournamentGroupingValue: "Hodnota seskupení turnajů",
    TournamentMatch: "Zápas",
    TournamentPrize: "Cena",
    TournamentStage: "Stage",
    TournamentTeam: "Turnajový tým",
    Matchbook: "Zápasovník",
    User: "Uživatel",
    Tag: "Tag",
    Guest: "Host",
    UserConsent: "Nastavení cookies",
  },
};

export const PROJECT_ARTICLES_LIMIT = 3;

export const TOURNAMENT_PAGE_TABS_SECTION_ID = "details";

export const MARKETING_COOKIE_CONSENT = "MARKETING_COOKIE_CONSENT";

export const GALLERY_CONSTANT = "gallery-item";
