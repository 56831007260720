import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deburr, kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styles from "./SocialLinks.module.scss";

const SOCIAL_LINKS = [
  {
    href: "https://www.facebook.com/priznanihracusobe",
    title: "Navštívte nás na Facebook",
    icon: <FontAwesomeIcon icon={["fab", "facebook"]} />,
  },
  {
    href: "https://www.instagram.com/ygames.sk/",
    title: "Navštívte nás na Instagram",
    icon: <FontAwesomeIcon icon={["fab", "instagram"]} />,
  },
  {
    href: "https://discord.gg/ygames-432799253090402305",
    title: "Navštívte nás na Discord",
    icon: <FontAwesomeIcon icon={["fab", "discord"]} />,
  },
  {
    href: "https://twitter.com/ygamesslovakia",
    title: "Navštívte nás na Twitter",
    icon: <FontAwesomeIcon icon={["fab", "twitter"]} />,
  },
  {
    href: "https://www.youtube.com/@Y-gamesSk",
    title: "Navštívte nás na YouTube",
    icon: <FontAwesomeIcon icon={["fab", "youtube"]} />,
  },
  {
    href: "https://www.twitch.tv/ygamessk",
    title: "Navštívte nás na Twitch",
    icon: <FontAwesomeIcon icon={["fab", "twitch"]} />,
  },
];

const SocialLinks = ({ className = "" }) => (
  <ul className={`${className} ${styles.socialLinks}`}>
    {SOCIAL_LINKS.map(({ href, icon, title }) => (
      <li
        key={`header-social-link-${kebabCase(deburr(title))}`}
        className={styles.socialLink}
      >
        <a href={href} target="__blank" title={title}>
          {icon}
        </a>
      </li>
    ))}
  </ul>
);

SocialLinks.propTypes = {
  className: PropTypes.string,
};

export default SocialLinks;
