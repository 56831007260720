import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { ReactNode, useEffect } from "react";
import { LoadingIndicator } from "base-components/LoadingIndicator";
import useAdminRedirect from "hooks/useAdminRedirect";
import useUser from "hooks/useUser";
import { CurrentUser_currentUser, Role } from "types/graphql";

export type RoutePermissionGuardProps = {
  children?: ReactNode;
  loading?: boolean;
  loadingFallback?: ReactNode;
  error?: any;
};

export type PermissionGuardProps = {
  children?: ReactNode;
  roles: Role[];
  redirect?: string;
};

export const hasAccess = (
  user?: CurrentUser_currentUser | null,
  roles?: Role[] | null
) =>
  user?.roles?.some(
    ({ role }) => roles?.includes(role) || role === Role.SUPERADMIN
  );

export const PermissionGuard = ({
  children,
  roles,
  redirect,
}: PermissionGuardProps) => {
  const router = useRouter();
  const { user } = useUser();

  useEffect(() => {
    if (redirect) {
      router.push(redirect);
    }
  }, [redirect, router]);

  if (hasAccess(user, roles)) return children;

  return null;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string,
  redirect: PropTypes.string,
};

export const RoutePermissionGuard = ({
  children,
  loading,
  loadingFallback,
  error,
}: RoutePermissionGuardProps) => {
  const adminLoading = useAdminRedirect();

  if (loading || adminLoading) {
    return <>{loadingFallback || <LoadingIndicator error={error} />}</>;
  }

  return <>{children}</>;
};
