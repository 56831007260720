import { gql } from "@apollo/client";

export const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam($team: TeamInput!) {
    createTeam(team: $team) {
      type
      message
    }
  }
`;

export const REGISTER_TEAM = gql`
  mutation RegisterTeam(
    $email: String
    $tournamentId: Int!
    $team: TournamentTeamInput!
  ) {
    registerTeam(email: $email, tournamentId: $tournamentId, team: $team) {
      type
      message
    }
  }
`;

export const RESOLVE_INVITATION = gql`
  mutation ResolveInvitation($id: Int!, $resolution: Boolean!) {
    resolveInvitation(id: $id, resolution: $resolution) {
      id
    }
  }
`;

export const USER_TEAMS_QUERY = gql`
  query UserTeams {
    userTeams {
      id
      ownerId
      name
      game {
        id
        name
      }
      logo
      players
    }
  }
`;

export const USER_TOURNAMENT_TEAMS_QUERY = gql`
  query UserTournamentTeams {
    userTournamentTeams {
      id
      createdAt
      name
      contact
      tournamentId
      tournament {
        id
        name
        startDate
        endDate
        capMax
        checkedInRegistrationsCount
        checkInStartTime
        checkInEndTime
        checkInStartTimePending
        checkInEndTimePending
        gameId
        publicMatches
        externalTournament
        game {
          id
          name
          gameId {
            id
            name
          }
        }
      }
      pending
      pendingPosition
      state
      confirmed
    }
  }
`;

export const USER_INVITATIONS_QUERY = gql`
  query UserInvitations {
    userInvitations {
      id
      team {
        id
        name
      }
    }
  }
`;
