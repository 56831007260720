import { useCookieConsent, MergedConsentState } from "@uniit/cookie-consent";
import { noop } from "lodash";
import { FunctionComponent, createContext, useContext } from "react";

export type CookieConsentContextType = [
  updateConsent: (consent?: MergedConsentState<{}> | undefined) => void,
  consentState: MergedConsentState<{}> | undefined
];

export const CookieConsentContext = createContext<CookieConsentContextType>([
  noop,
  undefined,
]);

export const useCookieConsentContext = () => useContext(CookieConsentContext);

export const CookieConsentProvider: FunctionComponent = ({ children }) => {
  const cookieConsent = useCookieConsent<{}>();

  return (
    <CookieConsentContext.Provider value={cookieConsent}>
      {children}
    </CookieConsentContext.Provider>
  );
};
