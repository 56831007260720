import { LoadingOutlined } from "@ant-design/icons";
import { captureException } from "@sentry/nextjs";
import { Spin } from "antd";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";
import StatusMessage from "base-components/StatusMessage/StatusMessage";
import styles from "./LoadingIndicator.module.scss";

export type LoadingIndicatorInlineProps = {
  error?: any;
};

export type LoadingIndicatorProps = {
  error?: any;
  retry?: () => void;
  timedOut?: boolean;
};

export const LoadingIndicatorInline = ({
  error,
}: LoadingIndicatorInlineProps) => {
  if (error) {
    console.error(error);
    captureException(error);

    return (
      <StatusMessage autoClose={false} open type="error">
        <FormattedMessage defaultMessage="An error occurred. Try again later" />
      </StatusMessage>
    );
  }

  return (
    <div aria-busy className={styles.inlineLoadingWrapper}>
      <Spin indicator={<LoadingOutlined spin />} />
    </div>
  );
};

export const LoadingIndicator = ({
  error,
  retry,
  timedOut,
}: LoadingIndicatorProps) => {
  if (error) {
    console.error(error);
    captureException(error);

    return (
      <section className={styles.loadingWrapper}>
        <section data-no-margin>
          <FormattedMessage defaultMessage="An error occurred while loading the page. Please try again later" />
          <br />
          <Link href="/">
            <a>
              <FormattedMessage defaultMessage="Back to home page" />
            </a>
          </Link>
        </section>
        <Button type="info" onClick={retry} htmlType="button">
          <FormattedMessage defaultMessage="Try again" />
        </Button>
      </section>
    );
  }

  if (timedOut) {
    return (
      <section className={styles.loadingWrapper}>
        <section data-no-margin>
          <FormattedMessage defaultMessage="Loading is taking too long. Please check your internet connection" />
        </section>
        <Button type="info" onClick={retry} htmlType="button">
          <FormattedMessage defaultMessage="Try again" />
        </Button>
      </section>
    );
  }

  return (
    <section aria-busy className={styles.loadingWrapper}>
      <Spin
        indicator={<LoadingOutlined className={styles.loadingIcon} spin />}
      />
    </section>
  );
};
