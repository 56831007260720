import { gql } from "@apollo/client";

export const BANNER_FRAGMENT = gql`
  fragment BannerParts on Banner {
    id
    image
    mobileImage
    validUntil
    pathMatch
    exact
    note
    link
    type
  }
`;

export const BANNER_QUERY = gql`
  query Banner($where: BannerWhereInput!) {
    banner(where: $where) {
      ...BannerParts
    }
  }
  ${BANNER_FRAGMENT}
`;

export const BANNERS_QUERY = gql`
  query Banners($where: BannerWhereInput, $offset: Int, $limit: Int) {
    banners(where: $where, offset: $offset, limit: $limit) {
      data {
        ...BannerParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${BANNER_FRAGMENT}
`;

export const CREATE_BANNER_MUTATION = gql`
  mutation CreateBanner($data: BannerInput!) {
    createBanner(data: $data) {
      id
    }
  }
`;

export const UPDATE_BANNER_MUTATION = gql`
  mutation UpdateBanner($where: BannerWhereUniqueInput!, $data: BannerInput!) {
    updateBanner(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_BANNER_MUTATION = gql`
  mutation DeleteBanner($where: BannerWhereUniqueInput!) {
    deleteBanner(where: $where) {
      id
    }
  }
`;
