import { noop } from "lodash";
import { FC, useContext, useState } from "react";
import { createStateContext } from "./StateContext";

export type PartnerLogoVisibilityProviderProps = {
  initialValue?: boolean;
};

export const PartnerLogoVisibilityContext = createStateContext<boolean>([
  false,
  (value) => noop(value),
]);

export const usePartnerLogoVisibility = () =>
  useContext(PartnerLogoVisibilityContext);

export const PartnerLogoVisibilityProvider: FC<
  PartnerLogoVisibilityProviderProps
> = ({ children, initialValue = false }) => {
  const visibilityState = useState(initialValue);

  return (
    <PartnerLogoVisibilityContext.Provider value={visibilityState}>
      {children}
    </PartnerLogoVisibilityContext.Provider>
  );
};
