import classNames from "classnames";
import { Field } from "formik";
import { FunctionComponent } from "react";
import styles from "./Switch.module.scss";

export type SwitchProps = {
  className: string;
  name: string;
};

const Switch: FunctionComponent<SwitchProps> = ({
  children,
  className,
  name,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <label className={styles.switch}>
        <Field type="checkbox" name={name} />
        <span className={styles.slider}></span>
      </label>
      {children}
    </div>
  );
};

export default Switch;
