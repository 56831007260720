export const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NODE_ENV;
export const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  "https://dcdff0fe76f24e23b92870baf487ff89@o1102110.ingest.sentry.io/6157075";
export const SENTRY_SAMPLE_RATE = Number.parseFloat(
  process.env.SENTRY_SAMPLE_RATE || "0.0"
);
export const SENTRY_TRACES_SAMPLE_RATE = Number.parseFloat(
  process.env.SENTRY_TRACES_SAMPLE_RATE || "0.0"
);
