import { MutationOptions, useMutation } from "@apollo/client";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import type { Argument } from "classnames";
import colorConvert from "color-convert";
import {
  AnchorHTMLAttributes,
  ChangeEventHandler,
  Dispatch,
  FocusEventHandler,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  SelectHTMLAttributes,
  SetStateAction,
  TextareaHTMLAttributes,
} from "react";
import { IntlShape } from "react-intl";
import ReactQuill from "react-quill";
import { AnySchema } from "yup";
import {
  TournamentMatchInput,
  CurrentUser_currentUser,
  ArticleParts,
} from "types/graphql";

/**
 * Recursive property types...
 * @see https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
 */
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

type Previous = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

export type NestedProperty<Type, Depth extends number = 10> = [Depth] extends [
  never
]
  ? never
  : Type extends object
  ? {
      [K in keyof Type]-?: K extends string | number
        ? `${K}` | Join<K, NestedProperty<Type[K], Previous[Depth]>>
        : never;
    }[keyof Type]
  : "";

export type LeafProperty<Type, Depth extends number = 10> = [Depth] extends [
  never
]
  ? never
  : Type extends object
  ? {
      [K in keyof Type]-?: Join<K, LeafProperty<Type[K], Previous[Depth]>>;
    }[keyof Type]
  : "";

/**
 * Credit goes to Aleksei Tsikov, author of the post:
 * @see https://dev.to/tipsy_dev/advanced-typescript-reinventing-lodash-get-4fhe
 */
export type PropertyType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ?
        | PropertyType<Exclude<T[Left], undefined>, Right>
        | Extract<T[Left], undefined>
    : undefined
  : P extends keyof T
  ? T[P]
  : undefined;

/** End of recursive property types. */

export type Primitive =
  | string
  | number
  | boolean
  | bigint
  | undefined
  | symbol
  | null;

export type PrimitiveValuesRecord<Depth extends number = 10> = [Depth] extends [
  never
]
  ? never
  : Record<
      string | number | symbol,
      Primitive | PrimitiveValuesRecord<Previous[Depth]>
    >;

/**
 * Using enums for locale keys and values to correspond to the GraphQL enum
 * types.
 */
export enum LocaleKey {
  sk = "sk",
  cs = "cs",
  en = "en",
}

export { Locale as LocaleValue } from "types/graphql";

export type FontAwesomeIconName = FontAwesomeIconProps["icon"];

export type ClassNameArgument = Argument;

export type IntrinsicElementName = keyof JSX.IntrinsicElements;

export type SetState<T> = Dispatch<SetStateAction<T>>;

export type QuillRef = RefObject<ReactQuill>;

export type TinyMCERef = RefObject<Editor>;

export type TwitterGlobal = {
  ready: (callback: (twitter: TwitterGlobal) => void) => void;
  events: any;
  widgets: {
    load: (...nodes: Node[]) => void;
  };
};

export type TwitterEmbedRequest = {
  url: string;
};

export type EmbedResponse = {
  url: string;
  html: string;
};

export type ScrollDirection = "up" | "down" | "off-range";

export type Size = "xs" | "sm" | "md" | "lg" | "xl";

export enum Breakpoint {
  sm = "35rem",
  md = "48rem",
  lg = "67.5rem",
  xl = "85.375rem",
  "2xl" = "100rem",
  "2.25xl" = "105rem",
  "3xl" = "120rem",
}

export type BreakpointKey = keyof typeof Breakpoint;

export type BreakpointCallback = (matches: boolean) => void;

export type ColorSpace = Exclude<
  keyof typeof colorConvert,
  "keyword" | `ansi${string}`
>;

export type Color =
  | "cool-gray"
  | "gray"
  | "auro-metal-saurus"
  | "india-green"
  | "sea-green"
  | "lava"
  | "sinopia"
  | "orange"
  | "blue"
  | "citrine";

export type ColorScale =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 950;

export type ThemeColor =
  | "current"
  | "black"
  | "white"
  | Color
  | `${Color}-${ColorScale}`;

export type ThemeColorValue = {
  baseName: Color;
  name: ThemeColor;
  scale: ColorScale | null;
  value: string;
};

export type BorderRadius = "xs" | "sm" | "md" | "lg" | "xl";

export type Mutation = Parameters<typeof useMutation>["0"];

export type RefetchQueries<
  ResultType = any,
  VariablesType = any
> = MutationOptions<ResultType, VariablesType>["refetchQueries"];

export enum BoardingDateType {
  ASAP = "ASAP",
  DATE = "Select date",
}

export type FormFileValue = string[] | string | null;

export type PatchFormFileFields<
  T,
  P extends keyof T,
  Required extends boolean = false
> = Omit<T, P> &
  (Required extends true
    ? {
        [K in P]: FormFileValue;
      }
    : {
        [K in P]?: FormFileValue;
      });

export type TournamentMatchFormValues = PatchFormFileFields<
  TournamentMatchInput,
  "printScreen"
>;

export type FormFieldRequired = boolean | "phony";

export type FormFieldElementType = HTMLInputElement &
  HTMLSelectElement &
  HTMLTextAreaElement;

export type FormFieldType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tags"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export type FormFieldElementAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement> &
    SelectHTMLAttributes<HTMLSelectElement> &
    TextareaHTMLAttributes<HTMLTextAreaElement>,
  "type" | "required"
> & {
  type?: FormFieldType;
  required?: FormFieldRequired;
};

export type FormFieldChangeHandler = ChangeEventHandler<FormFieldElementType>;
export type FormFieldFocusHandler = FocusEventHandler<FormFieldElementType>;

export type PageInfo = {
  totalCount: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
};

export type PaginationAndFilterVariables = {
  where?: {
    searchString?: string | null;
  } | null;
  offset?: number | null;
  limit?: number | null;
  skip?: number | null;
  take?: number | null;
};

export type FormatTimeParams = {
  date?: moment.MomentInput;
  dateFormat?: string;
  time?: moment.MomentInput;
  timeFormat?: string;
};

export type UserNameField = Partial<
  Pick<CurrentUser_currentUser, "name" | "surname" | "nickname" | "email">
>;

export type UserNamePickFromList = (keyof UserNameField)[];

export type Entity = {
  Article: "Article";
  Banner: "Banner";
  CareerApplication: "Career application";
  CareerPosition: "Career position";
  Currency: "Currency";
  File: "File";
  Game: "Game";
  GameId: "GameId";
  GameMap: "Game map";
  GeneralRule: "General rule";
  HeaderLink: "Header link";
  HeaderLinkGroup: "Header link group";
  HomeArticle: "Home article";
  HomeEvent: "Home event";
  Laptop: "Laptop";
  Merchant: "Merchant";
  Partner: "Partner";
  Project: "Project";
  ProjectTranslation: "ProjectTranslation";
  ProjectSections: "ProjectSections";
  ProjectVotingSectionVote: "ProjectVotingSectionVote";
  ProjectCompetitionSectionSubmission: "ProjectCompetitionSectionSubmission";
  Redirect: "Redirect";
  Team: "Team";
  Tournament: "Tournament";
  TournamentGroup: "Tournament group";
  TournamentGrouping: "Tournament grouping";
  TournamentGroupingValue: "Tournament grouping value";
  TournamentMatch: "Match";
  TournamentPrize: "Prize";
  TournamentStage: "Stage";
  TournamentTeam: "Tournament team";
  Matchbook: "Matchbook";
  User: "User";
  Tag: "Tag";
  Guest: "Guest";
  UserConsent: "User consent";
};

export type EntityKey = keyof Entity;

export type EntityTranslation = {
  DEFAULT: string;
} & {
  [Entity in EntityKey]?: string;
};

export type EntityTranslations = {
  [Key in LocaleKey]?: EntityTranslation;
};

export type RequiredEntityTranslations = {
  [Key in LocaleKey]: Required<EntityTranslation>;
};

export type Casing =
  | "upper"
  | "lower"
  | "title"
  | "kebab"
  | "snake"
  | "upper-snake";

export type EntityIntlFormatOptions = {
  casing?: Casing;
};

export type EntityIntl = {
  format: (
    entity?: EntityKey | null,
    values?: Record<string, string | number> | null,
    options?: EntityIntlFormatOptions | null
  ) => string;
};

export type ValidationSchema = (intl: IntlShape) => AnySchema;

export type PredefinedMessage<Values> = (
  intl: IntlShape,
  values?: Values
) => string;

export type ComparisonOption = "eq" | "ne" | "gt" | "gte" | "lt" | "lte";

export type Status =
  | "success"
  | "danger"
  | "warning"
  | "neutral"
  | "info"
  | "hazard";

export type InfoListItem = {
  title?: string | null;
  text?: string | null;
};

export enum ProjectPredefinedSections {
  matches = "matches",
  stages = "stages",
  tournaments = "tournaments",
  articles = "articles",
  questionsAndAnswers = "questionsAndAnswers",
  partners = "partners",
  contact = "contact",
}

export type AnchorTarget = AnchorHTMLAttributes<HTMLAnchorElement>["target"];

export type TagsFieldProps<TagType> = InputHTMLAttributes<HTMLInputElement> & {
  className?: ClassNameArgument;
  displaySuggestionsOnFocus?: boolean;
  label?: ReactNode;
  name: string;
  searchProperty?: NestedProperty<TagType, 4>;
  suggestions?: TagType[];
  suggestionsOnly?: boolean;
  onTagsChange?: (tags: TagType[]) => void;
  uniqueTags?: boolean;
};

export type ArticlePreviewPick = Pick<
  ArticleParts,
  "id" | "title" | "mainImage" | "tag" | "subtitle" | "publishedAt" | "tags"
>;
