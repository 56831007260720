import classNames from "classnames";
import { FunctionComponent } from "react";
import IntrinsicElementWrapper from "base-components/IntrinsicElementWrapper";
import { ClassNameArgument, ThemeColor, IntrinsicElementName } from "types";
import styles from "./ContentWrapper.module.scss";

export type ContentWrapperProps = {
  as?: IntrinsicElementName;
  backgroundColor?: ThemeColor;
  className?: ClassNameArgument;
  contentAs?: IntrinsicElementName;
  contentClassName?: ClassNameArgument;
  fillScreen?: boolean;
  id?: string;
  noPadding?: boolean;
  type?: "regular" | "article" | "form";
};

const ContentWrapper: FunctionComponent<ContentWrapperProps> = ({
  as = "div",
  backgroundColor,
  children,
  className,
  contentAs = "section",
  contentClassName,
  fillScreen = false,
  id,
  noPadding = false,
  type = "regular",
}) => (
  <IntrinsicElementWrapper
    as={as}
    className={classNames(className, styles.contentWrapper)}
    data-background-color={backgroundColor}
    data-fill-screen={fillScreen}
    data-no-padding={noPadding}
    data-type={type}
    id={id}
  >
    <IntrinsicElementWrapper
      as={contentAs}
      className={classNames(contentClassName, styles.content)}
    >
      {children}
    </IntrinsicElementWrapper>
  </IntrinsicElementWrapper>
);

export default ContentWrapper;
