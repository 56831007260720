import { gql } from "@apollo/client";

export const REDIRECTS_QUERY = gql`
  query Redirects($where: RedirectWhereInput, $offset: Int, $limit: Int) {
    redirects(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        url
        redirectUrl
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const CREATE_REDIRECT_MUTATION = gql`
  mutation CreateRedirect($data: RedirectInput!) {
    createRedirect(data: $data) {
      id
    }
  }
`;

export const DELETE_REDIRECT_MUTATION = gql`
  mutation DeleteRedirect($where: RedirectWhereUniqueInput!) {
    deleteRedirect(where: $where) {
      id
    }
  }
`;
