import PropTypes from "prop-types";
import React from "react";

const Logo = ({ className = "" }) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="468.09 1774.62 3063.62 452.78"
  >
    {" "}
    <path
      fill="#FFF"
      strokewidth="0px"
      className="cls-1"
      d="M594.79,1774.62h-126.7l182.95,294.25v158.53h117.6v-174.08l-173.85-278.7ZM814.78,1774.62l-82.98,133.48,63.68,102.08,146.44-235.56h-127.14ZM1340.18,2054.94l-170.78-107.86M3425.3,1947.08h-211.19c-14.89,0-26.96-12.07-26.96-26.96v-10.49c0-14.89,12.07-26.97,26.96-26.97h316.1c0-29.78-12.07-56.75-31.59-76.27-19.52-19.52-46.48-31.59-76.27-31.59h-236.66c-58.77,0-106.41,47.64-106.41,106.41v67.33c0,58.77,47.64,106.41,106.41,106.41h211.19c14.89,0,26.96,12.07,26.96,26.96v10.49c0,14.89-12.07,26.96-26.96,26.96h-316.1c0,29.78,12.08,56.75,31.6,76.27s46.48,31.59,76.27,31.59h236.65c58.77,0,106.41-47.64,106.41-106.4v-67.33c0-58.77-47.64-106.41-106.41-106.41ZM2736.22,1947.08v107.86h209.73v-107.86h-209.73ZM3013.37,1882.84v-108.22h-450.92v452.78h450.92v-108.22h-343.06v-236.34h343.06ZM2431.93,1774.82h0s-57.8,0-57.8,0l-118.46,229.39-118.47-229.39h-57.81s-50.05,0-50.05,0v452.39h107.86v-228.76l35.37,68.49,25.34,49.08,56.59,109.52.85,1.66h.63l.85-1.66,56.59-109.52,25.34-49.08,35.37-68.49h.01s0,228.76,0,228.76h107.86v-452.39h-50.06ZM1796.43,1774.82h-115.5l-172.94,452.39h115.46l62.78-164.18,4.23-11.1,48.22-126.11,23.85,62.36,24.38,63.75h-48.75l-41.24,107.86h131.2l25.79,67.42h115.46l-172.94-452.39ZM1448.04,2001.02v-53.94h-278.64l170.78,107.86h.01v46.45c0,9.92-8.04,17.97-17.97,17.97h-200.75c-9.92,0-17.97-8.05-17.97-17.97v-200.75c0-9.93,8.05-17.98,17.97-17.98h325.09v-107.86h-344.63c-58.71,0-106.3,47.59-106.3,106.29v239.84c0,58.71,47.59,106.3,106.3,106.3h239.84c58.7,0,106.29-47.59,106.29-106.3v-119.91h-.02Z"
    ></path>{" "}
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
