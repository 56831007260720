import { noop } from "lodash";
import { useRouter } from "next/router";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { createStateContext } from "./StateContext";

export const VerificationEmailContext = createStateContext<string>([
  "",
  (email) => noop(email),
]);

export const useVerificationEmail = () => useContext(VerificationEmailContext);

export const useVerificationEmailRouterQueryEffect = () => {
  const [email, setEmail] = useVerificationEmail();

  const router = useRouter();
  const queryEmail = router.query?.email as string;

  useEffect(() => {
    if (!email && queryEmail) setEmail(queryEmail);
  }, [email, setEmail, queryEmail]);

  return queryEmail;
};

export const VerificationEmailProvider: FunctionComponent = ({ children }) => {
  const emailState = useState("");

  return (
    <VerificationEmailContext.Provider value={emailState}>
      {children}
    </VerificationEmailContext.Provider>
  );
};
