import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Link, { LinkProps } from "next/link";
import { FunctionComponent, MouseEvent } from "react";
import { AnchorTarget, ClassNameArgument, FontAwesomeIconName } from "types";
import styles from "./AnchorLink.module.scss";

export type AnchorLinkProps = LinkProps & {
  className?: ClassNameArgument;
  icon?: FontAwesomeIconName | null;
  noUnderline?: boolean;
  inheritColor?: boolean;
  target?: AnchorTarget;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

const AnchorLink: FunctionComponent<AnchorLinkProps> = ({
  className,
  children,
  noUnderline,
  icon,
  inheritColor,
  target,
  onClick,
  ...props
}) => (
  <Link {...props}>
    <a
      className={classNames(className, styles.link)}
      data-no-underline={noUnderline}
      data-inherit-color={inheritColor}
      target={target}
      onClick={onClick}
    >
      {icon ? (
        <>
          <FontAwesomeIcon icon={icon} />
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </a>
  </Link>
);

export default AnchorLink;
