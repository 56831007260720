/* eslint-disable @next/next/no-img-element */
import { useQuery } from "@apollo/client";
import Link from "next/link";
import { Fragment, useState } from "react";
import ContentWrapper from "base-components/ContentWrapper";
import { getFilePath } from "common/utils";
import { BANNERS_QUERY } from "graphql/banner";
import useDelayedLoading from "hooks/useDelayedLoading";
import useMatchingPath from "hooks/useMatchingPath";
import {
  Banners,
  BannersVariables,
  Banners_banners_data,
  BannerType,
} from "types/graphql";
import styles from "./Banner.module.scss";

const LINK_PATTERN = /^((http|https|ftp):\/\/)/;

export type BannerProps = {
  type?: BannerType;
  withWrapper?: boolean;
};

const Banner = ({ type = BannerType.TOP, withWrapper }: BannerProps) => {
  const [banners, setBanners] = useState<Banners_banners_data[]>([]);

  const { loading } = useQuery<Banners, BannersVariables>(BANNERS_QUERY, {
    variables: {
      where: {
        type,
        checkValidity: true,
      },
    },
    onCompleted: (data) => {
      setBanners(data?.banners.data ?? []);
    },
  });

  const delayedLoading = useDelayedLoading(loading);
  const [matchingBanner] = useMatchingPath(banners);

  if (!matchingBanner || delayedLoading) return null;

  const { mobileImage, image, link } = matchingBanner;

  const Wrapper = withWrapper ? ContentWrapper : Fragment;

  const picture = (
    <picture>
      <source media="(min-width: 35rem)" srcSet={getFilePath(image)} />
      <img
        alt="Banner"
        className={styles.banner}
        src={mobileImage ? getFilePath(mobileImage) : getFilePath(image)}
      />
    </picture>
  );

  if (link && LINK_PATTERN.test(link)) {
    return (
      <Wrapper>
        <a href={link} target="__blank">
          {picture}
        </a>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Link href={link ?? "#"}>
        <a>{picture}</a>
      </Link>
    </Wrapper>
  );
};

export default Banner;
