const handleResponse = async (response: Response) => {
  const text = await response.text();

  if (!response.ok) {
    try {
      return Promise.reject(JSON.parse(text));
    } catch (error: any) {
      return Promise.reject(text);
    }
  }

  return text;
};

const post = async <Request extends {}, Response extends {}>(
  url: string,
  body: Request,
  options: RequestInit = {}
) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  });

  const data = await handleResponse(response);

  try {
    return JSON.parse(data) as Response;
  } catch (error: any) {
    return data as unknown as Response;
  }
};

export const fetchWrapper = {
  post,
};
