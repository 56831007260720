import { gql } from "@apollo/client";

const HEADER_LINK_FRAGMENT = gql`
  fragment HeaderLinkParts on HeaderLink {
    id
    createdAt
    updatedAt
    backgroundColor
    icon
    order
    path
    groupId
    text
  }
`;

const HEADER_LINK_GROUP_FRAGMENT = gql`
  fragment HeaderLinkGroupParts on HeaderLinkGroup {
    id
    createdAt
    updatedAt
    pathMatch
    name
    exact
    links(where: $linksWhere, orderBy: $linksOrderBy) {
      ...HeaderLinkParts
    }
  }
  ${HEADER_LINK_FRAGMENT}
`;

export const HEADER_LINKS_QUERY = gql`
  query HeaderLinks(
    $where: HeaderLinkWhereInput
    $orderBy: [HeaderLinkOrderByInput!]
    $offset: Int
    $limit: Int
  ) {
    headerLinks(
      where: $where
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      data {
        ...HeaderLinkParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${HEADER_LINK_FRAGMENT}
`;

export const HEADER_LINK_QUERY = gql`
  query HeaderLink($where: HeaderLinkWhereInput!) {
    headerLink(where: $where) {
      ...HeaderLinkParts
    }
  }
  ${HEADER_LINK_FRAGMENT}
`;

export const HEADER_LINK_GROUP_QUERY = gql`
  query HeaderLinkGroup(
    $where: HeaderLinkGroupWhereInput!
    $linksWhere: HeaderLinkWhereInput
    $linksOrderBy: [HeaderLinkOrderByInput!]
  ) {
    headerLinkGroup(where: $where) {
      ...HeaderLinkGroupParts
    }
  }
  ${HEADER_LINK_GROUP_FRAGMENT}
`;

export const HEADER_LINK_GROUPS_QUERY = gql`
  query HeaderLinkGroups(
    $where: HeaderLinkGroupWhereInput
    $offset: Int
    $limit: Int
    $linksWhere: HeaderLinkWhereInput
    $linksOrderBy: [HeaderLinkOrderByInput!]
  ) {
    headerLinkGroups(where: $where, offset: $offset, limit: $limit) {
      data {
        ...HeaderLinkGroupParts
      }
      pageInfo {
        totalCount
      }
    }
  }
  ${HEADER_LINK_GROUP_FRAGMENT}
`;

export const DELETE_HEADER_LINK_MUTATION = gql`
  mutation DeleteHeaderLink($where: HeaderLinkWhereUniqueInput!) {
    deleteHeaderLink(where: $where) {
      id
    }
  }
`;

export const DELETE_HEADER_LINK_GROUP_MUTATION = gql`
  mutation DeleteHeaderLinkGroup($where: HeaderLinkGroupWhereUniqueInput!) {
    deleteHeaderLinkGroup(where: $where) {
      id
    }
  }
`;

export const CREATE_HEADER_LINK_MUTATION = gql`
  mutation CreateHeaderLink($data: HeaderLinkInput!) {
    createHeaderLink(data: $data) {
      id
    }
  }
`;

export const UPDATE_HEADER_LINK_MUTATION = gql`
  mutation UpdateHeaderLink(
    $where: HeaderLinkWhereUniqueInput!
    $data: HeaderLinkInput!
  ) {
    updateHeaderLink(where: $where, data: $data) {
      id
    }
  }
`;

export const CREATE_HEADER_LINK_GROUP_MUTATION = gql`
  mutation CreateHeaderLinkGroup($data: HeaderLinkGroupInput!) {
    createHeaderLinkGroup(data: $data) {
      id
    }
  }
`;

export const UPDATE_HEADER_LINK_GROUP_MUTATION = gql`
  mutation UpdateHeaderLinkGroup(
    $where: HeaderLinkGroupWhereUniqueInput!
    $data: HeaderLinkGroupInput!
  ) {
    updateHeaderLinkGroup(where: $where, data: $data) {
      id
    }
  }
`;
