/* eslint-disable import/named */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { FunctionComponent, useMemo } from "react";
import { GRAPHQL_API_URL } from "common/constants";
import { useLocale } from "i18n";
import { useAuthToken } from "./AuthTokenContext";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type GetApolloClientParams = {
  forceNewInstance?: boolean;
  token?: string | null;
  locale?: string;
};

export const getApolloClientInstance = (
  { forceNewInstance, token, locale = "sk" }: GetApolloClientParams = {
    locale: "sk",
  }
) => {
  if (apolloClient && !forceNewInstance) return apolloClient;

  const httpLink = createHttpLink({
    uri: GRAPHQL_API_URL,
    credentials: "same-origin",
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ?? "",
      "Accept-Language": locale,
    },
  }));

  apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    cache: new InMemoryCache(),
  });

  return apolloClient;
};

export const useApolloClient = () => {
  const { token } = useAuthToken();
  const [, locale] = useLocale();

  const client = useMemo(
    () =>
      getApolloClientInstance({
        token,
        locale,
        forceNewInstance: true,
      }),
    [token, locale]
  );

  return client;
};

export const ApolloClientProvider: FunctionComponent = ({ children }) => {
  const client = useApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
