/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Articles
// ====================================================

export interface Articles_articles_data_author {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface Articles_articles_data_observers {
  __typename: "User";
  id: number;
}

export interface Articles_articles_data_mainImageFile {
  __typename: "File";
  sourceTitle: string | null;
  sourceURL: string | null;
}

export interface Articles_articles_data_subcategories {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
}

export interface Articles_articles_data_categories {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface Articles_articles_data {
  __typename: "Article";
  id: number;
  author: Articles_articles_data_author | null;
  observers: Articles_articles_data_observers[] | null;
  userId: number | null;
  title: string | null;
  subtitle: string | null;
  text: string | null;
  mainImage: string | null;
  mainImageFile: Articles_articles_data_mainImageFile | null;
  publishedAt: any | null;
  createdAt: any;
  tag: string | null;
  tags: string[] | null;
  subcategories: Articles_articles_data_subcategories[] | null;
  categories: Articles_articles_data_categories[] | null;
  uniqueVisitCount: number;
  pageLoadCount: number;
}

export interface Articles_articles_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Articles_articles {
  __typename: "PaginatedArticles";
  data: Articles_articles_data[];
  pageInfo: Articles_articles_pageInfo;
}

export interface Articles {
  articles: Articles_articles;
}

export interface ArticlesVariables {
  where?: ArticleWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: ArticleOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MostReadArticles
// ====================================================

export interface MostReadArticles_mostReadArticles_data_author {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface MostReadArticles_mostReadArticles_data_observers {
  __typename: "User";
  id: number;
}

export interface MostReadArticles_mostReadArticles_data_mainImageFile {
  __typename: "File";
  sourceTitle: string | null;
  sourceURL: string | null;
}

export interface MostReadArticles_mostReadArticles_data_subcategories {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
}

export interface MostReadArticles_mostReadArticles_data_categories {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface MostReadArticles_mostReadArticles_data {
  __typename: "Article";
  id: number;
  author: MostReadArticles_mostReadArticles_data_author | null;
  observers: MostReadArticles_mostReadArticles_data_observers[] | null;
  userId: number | null;
  title: string | null;
  subtitle: string | null;
  text: string | null;
  mainImage: string | null;
  mainImageFile: MostReadArticles_mostReadArticles_data_mainImageFile | null;
  publishedAt: any | null;
  createdAt: any;
  tag: string | null;
  tags: string[] | null;
  subcategories: MostReadArticles_mostReadArticles_data_subcategories[] | null;
  categories: MostReadArticles_mostReadArticles_data_categories[] | null;
  uniqueVisitCount: number;
  pageLoadCount: number;
}

export interface MostReadArticles_mostReadArticles {
  __typename: "PaginatedArticles";
  data: MostReadArticles_mostReadArticles_data[];
}

export interface MostReadArticles {
  mostReadArticles: MostReadArticles_mostReadArticles;
}

export interface MostReadArticlesVariables {
  visitDayLimit?: number | null;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleSelect
// ====================================================

export interface ArticleSelect_articles_data {
  __typename: "Article";
  id: number;
  title: string | null;
  mainImage: string | null;
}

export interface ArticleSelect_articles_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface ArticleSelect_articles {
  __typename: "PaginatedArticles";
  data: ArticleSelect_articles_data[];
  pageInfo: ArticleSelect_articles_pageInfo;
}

export interface ArticleSelect {
  articles: ArticleSelect_articles;
}

export interface ArticleSelectVariables {
  where?: ArticleWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: ArticleOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Article
// ====================================================

export interface Article_article_author {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface Article_article_observers {
  __typename: "User";
  id: number;
}

export interface Article_article_mainImageFile {
  __typename: "File";
  sourceTitle: string | null;
  sourceURL: string | null;
}

export interface Article_article_subcategories {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
}

export interface Article_article_categories {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface Article_article {
  __typename: "Article";
  id: number;
  author: Article_article_author | null;
  observers: Article_article_observers[] | null;
  userId: number | null;
  title: string | null;
  subtitle: string | null;
  text: string | null;
  mainImage: string | null;
  mainImageFile: Article_article_mainImageFile | null;
  publishedAt: any | null;
  createdAt: any;
  tag: string | null;
  tags: string[] | null;
  subcategories: Article_article_subcategories[] | null;
  categories: Article_article_categories[] | null;
  uniqueVisitCount: number;
  pageLoadCount: number;
}

export interface Article {
  article: Article_article | null;
}

export interface ArticleVariables {
  where: ArticleWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticlePage
// ====================================================

export interface ArticlePage_article_author {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface ArticlePage_article_observers {
  __typename: "User";
  id: number;
}

export interface ArticlePage_article_mainImageFile {
  __typename: "File";
  sourceTitle: string | null;
  sourceURL: string | null;
}

export interface ArticlePage_article_subcategories {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
}

export interface ArticlePage_article_categories {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface ArticlePage_article {
  __typename: "Article";
  id: number;
  author: ArticlePage_article_author | null;
  observers: ArticlePage_article_observers[] | null;
  userId: number | null;
  title: string | null;
  subtitle: string | null;
  text: string | null;
  mainImage: string | null;
  mainImageFile: ArticlePage_article_mainImageFile | null;
  publishedAt: any | null;
  createdAt: any;
  tag: string | null;
  tags: string[] | null;
  subcategories: ArticlePage_article_subcategories[] | null;
  categories: ArticlePage_article_categories[] | null;
  uniqueVisitCount: number;
  pageLoadCount: number;
}

export interface ArticlePage {
  article: ArticlePage_article | null;
}

export interface ArticlePageVariables {
  where: ArticleWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleList
// ====================================================

export interface ArticleList_articles_data {
  __typename: "Article";
  id: number;
  title: string | null;
  mainImage: string | null;
  tag: string | null;
  tags: string[] | null;
  subtitle: string | null;
  publishedAt: any | null;
}

export interface ArticleList_articles_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface ArticleList_articles {
  __typename: "PaginatedArticles";
  data: ArticleList_articles_data[];
  pageInfo: ArticleList_articles_pageInfo;
}

export interface ArticleList {
  articles: ArticleList_articles;
}

export interface ArticleListVariables {
  where?: ArticleWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: ArticleOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleCategories
// ====================================================

export interface ArticleCategories_articleCategories_data {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
  default: boolean;
}

export interface ArticleCategories_articleCategories_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface ArticleCategories_articleCategories {
  __typename: "PaginatedArticleCategories";
  data: ArticleCategories_articleCategories_data[];
  pageInfo: ArticleCategories_articleCategories_pageInfo;
}

export interface ArticleCategories {
  articleCategories: ArticleCategories_articleCategories;
}

export interface ArticleCategoriesVariables {
  where?: ArticleCategoryWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleSubcategories
// ====================================================

export interface ArticleSubcategories_articleSubcategories_data {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
  default: boolean;
}

export interface ArticleSubcategories_articleSubcategories_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface ArticleSubcategories_articleSubcategories {
  __typename: "PaginatedArticleSubcategories";
  data: ArticleSubcategories_articleSubcategories_data[];
  pageInfo: ArticleSubcategories_articleSubcategories_pageInfo;
}

export interface ArticleSubcategories {
  articleSubcategories: ArticleSubcategories_articleSubcategories;
}

export interface ArticleSubcategoriesVariables {
  where?: ArticleSubcategoryWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleCategory
// ====================================================

export interface ArticleCategory_articleCategory {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface ArticleCategory {
  articleCategory: ArticleCategory_articleCategory | null;
}

export interface ArticleCategoryVariables {
  where: ArticleCategoryWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArticleObservers
// ====================================================

export interface ArticleObservers_articleObservers {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface ArticleObservers {
  articleObservers: ArticleObservers_articleObservers[];
}

export interface ArticleObserversVariables {
  articleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateArticle
// ====================================================

export interface CreateArticle_createArticle {
  __typename: "Article";
  id: number;
}

export interface CreateArticle {
  createArticle: CreateArticle_createArticle;
}

export interface CreateArticleVariables {
  data: ArticleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArticle
// ====================================================

export interface UpdateArticle_updateArticle {
  __typename: "Article";
  id: number;
}

export interface UpdateArticle {
  updateArticle: UpdateArticle_updateArticle;
}

export interface UpdateArticleVariables {
  where: ArticleWhereUniqueInput;
  data: ArticleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteArticle
// ====================================================

export interface DeleteArticle_deleteArticle {
  __typename: "Article";
  id: number;
}

export interface DeleteArticle {
  deleteArticle: DeleteArticle_deleteArticle;
}

export interface DeleteArticleVariables {
  where: ArticleWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddObserver
// ====================================================

export interface AddObserver_connectObserver {
  __typename: "Article";
  id: number;
}

export interface AddObserver {
  connectObserver: AddObserver_connectObserver;
}

export interface AddObserverVariables {
  articleId: number;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveObserver
// ====================================================

export interface RemoveObserver_disconnectObserver {
  __typename: "Article";
  id: number;
}

export interface RemoveObserver {
  disconnectObserver: RemoveObserver_disconnectObserver;
}

export interface RemoveObserverVariables {
  articleId: number;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertArticleVisit
// ====================================================

export interface UpsertArticleVisit_upsertArticleVisit {
  __typename: "ArticleVisit";
  visitorId: string;
}

export interface UpsertArticleVisit {
  upsertArticleVisit: UpsertArticleVisit_upsertArticleVisit;
}

export interface UpsertArticleVisitVariables {
  data: ArticleVisitInput;
  incrementPageLoad?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Banner
// ====================================================

export interface Banner_banner {
  __typename: "Banner";
  id: number;
  image: string | null;
  mobileImage: string | null;
  validUntil: any | null;
  pathMatch: string;
  exact: boolean;
  note: string | null;
  link: string | null;
  type: BannerType;
}

export interface Banner {
  banner: Banner_banner | null;
}

export interface BannerVariables {
  where: BannerWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Banners
// ====================================================

export interface Banners_banners_data {
  __typename: "Banner";
  id: number;
  image: string | null;
  mobileImage: string | null;
  validUntil: any | null;
  pathMatch: string;
  exact: boolean;
  note: string | null;
  link: string | null;
  type: BannerType;
}

export interface Banners_banners_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Banners_banners {
  __typename: "PaginatedBanners";
  data: Banners_banners_data[];
  pageInfo: Banners_banners_pageInfo;
}

export interface Banners {
  banners: Banners_banners;
}

export interface BannersVariables {
  where?: BannerWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBanner
// ====================================================

export interface CreateBanner_createBanner {
  __typename: "Banner";
  id: number;
}

export interface CreateBanner {
  createBanner: CreateBanner_createBanner;
}

export interface CreateBannerVariables {
  data: BannerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBanner
// ====================================================

export interface UpdateBanner_updateBanner {
  __typename: "Banner";
  id: number;
}

export interface UpdateBanner {
  updateBanner: UpdateBanner_updateBanner;
}

export interface UpdateBannerVariables {
  where: BannerWhereUniqueInput;
  data: BannerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteBanner
// ====================================================

export interface DeleteBanner_deleteBanner {
  __typename: "Banner";
  id: number;
}

export interface DeleteBanner {
  deleteBanner: DeleteBanner_deleteBanner;
}

export interface DeleteBannerVariables {
  where: BannerWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CareerPositions
// ====================================================

export interface CareerPositions_careerPositions_data_applications {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

export interface CareerPositions_careerPositions_data {
  __typename: "CareerPosition";
  id: number;
  name: string;
  shortDescription: string;
  boardingDate: string;
  workplaceCity: string;
  contractType: string;
  paymentFrom: string;
  paymentUpTo: string | null;
  published: boolean;
  contact: string;
  createdAt: any;
  description: string;
  applications: CareerPositions_careerPositions_data_applications[];
}

export interface CareerPositions_careerPositions_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface CareerPositions_careerPositions {
  __typename: "PaginatedCareerPositions";
  data: CareerPositions_careerPositions_data[];
  pageInfo: CareerPositions_careerPositions_pageInfo;
}

export interface CareerPositions {
  careerPositions: CareerPositions_careerPositions;
}

export interface CareerPositionsVariables {
  where?: CareerPositionWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CareerPosition
// ====================================================

export interface CareerPosition_careerPosition_applications {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

export interface CareerPosition_careerPosition {
  __typename: "CareerPosition";
  id: number;
  name: string;
  shortDescription: string;
  boardingDate: string;
  workplaceCity: string;
  contractType: string;
  paymentFrom: string;
  paymentUpTo: string | null;
  published: boolean;
  contact: string;
  createdAt: any;
  description: string;
  applications: CareerPosition_careerPosition_applications[];
}

export interface CareerPosition {
  careerPosition: CareerPosition_careerPosition | null;
}

export interface CareerPositionVariables {
  where: CareerPositionWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCareerPosition
// ====================================================

export interface DeleteCareerPosition_deleteCareerPosition {
  __typename: "CareerPosition";
  id: number;
}

export interface DeleteCareerPosition {
  deleteCareerPosition: DeleteCareerPosition_deleteCareerPosition;
}

export interface DeleteCareerPositionVariables {
  where: CareerPositionWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCareerPosition
// ====================================================

export interface CreateCareerPosition_createCareerPosition {
  __typename: "CareerPosition";
  id: number;
}

export interface CreateCareerPosition {
  createCareerPosition: CreateCareerPosition_createCareerPosition;
}

export interface CreateCareerPositionVariables {
  data: CareerPositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCareerPosition
// ====================================================

export interface UpdateCareerPosition_updateCareerPosition {
  __typename: "CareerPosition";
  id: number;
}

export interface UpdateCareerPosition {
  updateCareerPosition: UpdateCareerPosition_updateCareerPosition;
}

export interface UpdateCareerPositionVariables {
  where: CareerPositionWhereUniqueInput;
  data: CareerPositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CareerApplications
// ====================================================

export interface CareerApplications_careerApplications_data {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

export interface CareerApplications_careerApplications_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface CareerApplications_careerApplications {
  __typename: "PaginatedCareerApplications";
  data: CareerApplications_careerApplications_data[];
  pageInfo: CareerApplications_careerApplications_pageInfo;
}

export interface CareerApplications {
  careerApplications: CareerApplications_careerApplications;
}

export interface CareerApplicationsVariables {
  where?: CareerApplicationWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CareerApplication
// ====================================================

export interface CareerApplication_careerApplication {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

export interface CareerApplication {
  careerApplication: CareerApplication_careerApplication | null;
}

export interface CareerApplicationVariables {
  where: CareerApplicationWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCareerApplication
// ====================================================

export interface DeleteCareerApplication_deleteCareerApplication {
  __typename: "CareerApplication";
  id: number;
}

export interface DeleteCareerApplication {
  deleteCareerApplication: DeleteCareerApplication_deleteCareerApplication;
}

export interface DeleteCareerApplicationVariables {
  where: CareerApplicationWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCareerApplication
// ====================================================

export interface CreateCareerApplication_createCareerApplication {
  __typename: "CareerApplication";
  id: number;
}

export interface CreateCareerApplication {
  createCareerApplication: CreateCareerApplication_createCareerApplication;
}

export interface CreateCareerApplicationVariables {
  data: CareerApplicationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Currency
// ====================================================

export interface Currency_currency {
  __typename: "Currency";
  id: number;
  createdAt: any;
  name: string;
  code: string;
  icon: string | null;
}

export interface Currency {
  currency: Currency_currency | null;
}

export interface CurrencyVariables {
  where: CurrencyWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserWallets
// ====================================================

export interface UserWallets_userWallets_currency {
  __typename: "Currency";
  id: number;
  name: string;
  icon: string | null;
  code: string;
}

export interface UserWallets_userWallets {
  __typename: "Wallet";
  merchantId: number;
  balance: number;
  currency: UserWallets_userWallets_currency | null;
}

export interface UserWallets {
  userWallets: UserWallets_userWallets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Merchant
// ====================================================

export interface Merchant_merchant {
  __typename: "Merchant";
  id: number;
  name: string;
  type: MerchantType | null;
}

export interface Merchant {
  merchant: Merchant_merchant | null;
}

export interface MerchantVariables {
  where: MerchantWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentMerchant
// ====================================================

export interface TournamentMerchant_tournamentMerchant {
  __typename: "Merchant";
  id: number;
  name: string;
  type: MerchantType | null;
}

export interface TournamentMerchant {
  tournamentMerchant: TournamentMerchant_tournamentMerchant | null;
}

export interface TournamentMerchantVariables {
  tournamentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Currencies
// ====================================================

export interface Currencies_currencies_data {
  __typename: "Currency";
  id: number;
  createdAt: any;
  name: string;
  code: string;
  icon: string | null;
}

export interface Currencies_currencies_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Currencies_currencies {
  __typename: "PaginatedCurrencies";
  data: Currencies_currencies_data[];
  pageInfo: Currencies_currencies_pageInfo;
}

export interface Currencies {
  currencies: Currencies_currencies;
}

export interface CurrenciesVariables {
  where?: CurrencyWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Merchants
// ====================================================

export interface Merchants_merchants_data {
  __typename: "Merchant";
  id: number;
  name: string;
  type: MerchantType | null;
}

export interface Merchants_merchants_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Merchants_merchants {
  __typename: "PaginatedMerchants";
  data: Merchants_merchants_data[];
  pageInfo: Merchants_merchants_pageInfo;
}

export interface Merchants {
  merchants: Merchants_merchants;
}

export interface MerchantsVariables {
  where?: MerchantWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCurrency
// ====================================================

export interface CreateCurrency_createCurrency {
  __typename: "Currency";
  id: number;
}

export interface CreateCurrency {
  createCurrency: CreateCurrency_createCurrency;
}

export interface CreateCurrencyVariables {
  data: CurrencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCurrency
// ====================================================

export interface UpdateCurrency_updateCurrency {
  __typename: "Currency";
  id: number;
}

export interface UpdateCurrency {
  updateCurrency: UpdateCurrency_updateCurrency;
}

export interface UpdateCurrencyVariables {
  where: CurrencyWhereUniqueInput;
  data: CurrencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMerchant
// ====================================================

export interface CreateMerchant_createMerchant {
  __typename: "Merchant";
  id: number;
}

export interface CreateMerchant {
  createMerchant: CreateMerchant_createMerchant;
}

export interface CreateMerchantVariables {
  data: MerchantInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMerchant
// ====================================================

export interface UpdateMerchant_updateMerchant {
  __typename: "Merchant";
  id: number;
}

export interface UpdateMerchant {
  updateMerchant: UpdateMerchant_updateMerchant;
}

export interface UpdateMerchantVariables {
  where: MerchantWhereUniqueInput;
  data: MerchantInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentMerchant
// ====================================================

export interface UpdateTournamentMerchant_updateTournamentMerchant {
  __typename: "Tournament";
  id: number;
}

export interface UpdateTournamentMerchant {
  updateTournamentMerchant: UpdateTournamentMerchant_updateTournamentMerchant;
}

export interface UpdateTournamentMerchantVariables {
  tournamentId: number;
  merchantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCurrency
// ====================================================

export interface DeleteCurrency_deleteCurrency {
  __typename: "Currency";
  id: number;
}

export interface DeleteCurrency {
  deleteCurrency: DeleteCurrency_deleteCurrency;
}

export interface DeleteCurrencyVariables {
  where: CurrencyWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMerchant
// ====================================================

export interface DeleteMerchant_deleteMerchant {
  __typename: "Merchant";
  id: number;
}

export interface DeleteMerchant {
  deleteMerchant: DeleteMerchant_deleteMerchant;
}

export interface DeleteMerchantVariables {
  where: MerchantWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Files
// ====================================================

export interface Files_files_data {
  __typename: "File";
  id: number;
  createdAt: any;
  extension: string;
  isImage: boolean;
  lastModified: any;
  mimeType: string;
  name: string;
  originalName: string;
  size: number;
  sourceURL: string | null;
  sourceTitle: string | null;
}

export interface Files_files_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Files_files {
  __typename: "PaginatedFiles";
  data: Files_files_data[];
  pageInfo: Files_files_pageInfo;
}

export interface Files {
  files: Files_files;
}

export interface FilesVariables {
  where?: FileWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: File
// ====================================================

export interface File_file {
  __typename: "File";
  id: number;
  createdAt: any;
  extension: string;
  isImage: boolean;
  lastModified: any;
  mimeType: string;
  name: string;
  originalName: string;
  size: number;
  sourceURL: string | null;
  sourceTitle: string | null;
}

export interface File {
  file: File_file | null;
}

export interface FileVariables {
  where: FileWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFile
// ====================================================

export interface DeleteFile_deleteFile {
  __typename: "File";
  id: number;
}

export interface DeleteFile {
  deleteFile: DeleteFile_deleteFile;
}

export interface DeleteFileVariables {
  where: FileWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFile
// ====================================================

export interface UpsertFile_upsertFile {
  __typename: "File";
  id: number;
}

export interface UpsertFile {
  upsertFile: UpsertFile_upsertFile;
}

export interface UpsertFileVariables {
  data: FileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Game
// ====================================================

export interface Game_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface Game_game {
  __typename: "Game";
  id: number;
  name: string;
  coverImage: string | null;
  gameIdId: number | null;
  gameId: Game_game_gameId | null;
  type: GameType | null;
  filterOrder: number | null;
  tag: string | null;
  tags: string[] | null;
}

export interface Game {
  game: Game_game | null;
}

export interface GameVariables {
  where: GameWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Games
// ====================================================

export interface Games_games_data_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface Games_games_data {
  __typename: "Game";
  id: number;
  name: string;
  coverImage: string | null;
  gameIdId: number | null;
  gameId: Games_games_data_gameId | null;
  type: GameType | null;
  filterOrder: number | null;
  tag: string | null;
  tags: string[] | null;
}

export interface Games_games_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Games_games {
  __typename: "PaginatedGames";
  data: Games_games_data[];
  pageInfo: Games_games_pageInfo;
}

export interface Games {
  games: Games_games;
}

export interface GamesVariables {
  where?: GameWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: GameOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameSelect
// ====================================================

export interface GameSelect_games_data {
  __typename: "Game";
  id: number;
  name: string;
  coverImage: string | null;
}

export interface GameSelect_games_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface GameSelect_games {
  __typename: "PaginatedGames";
  data: GameSelect_games_data[];
  pageInfo: GameSelect_games_pageInfo;
}

export interface GameSelect {
  games: GameSelect_games;
}

export interface GameSelectVariables {
  where?: GameWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: GameOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameTextSelect
// ====================================================

export interface GameTextSelect_games_data {
  __typename: "Game";
  id: number;
  name: string;
}

export interface GameTextSelect_games {
  __typename: "PaginatedGames";
  data: GameTextSelect_games_data[];
}

export interface GameTextSelect {
  games: GameTextSelect_games;
}

export interface GameTextSelectVariables {
  where?: GameWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: GameOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameMap
// ====================================================

export interface GameMap_gameMap {
  __typename: "GameMap";
  id: number;
  gameId: number;
  name: string;
  image: string | null;
}

export interface GameMap {
  gameMap: GameMap_gameMap | null;
}

export interface GameMapVariables {
  where: GameMapWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameMaps
// ====================================================

export interface GameMaps_gameMaps_data {
  __typename: "GameMap";
  id: number;
  gameId: number;
  name: string;
  image: string | null;
}

export interface GameMaps_gameMaps_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface GameMaps_gameMaps {
  __typename: "PaginatedGameMaps";
  data: GameMaps_gameMaps_data[];
  pageInfo: GameMaps_gameMaps_pageInfo;
}

export interface GameMaps {
  gameMaps: GameMaps_gameMaps;
}

export interface GameMapsVariables {
  where?: GameMapWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGame
// ====================================================

export interface CreateGame_createGame {
  __typename: "Game";
  id: number;
}

export interface CreateGame {
  createGame: CreateGame_createGame;
}

export interface CreateGameVariables {
  data: GameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGame
// ====================================================

export interface UpdateGame_updateGame {
  __typename: "Game";
  id: number;
}

export interface UpdateGame {
  updateGame: UpdateGame_updateGame;
}

export interface UpdateGameVariables {
  where: GameWhereUniqueInput;
  data: GameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGame
// ====================================================

export interface DeleteGame_deleteGame {
  __typename: "Game";
  id: number;
}

export interface DeleteGame {
  deleteGame: DeleteGame_deleteGame;
}

export interface DeleteGameVariables {
  where: GameWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGameMap
// ====================================================

export interface CreateGameMap_createGameMap {
  __typename: "GameMap";
  id: number;
}

export interface CreateGameMap {
  createGameMap: CreateGameMap_createGameMap;
}

export interface CreateGameMapVariables {
  data: GameMapInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGameMap
// ====================================================

export interface UpdateGameMap_updateGameMap {
  __typename: "GameMap";
  id: number;
}

export interface UpdateGameMap {
  updateGameMap: UpdateGameMap_updateGameMap;
}

export interface UpdateGameMapVariables {
  where: GameMapWhereUniqueInput;
  data: GameMapInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGameMap
// ====================================================

export interface DeleteGameMap_deleteGameMap {
  __typename: "GameMap";
  id: number;
}

export interface DeleteGameMap {
  deleteGameMap: DeleteGameMap_deleteGameMap;
}

export interface DeleteGameMapVariables {
  where: GameMapWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameIds
// ====================================================

export interface GameIds_gameIds_data {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface GameIds_gameIds_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface GameIds_gameIds {
  __typename: "PaginatedGameIds";
  data: GameIds_gameIds_data[];
  pageInfo: GameIds_gameIds_pageInfo;
}

export interface GameIds {
  gameIds: GameIds_gameIds;
}

export interface GameIdsVariables {
  where?: GameIdWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: GameIdOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameId
// ====================================================

export interface GameId_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface GameId {
  gameId: GameId_gameId | null;
}

export interface GameIdVariables {
  where: GameIdWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGameId
// ====================================================

export interface CreateGameId_createGameId {
  __typename: "GameId";
  id: number;
}

export interface CreateGameId {
  createGameId: CreateGameId_createGameId;
}

export interface CreateGameIdVariables {
  data: GameIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGameId
// ====================================================

export interface UpdateGameId_updateGameId {
  __typename: "GameId";
  id: number;
}

export interface UpdateGameId {
  updateGameId: UpdateGameId_updateGameId;
}

export interface UpdateGameIdVariables {
  where: GameIdWhereUniqueInput;
  data: GameIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGameId
// ====================================================

export interface DeleteGameId_deleteGameId {
  __typename: "GameId";
  id: number;
}

export interface DeleteGameId {
  deleteGameId: DeleteGameId_deleteGameId;
}

export interface DeleteGameIdVariables {
  where: GameIdWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Guests
// ====================================================

export interface Guests_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface Guests_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface Guests_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  photo: string | null;
  media: Guests_guests_data_media | null;
  address: Guests_guests_data_address | null;
}

export interface Guests_guests_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Guests_guests {
  __typename: "GuestCollection";
  data: Guests_guests_data[];
  pageInfo: Guests_guests_pageInfo;
}

export interface Guests {
  guests: Guests_guests;
}

export interface GuestsVariables {
  searchString?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: GuestOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Guest
// ====================================================

export interface Guest_uniqueGuest_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface Guest_uniqueGuest_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface Guest_uniqueGuest {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  photo: string | null;
  media: Guest_uniqueGuest_media | null;
  address: Guest_uniqueGuest_address | null;
}

export interface Guest {
  uniqueGuest: Guest_uniqueGuest | null;
}

export interface GuestVariables {
  where: GuestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuestPrograms
// ====================================================

export interface GuestPrograms_programSectionsByGuests_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface GuestPrograms_programSectionsByGuests_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface GuestPrograms_programSectionsByGuests_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  photo: string | null;
  media: GuestPrograms_programSectionsByGuests_guests_data_media | null;
  address: GuestPrograms_programSectionsByGuests_guests_data_address | null;
}

export interface GuestPrograms_programSectionsByGuests_guests {
  __typename: "GuestCollection";
  data: GuestPrograms_programSectionsByGuests_guests_data[];
}

export interface GuestPrograms_programSectionsByGuests {
  __typename: "ProjectProgramSection";
  id: number;
  projectSectionId: number;
  duration: number;
  createdAt: any;
  updatedAt: any;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  guests: GuestPrograms_programSectionsByGuests_guests | null;
}

export interface GuestPrograms {
  programSectionsByGuests: (GuestPrograms_programSectionsByGuests | null)[];
}

export interface GuestProgramsVariables {
  projectId: number;
  guestId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuest
// ====================================================

export interface CreateGuest_createGuest {
  __typename: "Guest";
  id: number;
}

export interface CreateGuest {
  createGuest: CreateGuest_createGuest;
}

export interface CreateGuestVariables {
  data: GuestCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuest
// ====================================================

export interface UpdateGuest_updateGuest {
  __typename: "Guest";
  id: number;
}

export interface UpdateGuest {
  updateGuest: UpdateGuest_updateGuest;
}

export interface UpdateGuestVariables {
  where: GuestWhereUniqueInput;
  data: GuestUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGuest
// ====================================================

export interface DeleteGuest_deleteGuest {
  __typename: "Guest";
  id: number;
}

export interface DeleteGuest {
  deleteGuest: DeleteGuest_deleteGuest;
}

export interface DeleteGuestVariables {
  where: GuestWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderLinks
// ====================================================

export interface HeaderLinks_headerLinks_data {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

export interface HeaderLinks_headerLinks_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface HeaderLinks_headerLinks {
  __typename: "PaginatedHeaderLinks";
  data: HeaderLinks_headerLinks_data[];
  pageInfo: HeaderLinks_headerLinks_pageInfo;
}

export interface HeaderLinks {
  headerLinks: HeaderLinks_headerLinks;
}

export interface HeaderLinksVariables {
  where?: HeaderLinkWhereInput | null;
  orderBy?: HeaderLinkOrderByInput[] | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderLink
// ====================================================

export interface HeaderLink_headerLink {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

export interface HeaderLink {
  headerLink: HeaderLink_headerLink | null;
}

export interface HeaderLinkVariables {
  where: HeaderLinkWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderLinkGroup
// ====================================================

export interface HeaderLinkGroup_headerLinkGroup_links {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

export interface HeaderLinkGroup_headerLinkGroup {
  __typename: "HeaderLinkGroup";
  id: number;
  createdAt: any;
  updatedAt: any;
  pathMatch: string;
  name: string | null;
  exact: boolean;
  links: HeaderLinkGroup_headerLinkGroup_links[] | null;
}

export interface HeaderLinkGroup {
  headerLinkGroup: HeaderLinkGroup_headerLinkGroup | null;
}

export interface HeaderLinkGroupVariables {
  where: HeaderLinkGroupWhereInput;
  linksWhere?: HeaderLinkWhereInput | null;
  linksOrderBy?: HeaderLinkOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderLinkGroups
// ====================================================

export interface HeaderLinkGroups_headerLinkGroups_data_links {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

export interface HeaderLinkGroups_headerLinkGroups_data {
  __typename: "HeaderLinkGroup";
  id: number;
  createdAt: any;
  updatedAt: any;
  pathMatch: string;
  name: string | null;
  exact: boolean;
  links: HeaderLinkGroups_headerLinkGroups_data_links[] | null;
}

export interface HeaderLinkGroups_headerLinkGroups_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface HeaderLinkGroups_headerLinkGroups {
  __typename: "PaginatedHeaderLinkGroups";
  data: HeaderLinkGroups_headerLinkGroups_data[];
  pageInfo: HeaderLinkGroups_headerLinkGroups_pageInfo;
}

export interface HeaderLinkGroups {
  headerLinkGroups: HeaderLinkGroups_headerLinkGroups;
}

export interface HeaderLinkGroupsVariables {
  where?: HeaderLinkGroupWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  linksWhere?: HeaderLinkWhereInput | null;
  linksOrderBy?: HeaderLinkOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHeaderLink
// ====================================================

export interface DeleteHeaderLink_deleteHeaderLink {
  __typename: "HeaderLink";
  id: number;
}

export interface DeleteHeaderLink {
  deleteHeaderLink: DeleteHeaderLink_deleteHeaderLink;
}

export interface DeleteHeaderLinkVariables {
  where: HeaderLinkWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHeaderLinkGroup
// ====================================================

export interface DeleteHeaderLinkGroup_deleteHeaderLinkGroup {
  __typename: "HeaderLinkGroup";
  id: number;
}

export interface DeleteHeaderLinkGroup {
  deleteHeaderLinkGroup: DeleteHeaderLinkGroup_deleteHeaderLinkGroup;
}

export interface DeleteHeaderLinkGroupVariables {
  where: HeaderLinkGroupWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHeaderLink
// ====================================================

export interface CreateHeaderLink_createHeaderLink {
  __typename: "HeaderLink";
  id: number;
}

export interface CreateHeaderLink {
  createHeaderLink: CreateHeaderLink_createHeaderLink;
}

export interface CreateHeaderLinkVariables {
  data: HeaderLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateHeaderLink
// ====================================================

export interface UpdateHeaderLink_updateHeaderLink {
  __typename: "HeaderLink";
  id: number;
}

export interface UpdateHeaderLink {
  updateHeaderLink: UpdateHeaderLink_updateHeaderLink;
}

export interface UpdateHeaderLinkVariables {
  where: HeaderLinkWhereUniqueInput;
  data: HeaderLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHeaderLinkGroup
// ====================================================

export interface CreateHeaderLinkGroup_createHeaderLinkGroup {
  __typename: "HeaderLinkGroup";
  id: number;
}

export interface CreateHeaderLinkGroup {
  createHeaderLinkGroup: CreateHeaderLinkGroup_createHeaderLinkGroup;
}

export interface CreateHeaderLinkGroupVariables {
  data: HeaderLinkGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateHeaderLinkGroup
// ====================================================

export interface UpdateHeaderLinkGroup_updateHeaderLinkGroup {
  __typename: "HeaderLinkGroup";
  id: number;
}

export interface UpdateHeaderLinkGroup {
  updateHeaderLinkGroup: UpdateHeaderLinkGroup_updateHeaderLinkGroup;
}

export interface UpdateHeaderLinkGroupVariables {
  where: HeaderLinkGroupWhereUniqueInput;
  data: HeaderLinkGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeEvents
// ====================================================

export interface HomeEvents_homeEvents_data {
  __typename: "HomeEvent";
  id: number;
  date: any | null;
  place: string | null;
  title: string | null;
  url: string | null;
  note: string | null;
  image: string | null;
}

export interface HomeEvents_homeEvents_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface HomeEvents_homeEvents {
  __typename: "PaginatedHomeEvents";
  data: HomeEvents_homeEvents_data[];
  pageInfo: HomeEvents_homeEvents_pageInfo;
}

export interface HomeEvents {
  homeEvents: HomeEvents_homeEvents;
}

export interface HomeEventsVariables {
  where?: HomeEventWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeEvent
// ====================================================

export interface HomeEvent_homeEvent {
  __typename: "HomeEvent";
  id: number;
  date: any | null;
  place: string | null;
  title: string | null;
  url: string | null;
  note: string | null;
  image: string | null;
}

export interface HomeEvent {
  homeEvent: HomeEvent_homeEvent | null;
}

export interface HomeEventVariables {
  where: HomeEventWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeArticles
// ====================================================

export interface HomeArticles_homeArticles_data_article {
  __typename: "Article";
  id: number;
  title: string | null;
  tag: string | null;
  mainImage: string | null;
  tags: string[] | null;
  subtitle: string | null;
  publishedAt: any | null;
}

export interface HomeArticles_homeArticles_data {
  __typename: "HomeArticle";
  id: number;
  article: HomeArticles_homeArticles_data_article | null;
}

export interface HomeArticles_homeArticles_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface HomeArticles_homeArticles {
  __typename: "PaginatedHomeArticles";
  data: HomeArticles_homeArticles_data[];
  pageInfo: HomeArticles_homeArticles_pageInfo;
}

export interface HomeArticles {
  homeArticles: HomeArticles_homeArticles;
}

export interface HomeArticlesVariables {
  where?: HomeArticleWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameGeneralRule
// ====================================================

export interface GameGeneralRule_gameGeneralRule {
  __typename: "GameGeneralRule";
  gameId: number | null;
  value: string | null;
}

export interface GameGeneralRule {
  gameGeneralRule: GameGeneralRule_gameGeneralRule | null;
}

export interface GameGeneralRuleVariables {
  where: GameGeneralRuleWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGameGeneralRule
// ====================================================

export interface UpsertGameGeneralRule_upsertGameGeneralRule {
  __typename: "GameGeneralRule";
  value: string | null;
}

export interface UpsertGameGeneralRule {
  upsertGameGeneralRule: UpsertGameGeneralRule_upsertGameGeneralRule;
}

export interface UpsertGameGeneralRuleVariables {
  data: GameGeneralRuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetHomeArticles
// ====================================================

export interface SetHomeArticles {
  setHomeArticles: number;
}

export interface SetHomeArticlesVariables {
  articleIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHomeEvent
// ====================================================

export interface CreateHomeEvent_createHomeEvent {
  __typename: "HomeEvent";
  id: number;
}

export interface CreateHomeEvent {
  createHomeEvent: CreateHomeEvent_createHomeEvent;
}

export interface CreateHomeEventVariables {
  data: HomeEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateHomeEvent
// ====================================================

export interface UpdateHomeEvent_updateHomeEvent {
  __typename: "HomeEvent";
  id: number;
}

export interface UpdateHomeEvent {
  updateHomeEvent: UpdateHomeEvent_updateHomeEvent;
}

export interface UpdateHomeEventVariables {
  where: HomeEventWhereUniqueInput;
  data: HomeEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHomeEvent
// ====================================================

export interface DeleteHomeEvent_deleteHomeEvent {
  __typename: "HomeEvent";
  id: number;
}

export interface DeleteHomeEvent {
  deleteHomeEvent: DeleteHomeEvent_deleteHomeEvent;
}

export interface DeleteHomeEventVariables {
  where: HomeEventWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Laptops
// ====================================================

export interface Laptops_laptops_performance_description {
  __typename: "LaptopPerformanceDescription";
  sk: string | null;
  cz: string | null;
}

export interface Laptops_laptops_performance_fullhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptops_laptops_performance_hd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptops_laptops_performance_hdplus {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptops_laptops_performance_quadhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptops_laptops_performance_wxga {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptops_laptops_performance {
  __typename: "LaptopPerformance";
  game: string | null;
  description: Laptops_laptops_performance_description | null;
  fullhd: Laptops_laptops_performance_fullhd | null;
  hd: Laptops_laptops_performance_hd | null;
  hdplus: Laptops_laptops_performance_hdplus | null;
  quadhd: Laptops_laptops_performance_quadhd | null;
  wxga: Laptops_laptops_performance_wxga | null;
}

export interface Laptops_laptops_sellers {
  __typename: "LaptopSeller";
  name: string | null;
  value: string | null;
}

export interface Laptops_laptops {
  __typename: "Laptop";
  id: number;
  name: string;
  description: string | null;
  mainImage: string;
  gallery: (string | null)[] | null;
  performance: (Laptops_laptops_performance | null)[] | null;
  url: string | null;
  sellers: (Laptops_laptops_sellers | null)[] | null;
}

export interface Laptops {
  laptops: Laptops_laptops[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ParamCategories
// ====================================================

export interface ParamCategories_paramCategories {
  __typename: "ParamCategory";
  id: number;
  category: string;
  subcategory: string;
  value: string;
}

export interface ParamCategories {
  paramCategories: ParamCategories_paramCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Laptop
// ====================================================

export interface Laptop_laptop_performance_description {
  __typename: "LaptopPerformanceDescription";
  sk: string | null;
  cz: string | null;
}

export interface Laptop_laptop_performance_fullhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptop_laptop_performance_hd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptop_laptop_performance_hdplus {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptop_laptop_performance_quadhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptop_laptop_performance_wxga {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface Laptop_laptop_performance {
  __typename: "LaptopPerformance";
  game: string | null;
  description: Laptop_laptop_performance_description | null;
  fullhd: Laptop_laptop_performance_fullhd | null;
  hd: Laptop_laptop_performance_hd | null;
  hdplus: Laptop_laptop_performance_hdplus | null;
  quadhd: Laptop_laptop_performance_quadhd | null;
  wxga: Laptop_laptop_performance_wxga | null;
}

export interface Laptop_laptop_sellers {
  __typename: "LaptopSeller";
  name: string | null;
  value: string | null;
}

export interface Laptop_laptop {
  __typename: "Laptop";
  id: number;
  name: string;
  description: string | null;
  mainImage: string;
  gallery: (string | null)[] | null;
  performance: (Laptop_laptop_performance | null)[] | null;
  url: string | null;
  sellers: (Laptop_laptop_sellers | null)[] | null;
}

export interface Laptop {
  laptop: Laptop_laptop | null;
}

export interface LaptopVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLaptop
// ====================================================

export interface CreateLaptop_createLaptop {
  __typename: "Laptop";
  id: number;
}

export interface CreateLaptop {
  createLaptop: CreateLaptop_createLaptop | null;
}

export interface CreateLaptopVariables {
  data: LaptopInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLaptop
// ====================================================

export interface DeleteLaptop_deleteLaptop {
  __typename: "Laptop";
  id: number;
}

export interface DeleteLaptop {
  deleteLaptop: DeleteLaptop_deleteLaptop | null;
}

export interface DeleteLaptopVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLaptop
// ====================================================

export interface UpdateLaptop_updateLaptop {
  __typename: "Laptop";
  id: number;
}

export interface UpdateLaptop {
  updateLaptop: UpdateLaptop_updateLaptop | null;
}

export interface UpdateLaptopVariables {
  id: number;
  data: LaptopInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocaleString
// ====================================================

export interface LocaleString_localeString {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

export interface LocaleString {
  localeString: LocaleString_localeString | null;
}

export interface LocaleStringVariables {
  name: string;
  locale: Locale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocaleStringsFiltered
// ====================================================

export interface LocaleStringsFiltered_localeStringsFiltered {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

export interface LocaleStringsFiltered {
  localeStringsFiltered: LocaleStringsFiltered_localeStringsFiltered[];
}

export interface LocaleStringsFilteredVariables {
  name: string[];
  locale: Locale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocaleStringsLike
// ====================================================

export interface LocaleStringsLike_localeStringsLike {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

export interface LocaleStringsLike {
  localeStringsLike: LocaleStringsLike_localeStringsLike[];
}

export interface LocaleStringsLikeVariables {
  name?: string | null;
  locale: Locale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllLocaleStringsLike
// ====================================================

export interface AllLocaleStringsLike_allLocaleStringsLike {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

export interface AllLocaleStringsLike {
  allLocaleStringsLike: AllLocaleStringsLike_allLocaleStringsLike[];
}

export interface AllLocaleStringsLikeVariables {
  name?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocaleStrings
// ====================================================

export interface LocaleStrings_localeStrings {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

export interface LocaleStrings {
  localeStrings: LocaleStrings_localeStrings[];
}

export interface LocaleStringsVariables {
  locale: Locale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Partners
// ====================================================

export interface Partners_partners_data {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  orsr: string | null;
  ico: string | null;
  dic: string | null;
  dph: string | null;
  media: any | null;
  address: any | null;
}

export interface Partners_partners_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Partners_partners {
  __typename: "PartnerCollection";
  data: Partners_partners_data[];
  pageInfo: Partners_partners_pageInfo;
}

export interface Partners {
  partners: Partners_partners;
}

export interface PartnersVariables {
  where?: PartnerWhereInput | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerSelect
// ====================================================

export interface PartnerSelect_partners_data {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
}

export interface PartnerSelect_partners_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface PartnerSelect_partners {
  __typename: "PartnerCollection";
  data: PartnerSelect_partners_data[];
  pageInfo: PartnerSelect_partners_pageInfo;
}

export interface PartnerSelect {
  partners: PartnerSelect_partners;
}

export interface PartnerSelectVariables {
  searchString?: string | null;
  offset?: number | null;
  limit?: number | null;
  mode?: StringSearchMode | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Partner
// ====================================================

export interface Partner_partner {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  orsr: string | null;
  ico: string | null;
  dic: string | null;
  dph: string | null;
  media: any | null;
  address: any | null;
}

export interface Partner {
  partner: Partner_partner | null;
}

export interface PartnerVariables {
  where: PartnerWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePartner
// ====================================================

export interface CreatePartner_createPartner {
  __typename: "Partner";
  id: number;
}

export interface CreatePartner {
  createPartner: CreatePartner_createPartner;
}

export interface CreatePartnerVariables {
  data: PartnerCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePartner
// ====================================================

export interface UpdatePartner_updatePartner {
  __typename: "Partner";
  id: number;
}

export interface UpdatePartner {
  updatePartner: UpdatePartner_updatePartner;
}

export interface UpdatePartnerVariables {
  where: PartnerWhereUniqueInput;
  data: PartnerUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePartner
// ====================================================

export interface DeletePartner_deletePartner {
  __typename: "Partner";
  id: number;
}

export interface DeletePartner {
  deletePartner: DeletePartner_deletePartner;
}

export interface DeletePartnerVariables {
  where: PartnerWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Projects
// ====================================================

export interface Projects_projects_data_translation {
  __typename: "ProjectTranslation";
  name: string;
}

export interface Projects_projects_data_translations_data {
  __typename: "ProjectTranslation";
  name: string;
}

export interface Projects_projects_data_translations {
  __typename: "ProjectTranslationCollection";
  data: Projects_projects_data_translations_data[];
}

export interface Projects_projects_data {
  __typename: "Project";
  id: number;
  link: string | null;
  contactEmail: string | null;
  coverImage: string | null;
  published: boolean;
  translation: Projects_projects_data_translation | null;
  translations: Projects_projects_data_translations | null;
}

export interface Projects_projects_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Projects_projects {
  __typename: "ProjectCollection";
  data: Projects_projects_data[];
  pageInfo: Projects_projects_pageInfo;
}

export interface Projects {
  projects: Projects_projects;
}

export interface ProjectsVariables {
  where?: ProjectWhereInput | null;
  skip?: number | null;
  take?: number | null;
  locale: Locale;
  orderBy?: ProjectOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NextProjectId
// ====================================================

export interface NextProjectId {
  nextProjectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectPage
// ====================================================

export interface ProjectPage_project_translations_data {
  __typename: "ProjectTranslation";
  id: number;
  projectId: number;
  locale: Locale;
  name: string;
}

export interface ProjectPage_project_translations {
  __typename: "ProjectTranslationCollection";
  data: ProjectPage_project_translations_data[];
}

export interface ProjectPage_project_translation {
  __typename: "ProjectTranslation";
  id: number;
  projectId: number;
  locale: Locale;
  name: string;
}

export interface ProjectPage_project_projectSections_data_translations_data {
  __typename: "ProjectSectionTranslation";
  id: number;
  order: number;
  locale: Locale;
}

export interface ProjectPage_project_projectSections_data_translations {
  __typename: "ProjectSectionTranslationCollection";
  data: ProjectPage_project_projectSections_data_translations_data[];
}

export interface ProjectPage_project_projectSections_data_video {
  __typename: "ProjectVideoSection";
  id: number;
  title: string | null;
  videosPerRow: number | null;
  links: string[];
}

export interface ProjectPage_project_projectSections_data_figure {
  __typename: "ProjectFigureSection";
  id: number;
  title: string | null;
  caption: string | null;
  image: string | null;
}

export interface ProjectPage_project_projectSections_data_text {
  __typename: "ProjectTextSection";
  id: number;
  title: string | null;
  image: string | null;
  imagePosition: ProjectSectionImagePosition | null;
  buttonLink: string | null;
  buttonText: string | null;
  text: string | null;
}

export interface ProjectPage_project_projectSections_data_articles {
  __typename: "ProjectArticlesSection";
  id: number;
  tags: string[];
}

export interface ProjectPage_project_projectSections_data_voting_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectPage_project_projectSections_data_voting {
  __typename: "ProjectVotingSection";
  id: number;
  tournamentId: number;
  title: string;
  slotCount: number;
  teams: ProjectPage_project_projectSections_data_voting_teams[] | null;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectPage_project_projectSections_data_competition {
  __typename: "ProjectCompetitionSection";
  id: number;
  title: string;
  buttonLabel: string;
  successMessage: string;
  terms: string;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectPage_project_projectSections_data_guests_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectPage_project_projectSections_data_guests_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectPage_project_projectSections_data_guests_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectPage_project_projectSections_data_guests_guests_data_media | null;
  address: ProjectPage_project_projectSections_data_guests_guests_data_address | null;
  photo: string | null;
}

export interface ProjectPage_project_projectSections_data_guests_guests {
  __typename: "GuestCollection";
  data: ProjectPage_project_projectSections_data_guests_guests_data[];
}

export interface ProjectPage_project_projectSections_data_guests {
  __typename: "ProjectGuestsSection";
  id: number;
  guests: ProjectPage_project_projectSections_data_guests_guests | null;
}

export interface ProjectPage_project_projectSections_data_partnerTypes_data_partners_data {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectPage_project_projectSections_data_partnerTypes_data_partners {
  __typename: "PartnerCollection";
  data: ProjectPage_project_projectSections_data_partnerTypes_data_partners_data[];
}

export interface ProjectPage_project_projectSections_data_partnerTypes_data {
  __typename: "ProjectPartnerType";
  id: number;
  name: string;
  order: number;
  tilesPerRowMobile: number | null;
  tilesPerRowDesktop: number | null;
  partners: ProjectPage_project_projectSections_data_partnerTypes_data_partners | null;
}

export interface ProjectPage_project_projectSections_data_partnerTypes {
  __typename: "ProjectPartnerTypeCollection";
  data: ProjectPage_project_projectSections_data_partnerTypes_data[];
}

export interface ProjectPage_project_projectSections_data_attractions_data_partner {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectPage_project_projectSections_data_attractions_data {
  __typename: "ProjectAttractionsSection";
  id: number;
  title: string;
  priority: ProjectAttractionPriority;
  order: number;
  description: string | null;
  partnerId: number | null;
  image: string | null;
  partner: ProjectPage_project_projectSections_data_attractions_data_partner | null;
}

export interface ProjectPage_project_projectSections_data_attractions {
  __typename: "ProjectAttractionsSectionCollection";
  data: ProjectPage_project_projectSections_data_attractions_data[];
}

export interface ProjectPage_project_projectSections_data_programs_data_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectPage_project_projectSections_data_programs_data_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectPage_project_projectSections_data_programs_data_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectPage_project_projectSections_data_programs_data_guests_data_media | null;
  address: ProjectPage_project_projectSections_data_programs_data_guests_data_address | null;
  photo: string | null;
}

export interface ProjectPage_project_projectSections_data_programs_data_guests {
  __typename: "GuestCollection";
  data: ProjectPage_project_projectSections_data_programs_data_guests_data[];
}

export interface ProjectPage_project_projectSections_data_programs_data {
  __typename: "ProjectProgramSection";
  id: number;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  duration: number;
  guests: ProjectPage_project_projectSections_data_programs_data_guests | null;
}

export interface ProjectPage_project_projectSections_data_programs {
  __typename: "ProjectProgramSectionCollection";
  data: ProjectPage_project_projectSections_data_programs_data[];
}

export interface ProjectPage_project_projectSections_data_questionsAndAnswers_data {
  __typename: "ProjectQuestionAndAnswer";
  id: number;
  title: string | null;
  text: string | null;
}

export interface ProjectPage_project_projectSections_data_questionsAndAnswers {
  __typename: "ProjectQuestionAndAnswerCollection";
  data: ProjectPage_project_projectSections_data_questionsAndAnswers_data[];
}

export interface ProjectPage_project_projectSections_data_tournaments_data_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface ProjectPage_project_projectSections_data_tournaments_data_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: ProjectPage_project_projectSections_data_tournaments_data_game_gameId | null;
  tags: string[] | null;
}

export interface ProjectPage_project_projectSections_data_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: ProjectPage_project_projectSections_data_tournaments_data_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
}

export interface ProjectPage_project_projectSections_data_tournaments {
  __typename: "TournamentCollection";
  data: ProjectPage_project_projectSections_data_tournaments_data[];
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectPage_project_projectSections_data_tournamentMatches_matches_teamA_playersUserData[] | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectPage_project_projectSections_data_tournamentMatches_matches_teamB_playersUserData[] | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: ProjectPage_project_projectSections_data_tournamentMatches_matches_tournament_game | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: ProjectPage_project_projectSections_data_tournamentMatches_matches_rounds_map | null;
}

export interface ProjectPage_project_projectSections_data_tournamentMatches_matches {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: ProjectPage_project_projectSections_data_tournamentMatches_matches_teamA | null;
  teamB: ProjectPage_project_projectSections_data_tournamentMatches_matches_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: ProjectPage_project_projectSections_data_tournamentMatches_matches_tournament;
  stage: ProjectPage_project_projectSections_data_tournamentMatches_matches_stage;
  rounds: ProjectPage_project_projectSections_data_tournamentMatches_matches_rounds[];
}

export interface ProjectPage_project_projectSections_data_tournamentMatches {
  __typename: "ProjectSectionTournamentMatchGrouping";
  tournamentId: number;
  matches: ProjectPage_project_projectSections_data_tournamentMatches_matches[];
}

export interface ProjectPage_project_projectSections_data_tournamentTeams_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectPage_project_projectSections_data_tournamentTeams {
  __typename: "ProjectSectionTournamentTeamGrouping";
  tournamentId: number;
  tournamentName: string | null;
  teams: ProjectPage_project_projectSections_data_tournamentTeams_teams[];
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_stages_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_stages {
  __typename: "TournamentCollection";
  data: ProjectPage_project_projectSections_data_tournaments_relation_stages_data[];
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_matches_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_matches {
  __typename: "TournamentCollection";
  data: ProjectPage_project_projectSections_data_tournaments_relation_matches_data[];
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_teams_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectPage_project_projectSections_data_tournaments_relation_teams {
  __typename: "TournamentCollection";
  data: ProjectPage_project_projectSections_data_tournaments_relation_teams_data[];
}

export interface ProjectPage_project_projectSections_data {
  __typename: "ProjectSection";
  id: number;
  projectId: number;
  locale: Locale | null;
  type: ProjectSectionType;
  contentTableName: string | null;
  icon: string | null;
  addToContentTable: boolean;
  translations: ProjectPage_project_projectSections_data_translations | null;
  video: ProjectPage_project_projectSections_data_video | null;
  figure: ProjectPage_project_projectSections_data_figure | null;
  text: ProjectPage_project_projectSections_data_text | null;
  articles: ProjectPage_project_projectSections_data_articles | null;
  voting: ProjectPage_project_projectSections_data_voting | null;
  competition: ProjectPage_project_projectSections_data_competition | null;
  guests: ProjectPage_project_projectSections_data_guests | null;
  partnerTypes: ProjectPage_project_projectSections_data_partnerTypes | null;
  attractions: ProjectPage_project_projectSections_data_attractions | null;
  programs: ProjectPage_project_projectSections_data_programs | null;
  questionsAndAnswers: ProjectPage_project_projectSections_data_questionsAndAnswers | null;
  tournaments: ProjectPage_project_projectSections_data_tournaments | null;
  tournamentMatches: ProjectPage_project_projectSections_data_tournamentMatches[] | null;
  tournamentTeams: ProjectPage_project_projectSections_data_tournamentTeams[] | null;
  tournaments_relation_stages: ProjectPage_project_projectSections_data_tournaments_relation_stages | null;
  tournaments_relation_matches: ProjectPage_project_projectSections_data_tournaments_relation_matches | null;
  tournaments_relation_teams: ProjectPage_project_projectSections_data_tournaments_relation_teams | null;
}

export interface ProjectPage_project_projectSections {
  __typename: "ProjectSectionCollection";
  data: ProjectPage_project_projectSections_data[];
}

export interface ProjectPage_project {
  __typename: "Project";
  id: number;
  coverImage: string | null;
  contactEmail: string | null;
  translations: ProjectPage_project_translations | null;
  translation: ProjectPage_project_translation | null;
  projectSections: ProjectPage_project_projectSections | null;
}

export interface ProjectPage {
  project: ProjectPage_project | null;
}

export interface ProjectPageVariables {
  where: ProjectWhereInput;
  locale: Locale;
  sectionsOrderBy?: ProjectSectionOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AttractionPage
// ====================================================

export interface AttractionPage_uniqueProjectAttractionsSection_partner {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface AttractionPage_uniqueProjectAttractionsSection {
  __typename: "ProjectAttractionsSection";
  id: number;
  title: string;
  priority: ProjectAttractionPriority;
  order: number;
  description: string | null;
  partnerId: number | null;
  image: string | null;
  partner: AttractionPage_uniqueProjectAttractionsSection_partner | null;
}

export interface AttractionPage {
  uniqueProjectAttractionsSection: AttractionPage_uniqueProjectAttractionsSection | null;
}

export interface AttractionPageVariables {
  where: ProjectAttractionsSectionWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProgramPage
// ====================================================

export interface ProgramPage_uniqueProjectProgramSection_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProgramPage_uniqueProjectProgramSection_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProgramPage_uniqueProjectProgramSection_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProgramPage_uniqueProjectProgramSection_guests_data_media | null;
  address: ProgramPage_uniqueProjectProgramSection_guests_data_address | null;
  photo: string | null;
}

export interface ProgramPage_uniqueProjectProgramSection_guests {
  __typename: "GuestCollection";
  data: ProgramPage_uniqueProjectProgramSection_guests_data[];
}

export interface ProgramPage_uniqueProjectProgramSection {
  __typename: "ProjectProgramSection";
  id: number;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  duration: number;
  guests: ProgramPage_uniqueProjectProgramSection_guests | null;
}

export interface ProgramPage {
  uniqueProjectProgramSection: ProgramPage_uniqueProjectProgramSection | null;
}

export interface ProgramPageVariables {
  where: ProjectProgramSectionWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Data
// ====================================================

export interface Data_projectProgramSections_data_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface Data_projectProgramSections_data_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface Data_projectProgramSections_data_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: Data_projectProgramSections_data_guests_data_media | null;
  address: Data_projectProgramSections_data_guests_data_address | null;
  photo: string | null;
}

export interface Data_projectProgramSections_data_guests {
  __typename: "GuestCollection";
  data: Data_projectProgramSections_data_guests_data[];
}

export interface Data_projectProgramSections_data {
  __typename: "ProjectProgramSection";
  id: number;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  duration: number;
  guests: Data_projectProgramSections_data_guests | null;
}

export interface Data_projectProgramSections {
  __typename: "ProjectProgramSectionCollection";
  data: Data_projectProgramSections_data[];
}

export interface Data {
  projectProgramSections: Data_projectProgramSections;
}

export interface DataVariables {
  where?: ProjectProgramSectionWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectSectionsSubmissions
// ====================================================

export interface ProjectSectionsSubmissions_projectSectionSubmissions_voting {
  __typename: "ProjectVotingSection";
  id: number;
  title: string;
}

export interface ProjectSectionsSubmissions_projectSectionSubmissions_competition {
  __typename: "ProjectCompetitionSection";
  id: number;
  title: string;
}

export interface ProjectSectionsSubmissions_projectSectionSubmissions {
  __typename: "ProjectSection";
  id: number;
  type: ProjectSectionType;
  voting: ProjectSectionsSubmissions_projectSectionSubmissions_voting | null;
  competition: ProjectSectionsSubmissions_projectSectionSubmissions_competition | null;
}

export interface ProjectSectionsSubmissions {
  projectSectionSubmissions: (ProjectSectionsSubmissions_projectSectionSubmissions | null)[];
}

export interface ProjectSectionsSubmissionsVariables {
  projectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectCompetitionSectionSubmissions
// ====================================================

export interface ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions_data {
  __typename: "ProjectCompetitionSectionSubmission";
  id: number;
  email: string;
  projectCompetitionSectionId: number;
  phone: string;
}

export interface ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions {
  __typename: "ProjectCompetitionSectionSubmissionCollection";
  data: ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions_data[];
  pageInfo: ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions_pageInfo;
}

export interface ProjectCompetitionSectionSubmissions {
  projectCompetitionSectionSubmissions: ProjectCompetitionSectionSubmissions_projectCompetitionSectionSubmissions;
}

export interface ProjectCompetitionSectionSubmissionsVariables {
  where?: ProjectCompetitionSectionSubmissionWhereInput | null;
  orderBy?: ProjectCompetitionSectionSubmissionOrderByInput[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectVotingSectionVotes
// ====================================================

export interface ProjectVotingSectionVotes_projectVotingSectionVotes_data {
  __typename: "ProjectVotingSectionVote";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  projectVotingSectionId: number;
}

export interface ProjectVotingSectionVotes_projectVotingSectionVotes_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface ProjectVotingSectionVotes_projectVotingSectionVotes {
  __typename: "ProjectVotingSectionVoteCollection";
  data: ProjectVotingSectionVotes_projectVotingSectionVotes_data[];
  pageInfo: ProjectVotingSectionVotes_projectVotingSectionVotes_pageInfo;
}

export interface ProjectVotingSectionVotes {
  projectVotingSectionVotes: ProjectVotingSectionVotes_projectVotingSectionVotes;
}

export interface ProjectVotingSectionVotesVariables {
  where?: ProjectVotingSectionVoteWhereInput | null;
  orderBy?: ProjectVotingSectionVoteOrderByInput[] | null;
  skip?: number | null;
  take?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectCompetitionSectionSubmissionsCSV
// ====================================================

export interface ProjectCompetitionSectionSubmissionsCSV {
  projectCompetitionSectionSubmissionsCSV: string;
}

export interface ProjectCompetitionSectionSubmissionsCSVVariables {
  where?: ProjectCompetitionSectionSubmissionWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectVotingSectionVotesCSV
// ====================================================

export interface ProjectVotingSectionVotesCSV {
  projectVotingSectionVotesCSV: string;
}

export interface ProjectVotingSectionVotesCSVVariables {
  where?: ProjectVotingSectionVoteWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProgramGroupQuery
// ====================================================

export interface ProgramGroupQuery {
  programGroupQuery: any;
}

export interface ProgramGroupQueryVariables {
  projectSectionId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditProjectFormQuery
// ====================================================

export interface EditProjectFormQuery_uniqueProject {
  __typename: "Project";
  published: boolean;
  link: string | null;
  contactEmail: string | null;
  coverImage: string | null;
  matchesDisplayCount: number | null;
}

export interface EditProjectFormQuery {
  uniqueProject: EditProjectFormQuery_uniqueProject | null;
}

export interface EditProjectFormQueryVariables {
  where: ProjectWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectTranslation
// ====================================================

export interface ProjectTranslation_projectTranslation {
  __typename: "ProjectTranslation";
  id: number;
  projectId: number;
  locale: Locale;
  name: string;
}

export interface ProjectTranslation {
  projectTranslation: ProjectTranslation_projectTranslation | null;
}

export interface ProjectTranslationVariables {
  where: ProjectTranslationWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UniqueProjectTranslation
// ====================================================

export interface UniqueProjectTranslation_uniqueProjectTranslation {
  __typename: "ProjectTranslation";
  id: number;
  projectId: number;
  locale: Locale;
  name: string;
}

export interface UniqueProjectTranslation {
  uniqueProjectTranslation: UniqueProjectTranslation_uniqueProjectTranslation | null;
}

export interface UniqueProjectTranslationVariables {
  where: ProjectTranslationWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectSections
// ====================================================

export interface ProjectSections_projectSections_data_translations_data {
  __typename: "ProjectSectionTranslation";
  id: number;
  order: number;
  locale: Locale;
}

export interface ProjectSections_projectSections_data_translations {
  __typename: "ProjectSectionTranslationCollection";
  data: ProjectSections_projectSections_data_translations_data[];
}

export interface ProjectSections_projectSections_data {
  __typename: "ProjectSection";
  id: number;
  projectId: number;
  locale: Locale | null;
  type: ProjectSectionType;
  contentTableName: string | null;
  icon: string | null;
  addToContentTable: boolean;
  translations: ProjectSections_projectSections_data_translations | null;
}

export interface ProjectSections_projectSections {
  __typename: "ProjectSectionCollection";
  data: ProjectSections_projectSections_data[];
}

export interface ProjectSections {
  projectSections: ProjectSections_projectSections;
}

export interface ProjectSectionsVariables {
  where: ProjectSectionWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectSectionsIcons
// ====================================================

export interface ProjectSectionsIcons_projectSections_data {
  __typename: "ProjectSection";
  icon: string | null;
}

export interface ProjectSectionsIcons_projectSections {
  __typename: "ProjectSectionCollection";
  data: ProjectSectionsIcons_projectSections_data[];
}

export interface ProjectSectionsIcons {
  projectSections: ProjectSectionsIcons_projectSections;
}

export interface ProjectSectionsIconsVariables {
  where: ProjectSectionWhereInput;
  orderBy?: ProjectSectionOrderByInput[] | null;
  take?: number | null;
  distinct?: ProjectSectionScalarField[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectSection
// ====================================================

export interface ProjectSection_projectSection_translations_data {
  __typename: "ProjectSectionTranslation";
  id: number;
  order: number;
  locale: Locale;
}

export interface ProjectSection_projectSection_translations {
  __typename: "ProjectSectionTranslationCollection";
  data: ProjectSection_projectSection_translations_data[];
}

export interface ProjectSection_projectSection_video {
  __typename: "ProjectVideoSection";
  id: number;
  title: string | null;
  videosPerRow: number | null;
  links: string[];
}

export interface ProjectSection_projectSection_figure {
  __typename: "ProjectFigureSection";
  id: number;
  title: string | null;
  caption: string | null;
  image: string | null;
}

export interface ProjectSection_projectSection_text {
  __typename: "ProjectTextSection";
  id: number;
  title: string | null;
  image: string | null;
  imagePosition: ProjectSectionImagePosition | null;
  buttonLink: string | null;
  buttonText: string | null;
  text: string | null;
}

export interface ProjectSection_projectSection_articles {
  __typename: "ProjectArticlesSection";
  id: number;
  tags: string[];
}

export interface ProjectSection_projectSection_voting_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectSection_projectSection_voting {
  __typename: "ProjectVotingSection";
  id: number;
  tournamentId: number;
  title: string;
  slotCount: number;
  teams: ProjectSection_projectSection_voting_teams[] | null;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectSection_projectSection_competition {
  __typename: "ProjectCompetitionSection";
  id: number;
  title: string;
  buttonLabel: string;
  successMessage: string;
  terms: string;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectSection_projectSection_guests_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectSection_projectSection_guests_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectSection_projectSection_guests_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectSection_projectSection_guests_guests_data_media | null;
  address: ProjectSection_projectSection_guests_guests_data_address | null;
  photo: string | null;
}

export interface ProjectSection_projectSection_guests_guests {
  __typename: "GuestCollection";
  data: ProjectSection_projectSection_guests_guests_data[];
}

export interface ProjectSection_projectSection_guests {
  __typename: "ProjectGuestsSection";
  id: number;
  guests: ProjectSection_projectSection_guests_guests | null;
}

export interface ProjectSection_projectSection_partnerTypes_data_partners_data {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectSection_projectSection_partnerTypes_data_partners {
  __typename: "PartnerCollection";
  data: ProjectSection_projectSection_partnerTypes_data_partners_data[];
}

export interface ProjectSection_projectSection_partnerTypes_data {
  __typename: "ProjectPartnerType";
  id: number;
  name: string;
  order: number;
  tilesPerRowMobile: number | null;
  tilesPerRowDesktop: number | null;
  partners: ProjectSection_projectSection_partnerTypes_data_partners | null;
}

export interface ProjectSection_projectSection_partnerTypes {
  __typename: "ProjectPartnerTypeCollection";
  data: ProjectSection_projectSection_partnerTypes_data[];
}

export interface ProjectSection_projectSection_attractions_data_partner {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectSection_projectSection_attractions_data {
  __typename: "ProjectAttractionsSection";
  id: number;
  title: string;
  priority: ProjectAttractionPriority;
  order: number;
  description: string | null;
  partnerId: number | null;
  image: string | null;
  partner: ProjectSection_projectSection_attractions_data_partner | null;
}

export interface ProjectSection_projectSection_attractions {
  __typename: "ProjectAttractionsSectionCollection";
  data: ProjectSection_projectSection_attractions_data[];
}

export interface ProjectSection_projectSection_programs_data_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectSection_projectSection_programs_data_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectSection_projectSection_programs_data_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectSection_projectSection_programs_data_guests_data_media | null;
  address: ProjectSection_projectSection_programs_data_guests_data_address | null;
  photo: string | null;
}

export interface ProjectSection_projectSection_programs_data_guests {
  __typename: "GuestCollection";
  data: ProjectSection_projectSection_programs_data_guests_data[];
}

export interface ProjectSection_projectSection_programs_data {
  __typename: "ProjectProgramSection";
  id: number;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  duration: number;
  guests: ProjectSection_projectSection_programs_data_guests | null;
}

export interface ProjectSection_projectSection_programs {
  __typename: "ProjectProgramSectionCollection";
  data: ProjectSection_projectSection_programs_data[];
}

export interface ProjectSection_projectSection_questionsAndAnswers_data {
  __typename: "ProjectQuestionAndAnswer";
  id: number;
  title: string | null;
  text: string | null;
}

export interface ProjectSection_projectSection_questionsAndAnswers {
  __typename: "ProjectQuestionAndAnswerCollection";
  data: ProjectSection_projectSection_questionsAndAnswers_data[];
}

export interface ProjectSection_projectSection_tournaments_data_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface ProjectSection_projectSection_tournaments_data_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: ProjectSection_projectSection_tournaments_data_game_gameId | null;
  tags: string[] | null;
}

export interface ProjectSection_projectSection_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: ProjectSection_projectSection_tournaments_data_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
}

export interface ProjectSection_projectSection_tournaments {
  __typename: "TournamentCollection";
  data: ProjectSection_projectSection_tournaments_data[];
}

export interface ProjectSection_projectSection_tournamentMatches_matches_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectSection_projectSection_tournamentMatches_matches_teamA_playersUserData[] | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectSection_projectSection_tournamentMatches_matches_teamB_playersUserData[] | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: ProjectSection_projectSection_tournamentMatches_matches_tournament_game | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: ProjectSection_projectSection_tournamentMatches_matches_rounds_map | null;
}

export interface ProjectSection_projectSection_tournamentMatches_matches {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: ProjectSection_projectSection_tournamentMatches_matches_teamA | null;
  teamB: ProjectSection_projectSection_tournamentMatches_matches_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: ProjectSection_projectSection_tournamentMatches_matches_tournament;
  stage: ProjectSection_projectSection_tournamentMatches_matches_stage;
  rounds: ProjectSection_projectSection_tournamentMatches_matches_rounds[];
}

export interface ProjectSection_projectSection_tournamentMatches {
  __typename: "ProjectSectionTournamentMatchGrouping";
  tournamentId: number;
  matches: ProjectSection_projectSection_tournamentMatches_matches[];
}

export interface ProjectSection_projectSection_tournamentTeams_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectSection_projectSection_tournamentTeams {
  __typename: "ProjectSectionTournamentTeamGrouping";
  tournamentId: number;
  tournamentName: string | null;
  teams: ProjectSection_projectSection_tournamentTeams_teams[];
}

export interface ProjectSection_projectSection_tournaments_relation_stages_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface ProjectSection_projectSection_tournaments_relation_stages {
  __typename: "TournamentCollection";
  data: ProjectSection_projectSection_tournaments_relation_stages_data[];
}

export interface ProjectSection_projectSection_tournaments_relation_matches_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectSection_projectSection_tournaments_relation_matches {
  __typename: "TournamentCollection";
  data: ProjectSection_projectSection_tournaments_relation_matches_data[];
}

export interface ProjectSection_projectSection_tournaments_relation_teams_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectSection_projectSection_tournaments_relation_teams {
  __typename: "TournamentCollection";
  data: ProjectSection_projectSection_tournaments_relation_teams_data[];
}

export interface ProjectSection_projectSection {
  __typename: "ProjectSection";
  id: number;
  projectId: number;
  locale: Locale | null;
  type: ProjectSectionType;
  contentTableName: string | null;
  icon: string | null;
  addToContentTable: boolean;
  translations: ProjectSection_projectSection_translations | null;
  video: ProjectSection_projectSection_video | null;
  figure: ProjectSection_projectSection_figure | null;
  text: ProjectSection_projectSection_text | null;
  articles: ProjectSection_projectSection_articles | null;
  voting: ProjectSection_projectSection_voting | null;
  competition: ProjectSection_projectSection_competition | null;
  guests: ProjectSection_projectSection_guests | null;
  partnerTypes: ProjectSection_projectSection_partnerTypes | null;
  attractions: ProjectSection_projectSection_attractions | null;
  programs: ProjectSection_projectSection_programs | null;
  questionsAndAnswers: ProjectSection_projectSection_questionsAndAnswers | null;
  tournaments: ProjectSection_projectSection_tournaments | null;
  tournamentMatches: ProjectSection_projectSection_tournamentMatches[] | null;
  tournamentTeams: ProjectSection_projectSection_tournamentTeams[] | null;
  tournaments_relation_stages: ProjectSection_projectSection_tournaments_relation_stages | null;
  tournaments_relation_matches: ProjectSection_projectSection_tournaments_relation_matches | null;
  tournaments_relation_teams: ProjectSection_projectSection_tournaments_relation_teams | null;
}

export interface ProjectSection {
  projectSection: ProjectSection_projectSection | null;
}

export interface ProjectSectionVariables {
  where: ProjectSectionWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProjectSection
// ====================================================

export interface CreateProjectSection_createProjectSection {
  __typename: "ProjectSection";
  id: number;
}

export interface CreateProjectSection {
  createProjectSection: CreateProjectSection_createProjectSection;
}

export interface CreateProjectSectionVariables {
  data: ProjectSectionCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProjectSection
// ====================================================

export interface DeleteProjectSection_deleteProjectSection {
  __typename: "ProjectSection";
  id: number;
}

export interface DeleteProjectSection {
  deleteProjectSection: DeleteProjectSection_deleteProjectSection;
}

export interface DeleteProjectSectionVariables {
  where: ProjectSectionWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveProjectSection
// ====================================================

export interface MoveProjectSection {
  moveSection: boolean | null;
}

export interface MoveProjectSectionVariables {
  data: ReorderProjectSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProjectSection
// ====================================================

export interface UpdateProjectSection_updateProjectSection {
  __typename: "ProjectSection";
  id: number;
}

export interface UpdateProjectSection {
  updateProjectSection: UpdateProjectSection_updateProjectSection;
}

export interface UpdateProjectSectionVariables {
  where: ProjectSectionWhereUniqueInput;
  data: ProjectSectionUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectFormProjectTranslationsQuery
// ====================================================

export interface ProjectFormProjectTranslationsQuery_projectTranslations_data {
  __typename: "ProjectTranslation";
  id: number;
  locale: Locale;
}

export interface ProjectFormProjectTranslationsQuery_projectTranslations {
  __typename: "ProjectTranslationCollection";
  data: ProjectFormProjectTranslationsQuery_projectTranslations_data[];
}

export interface ProjectFormProjectTranslationsQuery {
  projectTranslations: ProjectFormProjectTranslationsQuery_projectTranslations;
}

export interface ProjectFormProjectTranslationsQueryVariables {
  where?: ProjectTranslationWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProject
// ====================================================

export interface CreateProject_createProject {
  __typename: "Project";
  id: number;
}

export interface CreateProject {
  createProject: CreateProject_createProject;
}

export interface CreateProjectVariables {
  data: ProjectCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProject
// ====================================================

export interface UpdateProject_updateProject {
  __typename: "Project";
  id: number;
}

export interface UpdateProject {
  updateProject: UpdateProject_updateProject;
}

export interface UpdateProjectVariables {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProject
// ====================================================

export interface DeleteProject_deleteProject {
  __typename: "Project";
  id: number;
}

export interface DeleteProject {
  deleteProject: DeleteProject_deleteProject;
}

export interface DeleteProjectVariables {
  where: ProjectWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProjectVotingSectionVote
// ====================================================

export interface CreateProjectVotingSectionVote_createProjectVotingSectionVote {
  __typename: "ProjectVotingSectionVote";
  id: number;
}

export interface CreateProjectVotingSectionVote {
  createProjectVotingSectionVote: CreateProjectVotingSectionVote_createProjectVotingSectionVote;
}

export interface CreateProjectVotingSectionVoteVariables {
  data: ProjectVotingSectionVoteCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProjectCompetitionSectionSubmission
// ====================================================

export interface CreateProjectCompetitionSectionSubmission_createProjectCompetitionSectionSubmission {
  __typename: "ProjectCompetitionSectionSubmission";
  id: number;
}

export interface CreateProjectCompetitionSectionSubmission {
  createProjectCompetitionSectionSubmission: CreateProjectCompetitionSectionSubmission_createProjectCompetitionSectionSubmission;
}

export interface CreateProjectCompetitionSectionSubmissionVariables {
  data: ProjectCompetitionSectionSubmissionCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProjectContactFormSubmission
// ====================================================

export interface CreateProjectContactFormSubmission_createProjectContactFormSubmission {
  __typename: "ProjectContactFormSubmission";
  id: number;
}

export interface CreateProjectContactFormSubmission {
  createProjectContactFormSubmission: CreateProjectContactFormSubmission_createProjectContactFormSubmission;
}

export interface CreateProjectContactFormSubmissionVariables {
  data: ProjectContactFormSubmissionCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProjectTranslation
// ====================================================

export interface CreateProjectTranslation_createProjectTranslation {
  __typename: "ProjectTranslation";
  id: number;
}

export interface CreateProjectTranslation {
  createProjectTranslation: CreateProjectTranslation_createProjectTranslation;
}

export interface CreateProjectTranslationVariables {
  data: ProjectTranslationCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProjectTranslation
// ====================================================

export interface UpdateProjectTranslation_updateProjectTranslation {
  __typename: "ProjectTranslation";
  id: number;
}

export interface UpdateProjectTranslation {
  updateProjectTranslation: UpdateProjectTranslation_updateProjectTranslation;
}

export interface UpdateProjectTranslationVariables {
  where: ProjectTranslationWhereUniqueInput;
  data: ProjectTranslationUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProjectTranslation
// ====================================================

export interface DeleteProjectTranslation_deleteProjectTranslation {
  __typename: "ProjectTranslation";
  id: number;
}

export interface DeleteProjectTranslation {
  deleteProjectTranslation: DeleteProjectTranslation_deleteProjectTranslation;
}

export interface DeleteProjectTranslationVariables {
  where: ProjectTranslationWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Redirects
// ====================================================

export interface Redirects_redirects_data {
  __typename: "Redirect";
  id: number;
  url: string;
  redirectUrl: string;
}

export interface Redirects_redirects_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Redirects_redirects {
  __typename: "PaginatedRedirects";
  data: Redirects_redirects_data[];
  pageInfo: Redirects_redirects_pageInfo;
}

export interface Redirects {
  redirects: Redirects_redirects;
}

export interface RedirectsVariables {
  where?: RedirectWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRedirect
// ====================================================

export interface CreateRedirect_createRedirect {
  __typename: "Redirect";
  id: number;
}

export interface CreateRedirect {
  createRedirect: CreateRedirect_createRedirect;
}

export interface CreateRedirectVariables {
  data: RedirectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRedirect
// ====================================================

export interface DeleteRedirect_deleteRedirect {
  __typename: "Redirect";
  id: number;
}

export interface DeleteRedirect {
  deleteRedirect: DeleteRedirect_deleteRedirect;
}

export interface DeleteRedirectVariables {
  where: RedirectWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserRolesPage
// ====================================================

export interface UserRolesPage_users_data_roles {
  __typename: "UserRole";
  role: Role;
}

export interface UserRolesPage_users_data {
  __typename: "User";
  id: number;
  email: string;
  nickname: string | null;
  name: string | null;
  surname: string | null;
  roles: UserRolesPage_users_data_roles[] | null;
}

export interface UserRolesPage_users_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface UserRolesPage_users {
  __typename: "PaginatedUsers";
  data: UserRolesPage_users_data[];
  pageInfo: UserRolesPage_users_pageInfo;
}

export interface UserRolesPage {
  users: UserRolesPage_users;
}

export interface UserRolesPageVariables {
  where?: UserWhereInput | null;
  orderBy?: UserOrderByInput[] | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Tags
// ====================================================

export interface Tags_tags_data {
  __typename: "Tag";
  id: number;
  name: string;
  createdAt: any;
}

export interface Tags_tags_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Tags_tags {
  __typename: "PaginatedTags";
  data: Tags_tags_data[];
  pageInfo: Tags_tags_pageInfo;
}

export interface Tags {
  tags: Tags_tags;
}

export interface TagsVariables {
  where?: TagWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TagOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Tag
// ====================================================

export interface Tag_uniqueTag {
  __typename: "Tag";
  id: number;
  name: string;
  createdAt: any;
}

export interface Tag {
  uniqueTag: Tag_uniqueTag | null;
}

export interface TagVariables {
  where: TagWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTag
// ====================================================

export interface CreateTag_createTag {
  __typename: "Tag";
  id: number;
}

export interface CreateTag {
  createTag: CreateTag_createTag;
}

export interface CreateTagVariables {
  data: TagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTag
// ====================================================

export interface UpdateTag_updateTag {
  __typename: "Tag";
  id: number;
}

export interface UpdateTag {
  updateTag: UpdateTag_updateTag;
}

export interface UpdateTagVariables {
  where: TagWhereUniqueInput;
  data: TagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTag
// ====================================================

export interface DeleteTag_deleteTag {
  __typename: "Tag";
  id: number;
}

export interface DeleteTag {
  deleteTag: DeleteTag_deleteTag;
}

export interface DeleteTagVariables {
  where: TagWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTeam
// ====================================================

export interface CreateTeam_createTeam {
  __typename: "QueryResult";
  type: string;
  message: string;
}

export interface CreateTeam {
  createTeam: CreateTeam_createTeam;
}

export interface CreateTeamVariables {
  team: TeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegisterTeam
// ====================================================

export interface RegisterTeam_registerTeam {
  __typename: "QueryResult";
  type: string;
  message: string;
}

export interface RegisterTeam {
  registerTeam: RegisterTeam_registerTeam;
}

export interface RegisterTeamVariables {
  email?: string | null;
  tournamentId: number;
  team: TournamentTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResolveInvitation
// ====================================================

export interface ResolveInvitation_resolveInvitation {
  __typename: "TeamInvitation";
  id: number;
}

export interface ResolveInvitation {
  resolveInvitation: ResolveInvitation_resolveInvitation;
}

export interface ResolveInvitationVariables {
  id: number;
  resolution: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTeams
// ====================================================

export interface UserTeams_userTeams_game {
  __typename: "Game";
  id: number;
  name: string;
}

export interface UserTeams_userTeams {
  __typename: "Team";
  id: number;
  ownerId: number | null;
  name: string;
  game: UserTeams_userTeams_game | null;
  logo: string | null;
  players: any;
}

export interface UserTeams {
  userTeams: UserTeams_userTeams[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTournamentTeams
// ====================================================

export interface UserTournamentTeams_userTournamentTeams_tournament_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface UserTournamentTeams_userTournamentTeams_tournament_game {
  __typename: "Game";
  id: number;
  name: string;
  gameId: UserTournamentTeams_userTournamentTeams_tournament_game_gameId | null;
}

export interface UserTournamentTeams_userTournamentTeams_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  startDate: any | null;
  endDate: any | null;
  capMax: number | null;
  checkedInRegistrationsCount: number | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  gameId: number | null;
  publicMatches: boolean | null;
  externalTournament: string | null;
  game: UserTournamentTeams_userTournamentTeams_tournament_game | null;
}

export interface UserTournamentTeams_userTournamentTeams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  tournamentId: number;
  tournament: UserTournamentTeams_userTournamentTeams_tournament;
  pending: boolean | null;
  pendingPosition: number | null;
  state: number | null;
  confirmed: boolean | null;
}

export interface UserTournamentTeams {
  userTournamentTeams: UserTournamentTeams_userTournamentTeams[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInvitations
// ====================================================

export interface UserInvitations_userInvitations_team {
  __typename: "Team";
  id: number;
  name: string;
}

export interface UserInvitations_userInvitations {
  __typename: "TeamInvitation";
  id: number;
  team: UserInvitations_userInvitations_team | null;
}

export interface UserInvitations {
  userInvitations: UserInvitations_userInvitations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentRegistrationPageQuery
// ====================================================

export interface TournamentRegistrationPageQuery_tournament_game_gameId {
  __typename: "GameId";
  name: string;
}

export interface TournamentRegistrationPageQuery_tournament_game {
  __typename: "Game";
  id: number;
  name: string;
  gameId: TournamentRegistrationPageQuery_tournament_game_gameId | null;
}

export interface TournamentRegistrationPageQuery_tournament_customFields_typed {
  __typename: "CustomField";
  name: string | null;
  editable: boolean | null;
  required: boolean | null;
  data: any | null;
}

export interface TournamentRegistrationPageQuery_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
  game: TournamentRegistrationPageQuery_tournament_game | null;
  type: string | null;
  enablePending: boolean | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMax: number | null;
  registrationsCount: number | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  customFields: any | null;
  customFields_typed: TournamentRegistrationPageQuery_tournament_customFields_typed[] | null;
}

export interface TournamentRegistrationPageQuery {
  tournament: TournamentRegistrationPageQuery_tournament | null;
}

export interface TournamentRegistrationPageQueryVariables {
  where: TournamentWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentStages
// ====================================================

export interface TournamentStages_tournamentStages_data_prizes {
  __typename: "TournamentPrize";
  id: number;
  placement: number[];
  state: TournamentPrizeState | null;
}

export interface TournamentStages_tournamentStages_data {
  __typename: "TournamentStage";
  id: number;
  type: TournamentStageType | null;
  dateStart: any | null;
  dateEnd: any | null;
  name: string | null;
  hasLoserMatch: boolean | null;
  entering: number | null;
  leaving: number | null;
  active: boolean | null;
  prizes: TournamentStages_tournamentStages_data_prizes[] | null;
}

export interface TournamentStages_tournamentStages_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentStages_tournamentStages {
  __typename: "PaginatedTournamentStages";
  data: TournamentStages_tournamentStages_data[];
  pageInfo: TournamentStages_tournamentStages_pageInfo;
}

export interface TournamentStages {
  tournamentStages: TournamentStages_tournamentStages;
}

export interface TournamentStagesVariables {
  where?: TournamentStageWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTournamentStage
// ====================================================

export interface CreateTournamentStage_createTournamentStage {
  __typename: "TournamentStage";
  id: number;
}

export interface CreateTournamentStage {
  createTournamentStage: CreateTournamentStage_createTournamentStage;
}

export interface CreateTournamentStageVariables {
  data: TournamentStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournamentStage
// ====================================================

export interface DeleteTournamentStage_deleteTournamentStage {
  __typename: "TournamentStage";
  id: number;
}

export interface DeleteTournamentStage {
  deleteTournamentStage: DeleteTournamentStage_deleteTournamentStage;
}

export interface DeleteTournamentStageVariables {
  where: TournamentStageWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentLadder
// ====================================================

export interface TournamentLadder_tournamentLadder_rankings {
  __typename: "TournamentTeamRank";
  teamId: number;
  teamName: string;
  bucket: string | null;
  ourScore: number;
  theirScore: number;
  matchesPlayed: number;
  points: number;
  wins: number;
  loses: number;
  draws: number;
}

export interface TournamentLadder_tournamentLadder {
  __typename: "TournamentBucketRank";
  bucket: string | null;
  rankings: TournamentLadder_tournamentLadder_rankings[];
}

export interface TournamentLadder {
  tournamentLadder: TournamentLadder_tournamentLadder[];
}

export interface TournamentLadderVariables {
  tournamentId: number;
  stageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentTeamState
// ====================================================

export interface UpdateTournamentTeamState_updateTournamentTeamState {
  __typename: "TournamentTeam";
  id: number;
}

export interface UpdateTournamentTeamState {
  updateTournamentTeamState: UpdateTournamentTeamState_updateTournamentTeamState;
}

export interface UpdateTournamentTeamStateVariables {
  teamId: number;
  state: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentTeams
// ====================================================

export interface TournamentTeams_tournamentTeams_data {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface TournamentTeams_tournamentTeams_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentTeams_tournamentTeams {
  __typename: "PaginatedTournamentTeams";
  data: TournamentTeams_tournamentTeams_data[];
  pageInfo: TournamentTeams_tournamentTeams_pageInfo;
}

export interface TournamentTeams {
  tournamentTeams: TournamentTeams_tournamentTeams;
}

export interface TournamentTeamsVariables {
  where?: TournamentTeamWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentTeamSelect
// ====================================================

export interface TournamentTeamSelect_tournamentTeams_data {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentTeamSelect_tournamentTeams_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface TournamentTeamSelect_tournamentTeams {
  __typename: "PaginatedTournamentTeams";
  data: TournamentTeamSelect_tournamentTeams_data[];
  pageInfo: TournamentTeamSelect_tournamentTeams_pageInfo;
}

export interface TournamentTeamSelect {
  tournamentTeams: TournamentTeamSelect_tournamentTeams;
}

export interface TournamentTeamSelectVariables {
  where?: TournamentTeamWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GameFilter
// ====================================================

export interface GameFilter_games_data {
  __typename: "Game";
  id: number;
  name: string;
  coverImage: string | null;
  filterOrder: number | null;
}

export interface GameFilter_games_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface GameFilter_games {
  __typename: "PaginatedGames";
  data: GameFilter_games_data[];
  pageInfo: GameFilter_games_pageInfo;
}

export interface GameFilter {
  games: GameFilter_games;
}

export interface GameFilterVariables {
  where?: GameWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: GameOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Tournaments
// ====================================================

export interface Tournaments_tournaments_data_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface Tournaments_tournaments_data_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: Tournaments_tournaments_data_game_gameId | null;
  tags: string[] | null;
}

export interface Tournaments_tournaments_data_admins {
  __typename: "User";
  email: string;
  name: string | null;
  surname: string | null;
  nickname: string | null;
}

export interface Tournaments_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: Tournaments_tournaments_data_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
  admins: (Tournaments_tournaments_data_admins | null)[] | null;
}

export interface Tournaments_tournaments_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Tournaments_tournaments {
  __typename: "PaginatedTournaments";
  data: Tournaments_tournaments_data[];
  pageInfo: Tournaments_tournaments_pageInfo;
}

export interface Tournaments {
  tournaments: Tournaments_tournaments;
}

export interface TournamentsVariables {
  where?: TournamentWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TournamentOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentSelect
// ====================================================

export interface TournamentSelect_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface TournamentSelect_tournaments_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
  hasNextPage: boolean;
}

export interface TournamentSelect_tournaments {
  __typename: "PaginatedTournaments";
  data: TournamentSelect_tournaments_data[];
  pageInfo: TournamentSelect_tournaments_pageInfo;
}

export interface TournamentSelect {
  tournaments: TournamentSelect_tournaments;
}

export interface TournamentSelectVariables {
  where?: TournamentWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TournamentOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentInputs
// ====================================================

export interface TournamentInputs_tournaments_data_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface TournamentInputs_tournaments_data_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: TournamentInputs_tournaments_data_game_gameId | null;
  tags: string[] | null;
}

export interface TournamentInputs_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: TournamentInputs_tournaments_data_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
}

export interface TournamentInputs_tournaments_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentInputs_tournaments {
  __typename: "PaginatedTournaments";
  data: TournamentInputs_tournaments_data[];
  pageInfo: TournamentInputs_tournaments_pageInfo;
}

export interface TournamentInputs {
  tournaments: TournamentInputs_tournaments;
}

export interface TournamentInputsVariables {
  where?: TournamentWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TournamentOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Tournament
// ====================================================

export interface Tournament_tournament_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface Tournament_tournament_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: Tournament_tournament_game_gameId | null;
  tags: string[] | null;
}

export interface Tournament_tournament_admins {
  __typename: "User";
  email: string;
  name: string | null;
  surname: string | null;
  nickname: string | null;
}

export interface Tournament_tournament_prizes_customType {
  __typename: "TournamentCustomPrizeType";
  name: string;
}

export interface Tournament_tournament_prizes_currency {
  __typename: "Currency";
  name: string;
  code: string;
}

export interface Tournament_tournament_prizes_tournamentStage {
  __typename: "TournamentStage";
  id: number;
  name: string | null;
}

export interface Tournament_tournament_prizes_winners {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface Tournament_tournament_prizes_actionHistory_team {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface Tournament_tournament_prizes_actionHistory_author {
  __typename: "User";
  name: string | null;
  surname: string | null;
  email: string;
}

export interface Tournament_tournament_prizes_actionHistory {
  __typename: "TournamentPrizeAction";
  id: number;
  createdAt: any;
  prizeId: number;
  teamId: number | null;
  state: TournamentPrizeState;
  team: Tournament_tournament_prizes_actionHistory_team | null;
  author: Tournament_tournament_prizes_actionHistory_author | null;
}

export interface Tournament_tournament_prizes {
  __typename: "TournamentPrize";
  id: number;
  availablePlacementsCount: number;
  customTypeId: number | null;
  customType: Tournament_tournament_prizes_customType | null;
  currencyId: number | null;
  currency: Tournament_tournament_prizes_currency | null;
  quantity: number;
  placement: number[];
  placementType: TournamentPrizePlacementType;
  tournamentId: number;
  tournamentStageId: number | null;
  tournamentStage: Tournament_tournament_prizes_tournamentStage | null;
  type: TournamentPrizeType | null;
  state: TournamentPrizeState | null;
  winners: Tournament_tournament_prizes_winners[] | null;
  actionHistory: Tournament_tournament_prizes_actionHistory[] | null;
}

export interface Tournament_tournament_groupingValue {
  __typename: "TournamentGroupingValue";
  groupingId: number;
}

export interface Tournament_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: Tournament_tournament_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
  admins: (Tournament_tournament_admins | null)[] | null;
  prizes: Tournament_tournament_prizes[] | null;
  groupingValue: Tournament_tournament_groupingValue | null;
}

export interface Tournament {
  tournament: Tournament_tournament | null;
}

export interface TournamentVariables {
  where: TournamentWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentDetails
// ====================================================

export interface TournamentDetails_tournament_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface TournamentDetails_tournament_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: TournamentDetails_tournament_game_gameId | null;
  tags: string[] | null;
}

export interface TournamentDetails_tournament_admins {
  __typename: "User";
  email: string;
  name: string | null;
  surname: string | null;
  nickname: string | null;
}

export interface TournamentDetails_tournament_prizes_customType {
  __typename: "TournamentCustomPrizeType";
  name: string;
}

export interface TournamentDetails_tournament_prizes_currency {
  __typename: "Currency";
  name: string;
  code: string;
}

export interface TournamentDetails_tournament_prizes_tournamentStage {
  __typename: "TournamentStage";
  id: number;
  name: string | null;
}

export interface TournamentDetails_tournament_prizes_winners {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentDetails_tournament_prizes_actionHistory_team {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentDetails_tournament_prizes_actionHistory_author {
  __typename: "User";
  name: string | null;
  surname: string | null;
  email: string;
}

export interface TournamentDetails_tournament_prizes_actionHistory {
  __typename: "TournamentPrizeAction";
  id: number;
  createdAt: any;
  prizeId: number;
  teamId: number | null;
  state: TournamentPrizeState;
  team: TournamentDetails_tournament_prizes_actionHistory_team | null;
  author: TournamentDetails_tournament_prizes_actionHistory_author | null;
}

export interface TournamentDetails_tournament_prizes {
  __typename: "TournamentPrize";
  id: number;
  availablePlacementsCount: number;
  customTypeId: number | null;
  customType: TournamentDetails_tournament_prizes_customType | null;
  currencyId: number | null;
  currency: TournamentDetails_tournament_prizes_currency | null;
  quantity: number;
  placement: number[];
  placementType: TournamentPrizePlacementType;
  tournamentId: number;
  tournamentStageId: number | null;
  tournamentStage: TournamentDetails_tournament_prizes_tournamentStage | null;
  type: TournamentPrizeType | null;
  state: TournamentPrizeState | null;
  winners: TournamentDetails_tournament_prizes_winners[] | null;
  actionHistory: TournamentDetails_tournament_prizes_actionHistory[] | null;
}

export interface TournamentDetails_tournament_groupingValue {
  __typename: "TournamentGroupingValue";
  groupingId: number;
}

export interface TournamentDetails_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: TournamentDetails_tournament_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
  admins: (TournamentDetails_tournament_admins | null)[] | null;
  prizes: TournamentDetails_tournament_prizes[] | null;
  groupingValue: TournamentDetails_tournament_groupingValue | null;
}

export interface TournamentDetails_tournamentTeams_aggregation {
  __typename: "TournamentTeamsAggregation";
  confirmedCount: number;
}

export interface TournamentDetails_tournamentTeams_data {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface TournamentDetails_tournamentTeams_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentDetails_tournamentTeams {
  __typename: "PaginatedTournamentTeams";
  aggregation: TournamentDetails_tournamentTeams_aggregation;
  data: TournamentDetails_tournamentTeams_data[];
  pageInfo: TournamentDetails_tournamentTeams_pageInfo;
}

export interface TournamentDetails {
  tournament: TournamentDetails_tournament | null;
  tournamentTeams: TournamentDetails_tournamentTeams;
}

export interface TournamentDetailsVariables {
  tournamentWhere: TournamentWhereInput;
  tournamentTeamWhere?: TournamentTeamWhereInput | null;
  tournamentTeamOffset?: number | null;
  tournamentTeamLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentTeamsCSV
// ====================================================

export interface TournamentTeamsCSV {
  tournamentTeamsCSV: string;
}

export interface TournamentTeamsCSVVariables {
  where?: TournamentTeamWhereInput | null;
  orderBy?: TournamentTeamOrderByInput[] | null;
  offset?: number | null;
  limit?: number | null;
  cursor?: TournamentTeamWhereUniqueInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTournament
// ====================================================

export interface CreateTournament_createTournament {
  __typename: "Tournament";
  id: number;
}

export interface CreateTournament {
  createTournament: CreateTournament_createTournament;
}

export interface CreateTournamentVariables {
  data: TournamentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournament
// ====================================================

export interface UpdateTournament_updateTournament {
  __typename: "Tournament";
  id: number;
}

export interface UpdateTournament {
  updateTournament: UpdateTournament_updateTournament;
}

export interface UpdateTournamentVariables {
  where: TournamentWhereUniqueInput;
  data: TournamentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournament
// ====================================================

export interface DeleteTournament_deleteTournament {
  __typename: "Tournament";
  id: number;
}

export interface DeleteTournament {
  deleteTournament: DeleteTournament_deleteTournament;
}

export interface DeleteTournamentVariables {
  where: TournamentWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentTeam
// ====================================================

export interface UpdateTournamentTeam_updateTournamentTeam {
  __typename: "TournamentTeam";
  id: number;
}

export interface UpdateTournamentTeam {
  updateTournamentTeam: UpdateTournamentTeam_updateTournamentTeam;
}

export interface UpdateTournamentTeamVariables {
  where: TournamentTeamWhereUniqueInput;
  data: UpdateTournamentTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournamentTeam
// ====================================================

export interface DeleteTournamentTeam_deleteTournamentTeam {
  __typename: "TournamentTeam";
  id: number;
}

export interface DeleteTournamentTeam {
  deleteTournamentTeam: DeleteTournamentTeam_deleteTournamentTeam;
}

export interface DeleteTournamentTeamVariables {
  where: TournamentTeamWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomFieldOptions
// ====================================================

export interface CustomFieldOptions_customFieldOptions {
  __typename: "CustomFieldOption";
  name: string | null;
  label: string | null;
  type: string | null;
  category: string | null;
}

export interface CustomFieldOptions {
  customFieldOptions: CustomFieldOptions_customFieldOptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentPlayerIsUnique
// ====================================================

export interface TournamentPlayerIsUnique {
  tournamentPlayerIsUnique: boolean | null;
}

export interface TournamentPlayerIsUniqueVariables {
  tournamentId: number;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UniqueTournamentTeam
// ====================================================

export interface UniqueTournamentTeam_uniqueTournamentTeam_tournament_game {
  __typename: "Game";
  name: string;
  coverImage: string | null;
}

export interface UniqueTournamentTeam_uniqueTournamentTeam_tournament {
  __typename: "Tournament";
  game: UniqueTournamentTeam_uniqueTournamentTeam_tournament_game | null;
}

export interface UniqueTournamentTeam_uniqueTournamentTeam {
  __typename: "TournamentTeam";
  players: any;
  logo: string | null;
  isManager: boolean | null;
  name: string;
  extra: string | null;
  tournament: UniqueTournamentTeam_uniqueTournamentTeam_tournament;
}

export interface UniqueTournamentTeam {
  uniqueTournamentTeam: UniqueTournamentTeam_uniqueTournamentTeam | null;
}

export interface UniqueTournamentTeamVariables {
  where: TournamentTeamWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentGroups
// ====================================================

export interface TournamentGroups_tournamentGroups_data_groupings_values_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface TournamentGroups_tournamentGroups_data_groupings_values {
  __typename: "TournamentGroupingValue";
  id: number;
  value: string;
  order: number | null;
  tournament: TournamentGroups_tournamentGroups_data_groupings_values_tournament | null;
}

export interface TournamentGroups_tournamentGroups_data_groupings {
  __typename: "TournamentGrouping";
  id: number;
  title: string;
  values: TournamentGroups_tournamentGroups_data_groupings_values[];
}

export interface TournamentGroups_tournamentGroups_data_tournaments {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface TournamentGroups_tournamentGroups_data {
  __typename: "TournamentGroup";
  id: number;
  title: string;
  coverImage: string | null;
  groupings: TournamentGroups_tournamentGroups_data_groupings[];
  tournaments: TournamentGroups_tournamentGroups_data_tournaments[];
}

export interface TournamentGroups_tournamentGroups_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentGroups_tournamentGroups {
  __typename: "PaginatedTournamentGroups";
  data: TournamentGroups_tournamentGroups_data[];
  pageInfo: TournamentGroups_tournamentGroups_pageInfo;
}

export interface TournamentGroups {
  tournamentGroups: TournamentGroups_tournamentGroups;
}

export interface TournamentGroupsVariables {
  where?: TournamentGroupWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentGroup
// ====================================================

export interface TournamentGroup_tournamentGroup_groupings_values_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface TournamentGroup_tournamentGroup_groupings_values {
  __typename: "TournamentGroupingValue";
  id: number;
  value: string;
  order: number | null;
  tournamentId: number;
  tournament: TournamentGroup_tournamentGroup_groupings_values_tournament | null;
}

export interface TournamentGroup_tournamentGroup_groupings {
  __typename: "TournamentGrouping";
  id: number;
  title: string;
  gameId: number | null;
  description: string | null;
  coverImage: string | null;
  values: TournamentGroup_tournamentGroup_groupings_values[];
}

export interface TournamentGroup_tournamentGroup_tournaments {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface TournamentGroup_tournamentGroup {
  __typename: "TournamentGroup";
  id: number;
  groupings: TournamentGroup_tournamentGroup_groupings[];
  title: string;
  coverImage: string | null;
  description: string | null;
  tournaments: TournamentGroup_tournamentGroup_tournaments[];
}

export interface TournamentGroup {
  tournamentGroup: TournamentGroup_tournamentGroup | null;
}

export interface TournamentGroupVariables {
  where: TournamentGroupWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentGrouping
// ====================================================

export interface TournamentGrouping_tournamentGrouping_values_tournament {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface TournamentGrouping_tournamentGrouping_values {
  __typename: "TournamentGroupingValue";
  id: number;
  value: string;
  tournament: TournamentGrouping_tournamentGrouping_values_tournament | null;
}

export interface TournamentGrouping_tournamentGrouping_group {
  __typename: "TournamentGroup";
  title: string;
  coverImage: string | null;
  description: string | null;
}

export interface TournamentGrouping_tournamentGrouping {
  __typename: "TournamentGrouping";
  id: number;
  title: string;
  coverImage: string | null;
  description: string | null;
  values: TournamentGrouping_tournamentGrouping_values[];
  group: TournamentGrouping_tournamentGrouping_group | null;
}

export interface TournamentGrouping {
  tournamentGrouping: TournamentGrouping_tournamentGrouping | null;
}

export interface TournamentGroupingVariables {
  where: TournamentGroupingWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTournamentGroup
// ====================================================

export interface CreateTournamentGroup_createTournamentGroup {
  __typename: "TournamentGroup";
  id: number;
}

export interface CreateTournamentGroup {
  createTournamentGroup: CreateTournamentGroup_createTournamentGroup;
}

export interface CreateTournamentGroupVariables {
  data: TournamentGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentGroup
// ====================================================

export interface UpdateTournamentGroup_updateTournamentGroup {
  __typename: "TournamentGroup";
  id: number;
}

export interface UpdateTournamentGroup {
  updateTournamentGroup: UpdateTournamentGroup_updateTournamentGroup;
}

export interface UpdateTournamentGroupVariables {
  where: TournamentGroupWhereUniqueInput;
  data: TournamentGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournamentGroup
// ====================================================

export interface DeleteTournamentGroup_deleteTournamentGroup {
  __typename: "TournamentGroup";
  id: number;
}

export interface DeleteTournamentGroup {
  deleteTournamentGroup: DeleteTournamentGroup_deleteTournamentGroup;
}

export interface DeleteTournamentGroupVariables {
  where: TournamentGroupWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTournamentMatches
// ====================================================

export interface UserTournamentMatches_userTournamentMatches_data_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  players: any;
  ownerId: number | null;
}

export interface UserTournamentMatches_userTournamentMatches_data_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  players: any;
  ownerId: number | null;
}

export interface UserTournamentMatches_userTournamentMatches_data_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface UserTournamentMatches_userTournamentMatches_data_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: UserTournamentMatches_userTournamentMatches_data_rounds_map | null;
}

export interface UserTournamentMatches_userTournamentMatches_data_tournament {
  __typename: "Tournament";
  gameId: number | null;
}

export interface UserTournamentMatches_userTournamentMatches_data {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  note: string | null;
  teamA: UserTournamentMatches_userTournamentMatches_data_teamA | null;
  teamB: UserTournamentMatches_userTournamentMatches_data_teamB | null;
  teamIdB: number | null;
  teamIdA: number | null;
  teamScoreA: number | null;
  resultBy: string | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  disputeBy: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  printScreen: string | null;
  stageId: number;
  twitchLink: string | null;
  fortunaLink: string | null;
  embed: string | null;
  round: number | null;
  rounds: UserTournamentMatches_userTournamentMatches_data_rounds[];
  tournamentId: number;
  tournament: UserTournamentMatches_userTournamentMatches_data_tournament;
}

export interface UserTournamentMatches_userTournamentMatches_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface UserTournamentMatches_userTournamentMatches {
  __typename: "PaginatedTournamentMatches";
  data: UserTournamentMatches_userTournamentMatches_data[];
  pageInfo: UserTournamentMatches_userTournamentMatches_pageInfo;
}

export interface UserTournamentMatches {
  userTournamentMatches: UserTournamentMatches_userTournamentMatches;
}

export interface UserTournamentMatchesVariables {
  offset?: number | null;
  limit?: number | null;
  orderBy?: TournamentMatchOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentMatch
// ====================================================

export interface TournamentMatch_tournamentMatch_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatch_tournamentMatch_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatch_tournamentMatch_teamA_playersUserData[] | null;
}

export interface TournamentMatch_tournamentMatch_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatch_tournamentMatch_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatch_tournamentMatch_teamB_playersUserData[] | null;
}

export interface TournamentMatch_tournamentMatch_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface TournamentMatch_tournamentMatch_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: TournamentMatch_tournamentMatch_tournament_game | null;
}

export interface TournamentMatch_tournamentMatch_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface TournamentMatch_tournamentMatch_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface TournamentMatch_tournamentMatch_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: TournamentMatch_tournamentMatch_rounds_map | null;
}

export interface TournamentMatch_tournamentMatch {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: TournamentMatch_tournamentMatch_teamA | null;
  teamB: TournamentMatch_tournamentMatch_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: TournamentMatch_tournamentMatch_tournament;
  stage: TournamentMatch_tournamentMatch_stage;
  rounds: TournamentMatch_tournamentMatch_rounds[];
}

export interface TournamentMatch {
  tournamentMatch: TournamentMatch_tournamentMatch | null;
}

export interface TournamentMatchVariables {
  where: TournamentMatchWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentMatches
// ====================================================

export interface TournamentMatches_tournamentMatches_data_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatches_tournamentMatches_data_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatches_tournamentMatches_data_teamA_playersUserData[] | null;
}

export interface TournamentMatches_tournamentMatches_data_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatches_tournamentMatches_data_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatches_tournamentMatches_data_teamB_playersUserData[] | null;
}

export interface TournamentMatches_tournamentMatches_data_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface TournamentMatches_tournamentMatches_data_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: TournamentMatches_tournamentMatches_data_tournament_game | null;
}

export interface TournamentMatches_tournamentMatches_data_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface TournamentMatches_tournamentMatches_data_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface TournamentMatches_tournamentMatches_data_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: TournamentMatches_tournamentMatches_data_rounds_map | null;
}

export interface TournamentMatches_tournamentMatches_data {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: TournamentMatches_tournamentMatches_data_teamA | null;
  teamB: TournamentMatches_tournamentMatches_data_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: TournamentMatches_tournamentMatches_data_tournament;
  stage: TournamentMatches_tournamentMatches_data_stage;
  rounds: TournamentMatches_tournamentMatches_data_rounds[];
}

export interface TournamentMatches_tournamentMatches_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentMatches_tournamentMatches {
  __typename: "PaginatedTournamentMatches";
  data: TournamentMatches_tournamentMatches_data[];
  pageInfo: TournamentMatches_tournamentMatches_pageInfo;
}

export interface TournamentMatches {
  tournamentMatches: TournamentMatches_tournamentMatches;
}

export interface TournamentMatchesVariables {
  where?: TournamentMatchWhereInput | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TournamentMatchOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentMatchForm
// ====================================================

export interface TournamentMatchForm_tournamentTeams_data {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentMatchForm_tournamentTeams_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentMatchForm_tournamentTeams {
  __typename: "PaginatedTournamentTeams";
  data: TournamentMatchForm_tournamentTeams_data[];
  pageInfo: TournamentMatchForm_tournamentTeams_pageInfo;
}

export interface TournamentMatchForm_gameMaps_data {
  __typename: "GameMap";
  id: number;
  name: string;
}

export interface TournamentMatchForm_gameMaps_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentMatchForm_gameMaps {
  __typename: "PaginatedGameMaps";
  data: TournamentMatchForm_gameMaps_data[];
  pageInfo: TournamentMatchForm_gameMaps_pageInfo;
}

export interface TournamentMatchForm {
  tournamentTeams: TournamentMatchForm_tournamentTeams;
  gameMaps: TournamentMatchForm_gameMaps;
}

export interface TournamentMatchFormVariables {
  tournamentTeamsWhere?: TournamentTeamWhereInput | null;
  tournamentTeamsOffset?: number | null;
  tournamentTeamsLimit?: number | null;
  gameMapsWhere?: GameMapWhereInput | null;
  gameMapsOffset?: number | null;
  gameMapsLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentMatchSpecialBuckets
// ====================================================

export interface TournamentMatchSpecialBuckets {
  tournamentMatchSpecialBuckets: TournamentMatchSpecialBucket[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UpcomingTournamentMatches
// ====================================================

export interface UpcomingTournamentMatches_upcomingMatches_data_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: UpcomingTournamentMatches_upcomingMatches_data_teamA_playersUserData[] | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: UpcomingTournamentMatches_upcomingMatches_data_teamB_playersUserData[] | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: UpcomingTournamentMatches_upcomingMatches_data_tournament_game | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: UpcomingTournamentMatches_upcomingMatches_data_rounds_map | null;
}

export interface UpcomingTournamentMatches_upcomingMatches_data {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: UpcomingTournamentMatches_upcomingMatches_data_teamA | null;
  teamB: UpcomingTournamentMatches_upcomingMatches_data_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: UpcomingTournamentMatches_upcomingMatches_data_tournament;
  stage: UpcomingTournamentMatches_upcomingMatches_data_stage;
  rounds: UpcomingTournamentMatches_upcomingMatches_data_rounds[];
}

export interface UpcomingTournamentMatches_upcomingMatches_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface UpcomingTournamentMatches_upcomingMatches {
  __typename: "PaginatedTournamentMatches";
  data: UpcomingTournamentMatches_upcomingMatches_data[];
  pageInfo: UpcomingTournamentMatches_upcomingMatches_pageInfo;
}

export interface UpcomingTournamentMatches {
  upcomingMatches: UpcomingTournamentMatches_upcomingMatches;
}

export interface UpcomingTournamentMatchesVariables {
  limit?: number | null;
  gameId?: number | null;
  hoursOffset?: number | null;
  searchString?: string | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTournamentMatch
// ====================================================

export interface CreateTournamentMatch_createTournamentMatch {
  __typename: "TournamentMatch";
  id: number;
}

export interface CreateTournamentMatch {
  createTournamentMatch: CreateTournamentMatch_createTournamentMatch;
}

export interface CreateTournamentMatchVariables {
  tournamentId: number;
  data: TournamentMatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournamentMatch
// ====================================================

export interface DeleteTournamentMatch_deleteTournamentMatch {
  __typename: "TournamentMatch";
  id: number;
}

export interface DeleteTournamentMatch {
  deleteTournamentMatch: DeleteTournamentMatch_deleteTournamentMatch;
}

export interface DeleteTournamentMatchVariables {
  where: TournamentMatchWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentMatchRoundDate
// ====================================================

export interface UpdateTournamentMatchRoundDate {
  updateTournamentMatchRoundDate: number;
}

export interface UpdateTournamentMatchRoundDateVariables {
  data: TournamentMatchRoundDateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentMatch
// ====================================================

export interface UpdateTournamentMatch_updateTournamentMatch {
  __typename: "TournamentMatch";
  id: number;
}

export interface UpdateTournamentMatch {
  updateTournamentMatch: UpdateTournamentMatch_updateTournamentMatch;
}

export interface UpdateTournamentMatchVariables {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetTournamentMatch
// ====================================================

export interface ResetTournamentMatch_resetTournamentMatch {
  __typename: "TournamentMatch";
  id: number;
}

export interface ResetTournamentMatch {
  resetTournamentMatch: ResetTournamentMatch_resetTournamentMatch;
}

export interface ResetTournamentMatchVariables {
  where: TournamentMatchWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateTournamentMatchGroups
// ====================================================

export interface GenerateTournamentMatchGroups {
  generateTournamentMatchGroups: number;
}

export interface GenerateTournamentMatchGroupsVariables {
  data: GenerateTournamentMatchGroupsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateTournamentMatchBrackets
// ====================================================

export interface GenerateTournamentMatchBrackets {
  generateTournamentMatchBrackets: number;
}

export interface GenerateTournamentMatchBracketsVariables {
  tournamentId: number;
  stageId: number;
  noPreFill?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProposeTournamentMatchScore
// ====================================================

export interface ProposeTournamentMatchScore_proposeTournamentMatchScore {
  __typename: "TournamentMatch";
  id: number;
}

export interface ProposeTournamentMatchScore {
  proposeTournamentMatchScore: ProposeTournamentMatchScore_proposeTournamentMatchScore;
}

export interface ProposeTournamentMatchScoreVariables {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisputeTournamentMatchScore
// ====================================================

export interface DisputeTournamentMatchScore_disputeTournamentMatchScore {
  __typename: "TournamentMatch";
  id: number;
}

export interface DisputeTournamentMatchScore {
  disputeTournamentMatchScore: DisputeTournamentMatchScore_disputeTournamentMatchScore;
}

export interface DisputeTournamentMatchScoreVariables {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatchScoreDisputeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmTournamentMatchScore
// ====================================================

export interface ConfirmTournamentMatchScore_confirmTournamentMatchScore {
  __typename: "TournamentMatch";
  id: number;
}

export interface ConfirmTournamentMatchScore {
  confirmTournamentMatchScore: ConfirmTournamentMatchScore_confirmTournamentMatchScore;
}

export interface ConfirmTournamentMatchScoreVariables {
  where: TournamentMatchWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentPrizes
// ====================================================

export interface TournamentPrizes_tournamentPrizes_data_customType {
  __typename: "TournamentCustomPrizeType";
  name: string;
}

export interface TournamentPrizes_tournamentPrizes_data_currency {
  __typename: "Currency";
  name: string;
  code: string;
}

export interface TournamentPrizes_tournamentPrizes_data_tournamentStage {
  __typename: "TournamentStage";
  id: number;
  name: string | null;
}

export interface TournamentPrizes_tournamentPrizes_data_winners {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentPrizes_tournamentPrizes_data_actionHistory_team {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentPrizes_tournamentPrizes_data_actionHistory_author {
  __typename: "User";
  name: string | null;
  surname: string | null;
  email: string;
}

export interface TournamentPrizes_tournamentPrizes_data_actionHistory {
  __typename: "TournamentPrizeAction";
  id: number;
  createdAt: any;
  prizeId: number;
  teamId: number | null;
  state: TournamentPrizeState;
  team: TournamentPrizes_tournamentPrizes_data_actionHistory_team | null;
  author: TournamentPrizes_tournamentPrizes_data_actionHistory_author | null;
}

export interface TournamentPrizes_tournamentPrizes_data {
  __typename: "TournamentPrize";
  id: number;
  availablePlacementsCount: number;
  customTypeId: number | null;
  customType: TournamentPrizes_tournamentPrizes_data_customType | null;
  currencyId: number | null;
  currency: TournamentPrizes_tournamentPrizes_data_currency | null;
  quantity: number;
  placement: number[];
  placementType: TournamentPrizePlacementType;
  tournamentId: number;
  tournamentStageId: number | null;
  tournamentStage: TournamentPrizes_tournamentPrizes_data_tournamentStage | null;
  type: TournamentPrizeType | null;
  state: TournamentPrizeState | null;
  winners: TournamentPrizes_tournamentPrizes_data_winners[] | null;
  actionHistory: TournamentPrizes_tournamentPrizes_data_actionHistory[] | null;
}

export interface TournamentPrizes_tournamentPrizes_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentPrizes_tournamentPrizes {
  __typename: "PaginatedTournamentPrizes";
  data: TournamentPrizes_tournamentPrizes_data[];
  pageInfo: TournamentPrizes_tournamentPrizes_pageInfo;
}

export interface TournamentPrizes {
  tournamentPrizes: TournamentPrizes_tournamentPrizes;
}

export interface TournamentPrizesVariables {
  where?: TournamentPrizeWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TournamentCustomPrizeTypes
// ====================================================

export interface TournamentCustomPrizeTypes_tournamentCustomPrizeTypes_data {
  __typename: "TournamentCustomPrizeType";
  id: number;
  name: string;
}

export interface TournamentCustomPrizeTypes_tournamentCustomPrizeTypes_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface TournamentCustomPrizeTypes_tournamentCustomPrizeTypes {
  __typename: "PaginatedTournamentCustomPrizeTypes";
  data: TournamentCustomPrizeTypes_tournamentCustomPrizeTypes_data[];
  pageInfo: TournamentCustomPrizeTypes_tournamentCustomPrizeTypes_pageInfo;
}

export interface TournamentCustomPrizeTypes {
  tournamentCustomPrizeTypes: TournamentCustomPrizeTypes_tournamentCustomPrizeTypes;
}

export interface TournamentCustomPrizeTypesVariables {
  where?: TournamentCustomPrizeTypeWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AwardTournamentPrize
// ====================================================

export interface AwardTournamentPrize_awardTournamentPrize {
  __typename: "Transaction";
  id: number;
}

export interface AwardTournamentPrize {
  awardTournamentPrize: AwardTournamentPrize_awardTournamentPrize[];
}

export interface AwardTournamentPrizeVariables {
  prizeId: number;
  teamId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeTournamentPrize
// ====================================================

export interface RevokeTournamentPrize_revokeTournamentPrize {
  __typename: "Transaction";
  id: number;
}

export interface RevokeTournamentPrize {
  revokeTournamentPrize: RevokeTournamentPrize_revokeTournamentPrize[];
}

export interface RevokeTournamentPrizeVariables {
  prizeId: number;
  teamId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTournamentPrizeState
// ====================================================

export interface SetTournamentPrizeState_setTournamentPrizeState {
  __typename: "TournamentPrize";
  id: number;
}

export interface SetTournamentPrizeState {
  setTournamentPrizeState: SetTournamentPrizeState_setTournamentPrizeState;
}

export interface SetTournamentPrizeStateVariables {
  where: TournamentPrizeWhereUniqueInput;
  state: TournamentPrizeState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTournamentPrize
// ====================================================

export interface CreateTournamentPrize_createTournamentPrize {
  __typename: "TournamentPrize";
  id: number;
}

export interface CreateTournamentPrize {
  createTournamentPrize: CreateTournamentPrize_createTournamentPrize;
}

export interface CreateTournamentPrizeVariables {
  data: TournamentPrizeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTournamentPrize
// ====================================================

export interface UpdateTournamentPrize_updateTournamentPrize {
  __typename: "TournamentPrize";
  id: number;
}

export interface UpdateTournamentPrize {
  updateTournamentPrize: UpdateTournamentPrize_updateTournamentPrize;
}

export interface UpdateTournamentPrizeVariables {
  where: TournamentPrizeWhereUniqueInput;
  data: TournamentPrizeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTournamentPrize
// ====================================================

export interface DeleteTournamentPrize_deleteTournamentPrize {
  __typename: "TournamentPrize";
  id: number;
}

export interface DeleteTournamentPrize {
  deleteTournamentPrize: DeleteTournamentPrize_deleteTournamentPrize;
}

export interface DeleteTournamentPrizeVariables {
  where: TournamentPrizeWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_users_data {
  __typename: "User";
  id: number;
  email: string;
  nickname: string | null;
}

export interface Users_users_pageInfo {
  __typename: "PageInfo";
  totalCount: number;
}

export interface Users_users {
  __typename: "PaginatedUsers";
  data: Users_users_data[];
  pageInfo: Users_users_pageInfo;
}

export interface Users {
  users: Users_users;
}

export interface UsersVariables {
  where?: UserWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUser
// ====================================================

export interface CurrentUser_currentUser_roles {
  __typename: "UserRole";
  role: Role;
}

export interface CurrentUser_currentUser {
  __typename: "User";
  id: number;
  name: string | null;
  email: string;
  surname: string | null;
  nickname: string | null;
  address: string | null;
  city: string | null;
  zipcode: string | null;
  phone: string | null;
  fblink: string | null;
  discord: string | null;
  roles: CurrentUser_currentUser_roles[] | null;
}

export interface CurrentUser {
  currentUser: CurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: number;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  data: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserRoles
// ====================================================

export interface SetUserRoles_setUserRoles {
  __typename: "User";
  id: number;
}

export interface SetUserRoles {
  setUserRoles: SetUserRoles_setUserRoles;
}

export interface SetUserRolesVariables {
  userId: number;
  roles: Role[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertUserConsents
// ====================================================

export interface UpsertUserConsents_upsertUserConsents {
  __typename: "UserConsent";
  id: number;
  visitorId: string | null;
  userAgent: string;
  cookies: (string | null)[];
}

export interface UpsertUserConsents {
  upsertUserConsents: UpsertUserConsents_upsertUserConsents;
}

export interface UpsertUserConsentsVariables {
  data: UserConsentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleParts
// ====================================================

export interface ArticleParts_author {
  __typename: "User";
  id: number;
  name: string | null;
  surname: string | null;
  email: string;
}

export interface ArticleParts_observers {
  __typename: "User";
  id: number;
}

export interface ArticleParts_mainImageFile {
  __typename: "File";
  sourceTitle: string | null;
  sourceURL: string | null;
}

export interface ArticleParts_subcategories {
  __typename: "ArticleSubcategory";
  id: number;
  title: string | null;
}

export interface ArticleParts_categories {
  __typename: "ArticleCategory";
  id: number;
  title: string | null;
}

export interface ArticleParts {
  __typename: "Article";
  id: number;
  author: ArticleParts_author | null;
  observers: ArticleParts_observers[] | null;
  userId: number | null;
  title: string | null;
  subtitle: string | null;
  text: string | null;
  mainImage: string | null;
  mainImageFile: ArticleParts_mainImageFile | null;
  publishedAt: any | null;
  createdAt: any;
  tag: string | null;
  tags: string[] | null;
  subcategories: ArticleParts_subcategories[] | null;
  categories: ArticleParts_categories[] | null;
  uniqueVisitCount: number;
  pageLoadCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerParts
// ====================================================

export interface BannerParts {
  __typename: "Banner";
  id: number;
  image: string | null;
  mobileImage: string | null;
  validUntil: any | null;
  pathMatch: string;
  exact: boolean;
  note: string | null;
  link: string | null;
  type: BannerType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CareerApplicationParts
// ====================================================

export interface CareerApplicationParts {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CareerPositionParts
// ====================================================

export interface CareerPositionParts_applications {
  __typename: "CareerApplication";
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn: string | null;
  description: string | null;
  attachments: (string | null)[] | null;
  positionId: number;
  createdAt: any;
}

export interface CareerPositionParts {
  __typename: "CareerPosition";
  id: number;
  name: string;
  shortDescription: string;
  boardingDate: string;
  workplaceCity: string;
  contractType: string;
  paymentFrom: string;
  paymentUpTo: string | null;
  published: boolean;
  contact: string;
  createdAt: any;
  description: string;
  applications: CareerPositionParts_applications[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrencyParts
// ====================================================

export interface CurrencyParts {
  __typename: "Currency";
  id: number;
  createdAt: any;
  name: string;
  code: string;
  icon: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MerchantParts
// ====================================================

export interface MerchantParts {
  __typename: "Merchant";
  id: number;
  name: string;
  type: MerchantType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileParts
// ====================================================

export interface FileParts {
  __typename: "File";
  id: number;
  createdAt: any;
  extension: string;
  isImage: boolean;
  lastModified: any;
  mimeType: string;
  name: string;
  originalName: string;
  size: number;
  sourceURL: string | null;
  sourceTitle: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GameParts
// ====================================================

export interface GameParts_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface GameParts {
  __typename: "Game";
  id: number;
  name: string;
  coverImage: string | null;
  gameIdId: number | null;
  gameId: GameParts_gameId | null;
  type: GameType | null;
  filterOrder: number | null;
  tag: string | null;
  tags: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GameMapParts
// ====================================================

export interface GameMapParts {
  __typename: "GameMap";
  id: number;
  gameId: number;
  name: string;
  image: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuestParts
// ====================================================

export interface GuestParts_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface GuestParts_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface GuestParts {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  photo: string | null;
  media: GuestParts_media | null;
  address: GuestParts_address | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderLinkParts
// ====================================================

export interface HeaderLinkParts {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderLinkGroupParts
// ====================================================

export interface HeaderLinkGroupParts_links {
  __typename: "HeaderLink";
  id: number;
  createdAt: any;
  updatedAt: any;
  backgroundColor: string | null;
  icon: string | null;
  order: number;
  path: string;
  groupId: number | null;
  text: string;
}

export interface HeaderLinkGroupParts {
  __typename: "HeaderLinkGroup";
  id: number;
  createdAt: any;
  updatedAt: any;
  pathMatch: string;
  name: string | null;
  exact: boolean;
  links: HeaderLinkGroupParts_links[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LaptopParts
// ====================================================

export interface LaptopParts_performance_description {
  __typename: "LaptopPerformanceDescription";
  sk: string | null;
  cz: string | null;
}

export interface LaptopParts_performance_fullhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface LaptopParts_performance_hd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface LaptopParts_performance_hdplus {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface LaptopParts_performance_quadhd {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface LaptopParts_performance_wxga {
  __typename: "LaptopPerformanceFpsStats";
  avg: string | null;
  max: string | null;
  min: string | null;
}

export interface LaptopParts_performance {
  __typename: "LaptopPerformance";
  game: string | null;
  description: LaptopParts_performance_description | null;
  fullhd: LaptopParts_performance_fullhd | null;
  hd: LaptopParts_performance_hd | null;
  hdplus: LaptopParts_performance_hdplus | null;
  quadhd: LaptopParts_performance_quadhd | null;
  wxga: LaptopParts_performance_wxga | null;
}

export interface LaptopParts_sellers {
  __typename: "LaptopSeller";
  name: string | null;
  value: string | null;
}

export interface LaptopParts {
  __typename: "Laptop";
  id: number;
  name: string;
  description: string | null;
  mainImage: string;
  gallery: (string | null)[] | null;
  performance: (LaptopParts_performance | null)[] | null;
  url: string | null;
  sellers: (LaptopParts_sellers | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LocaleParts
// ====================================================

export interface LocaleParts {
  __typename: "LocaleString";
  name: string;
  locale: Locale;
  string: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PartnerParts
// ====================================================

export interface PartnerParts {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  orsr: string | null;
  ico: string | null;
  dic: string | null;
  dph: string | null;
  media: any | null;
  address: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectSectionParts
// ====================================================

export interface ProjectSectionParts_translations_data {
  __typename: "ProjectSectionTranslation";
  id: number;
  order: number;
  locale: Locale;
}

export interface ProjectSectionParts_translations {
  __typename: "ProjectSectionTranslationCollection";
  data: ProjectSectionParts_translations_data[];
}

export interface ProjectSectionParts_video {
  __typename: "ProjectVideoSection";
  id: number;
  title: string | null;
  videosPerRow: number | null;
  links: string[];
}

export interface ProjectSectionParts_figure {
  __typename: "ProjectFigureSection";
  id: number;
  title: string | null;
  caption: string | null;
  image: string | null;
}

export interface ProjectSectionParts_text {
  __typename: "ProjectTextSection";
  id: number;
  title: string | null;
  image: string | null;
  imagePosition: ProjectSectionImagePosition | null;
  buttonLink: string | null;
  buttonText: string | null;
  text: string | null;
}

export interface ProjectSectionParts_articles {
  __typename: "ProjectArticlesSection";
  id: number;
  tags: string[];
}

export interface ProjectSectionParts_voting_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectSectionParts_voting {
  __typename: "ProjectVotingSection";
  id: number;
  tournamentId: number;
  title: string;
  slotCount: number;
  teams: ProjectSectionParts_voting_teams[] | null;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectSectionParts_competition {
  __typename: "ProjectCompetitionSection";
  id: number;
  title: string;
  buttonLabel: string;
  successMessage: string;
  terms: string;
  emailSubject: string | null;
  emailText: string | null;
}

export interface ProjectSectionParts_guests_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectSectionParts_guests_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectSectionParts_guests_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectSectionParts_guests_guests_data_media | null;
  address: ProjectSectionParts_guests_guests_data_address | null;
  photo: string | null;
}

export interface ProjectSectionParts_guests_guests {
  __typename: "GuestCollection";
  data: ProjectSectionParts_guests_guests_data[];
}

export interface ProjectSectionParts_guests {
  __typename: "ProjectGuestsSection";
  id: number;
  guests: ProjectSectionParts_guests_guests | null;
}

export interface ProjectSectionParts_partnerTypes_data_partners_data {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectSectionParts_partnerTypes_data_partners {
  __typename: "PartnerCollection";
  data: ProjectSectionParts_partnerTypes_data_partners_data[];
}

export interface ProjectSectionParts_partnerTypes_data {
  __typename: "ProjectPartnerType";
  id: number;
  name: string;
  order: number;
  tilesPerRowMobile: number | null;
  tilesPerRowDesktop: number | null;
  partners: ProjectSectionParts_partnerTypes_data_partners | null;
}

export interface ProjectSectionParts_partnerTypes {
  __typename: "ProjectPartnerTypeCollection";
  data: ProjectSectionParts_partnerTypes_data[];
}

export interface ProjectSectionParts_attractions_data_partner {
  __typename: "Partner";
  id: number;
  name: string;
  logo: string | null;
  media: any | null;
}

export interface ProjectSectionParts_attractions_data {
  __typename: "ProjectAttractionsSection";
  id: number;
  title: string;
  priority: ProjectAttractionPriority;
  order: number;
  description: string | null;
  partnerId: number | null;
  image: string | null;
  partner: ProjectSectionParts_attractions_data_partner | null;
}

export interface ProjectSectionParts_attractions {
  __typename: "ProjectAttractionsSectionCollection";
  data: ProjectSectionParts_attractions_data[];
}

export interface ProjectSectionParts_programs_data_guests_data_media {
  __typename: "Media";
  web: string | null;
  facebook: string | null;
  instagram: string | null;
  youtube: string | null;
  twitch: string | null;
  twitter: string | null;
}

export interface ProjectSectionParts_programs_data_guests_data_address {
  __typename: "Address";
  street: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface ProjectSectionParts_programs_data_guests_data {
  __typename: "Guest";
  id: number;
  firstName: string;
  lastName: string;
  nickname: string | null;
  media: ProjectSectionParts_programs_data_guests_data_media | null;
  address: ProjectSectionParts_programs_data_guests_data_address | null;
  photo: string | null;
}

export interface ProjectSectionParts_programs_data_guests {
  __typename: "GuestCollection";
  data: ProjectSectionParts_programs_data_guests_data[];
}

export interface ProjectSectionParts_programs_data {
  __typename: "ProjectProgramSection";
  id: number;
  name: string;
  description: string;
  place: string;
  streamUrl: string | null;
  date: any;
  duration: number;
  guests: ProjectSectionParts_programs_data_guests | null;
}

export interface ProjectSectionParts_programs {
  __typename: "ProjectProgramSectionCollection";
  data: ProjectSectionParts_programs_data[];
}

export interface ProjectSectionParts_questionsAndAnswers_data {
  __typename: "ProjectQuestionAndAnswer";
  id: number;
  title: string | null;
  text: string | null;
}

export interface ProjectSectionParts_questionsAndAnswers {
  __typename: "ProjectQuestionAndAnswerCollection";
  data: ProjectSectionParts_questionsAndAnswers_data[];
}

export interface ProjectSectionParts_tournaments_data_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface ProjectSectionParts_tournaments_data_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: ProjectSectionParts_tournaments_data_game_gameId | null;
  tags: string[] | null;
}

export interface ProjectSectionParts_tournaments_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: ProjectSectionParts_tournaments_data_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
}

export interface ProjectSectionParts_tournaments {
  __typename: "TournamentCollection";
  data: ProjectSectionParts_tournaments_data[];
}

export interface ProjectSectionParts_tournamentMatches_matches_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectSectionParts_tournamentMatches_matches_teamA_playersUserData[] | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: ProjectSectionParts_tournamentMatches_matches_teamB_playersUserData[] | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: ProjectSectionParts_tournamentMatches_matches_tournament_game | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface ProjectSectionParts_tournamentMatches_matches_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: ProjectSectionParts_tournamentMatches_matches_rounds_map | null;
}

export interface ProjectSectionParts_tournamentMatches_matches {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: ProjectSectionParts_tournamentMatches_matches_teamA | null;
  teamB: ProjectSectionParts_tournamentMatches_matches_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: ProjectSectionParts_tournamentMatches_matches_tournament;
  stage: ProjectSectionParts_tournamentMatches_matches_stage;
  rounds: ProjectSectionParts_tournamentMatches_matches_rounds[];
}

export interface ProjectSectionParts_tournamentMatches {
  __typename: "ProjectSectionTournamentMatchGrouping";
  tournamentId: number;
  matches: ProjectSectionParts_tournamentMatches_matches[];
}

export interface ProjectSectionParts_tournamentTeams_teams {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

export interface ProjectSectionParts_tournamentTeams {
  __typename: "ProjectSectionTournamentTeamGrouping";
  tournamentId: number;
  tournamentName: string | null;
  teams: ProjectSectionParts_tournamentTeams_teams[];
}

export interface ProjectSectionParts_tournaments_relation_stages_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
  coverImage: string | null;
}

export interface ProjectSectionParts_tournaments_relation_stages {
  __typename: "TournamentCollection";
  data: ProjectSectionParts_tournaments_relation_stages_data[];
}

export interface ProjectSectionParts_tournaments_relation_matches_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectSectionParts_tournaments_relation_matches {
  __typename: "TournamentCollection";
  data: ProjectSectionParts_tournaments_relation_matches_data[];
}

export interface ProjectSectionParts_tournaments_relation_teams_data {
  __typename: "Tournament";
  id: number;
  name: string | null;
}

export interface ProjectSectionParts_tournaments_relation_teams {
  __typename: "TournamentCollection";
  data: ProjectSectionParts_tournaments_relation_teams_data[];
}

export interface ProjectSectionParts {
  __typename: "ProjectSection";
  id: number;
  projectId: number;
  locale: Locale | null;
  type: ProjectSectionType;
  contentTableName: string | null;
  icon: string | null;
  addToContentTable: boolean;
  translations: ProjectSectionParts_translations | null;
  video: ProjectSectionParts_video | null;
  figure: ProjectSectionParts_figure | null;
  text: ProjectSectionParts_text | null;
  articles: ProjectSectionParts_articles | null;
  voting: ProjectSectionParts_voting | null;
  competition: ProjectSectionParts_competition | null;
  guests: ProjectSectionParts_guests | null;
  partnerTypes: ProjectSectionParts_partnerTypes | null;
  attractions: ProjectSectionParts_attractions | null;
  programs: ProjectSectionParts_programs | null;
  questionsAndAnswers: ProjectSectionParts_questionsAndAnswers | null;
  tournaments: ProjectSectionParts_tournaments | null;
  tournamentMatches: ProjectSectionParts_tournamentMatches[] | null;
  tournamentTeams: ProjectSectionParts_tournamentTeams[] | null;
  tournaments_relation_stages: ProjectSectionParts_tournaments_relation_stages | null;
  tournaments_relation_matches: ProjectSectionParts_tournaments_relation_matches | null;
  tournaments_relation_teams: ProjectSectionParts_tournaments_relation_teams | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProjectTranslationParts
// ====================================================

export interface ProjectTranslationParts {
  __typename: "ProjectTranslation";
  id: number;
  projectId: number;
  locale: Locale;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TagParts
// ====================================================

export interface TagParts {
  __typename: "Tag";
  id: number;
  name: string;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TournamentParts
// ====================================================

export interface TournamentParts_game_gameId {
  __typename: "GameId";
  id: number;
  name: string;
}

export interface TournamentParts_game {
  __typename: "Game";
  id: number;
  coverImage: string | null;
  name: string;
  gameId: TournamentParts_game_gameId | null;
  tags: string[] | null;
}

export interface TournamentParts {
  __typename: "Tournament";
  id: number;
  name: string | null;
  type: string | null;
  gameId: number | null;
  game: TournamentParts_game | null;
  status: TournamentStatus | null;
  partner: string | null;
  partner2: string | null;
  fakeRegistrations: number | null;
  playersCount: number | null;
  playersRequiredCount: number | null;
  extrasCount: number | null;
  contact: string | null;
  capMin: number | null;
  capMax: number | null;
  registrationsCount: number | null;
  startDate: any | null;
  endDate: any | null;
  registrationStartTime: any | null;
  registrationEndTime: any | null;
  checkInStartTime: any | null;
  checkInEndTime: any | null;
  checkInStartTimePending: any | null;
  checkInEndTimePending: any | null;
  rules: any | null;
  tournamentInfo: string | null;
  coverImage: string | null;
  banner: string | null;
  style: string | null;
  embedStream: string | null;
  embedTournament: any | null;
  mailText: string | null;
  mailSubject: string | null;
  enablePending: boolean | null;
  pendingMailSubject: string | null;
  pendingMailText: string | null;
  externalTournament: string | null;
  publicMatches: boolean | null;
  public: boolean | null;
  adminsEmails: string[] | null;
  customFields: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TournamentTeamParts
// ====================================================

export interface TournamentTeamParts {
  __typename: "TournamentTeam";
  id: number;
  createdAt: any;
  name: string;
  contact: string | null;
  discord: string | null;
  players: any;
  custom: any | null;
  manager: any | null;
  pending: boolean | null;
  pendingPosition: number | null;
  extra: string | null;
  state: number | null;
  confirmed: boolean | null;
  logo: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TournamentMatchParts
// ====================================================

export interface TournamentMatchParts_teamA_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatchParts_teamA {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatchParts_teamA_playersUserData[] | null;
}

export interface TournamentMatchParts_teamB_playersUserData {
  __typename: "TournamentTeamPlayer";
  id: number | null;
  nickname: string | null;
  profilePicture: string | null;
}

export interface TournamentMatchParts_teamB {
  __typename: "TournamentTeam";
  id: number;
  name: string;
  contact: string | null;
  logo: string | null;
  playersUserData: TournamentMatchParts_teamB_playersUserData[] | null;
}

export interface TournamentMatchParts_tournament_game {
  __typename: "Game";
  coverImage: string | null;
}

export interface TournamentMatchParts_tournament {
  __typename: "Tournament";
  gameId: number | null;
  name: string | null;
  game: TournamentMatchParts_tournament_game | null;
}

export interface TournamentMatchParts_stage {
  __typename: "TournamentStage";
  name: string | null;
}

export interface TournamentMatchParts_rounds_map {
  __typename: "GameMap";
  id: number;
  name: string;
  image: string | null;
}

export interface TournamentMatchParts_rounds {
  __typename: "TournamentMatchRound";
  id: number;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  mapId: number | null;
  map: TournamentMatchParts_rounds_map | null;
}

export interface TournamentMatchParts {
  __typename: "TournamentMatch";
  id: number;
  dateStart: any | null;
  dateEnd: any | null;
  teamIdA: number | null;
  teamIdB: number | null;
  teamA: TournamentMatchParts_teamA | null;
  teamB: TournamentMatchParts_teamB | null;
  teamScoreA: number | null;
  teamScoreB: number | null;
  confirmed: boolean | null;
  twitchLink: string | null;
  embed: string | null;
  printScreen: string | null;
  disputeScoreA: number | null;
  disputeScoreB: number | null;
  disputeBy: string | null;
  disputePrintScreen: string | null;
  bucket: string | null;
  siblingMatchId: number | null;
  childMatchId: number | null;
  stageId: number;
  loserMatchId: number | null;
  round: number | null;
  active: boolean | null;
  note: string | null;
  tournamentId: number;
  fortunaLink: string | null;
  tournament: TournamentMatchParts_tournament;
  stage: TournamentMatchParts_stage;
  rounds: TournamentMatchParts_rounds[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TournamentPrizeParts
// ====================================================

export interface TournamentPrizeParts_customType {
  __typename: "TournamentCustomPrizeType";
  name: string;
}

export interface TournamentPrizeParts_currency {
  __typename: "Currency";
  name: string;
  code: string;
}

export interface TournamentPrizeParts_tournamentStage {
  __typename: "TournamentStage";
  id: number;
  name: string | null;
}

export interface TournamentPrizeParts_winners {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentPrizeParts_actionHistory_team {
  __typename: "TournamentTeam";
  id: number;
  name: string;
}

export interface TournamentPrizeParts_actionHistory_author {
  __typename: "User";
  name: string | null;
  surname: string | null;
  email: string;
}

export interface TournamentPrizeParts_actionHistory {
  __typename: "TournamentPrizeAction";
  id: number;
  createdAt: any;
  prizeId: number;
  teamId: number | null;
  state: TournamentPrizeState;
  team: TournamentPrizeParts_actionHistory_team | null;
  author: TournamentPrizeParts_actionHistory_author | null;
}

export interface TournamentPrizeParts {
  __typename: "TournamentPrize";
  id: number;
  availablePlacementsCount: number;
  customTypeId: number | null;
  customType: TournamentPrizeParts_customType | null;
  currencyId: number | null;
  currency: TournamentPrizeParts_currency | null;
  quantity: number;
  placement: number[];
  placementType: TournamentPrizePlacementType;
  tournamentId: number;
  tournamentStageId: number | null;
  tournamentStage: TournamentPrizeParts_tournamentStage | null;
  type: TournamentPrizeType | null;
  state: TournamentPrizeState | null;
  winners: TournamentPrizeParts_winners[] | null;
  actionHistory: TournamentPrizeParts_actionHistory[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BannerType {
  SIDEBAR = "SIDEBAR",
  TOP = "TOP",
}

export enum GameType {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
}

export enum Locale {
  cz = "cz",
  en = "en",
  sk = "sk",
}

export enum MerchantType {
  GLOBAL = "GLOBAL",
  USER = "USER",
}

export enum ProjectAttractionPriority {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum ProjectSectionImagePosition {
  AUTO = "AUTO",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum ProjectSectionScalarField {
  addToContentTable = "addToContentTable",
  contentTableName = "contentTableName",
  createdAt = "createdAt",
  icon = "icon",
  id = "id",
  locale = "locale",
  projectId = "projectId",
  type = "type",
  updatedAt = "updatedAt",
}

export enum ProjectSectionType {
  ARTICLES = "ARTICLES",
  ATTRACTIONS = "ATTRACTIONS",
  COMPETITION = "COMPETITION",
  FIGURE = "FIGURE",
  GUESTS = "GUESTS",
  PARTNERS = "PARTNERS",
  PROGRAM = "PROGRAM",
  Q_AND_A = "Q_AND_A",
  SECTION = "SECTION",
  TOURNAMENT = "TOURNAMENT",
  TOURNAMENT_MATCHES = "TOURNAMENT_MATCHES",
  TOURNAMENT_STAGES = "TOURNAMENT_STAGES",
  TOURNAMENT_TEAMS = "TOURNAMENT_TEAMS",
  VIDEO = "VIDEO",
  VOTING = "VOTING",
}

export enum Role {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  REDACTOR = "REDACTOR",
  SUPERADMIN = "SUPERADMIN",
  TOURNAMENT = "TOURNAMENT",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum StringSearchMode {
  default = "default",
  insensitive = "insensitive",
}

export enum TournamentMatchSpecialBucket {
  grand_finale = "grand_finale",
  loser = "loser",
  losers_bracket = "losers_bracket",
}

export enum TournamentPrizePlacementType {
  RANGE = "RANGE",
  SPECIFIC = "SPECIFIC",
}

export enum TournamentPrizeState {
  AWARDED = "AWARDED",
  CREATED = "CREATED",
  PARTIALLY_AWARDED = "PARTIALLY_AWARDED",
  PARTIALLY_REVOKED = "PARTIALLY_REVOKED",
  REVOKED = "REVOKED",
}

export enum TournamentPrizeType {
  CURRENCY = "CURRENCY",
  CUSTOM = "CUSTOM",
}

export enum TournamentStageType {
  DOUBLE_ELIMINATION = "DOUBLE_ELIMINATION",
  GROUP = "GROUP",
  SINGLE_ELIMINATION = "SINGLE_ELIMINATION",
}

export enum TournamentStatus {
  FINISHED = "FINISHED",
  LOCKED = "LOCKED",
  REGISTRATION_CLOSED = "REGISTRATION_CLOSED",
  REGISTRATION_OPEN = "REGISTRATION_OPEN",
  RUNNING = "RUNNING",
}

export interface AddressOrderByInput {
  id?: SortOrder | null;
  street?: SortOrder | null;
  postalCode?: SortOrder | null;
  country?: SortOrder | null;
  Guest?: GuestOrderByInput | null;
}

export interface AddressWhereInput {
  OR?: (AddressWhereInput | null)[] | null;
  AND?: (AddressWhereInput | null)[] | null;
  NOT?: (AddressWhereInput | null)[] | null;
  id?: Address_IdFieldWhereInput | null;
  street?: Address_StreetFieldWhereInput | null;
  postalCode?: Address_PostalCodeFieldWhereInput | null;
  country?: Address_CountryFieldWhereInput | null;
  Guest?: GuestWhereInput | null;
}

export interface AddressWhereUniqueInput {
  id: number;
}

export interface Address_CountryFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Address_CountryFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Address_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Address_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Address_PostalCodeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Address_PostalCodeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Address_StreetFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Address_StreetFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleCategoriesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ArticleCategoriesFieldWhereInput {
  every?: ArticleCategoryWhereInput | null;
  none?: ArticleCategoryWhereInput | null;
  some?: ArticleCategoryWhereInput | null;
}

export interface ArticleCategoryIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleCategoryIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ArticleCategoryTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleCategoryTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleCategoryWhereInput {
  id?: ArticleCategoryIdFieldWhereInput | null;
  title?: ArticleCategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleCategoryWhereLogicAndInput[] | null;
  NOT?: ArticleCategoryWhereLogicNotInput[] | null;
  OR?: ArticleCategoryWhereLogicOrInput[] | null;
}

export interface ArticleCategoryWhereLogicAndInput {
  id?: ArticleCategoryIdFieldWhereInput | null;
  title?: ArticleCategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleCategoryWhereLogicAndInput[] | null;
  NOT?: ArticleCategoryWhereLogicNotInput[] | null;
  OR?: ArticleCategoryWhereLogicOrInput[] | null;
}

export interface ArticleCategoryWhereLogicNotInput {
  id?: ArticleCategoryIdFieldWhereInput | null;
  title?: ArticleCategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleCategoryWhereLogicAndInput[] | null;
  NOT?: ArticleCategoryWhereLogicNotInput[] | null;
  OR?: ArticleCategoryWhereLogicOrInput[] | null;
}

export interface ArticleCategoryWhereLogicOrInput {
  id?: ArticleCategoryIdFieldWhereInput | null;
  title?: ArticleCategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleCategoryWhereLogicAndInput[] | null;
  NOT?: ArticleCategoryWhereLogicNotInput[] | null;
  OR?: ArticleCategoryWhereLogicOrInput[] | null;
}

export interface ArticleCategoryWhereScalarInput {
  OR?: (ArticleCategoryWhereScalarInput | null)[] | null;
  AND?: (ArticleCategoryWhereScalarInput | null)[] | null;
  NOT?: (ArticleCategoryWhereScalarInput | null)[] | null;
  id?: ArticleCategory_IdFieldWhereScalarInput | null;
  createdAt?: ArticleCategory_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ArticleCategory_UpdatedAtFieldWhereScalarInput | null;
  title?: ArticleCategory_TitleFieldWhereScalarInput | null;
  default?: ArticleCategory_DefaultFieldWhereScalarInput | null;
}

export interface ArticleCategoryWhereUniqueInput {
  id: number;
}

export interface ArticleCategory_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleCategory_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleCategory_DefaultFieldWhereScalarInput {
  equals?: boolean | null;
  not?: ArticleCategory_DefaultFieldWhereScalarInput | null;
}

export interface ArticleCategory_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleCategory_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ArticleCategory_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleCategory_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleCategory_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleCategory_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ArticleInput {
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  tags?: string[] | null;
  categoryIds?: number[] | null;
  subcategoryIds?: number[] | null;
  publishedAt?: any | null;
}

export interface ArticleMainImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleMainImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleObserversFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ArticleOrderByInput {
  author?: UserOrderByInput | null;
  categories?: ArticleCategoriesFieldOrderByInput | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  mainImage?: SortOrder | null;
  observers?: ArticleObserversFieldOrderByInput | null;
  pageLoadCount?: SortOrder | null;
  publishedAt?: SortOrder | null;
  subcategories?: ArticleSubcategoriesFieldOrderByInput | null;
  subtitle?: SortOrder | null;
  tag?: SortOrder | null;
  tags?: SortOrder | null;
  text?: SortOrder | null;
  title?: SortOrder | null;
  uniqueVisits?: ArticleUniqueVisitsFieldOrderByInput | null;
  userId?: SortOrder | null;
}

export interface ArticlePageLoadCountFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticlePageLoadCountFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ArticlePublishedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticlePublishedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleSubcategoriesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ArticleSubcategoriesFieldWhereInput {
  every?: ArticleSubcategoryWhereInput | null;
  none?: ArticleSubcategoryWhereInput | null;
  some?: ArticleSubcategoryWhereInput | null;
}

export interface ArticleSubcategoryIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleSubcategoryIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ArticleSubcategoryTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleSubcategoryTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleSubcategoryWhereInput {
  id?: ArticleSubcategoryIdFieldWhereInput | null;
  title?: ArticleSubcategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleSubcategoryWhereLogicAndInput[] | null;
  NOT?: ArticleSubcategoryWhereLogicNotInput[] | null;
  OR?: ArticleSubcategoryWhereLogicOrInput[] | null;
}

export interface ArticleSubcategoryWhereLogicAndInput {
  id?: ArticleSubcategoryIdFieldWhereInput | null;
  title?: ArticleSubcategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleSubcategoryWhereLogicAndInput[] | null;
  NOT?: ArticleSubcategoryWhereLogicNotInput[] | null;
  OR?: ArticleSubcategoryWhereLogicOrInput[] | null;
}

export interface ArticleSubcategoryWhereLogicNotInput {
  id?: ArticleSubcategoryIdFieldWhereInput | null;
  title?: ArticleSubcategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleSubcategoryWhereLogicAndInput[] | null;
  NOT?: ArticleSubcategoryWhereLogicNotInput[] | null;
  OR?: ArticleSubcategoryWhereLogicOrInput[] | null;
}

export interface ArticleSubcategoryWhereLogicOrInput {
  id?: ArticleSubcategoryIdFieldWhereInput | null;
  title?: ArticleSubcategoryTitleFieldWhereInput | null;
  default?: boolean | null;
  AND?: ArticleSubcategoryWhereLogicAndInput[] | null;
  NOT?: ArticleSubcategoryWhereLogicNotInput[] | null;
  OR?: ArticleSubcategoryWhereLogicOrInput[] | null;
}

export interface ArticleSubcategoryWhereScalarInput {
  OR?: (ArticleSubcategoryWhereScalarInput | null)[] | null;
  AND?: (ArticleSubcategoryWhereScalarInput | null)[] | null;
  NOT?: (ArticleSubcategoryWhereScalarInput | null)[] | null;
  id?: ArticleSubcategory_IdFieldWhereScalarInput | null;
  createdAt?: ArticleSubcategory_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ArticleSubcategory_UpdatedAtFieldWhereScalarInput | null;
  title?: ArticleSubcategory_TitleFieldWhereScalarInput | null;
  default?: ArticleSubcategory_DefaultFieldWhereScalarInput | null;
}

export interface ArticleSubcategoryWhereUniqueInput {
  id: number;
}

export interface ArticleSubcategory_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleSubcategory_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleSubcategory_DefaultFieldWhereScalarInput {
  equals?: boolean | null;
  not?: ArticleSubcategory_DefaultFieldWhereScalarInput | null;
}

export interface ArticleSubcategory_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleSubcategory_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ArticleSubcategory_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleSubcategory_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleSubcategory_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleSubcategory_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleSubtitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleSubtitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleTagFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleTagFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleTagsFieldWhereInput {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface ArticleTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleUniqueVisitsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ArticleVisitCompositeFieldWhereUniqueInput {
  visitorId: string;
  articleId: number;
}

export interface ArticleVisitInput {
  visitorId: string;
  articleId: number;
  userId?: number | null;
}

export interface ArticleVisitWhereScalarInput {
  OR?: (ArticleVisitWhereScalarInput | null)[] | null;
  AND?: (ArticleVisitWhereScalarInput | null)[] | null;
  NOT?: (ArticleVisitWhereScalarInput | null)[] | null;
  createdAt?: ArticleVisit_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ArticleVisit_UpdatedAtFieldWhereScalarInput | null;
  visitorId?: ArticleVisit_VisitorIdFieldWhereScalarInput | null;
  articleId?: ArticleVisit_ArticleIdFieldWhereScalarInput | null;
  userId?: ArticleVisit_UserIdFieldWhereScalarInput | null;
}

export interface ArticleVisitWhereUniqueInput {
  visitorId_articleId: ArticleVisitCompositeFieldWhereUniqueInput;
}

export interface ArticleVisit_ArticleFieldConnectOrCreateInput {
  where: ArticleWhereUniqueInput;
  create: ArticleVisit_ArticleFieldNestedCreateInput;
}

export interface ArticleVisit_ArticleFieldCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  connectOrCreate?: ArticleVisit_ArticleFieldConnectOrCreateInput | null;
  create?: ArticleVisit_ArticleFieldNestedCreateInput | null;
}

export interface ArticleVisit_ArticleFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldCreateInput | null;
  subcategories?: Article_SubcategoriesFieldCreateInput | null;
  tags: string[];
  userId?: number | null;
  mainImageFile?: Article_MainImageFileFieldCreateInput | null;
  author?: Article_AuthorFieldCreateInput | null;
  observers?: Article_ObserversFieldCreateInput | null;
  homeArticles?: Article_HomeArticlesFieldCreateInput | null;
}

export interface ArticleVisit_ArticleFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldUpdateInput | null;
  subcategories?: Article_SubcategoriesFieldUpdateInput | null;
  tags?: string[] | null;
  userId?: number | null;
  mainImageFile?: Article_MainImageFileFieldUpdateInput | null;
  author?: Article_AuthorFieldUpdateInput | null;
  observers?: Article_ObserversFieldUpdateInput | null;
  homeArticles?: Article_HomeArticlesFieldUpdateInput | null;
}

export interface ArticleVisit_ArticleFieldNestedUpsertInput {
  create: ArticleVisit_ArticleFieldNestedCreateInput;
  update: ArticleVisit_ArticleFieldNestedUpdateInput;
}

export interface ArticleVisit_ArticleFieldUpdateInput {
  connect?: ArticleWhereUniqueInput | null;
  connectOrCreate?: ArticleVisit_ArticleFieldConnectOrCreateInput | null;
  create?: ArticleVisit_ArticleFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ArticleVisit_ArticleFieldNestedUpdateInput | null;
  upsert?: ArticleVisit_ArticleFieldNestedUpsertInput | null;
}

export interface ArticleVisit_ArticleIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleVisit_ArticleIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ArticleVisit_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleVisit_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleVisit_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ArticleVisit_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ArticleVisit_UserFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: ArticleVisit_UserFieldNestedCreateInput;
}

export interface ArticleVisit_UserFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: ArticleVisit_UserFieldConnectOrCreateInput | null;
  create?: ArticleVisit_UserFieldNestedCreateInput | null;
}

export interface ArticleVisit_UserFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  articles?: User_ArticlesFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
}

export interface ArticleVisit_UserFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  articles?: User_ArticlesFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
}

export interface ArticleVisit_UserFieldNestedUpsertInput {
  create: ArticleVisit_UserFieldNestedCreateInput;
  update: ArticleVisit_UserFieldNestedUpdateInput;
}

export interface ArticleVisit_UserFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: ArticleVisit_UserFieldConnectOrCreateInput | null;
  create?: ArticleVisit_UserFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ArticleVisit_UserFieldNestedUpdateInput | null;
  upsert?: ArticleVisit_UserFieldNestedUpsertInput | null;
}

export interface ArticleVisit_UserIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ArticleVisit_UserIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ArticleVisit_VisitorIdFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ArticleVisit_VisitorIdFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ArticleWhereInput {
  createdAt?: ArticleCreatedAtFieldWhereInput | null;
  id?: ArticleIdFieldWhereInput | null;
  mainImage?: ArticleMainImageFieldWhereInput | null;
  pageLoadCount?: ArticlePageLoadCountFieldWhereInput | null;
  publishedAt?: ArticlePublishedAtFieldWhereInput | null;
  subtitle?: ArticleSubtitleFieldWhereInput | null;
  tag?: ArticleTagFieldWhereInput | null;
  text?: ArticleTextFieldWhereInput | null;
  title?: ArticleTitleFieldWhereInput | null;
  categories?: ArticleCategoriesFieldWhereInput | null;
  subcategories?: ArticleSubcategoriesFieldWhereInput | null;
  tags?: ArticleTagsFieldWhereInput | null;
  AND?: ArticleWhereLogicAndInput[] | null;
  NOT?: ArticleWhereLogicNotInput[] | null;
  OR?: ArticleWhereLogicOrInput[] | null;
  searchString?: string | null;
  published?: boolean | null;
  tagsSearch?: (string | null)[] | null;
}

export interface ArticleWhereLogicAndInput {
  createdAt?: ArticleCreatedAtFieldWhereInput | null;
  id?: ArticleIdFieldWhereInput | null;
  mainImage?: ArticleMainImageFieldWhereInput | null;
  pageLoadCount?: ArticlePageLoadCountFieldWhereInput | null;
  publishedAt?: ArticlePublishedAtFieldWhereInput | null;
  subtitle?: ArticleSubtitleFieldWhereInput | null;
  tag?: ArticleTagFieldWhereInput | null;
  text?: ArticleTextFieldWhereInput | null;
  title?: ArticleTitleFieldWhereInput | null;
  categories?: ArticleCategoriesFieldWhereInput | null;
  subcategories?: ArticleSubcategoriesFieldWhereInput | null;
  tags?: ArticleTagsFieldWhereInput | null;
  AND?: ArticleWhereLogicAndInput[] | null;
  NOT?: ArticleWhereLogicNotInput[] | null;
  OR?: ArticleWhereLogicOrInput[] | null;
}

export interface ArticleWhereLogicNotInput {
  createdAt?: ArticleCreatedAtFieldWhereInput | null;
  id?: ArticleIdFieldWhereInput | null;
  mainImage?: ArticleMainImageFieldWhereInput | null;
  pageLoadCount?: ArticlePageLoadCountFieldWhereInput | null;
  publishedAt?: ArticlePublishedAtFieldWhereInput | null;
  subtitle?: ArticleSubtitleFieldWhereInput | null;
  tag?: ArticleTagFieldWhereInput | null;
  text?: ArticleTextFieldWhereInput | null;
  title?: ArticleTitleFieldWhereInput | null;
  categories?: ArticleCategoriesFieldWhereInput | null;
  subcategories?: ArticleSubcategoriesFieldWhereInput | null;
  tags?: ArticleTagsFieldWhereInput | null;
  AND?: ArticleWhereLogicAndInput[] | null;
  NOT?: ArticleWhereLogicNotInput[] | null;
  OR?: ArticleWhereLogicOrInput[] | null;
}

export interface ArticleWhereLogicOrInput {
  createdAt?: ArticleCreatedAtFieldWhereInput | null;
  id?: ArticleIdFieldWhereInput | null;
  mainImage?: ArticleMainImageFieldWhereInput | null;
  pageLoadCount?: ArticlePageLoadCountFieldWhereInput | null;
  publishedAt?: ArticlePublishedAtFieldWhereInput | null;
  subtitle?: ArticleSubtitleFieldWhereInput | null;
  tag?: ArticleTagFieldWhereInput | null;
  text?: ArticleTextFieldWhereInput | null;
  title?: ArticleTitleFieldWhereInput | null;
  categories?: ArticleCategoriesFieldWhereInput | null;
  subcategories?: ArticleSubcategoriesFieldWhereInput | null;
  tags?: ArticleTagsFieldWhereInput | null;
  AND?: ArticleWhereLogicAndInput[] | null;
  NOT?: ArticleWhereLogicNotInput[] | null;
  OR?: ArticleWhereLogicOrInput[] | null;
}

export interface ArticleWhereScalarInput {
  OR?: (ArticleWhereScalarInput | null)[] | null;
  AND?: (ArticleWhereScalarInput | null)[] | null;
  NOT?: (ArticleWhereScalarInput | null)[] | null;
  id?: Article_IdFieldWhereScalarInput | null;
  createdAt?: Article_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Article_UpdatedAtFieldWhereScalarInput | null;
  publishedAt?: Article_PublishedAtFieldWhereScalarInput | null;
  title?: Article_TitleFieldWhereScalarInput | null;
  subtitle?: Article_SubtitleFieldWhereScalarInput | null;
  mainImage?: Article_MainImageFieldWhereScalarInput | null;
  text?: Article_TextFieldWhereScalarInput | null;
  tag?: Article_TagFieldWhereScalarInput | null;
  pageLoadCount?: Article_PageLoadCountFieldWhereScalarInput | null;
  tags?: Article_TagsFieldWhereScalarInput | null;
  userId?: Article_UserIdFieldWhereScalarInput | null;
}

export interface ArticleWhereUniqueInput {
  id: number;
}

export interface Article_AuthorFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: Article_AuthorFieldNestedCreateInput;
}

export interface Article_AuthorFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Article_AuthorFieldConnectOrCreateInput | null;
  create?: Article_AuthorFieldNestedCreateInput | null;
}

export interface Article_AuthorFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface Article_AuthorFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface Article_AuthorFieldNestedUpsertInput {
  create: Article_AuthorFieldNestedCreateInput;
  update: Article_AuthorFieldNestedUpdateInput;
}

export interface Article_AuthorFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Article_AuthorFieldConnectOrCreateInput | null;
  create?: Article_AuthorFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Article_AuthorFieldNestedUpdateInput | null;
  upsert?: Article_AuthorFieldNestedUpsertInput | null;
}

export interface Article_CategoriesFieldConnectOrCreateInput {
  where: ArticleCategoryWhereUniqueInput;
  create: Article_CategoriesFieldNestedCreateInput;
}

export interface Article_CategoriesFieldCreateInput {
  connect?: ArticleCategoryWhereUniqueInput[] | null;
  connectOrCreate?: Article_CategoriesFieldConnectOrCreateInput[] | null;
  create?: Article_CategoriesFieldNestedCreateInput[] | null;
}

export interface Article_CategoriesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  default?: boolean | null;
}

export interface Article_CategoriesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  default?: boolean | null;
}

export interface Article_CategoriesFieldNestedUpdateInput {
  where: ArticleCategoryWhereUniqueInput;
  data: Article_CategoriesFieldNestedUpdateDataInput;
}

export interface Article_CategoriesFieldNestedUpsertInput {
  create: Article_CategoriesFieldNestedCreateInput;
  update: Article_CategoriesFieldNestedUpdateDataInput;
  where: ArticleCategoryWhereUniqueInput;
}

export interface Article_CategoriesFieldUpdateInput {
  connect?: ArticleCategoryWhereUniqueInput[] | null;
  connectOrCreate?: Article_CategoriesFieldConnectOrCreateInput[] | null;
  create?: Article_CategoriesFieldNestedCreateInput[] | null;
  delete?: ArticleCategoryWhereUniqueInput[] | null;
  deleteMany?: ArticleCategoryWhereScalarInput | null;
  disconnect?: ArticleCategoryWhereUniqueInput[] | null;
  set?: ArticleCategoryWhereUniqueInput[] | null;
  update?: Article_CategoriesFieldNestedUpdateInput[] | null;
  upsert?: Article_CategoriesFieldNestedUpsertInput[] | null;
}

export interface Article_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Article_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Article_HomeArticlesFieldConnectOrCreateInput {
  where: HomeArticleWhereUniqueInput;
  create: Article_HomeArticlesFieldNestedCreateInput;
}

export interface Article_HomeArticlesFieldCreateInput {
  connect?: HomeArticleWhereUniqueInput[] | null;
  connectOrCreate?: Article_HomeArticlesFieldConnectOrCreateInput[] | null;
  create?: Article_HomeArticlesFieldNestedCreateInput[] | null;
}

export interface Article_HomeArticlesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  note?: string | null;
  date?: any | null;
}

export interface Article_HomeArticlesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  note?: string | null;
  date?: any | null;
}

export interface Article_HomeArticlesFieldNestedUpdateInput {
  where: HomeArticleWhereUniqueInput;
  data: Article_HomeArticlesFieldNestedUpdateDataInput;
}

export interface Article_HomeArticlesFieldNestedUpsertInput {
  create: Article_HomeArticlesFieldNestedCreateInput;
  update: Article_HomeArticlesFieldNestedUpdateDataInput;
  where: HomeArticleWhereUniqueInput;
}

export interface Article_HomeArticlesFieldUpdateInput {
  connect?: HomeArticleWhereUniqueInput[] | null;
  connectOrCreate?: Article_HomeArticlesFieldConnectOrCreateInput[] | null;
  create?: Article_HomeArticlesFieldNestedCreateInput[] | null;
  delete?: HomeArticleWhereUniqueInput[] | null;
  deleteMany?: HomeArticleWhereScalarInput | null;
  disconnect?: HomeArticleWhereUniqueInput[] | null;
  set?: HomeArticleWhereUniqueInput[] | null;
  update?: Article_HomeArticlesFieldNestedUpdateInput[] | null;
  upsert?: Article_HomeArticlesFieldNestedUpsertInput[] | null;
}

export interface Article_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Article_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Article_MainImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Article_MainImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Article_MainImageFileFieldConnectOrCreateInput {
  where: FileWhereUniqueInput;
  create: Article_MainImageFileFieldNestedCreateInput;
}

export interface Article_MainImageFileFieldCreateInput {
  connect?: FileWhereUniqueInput | null;
  connectOrCreate?: Article_MainImageFileFieldConnectOrCreateInput | null;
  create?: Article_MainImageFileFieldNestedCreateInput | null;
}

export interface Article_MainImageFileFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  extension: string;
  isImage?: boolean | null;
  lastModified: any;
  mimeType: string;
  name: string;
  originalName: string;
  size: number;
  sourceTitle?: string | null;
  sourceURL?: string | null;
}

export interface Article_MainImageFileFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  extension?: string | null;
  isImage?: boolean | null;
  lastModified?: any | null;
  mimeType?: string | null;
  name?: string | null;
  originalName?: string | null;
  size?: number | null;
  sourceTitle?: string | null;
  sourceURL?: string | null;
}

export interface Article_MainImageFileFieldNestedUpsertInput {
  create: Article_MainImageFileFieldNestedCreateInput;
  update: Article_MainImageFileFieldNestedUpdateInput;
}

export interface Article_MainImageFileFieldUpdateInput {
  connect?: FileWhereUniqueInput | null;
  connectOrCreate?: Article_MainImageFileFieldConnectOrCreateInput | null;
  create?: Article_MainImageFileFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Article_MainImageFileFieldNestedUpdateInput | null;
  upsert?: Article_MainImageFileFieldNestedUpsertInput | null;
}

export interface Article_ObserversFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: Article_ObserversFieldNestedCreateInput;
}

export interface Article_ObserversFieldCreateInput {
  connect?: UserWhereUniqueInput[] | null;
  connectOrCreate?: Article_ObserversFieldConnectOrCreateInput[] | null;
  create?: Article_ObserversFieldNestedCreateInput[] | null;
}

export interface Article_ObserversFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface Article_ObserversFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface Article_ObserversFieldNestedUpdateInput {
  where: UserWhereUniqueInput;
  data: Article_ObserversFieldNestedUpdateDataInput;
}

export interface Article_ObserversFieldNestedUpsertInput {
  create: Article_ObserversFieldNestedCreateInput;
  update: Article_ObserversFieldNestedUpdateDataInput;
  where: UserWhereUniqueInput;
}

export interface Article_ObserversFieldUpdateInput {
  connect?: UserWhereUniqueInput[] | null;
  connectOrCreate?: Article_ObserversFieldConnectOrCreateInput[] | null;
  create?: Article_ObserversFieldNestedCreateInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  deleteMany?: UserWhereScalarInput | null;
  disconnect?: UserWhereUniqueInput[] | null;
  set?: UserWhereUniqueInput[] | null;
  update?: Article_ObserversFieldNestedUpdateInput[] | null;
  upsert?: Article_ObserversFieldNestedUpsertInput[] | null;
}

export interface Article_PageLoadCountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Article_PageLoadCountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Article_PublishedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Article_PublishedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Article_SubcategoriesFieldConnectOrCreateInput {
  where: ArticleSubcategoryWhereUniqueInput;
  create: Article_SubcategoriesFieldNestedCreateInput;
}

export interface Article_SubcategoriesFieldCreateInput {
  connect?: ArticleSubcategoryWhereUniqueInput[] | null;
  connectOrCreate?: Article_SubcategoriesFieldConnectOrCreateInput[] | null;
  create?: Article_SubcategoriesFieldNestedCreateInput[] | null;
}

export interface Article_SubcategoriesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  default?: boolean | null;
}

export interface Article_SubcategoriesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  default?: boolean | null;
}

export interface Article_SubcategoriesFieldNestedUpdateInput {
  where: ArticleSubcategoryWhereUniqueInput;
  data: Article_SubcategoriesFieldNestedUpdateDataInput;
}

export interface Article_SubcategoriesFieldNestedUpsertInput {
  create: Article_SubcategoriesFieldNestedCreateInput;
  update: Article_SubcategoriesFieldNestedUpdateDataInput;
  where: ArticleSubcategoryWhereUniqueInput;
}

export interface Article_SubcategoriesFieldUpdateInput {
  connect?: ArticleSubcategoryWhereUniqueInput[] | null;
  connectOrCreate?: Article_SubcategoriesFieldConnectOrCreateInput[] | null;
  create?: Article_SubcategoriesFieldNestedCreateInput[] | null;
  delete?: ArticleSubcategoryWhereUniqueInput[] | null;
  deleteMany?: ArticleSubcategoryWhereScalarInput | null;
  disconnect?: ArticleSubcategoryWhereUniqueInput[] | null;
  set?: ArticleSubcategoryWhereUniqueInput[] | null;
  update?: Article_SubcategoriesFieldNestedUpdateInput[] | null;
  upsert?: Article_SubcategoriesFieldNestedUpsertInput[] | null;
}

export interface Article_SubtitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Article_SubtitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Article_TagFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Article_TagFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Article_TagsFieldWhereScalarInput {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface Article_TextFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Article_TextFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Article_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Article_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Article_UniqueVisitsFieldConnectOrCreateInput {
  where: ArticleVisitWhereUniqueInput;
  create: Article_UniqueVisitsFieldNestedCreateInput;
}

export interface Article_UniqueVisitsFieldCreateInput {
  connect?: ArticleVisitWhereUniqueInput[] | null;
  connectOrCreate?: Article_UniqueVisitsFieldConnectOrCreateInput[] | null;
  create?: Article_UniqueVisitsFieldNestedCreateInput[] | null;
}

export interface Article_UniqueVisitsFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  visitorId: string;
  userId?: number | null;
  user?: ArticleVisit_UserFieldCreateInput | null;
}

export interface Article_UniqueVisitsFieldNestedUpdateDataInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  visitorId?: string | null;
  userId?: number | null;
  user?: ArticleVisit_UserFieldUpdateInput | null;
}

export interface Article_UniqueVisitsFieldNestedUpdateInput {
  where: ArticleVisitWhereUniqueInput;
  data: Article_UniqueVisitsFieldNestedUpdateDataInput;
}

export interface Article_UniqueVisitsFieldNestedUpsertInput {
  create: Article_UniqueVisitsFieldNestedCreateInput;
  update: Article_UniqueVisitsFieldNestedUpdateDataInput;
  where: ArticleVisitWhereUniqueInput;
}

export interface Article_UniqueVisitsFieldUpdateInput {
  connect?: ArticleVisitWhereUniqueInput[] | null;
  connectOrCreate?: Article_UniqueVisitsFieldConnectOrCreateInput[] | null;
  create?: Article_UniqueVisitsFieldNestedCreateInput[] | null;
  delete?: ArticleVisitWhereUniqueInput[] | null;
  deleteMany?: ArticleVisitWhereScalarInput | null;
  disconnect?: ArticleVisitWhereUniqueInput[] | null;
  set?: ArticleVisitWhereUniqueInput[] | null;
  update?: Article_UniqueVisitsFieldNestedUpdateInput[] | null;
  upsert?: Article_UniqueVisitsFieldNestedUpsertInput[] | null;
}

export interface Article_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Article_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Article_UserIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Article_UserIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface BannerIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: BannerIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface BannerImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: BannerImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface BannerInput {
  image: string;
  mobileImage?: string | null;
  validUntil: any;
  pathMatch: string;
  exact: boolean;
  note?: string | null;
  link?: string | null;
  type: BannerType;
}

export interface BannerLinkFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: BannerLinkFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface BannerMobileImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: BannerMobileImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface BannerNoteFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: BannerNoteFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface BannerPathMatchFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: BannerPathMatchFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface BannerValidUntilFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: BannerValidUntilFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface BannerWhereInput {
  exact?: boolean | null;
  id?: BannerIdFieldWhereInput | null;
  image?: BannerImageFieldWhereInput | null;
  link?: BannerLinkFieldWhereInput | null;
  mobileImage?: BannerMobileImageFieldWhereInput | null;
  note?: BannerNoteFieldWhereInput | null;
  pathMatch?: BannerPathMatchFieldWhereInput | null;
  validUntil?: BannerValidUntilFieldWhereInput | null;
  type?: BannerType | null;
  AND?: BannerWhereLogicAndInput[] | null;
  NOT?: BannerWhereLogicNotInput[] | null;
  OR?: BannerWhereLogicOrInput[] | null;
  checkValidity?: boolean | null;
}

export interface BannerWhereLogicAndInput {
  exact?: boolean | null;
  id?: BannerIdFieldWhereInput | null;
  image?: BannerImageFieldWhereInput | null;
  link?: BannerLinkFieldWhereInput | null;
  mobileImage?: BannerMobileImageFieldWhereInput | null;
  note?: BannerNoteFieldWhereInput | null;
  pathMatch?: BannerPathMatchFieldWhereInput | null;
  validUntil?: BannerValidUntilFieldWhereInput | null;
  type?: BannerType | null;
  AND?: BannerWhereLogicAndInput[] | null;
  NOT?: BannerWhereLogicNotInput[] | null;
  OR?: BannerWhereLogicOrInput[] | null;
}

export interface BannerWhereLogicNotInput {
  exact?: boolean | null;
  id?: BannerIdFieldWhereInput | null;
  image?: BannerImageFieldWhereInput | null;
  link?: BannerLinkFieldWhereInput | null;
  mobileImage?: BannerMobileImageFieldWhereInput | null;
  note?: BannerNoteFieldWhereInput | null;
  pathMatch?: BannerPathMatchFieldWhereInput | null;
  validUntil?: BannerValidUntilFieldWhereInput | null;
  type?: BannerType | null;
  AND?: BannerWhereLogicAndInput[] | null;
  NOT?: BannerWhereLogicNotInput[] | null;
  OR?: BannerWhereLogicOrInput[] | null;
}

export interface BannerWhereLogicOrInput {
  exact?: boolean | null;
  id?: BannerIdFieldWhereInput | null;
  image?: BannerImageFieldWhereInput | null;
  link?: BannerLinkFieldWhereInput | null;
  mobileImage?: BannerMobileImageFieldWhereInput | null;
  note?: BannerNoteFieldWhereInput | null;
  pathMatch?: BannerPathMatchFieldWhereInput | null;
  validUntil?: BannerValidUntilFieldWhereInput | null;
  type?: BannerType | null;
  AND?: BannerWhereLogicAndInput[] | null;
  NOT?: BannerWhereLogicNotInput[] | null;
  OR?: BannerWhereLogicOrInput[] | null;
}

export interface BannerWhereUniqueInput {
  id: number;
}

export interface CareerApplicationCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: CareerApplicationCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface CareerApplicationDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationEmailFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationEmailFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationFirstnameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationFirstnameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: CareerApplicationIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface CareerApplicationInput {
  positionId: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedIn?: string | null;
  description?: string | null;
  attachments: string[];
}

export interface CareerApplicationLastnameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationLastnameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationLinkedInFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationLinkedInFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationPhoneFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerApplicationPhoneFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerApplicationPositionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: CareerApplicationPositionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface CareerApplicationWhereInput {
  id?: CareerApplicationIdFieldWhereInput | null;
  createdAt?: CareerApplicationCreatedAtFieldWhereInput | null;
  description?: CareerApplicationDescriptionFieldWhereInput | null;
  email?: CareerApplicationEmailFieldWhereInput | null;
  firstname?: CareerApplicationFirstnameFieldWhereInput | null;
  lastname?: CareerApplicationLastnameFieldWhereInput | null;
  linkedIn?: CareerApplicationLinkedInFieldWhereInput | null;
  phone?: CareerApplicationPhoneFieldWhereInput | null;
  positionId?: CareerApplicationPositionIdFieldWhereInput | null;
  AND?: CareerApplicationWhereLogicAndInput[] | null;
  NOT?: CareerApplicationWhereLogicNotInput[] | null;
  OR?: CareerApplicationWhereLogicOrInput[] | null;
}

export interface CareerApplicationWhereLogicAndInput {
  id?: CareerApplicationIdFieldWhereInput | null;
  createdAt?: CareerApplicationCreatedAtFieldWhereInput | null;
  description?: CareerApplicationDescriptionFieldWhereInput | null;
  email?: CareerApplicationEmailFieldWhereInput | null;
  firstname?: CareerApplicationFirstnameFieldWhereInput | null;
  lastname?: CareerApplicationLastnameFieldWhereInput | null;
  linkedIn?: CareerApplicationLinkedInFieldWhereInput | null;
  phone?: CareerApplicationPhoneFieldWhereInput | null;
  positionId?: CareerApplicationPositionIdFieldWhereInput | null;
  AND?: CareerApplicationWhereLogicAndInput[] | null;
  NOT?: CareerApplicationWhereLogicNotInput[] | null;
  OR?: CareerApplicationWhereLogicOrInput[] | null;
}

export interface CareerApplicationWhereLogicNotInput {
  id?: CareerApplicationIdFieldWhereInput | null;
  createdAt?: CareerApplicationCreatedAtFieldWhereInput | null;
  description?: CareerApplicationDescriptionFieldWhereInput | null;
  email?: CareerApplicationEmailFieldWhereInput | null;
  firstname?: CareerApplicationFirstnameFieldWhereInput | null;
  lastname?: CareerApplicationLastnameFieldWhereInput | null;
  linkedIn?: CareerApplicationLinkedInFieldWhereInput | null;
  phone?: CareerApplicationPhoneFieldWhereInput | null;
  positionId?: CareerApplicationPositionIdFieldWhereInput | null;
  AND?: CareerApplicationWhereLogicAndInput[] | null;
  NOT?: CareerApplicationWhereLogicNotInput[] | null;
  OR?: CareerApplicationWhereLogicOrInput[] | null;
}

export interface CareerApplicationWhereLogicOrInput {
  id?: CareerApplicationIdFieldWhereInput | null;
  createdAt?: CareerApplicationCreatedAtFieldWhereInput | null;
  description?: CareerApplicationDescriptionFieldWhereInput | null;
  email?: CareerApplicationEmailFieldWhereInput | null;
  firstname?: CareerApplicationFirstnameFieldWhereInput | null;
  lastname?: CareerApplicationLastnameFieldWhereInput | null;
  linkedIn?: CareerApplicationLinkedInFieldWhereInput | null;
  phone?: CareerApplicationPhoneFieldWhereInput | null;
  positionId?: CareerApplicationPositionIdFieldWhereInput | null;
  AND?: CareerApplicationWhereLogicAndInput[] | null;
  NOT?: CareerApplicationWhereLogicNotInput[] | null;
  OR?: CareerApplicationWhereLogicOrInput[] | null;
}

export interface CareerApplicationWhereUniqueInput {
  id: number;
}

export interface CareerPositionBoardingDateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: CareerPositionBoardingDateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface CareerPositionContactFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionContactFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionContractTypeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionContractTypeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: CareerPositionCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface CareerPositionDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: CareerPositionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface CareerPositionInput {
  name: string;
  shortDescription: string;
  boardingDate: string;
  workplaceCity: string;
  contractType: string;
  paymentFrom: string;
  paymentUpTo?: string | null;
  published: boolean;
  contact: string;
  description: string;
}

export interface CareerPositionNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionPaymentFromFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionPaymentFromFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionPaymentUpToFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionPaymentUpToFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionShortDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionShortDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CareerPositionWhereInput {
  boardingDate?: CareerPositionBoardingDateFieldWhereInput | null;
  contact?: CareerPositionContactFieldWhereInput | null;
  contractType?: CareerPositionContractTypeFieldWhereInput | null;
  createdAt?: CareerPositionCreatedAtFieldWhereInput | null;
  id?: CareerPositionIdFieldWhereInput | null;
  description?: CareerPositionDescriptionFieldWhereInput | null;
  name?: CareerPositionNameFieldWhereInput | null;
  paymentFrom?: CareerPositionPaymentFromFieldWhereInput | null;
  paymentUpTo?: CareerPositionPaymentUpToFieldWhereInput | null;
  published?: boolean | null;
  shortDescription?: CareerPositionShortDescriptionFieldWhereInput | null;
  workplaceCity?: CareerPositionWorkplaceCityFieldWhereInput | null;
  AND?: CareerPositionWhereLogicAndInput[] | null;
  NOT?: CareerPositionWhereLogicNotInput[] | null;
  OR?: CareerPositionWhereLogicOrInput[] | null;
}

export interface CareerPositionWhereLogicAndInput {
  boardingDate?: CareerPositionBoardingDateFieldWhereInput | null;
  contact?: CareerPositionContactFieldWhereInput | null;
  contractType?: CareerPositionContractTypeFieldWhereInput | null;
  createdAt?: CareerPositionCreatedAtFieldWhereInput | null;
  id?: CareerPositionIdFieldWhereInput | null;
  description?: CareerPositionDescriptionFieldWhereInput | null;
  name?: CareerPositionNameFieldWhereInput | null;
  paymentFrom?: CareerPositionPaymentFromFieldWhereInput | null;
  paymentUpTo?: CareerPositionPaymentUpToFieldWhereInput | null;
  published?: boolean | null;
  shortDescription?: CareerPositionShortDescriptionFieldWhereInput | null;
  workplaceCity?: CareerPositionWorkplaceCityFieldWhereInput | null;
  AND?: CareerPositionWhereLogicAndInput[] | null;
  NOT?: CareerPositionWhereLogicNotInput[] | null;
  OR?: CareerPositionWhereLogicOrInput[] | null;
}

export interface CareerPositionWhereLogicNotInput {
  boardingDate?: CareerPositionBoardingDateFieldWhereInput | null;
  contact?: CareerPositionContactFieldWhereInput | null;
  contractType?: CareerPositionContractTypeFieldWhereInput | null;
  createdAt?: CareerPositionCreatedAtFieldWhereInput | null;
  id?: CareerPositionIdFieldWhereInput | null;
  description?: CareerPositionDescriptionFieldWhereInput | null;
  name?: CareerPositionNameFieldWhereInput | null;
  paymentFrom?: CareerPositionPaymentFromFieldWhereInput | null;
  paymentUpTo?: CareerPositionPaymentUpToFieldWhereInput | null;
  published?: boolean | null;
  shortDescription?: CareerPositionShortDescriptionFieldWhereInput | null;
  workplaceCity?: CareerPositionWorkplaceCityFieldWhereInput | null;
  AND?: CareerPositionWhereLogicAndInput[] | null;
  NOT?: CareerPositionWhereLogicNotInput[] | null;
  OR?: CareerPositionWhereLogicOrInput[] | null;
}

export interface CareerPositionWhereLogicOrInput {
  boardingDate?: CareerPositionBoardingDateFieldWhereInput | null;
  contact?: CareerPositionContactFieldWhereInput | null;
  contractType?: CareerPositionContractTypeFieldWhereInput | null;
  createdAt?: CareerPositionCreatedAtFieldWhereInput | null;
  id?: CareerPositionIdFieldWhereInput | null;
  description?: CareerPositionDescriptionFieldWhereInput | null;
  name?: CareerPositionNameFieldWhereInput | null;
  paymentFrom?: CareerPositionPaymentFromFieldWhereInput | null;
  paymentUpTo?: CareerPositionPaymentUpToFieldWhereInput | null;
  published?: boolean | null;
  shortDescription?: CareerPositionShortDescriptionFieldWhereInput | null;
  workplaceCity?: CareerPositionWorkplaceCityFieldWhereInput | null;
  AND?: CareerPositionWhereLogicAndInput[] | null;
  NOT?: CareerPositionWhereLogicNotInput[] | null;
  OR?: CareerPositionWhereLogicOrInput[] | null;
}

export interface CareerPositionWhereUniqueInput {
  id: number;
}

export interface CareerPositionWorkplaceCityFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CareerPositionWorkplaceCityFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CurrencyCodeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CurrencyCodeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CurrencyCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: CurrencyCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface CurrencyIconFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CurrencyIconFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CurrencyIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: CurrencyIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface CurrencyInput {
  name: string;
  code: string;
  icon?: string | null;
}

export interface CurrencyNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: CurrencyNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface CurrencyWhereInput {
  id?: CurrencyIdFieldWhereInput | null;
  code?: CurrencyCodeFieldWhereInput | null;
  createdAt?: CurrencyCreatedAtFieldWhereInput | null;
  icon?: CurrencyIconFieldWhereInput | null;
  name?: CurrencyNameFieldWhereInput | null;
  AND?: CurrencyWhereLogicAndInput[] | null;
  NOT?: CurrencyWhereLogicNotInput[] | null;
  OR?: CurrencyWhereLogicOrInput[] | null;
}

export interface CurrencyWhereLogicAndInput {
  id?: CurrencyIdFieldWhereInput | null;
  code?: CurrencyCodeFieldWhereInput | null;
  createdAt?: CurrencyCreatedAtFieldWhereInput | null;
  icon?: CurrencyIconFieldWhereInput | null;
  name?: CurrencyNameFieldWhereInput | null;
  AND?: CurrencyWhereLogicAndInput[] | null;
  NOT?: CurrencyWhereLogicNotInput[] | null;
  OR?: CurrencyWhereLogicOrInput[] | null;
}

export interface CurrencyWhereLogicNotInput {
  id?: CurrencyIdFieldWhereInput | null;
  code?: CurrencyCodeFieldWhereInput | null;
  createdAt?: CurrencyCreatedAtFieldWhereInput | null;
  icon?: CurrencyIconFieldWhereInput | null;
  name?: CurrencyNameFieldWhereInput | null;
  AND?: CurrencyWhereLogicAndInput[] | null;
  NOT?: CurrencyWhereLogicNotInput[] | null;
  OR?: CurrencyWhereLogicOrInput[] | null;
}

export interface CurrencyWhereLogicOrInput {
  id?: CurrencyIdFieldWhereInput | null;
  code?: CurrencyCodeFieldWhereInput | null;
  createdAt?: CurrencyCreatedAtFieldWhereInput | null;
  icon?: CurrencyIconFieldWhereInput | null;
  name?: CurrencyNameFieldWhereInput | null;
  AND?: CurrencyWhereLogicAndInput[] | null;
  NOT?: CurrencyWhereLogicNotInput[] | null;
  OR?: CurrencyWhereLogicOrInput[] | null;
}

export interface CurrencyWhereUniqueInput {
  id: number;
}

export interface Currency_TournamentPrizesFieldConnectOrCreateInput {
  where: TournamentPrizeWhereUniqueInput;
  create: Currency_TournamentPrizesFieldNestedCreateInput;
}

export interface Currency_TournamentPrizesFieldCreateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: Currency_TournamentPrizesFieldConnectOrCreateInput[] | null;
  create?: Currency_TournamentPrizesFieldNestedCreateInput[] | null;
}

export interface Currency_TournamentPrizesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldCreateInput | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  quantity: number;
  placement: number[];
  state?: TournamentPrizeState | null;
  tournamentId: number;
  tournament?: TournamentPrize_TournamentFieldCreateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldCreateInput | null;
  winners?: TournamentPrize_WinnersFieldCreateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldCreateInput | null;
}

export interface Currency_TournamentPrizesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldUpdateInput | null;
  type?: TournamentPrizeType | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: number | null;
  placement?: number[] | null;
  state?: TournamentPrizeState | null;
  tournamentId?: number | null;
  tournament?: TournamentPrize_TournamentFieldUpdateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldUpdateInput | null;
  winners?: TournamentPrize_WinnersFieldUpdateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldUpdateInput | null;
}

export interface Currency_TournamentPrizesFieldNestedUpdateInput {
  where: TournamentPrizeWhereUniqueInput;
  data: Currency_TournamentPrizesFieldNestedUpdateDataInput;
}

export interface Currency_TournamentPrizesFieldNestedUpsertInput {
  create: Currency_TournamentPrizesFieldNestedCreateInput;
  update: Currency_TournamentPrizesFieldNestedUpdateDataInput;
  where: TournamentPrizeWhereUniqueInput;
}

export interface Currency_TournamentPrizesFieldUpdateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: Currency_TournamentPrizesFieldConnectOrCreateInput[] | null;
  create?: Currency_TournamentPrizesFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeWhereScalarInput | null;
  disconnect?: TournamentPrizeWhereUniqueInput[] | null;
  set?: TournamentPrizeWhereUniqueInput[] | null;
  update?: Currency_TournamentPrizesFieldNestedUpdateInput[] | null;
  upsert?: Currency_TournamentPrizesFieldNestedUpsertInput[] | null;
}

export interface Currency_WalletsFieldConnectOrCreateInput {
  where: WalletWhereUniqueInput;
  create: Currency_WalletsFieldNestedCreateInput;
}

export interface Currency_WalletsFieldCreateInput {
  connect?: WalletWhereUniqueInput[] | null;
  connectOrCreate?: Currency_WalletsFieldConnectOrCreateInput[] | null;
  create?: Currency_WalletsFieldNestedCreateInput[] | null;
}

export interface Currency_WalletsFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  merchantId: number;
  merchant?: Wallet_MerchantFieldCreateInput | null;
  senderTransactions?: Wallet_SenderTransactionsFieldCreateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldCreateInput | null;
}

export interface Currency_WalletsFieldNestedUpdateDataInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  merchantId?: number | null;
  merchant?: Wallet_MerchantFieldUpdateInput | null;
  senderTransactions?: Wallet_SenderTransactionsFieldUpdateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldUpdateInput | null;
}

export interface Currency_WalletsFieldNestedUpdateInput {
  where: WalletWhereUniqueInput;
  data: Currency_WalletsFieldNestedUpdateDataInput;
}

export interface Currency_WalletsFieldNestedUpsertInput {
  create: Currency_WalletsFieldNestedCreateInput;
  update: Currency_WalletsFieldNestedUpdateDataInput;
  where: WalletWhereUniqueInput;
}

export interface Currency_WalletsFieldUpdateInput {
  connect?: WalletWhereUniqueInput[] | null;
  connectOrCreate?: Currency_WalletsFieldConnectOrCreateInput[] | null;
  create?: Currency_WalletsFieldNestedCreateInput[] | null;
  delete?: WalletWhereUniqueInput[] | null;
  deleteMany?: WalletWhereScalarInput | null;
  disconnect?: WalletWhereUniqueInput[] | null;
  set?: WalletWhereUniqueInput[] | null;
  update?: Currency_WalletsFieldNestedUpdateInput[] | null;
  upsert?: Currency_WalletsFieldNestedUpsertInput[] | null;
}

export interface CustomFieldsInput {
  name?: string | null;
  required?: boolean | null;
  editable?: boolean | null;
  data?: any | null;
}

export interface EmbedTournamentInput {
  link?: string | null;
}

export interface FileCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: FileCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface FileExtensionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileExtensionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: FileIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface FileInput {
  extension: string;
  isImage: boolean;
  lastModified: any;
  mimeType: string;
  name: string;
  size: number;
  originalName: string;
  sourceURL?: string | null;
  sourceTitle?: string | null;
}

export interface FileLastModifiedFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: FileLastModifiedFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface FileMimeTypeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileMimeTypeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileOriginalNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileOriginalNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileSizeFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: FileSizeFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface FileSourceTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileSourceTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileSourceURLFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: FileSourceURLFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface FileWhereInput {
  id?: FileIdFieldWhereInput | null;
  createdAt?: FileCreatedAtFieldWhereInput | null;
  extension?: FileExtensionFieldWhereInput | null;
  isImage?: boolean | null;
  lastModified?: FileLastModifiedFieldWhereInput | null;
  mimeType?: FileMimeTypeFieldWhereInput | null;
  name?: FileNameFieldWhereInput | null;
  originalName?: FileOriginalNameFieldWhereInput | null;
  size?: FileSizeFieldWhereInput | null;
  sourceTitle?: FileSourceTitleFieldWhereInput | null;
  sourceURL?: FileSourceURLFieldWhereInput | null;
  AND?: FileWhereLogicAndInput[] | null;
  NOT?: FileWhereLogicNotInput[] | null;
  OR?: FileWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface FileWhereLogicAndInput {
  id?: FileIdFieldWhereInput | null;
  createdAt?: FileCreatedAtFieldWhereInput | null;
  extension?: FileExtensionFieldWhereInput | null;
  isImage?: boolean | null;
  lastModified?: FileLastModifiedFieldWhereInput | null;
  mimeType?: FileMimeTypeFieldWhereInput | null;
  name?: FileNameFieldWhereInput | null;
  originalName?: FileOriginalNameFieldWhereInput | null;
  size?: FileSizeFieldWhereInput | null;
  sourceTitle?: FileSourceTitleFieldWhereInput | null;
  sourceURL?: FileSourceURLFieldWhereInput | null;
  AND?: FileWhereLogicAndInput[] | null;
  NOT?: FileWhereLogicNotInput[] | null;
  OR?: FileWhereLogicOrInput[] | null;
}

export interface FileWhereLogicNotInput {
  id?: FileIdFieldWhereInput | null;
  createdAt?: FileCreatedAtFieldWhereInput | null;
  extension?: FileExtensionFieldWhereInput | null;
  isImage?: boolean | null;
  lastModified?: FileLastModifiedFieldWhereInput | null;
  mimeType?: FileMimeTypeFieldWhereInput | null;
  name?: FileNameFieldWhereInput | null;
  originalName?: FileOriginalNameFieldWhereInput | null;
  size?: FileSizeFieldWhereInput | null;
  sourceTitle?: FileSourceTitleFieldWhereInput | null;
  sourceURL?: FileSourceURLFieldWhereInput | null;
  AND?: FileWhereLogicAndInput[] | null;
  NOT?: FileWhereLogicNotInput[] | null;
  OR?: FileWhereLogicOrInput[] | null;
}

export interface FileWhereLogicOrInput {
  id?: FileIdFieldWhereInput | null;
  createdAt?: FileCreatedAtFieldWhereInput | null;
  extension?: FileExtensionFieldWhereInput | null;
  isImage?: boolean | null;
  lastModified?: FileLastModifiedFieldWhereInput | null;
  mimeType?: FileMimeTypeFieldWhereInput | null;
  name?: FileNameFieldWhereInput | null;
  originalName?: FileOriginalNameFieldWhereInput | null;
  size?: FileSizeFieldWhereInput | null;
  sourceTitle?: FileSourceTitleFieldWhereInput | null;
  sourceURL?: FileSourceURLFieldWhereInput | null;
  AND?: FileWhereLogicAndInput[] | null;
  NOT?: FileWhereLogicNotInput[] | null;
  OR?: FileWhereLogicOrInput[] | null;
}

export interface FileWhereUniqueInput {
  id?: number | null;
  name?: string | null;
}

export interface GameCoverImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameCoverImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameFilterOrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameFilterOrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameGameIdIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameGameIdIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameGeneralRuleGameIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameGeneralRuleGameIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameGeneralRuleIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameGeneralRuleIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameGeneralRuleInput {
  gameId: number;
  value: string;
}

export interface GameGeneralRuleNoteFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameGeneralRuleNoteFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameGeneralRuleValueFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameGeneralRuleValueFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameGeneralRuleWhereInput {
  id?: GameGeneralRuleIdFieldWhereInput | null;
  gameId?: GameGeneralRuleGameIdFieldWhereInput | null;
  note?: GameGeneralRuleNoteFieldWhereInput | null;
  value?: GameGeneralRuleValueFieldWhereInput | null;
  AND?: GameGeneralRuleWhereLogicAndInput[] | null;
  NOT?: GameGeneralRuleWhereLogicNotInput[] | null;
  OR?: GameGeneralRuleWhereLogicOrInput[] | null;
}

export interface GameGeneralRuleWhereLogicAndInput {
  id?: GameGeneralRuleIdFieldWhereInput | null;
  gameId?: GameGeneralRuleGameIdFieldWhereInput | null;
  note?: GameGeneralRuleNoteFieldWhereInput | null;
  value?: GameGeneralRuleValueFieldWhereInput | null;
  AND?: GameGeneralRuleWhereLogicAndInput[] | null;
  NOT?: GameGeneralRuleWhereLogicNotInput[] | null;
  OR?: GameGeneralRuleWhereLogicOrInput[] | null;
}

export interface GameGeneralRuleWhereLogicNotInput {
  id?: GameGeneralRuleIdFieldWhereInput | null;
  gameId?: GameGeneralRuleGameIdFieldWhereInput | null;
  note?: GameGeneralRuleNoteFieldWhereInput | null;
  value?: GameGeneralRuleValueFieldWhereInput | null;
  AND?: GameGeneralRuleWhereLogicAndInput[] | null;
  NOT?: GameGeneralRuleWhereLogicNotInput[] | null;
  OR?: GameGeneralRuleWhereLogicOrInput[] | null;
}

export interface GameGeneralRuleWhereLogicOrInput {
  id?: GameGeneralRuleIdFieldWhereInput | null;
  gameId?: GameGeneralRuleGameIdFieldWhereInput | null;
  note?: GameGeneralRuleNoteFieldWhereInput | null;
  value?: GameGeneralRuleValueFieldWhereInput | null;
  AND?: GameGeneralRuleWhereLogicAndInput[] | null;
  NOT?: GameGeneralRuleWhereLogicNotInput[] | null;
  OR?: GameGeneralRuleWhereLogicOrInput[] | null;
}

export interface GameGeneralRuleWhereUniqueInput {
  id?: number | null;
  gameId?: number | null;
}

export interface GameIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameIdIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameIdIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameIdInput {
  name: string;
}

export interface GameIdNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameIdNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameIdOrderByInput {
  id?: SortOrder | null;
  name?: SortOrder | null;
}

export interface GameIdWhereInput {
  id?: GameIdIdFieldWhereInput | null;
  name?: GameIdNameFieldWhereInput | null;
  AND?: GameIdWhereLogicAndInput[] | null;
  NOT?: GameIdWhereLogicNotInput[] | null;
  OR?: GameIdWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface GameIdWhereLogicAndInput {
  id?: GameIdIdFieldWhereInput | null;
  name?: GameIdNameFieldWhereInput | null;
  AND?: GameIdWhereLogicAndInput[] | null;
  NOT?: GameIdWhereLogicNotInput[] | null;
  OR?: GameIdWhereLogicOrInput[] | null;
}

export interface GameIdWhereLogicNotInput {
  id?: GameIdIdFieldWhereInput | null;
  name?: GameIdNameFieldWhereInput | null;
  AND?: GameIdWhereLogicAndInput[] | null;
  NOT?: GameIdWhereLogicNotInput[] | null;
  OR?: GameIdWhereLogicOrInput[] | null;
}

export interface GameIdWhereLogicOrInput {
  id?: GameIdIdFieldWhereInput | null;
  name?: GameIdNameFieldWhereInput | null;
  AND?: GameIdWhereLogicAndInput[] | null;
  NOT?: GameIdWhereLogicNotInput[] | null;
  OR?: GameIdWhereLogicOrInput[] | null;
}

export interface GameIdWhereUniqueInput {
  id: number;
}

export interface GameInput {
  name: string;
  coverImage?: string | null;
  gameIdId?: number | null;
  type?: string | null;
  filterOrder?: number | null;
  tag?: string | null;
  tags?: string[] | null;
}

export interface GameMapGameIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameMapGameIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameMapIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameMapIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface GameMapInput {
  name: string;
  image?: string | null;
  gameId: number;
}

export interface GameMapNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameMapNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameMapWhereInput {
  gameId?: GameMapGameIdFieldWhereInput | null;
  id?: GameMapIdFieldWhereInput | null;
  name?: GameMapNameFieldWhereInput | null;
  AND?: GameMapWhereLogicAndInput[] | null;
  NOT?: GameMapWhereLogicNotInput[] | null;
  OR?: GameMapWhereLogicOrInput[] | null;
}

export interface GameMapWhereLogicAndInput {
  gameId?: GameMapGameIdFieldWhereInput | null;
  id?: GameMapIdFieldWhereInput | null;
  name?: GameMapNameFieldWhereInput | null;
  AND?: GameMapWhereLogicAndInput[] | null;
  NOT?: GameMapWhereLogicNotInput[] | null;
  OR?: GameMapWhereLogicOrInput[] | null;
}

export interface GameMapWhereLogicNotInput {
  gameId?: GameMapGameIdFieldWhereInput | null;
  id?: GameMapIdFieldWhereInput | null;
  name?: GameMapNameFieldWhereInput | null;
  AND?: GameMapWhereLogicAndInput[] | null;
  NOT?: GameMapWhereLogicNotInput[] | null;
  OR?: GameMapWhereLogicOrInput[] | null;
}

export interface GameMapWhereLogicOrInput {
  gameId?: GameMapGameIdFieldWhereInput | null;
  id?: GameMapIdFieldWhereInput | null;
  name?: GameMapNameFieldWhereInput | null;
  AND?: GameMapWhereLogicAndInput[] | null;
  NOT?: GameMapWhereLogicNotInput[] | null;
  OR?: GameMapWhereLogicOrInput[] | null;
}

export interface GameMapWhereScalarInput {
  OR?: (GameMapWhereScalarInput | null)[] | null;
  AND?: (GameMapWhereScalarInput | null)[] | null;
  NOT?: (GameMapWhereScalarInput | null)[] | null;
  id?: GameMap_IdFieldWhereScalarInput | null;
  createdAt?: GameMap_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: GameMap_UpdatedAtFieldWhereScalarInput | null;
  gameId?: GameMap_GameIdFieldWhereScalarInput | null;
  name?: GameMap_NameFieldWhereScalarInput | null;
  image?: GameMap_ImageFieldWhereScalarInput | null;
}

export interface GameMapWhereUniqueInput {
  id: number;
}

export interface GameMap_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: GameMap_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface GameMap_GameFieldConnectOrCreateInput {
  where: GameWhereUniqueInput;
  create: GameMap_GameFieldNestedCreateInput;
}

export interface GameMap_GameFieldCreateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: GameMap_GameFieldConnectOrCreateInput | null;
  create?: GameMap_GameFieldNestedCreateInput | null;
}

export interface GameMap_GameFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags: string[];
  gameId?: Game_GameIdFieldCreateInput | null;
  tournaments?: Game_TournamentsFieldCreateInput | null;
  teams?: Game_TeamsFieldCreateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldCreateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldCreateInput | null;
}

export interface GameMap_GameFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags?: string[] | null;
  gameId?: Game_GameIdFieldUpdateInput | null;
  tournaments?: Game_TournamentsFieldUpdateInput | null;
  teams?: Game_TeamsFieldUpdateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldUpdateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldUpdateInput | null;
}

export interface GameMap_GameFieldNestedUpsertInput {
  create: GameMap_GameFieldNestedCreateInput;
  update: GameMap_GameFieldNestedUpdateInput;
}

export interface GameMap_GameFieldUpdateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: GameMap_GameFieldConnectOrCreateInput | null;
  create?: GameMap_GameFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: GameMap_GameFieldNestedUpdateInput | null;
  upsert?: GameMap_GameFieldNestedUpsertInput | null;
}

export interface GameMap_GameIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameMap_GameIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface GameMap_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: GameMap_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface GameMap_ImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameMap_ImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameMap_MatchRoundsFieldConnectOrCreateInput {
  where: TournamentMatchRoundWhereUniqueInput;
  create: GameMap_MatchRoundsFieldNestedCreateInput;
}

export interface GameMap_MatchRoundsFieldCreateInput {
  connect?: TournamentMatchRoundWhereUniqueInput[] | null;
  connectOrCreate?: GameMap_MatchRoundsFieldConnectOrCreateInput[] | null;
  create?: GameMap_MatchRoundsFieldNestedCreateInput[] | null;
}

export interface GameMap_MatchRoundsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  matchId: number;
  teamScoreA: number;
  teamScoreB: number;
  match?: TournamentMatchRound_MatchFieldCreateInput | null;
}

export interface GameMap_MatchRoundsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  matchId?: number | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  match?: TournamentMatchRound_MatchFieldUpdateInput | null;
}

export interface GameMap_MatchRoundsFieldNestedUpdateInput {
  where: TournamentMatchRoundWhereUniqueInput;
  data: GameMap_MatchRoundsFieldNestedUpdateDataInput;
}

export interface GameMap_MatchRoundsFieldNestedUpsertInput {
  create: GameMap_MatchRoundsFieldNestedCreateInput;
  update: GameMap_MatchRoundsFieldNestedUpdateDataInput;
  where: TournamentMatchRoundWhereUniqueInput;
}

export interface GameMap_MatchRoundsFieldUpdateInput {
  connect?: TournamentMatchRoundWhereUniqueInput[] | null;
  connectOrCreate?: GameMap_MatchRoundsFieldConnectOrCreateInput[] | null;
  create?: GameMap_MatchRoundsFieldNestedCreateInput[] | null;
  delete?: TournamentMatchRoundWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchRoundWhereScalarInput | null;
  disconnect?: TournamentMatchRoundWhereUniqueInput[] | null;
  set?: TournamentMatchRoundWhereUniqueInput[] | null;
  update?: GameMap_MatchRoundsFieldNestedUpdateInput[] | null;
  upsert?: GameMap_MatchRoundsFieldNestedUpsertInput[] | null;
}

export interface GameMap_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameMap_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameMap_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: GameMap_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface GameNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameOrderByInput {
  coverImage?: SortOrder | null;
  createdAt?: SortOrder | null;
  filterOrder?: SortOrder | null;
  gameId?: GameIdOrderByInput | null;
  gameIdId?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
}

export interface GameTagFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: GameTagFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface GameWhereInput {
  coverImage?: GameCoverImageFieldWhereInput | null;
  filterOrder?: GameFilterOrderFieldWhereInput | null;
  gameIdId?: GameGameIdIdFieldWhereInput | null;
  id?: GameIdFieldWhereInput | null;
  name?: GameNameFieldWhereInput | null;
  tag?: GameTagFieldWhereInput | null;
  AND?: GameWhereLogicAndInput[] | null;
  NOT?: GameWhereLogicNotInput[] | null;
  OR?: GameWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface GameWhereLogicAndInput {
  coverImage?: GameCoverImageFieldWhereInput | null;
  filterOrder?: GameFilterOrderFieldWhereInput | null;
  gameIdId?: GameGameIdIdFieldWhereInput | null;
  id?: GameIdFieldWhereInput | null;
  name?: GameNameFieldWhereInput | null;
  tag?: GameTagFieldWhereInput | null;
  AND?: GameWhereLogicAndInput[] | null;
  NOT?: GameWhereLogicNotInput[] | null;
  OR?: GameWhereLogicOrInput[] | null;
}

export interface GameWhereLogicNotInput {
  coverImage?: GameCoverImageFieldWhereInput | null;
  filterOrder?: GameFilterOrderFieldWhereInput | null;
  gameIdId?: GameGameIdIdFieldWhereInput | null;
  id?: GameIdFieldWhereInput | null;
  name?: GameNameFieldWhereInput | null;
  tag?: GameTagFieldWhereInput | null;
  AND?: GameWhereLogicAndInput[] | null;
  NOT?: GameWhereLogicNotInput[] | null;
  OR?: GameWhereLogicOrInput[] | null;
}

export interface GameWhereLogicOrInput {
  coverImage?: GameCoverImageFieldWhereInput | null;
  filterOrder?: GameFilterOrderFieldWhereInput | null;
  gameIdId?: GameGameIdIdFieldWhereInput | null;
  id?: GameIdFieldWhereInput | null;
  name?: GameNameFieldWhereInput | null;
  tag?: GameTagFieldWhereInput | null;
  AND?: GameWhereLogicAndInput[] | null;
  NOT?: GameWhereLogicNotInput[] | null;
  OR?: GameWhereLogicOrInput[] | null;
}

export interface GameWhereUniqueInput {
  id: number;
}

export interface Game_GameGeneralRuleFieldConnectOrCreateInput {
  where: GameGeneralRuleWhereUniqueInput;
  create: Game_GameGeneralRuleFieldNestedCreateInput;
}

export interface Game_GameGeneralRuleFieldCreateInput {
  connect?: GameGeneralRuleWhereUniqueInput | null;
  connectOrCreate?: Game_GameGeneralRuleFieldConnectOrCreateInput | null;
  create?: Game_GameGeneralRuleFieldNestedCreateInput | null;
}

export interface Game_GameGeneralRuleFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value?: string | null;
  note?: string | null;
}

export interface Game_GameGeneralRuleFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value?: string | null;
  note?: string | null;
}

export interface Game_GameGeneralRuleFieldNestedUpsertInput {
  create: Game_GameGeneralRuleFieldNestedCreateInput;
  update: Game_GameGeneralRuleFieldNestedUpdateInput;
}

export interface Game_GameGeneralRuleFieldUpdateInput {
  connect?: GameGeneralRuleWhereUniqueInput | null;
  connectOrCreate?: Game_GameGeneralRuleFieldConnectOrCreateInput | null;
  create?: Game_GameGeneralRuleFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Game_GameGeneralRuleFieldNestedUpdateInput | null;
  upsert?: Game_GameGeneralRuleFieldNestedUpsertInput | null;
}

export interface Game_GameIdFieldConnectOrCreateInput {
  where: GameIdWhereUniqueInput;
  create: Game_GameIdFieldNestedCreateInput;
}

export interface Game_GameIdFieldCreateInput {
  connect?: GameIdWhereUniqueInput | null;
  connectOrCreate?: Game_GameIdFieldConnectOrCreateInput | null;
  create?: Game_GameIdFieldNestedCreateInput | null;
}

export interface Game_GameIdFieldNestedCreateInput {
  id?: number | null;
  name: string;
}

export interface Game_GameIdFieldNestedUpdateInput {
  id?: number | null;
  name?: string | null;
}

export interface Game_GameIdFieldNestedUpsertInput {
  create: Game_GameIdFieldNestedCreateInput;
  update: Game_GameIdFieldNestedUpdateInput;
}

export interface Game_GameIdFieldUpdateInput {
  connect?: GameIdWhereUniqueInput | null;
  connectOrCreate?: Game_GameIdFieldConnectOrCreateInput | null;
  create?: Game_GameIdFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Game_GameIdFieldNestedUpdateInput | null;
  upsert?: Game_GameIdFieldNestedUpsertInput | null;
}

export interface Game_MapsFieldConnectOrCreateInput {
  where: GameMapWhereUniqueInput;
  create: Game_MapsFieldNestedCreateInput;
}

export interface Game_MapsFieldCreateInput {
  connect?: GameMapWhereUniqueInput[] | null;
  connectOrCreate?: Game_MapsFieldConnectOrCreateInput[] | null;
  create?: Game_MapsFieldNestedCreateInput[] | null;
}

export interface Game_MapsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  image?: string | null;
  matchRounds?: GameMap_MatchRoundsFieldCreateInput | null;
}

export interface Game_MapsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  image?: string | null;
  matchRounds?: GameMap_MatchRoundsFieldUpdateInput | null;
}

export interface Game_MapsFieldNestedUpdateInput {
  where: GameMapWhereUniqueInput;
  data: Game_MapsFieldNestedUpdateDataInput;
}

export interface Game_MapsFieldNestedUpsertInput {
  create: Game_MapsFieldNestedCreateInput;
  update: Game_MapsFieldNestedUpdateDataInput;
  where: GameMapWhereUniqueInput;
}

export interface Game_MapsFieldUpdateInput {
  connect?: GameMapWhereUniqueInput[] | null;
  connectOrCreate?: Game_MapsFieldConnectOrCreateInput[] | null;
  create?: Game_MapsFieldNestedCreateInput[] | null;
  delete?: GameMapWhereUniqueInput[] | null;
  deleteMany?: GameMapWhereScalarInput | null;
  disconnect?: GameMapWhereUniqueInput[] | null;
  set?: GameMapWhereUniqueInput[] | null;
  update?: Game_MapsFieldNestedUpdateInput[] | null;
  upsert?: Game_MapsFieldNestedUpsertInput[] | null;
}

export interface Game_TeamsFieldConnectOrCreateInput {
  where: TeamWhereUniqueInput;
  create: Game_TeamsFieldNestedCreateInput;
}

export interface Game_TeamsFieldCreateInput {
  connect?: TeamWhereUniqueInput[] | null;
  connectOrCreate?: Game_TeamsFieldConnectOrCreateInput[] | null;
  create?: Game_TeamsFieldNestedCreateInput[] | null;
}

export interface Game_TeamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  name: string;
  ownerId?: number | null;
  players?: any | null;
  logo?: string | null;
  owner?: Team_OwnerFieldCreateInput | null;
  tournamentTeams?: Team_TournamentTeamsFieldCreateInput | null;
  invitations?: Team_InvitationsFieldCreateInput | null;
  updatedAt?: any | null;
}

export interface Game_TeamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  name?: string | null;
  ownerId?: number | null;
  players?: any | null;
  logo?: string | null;
  owner?: Team_OwnerFieldUpdateInput | null;
  tournamentTeams?: Team_TournamentTeamsFieldUpdateInput | null;
  invitations?: Team_InvitationsFieldUpdateInput | null;
  updatedAt?: any | null;
}

export interface Game_TeamsFieldNestedUpdateInput {
  where: TeamWhereUniqueInput;
  data: Game_TeamsFieldNestedUpdateDataInput;
}

export interface Game_TeamsFieldNestedUpsertInput {
  create: Game_TeamsFieldNestedCreateInput;
  update: Game_TeamsFieldNestedUpdateDataInput;
  where: TeamWhereUniqueInput;
}

export interface Game_TeamsFieldUpdateInput {
  connect?: TeamWhereUniqueInput[] | null;
  connectOrCreate?: Game_TeamsFieldConnectOrCreateInput[] | null;
  create?: Game_TeamsFieldNestedCreateInput[] | null;
  delete?: TeamWhereUniqueInput[] | null;
  deleteMany?: TeamWhereScalarInput | null;
  disconnect?: TeamWhereUniqueInput[] | null;
  set?: TeamWhereUniqueInput[] | null;
  update?: Game_TeamsFieldNestedUpdateInput[] | null;
  upsert?: Game_TeamsFieldNestedUpsertInput[] | null;
}

export interface Game_TournamentGroupingFieldConnectOrCreateInput {
  where: TournamentGroupingWhereUniqueInput;
  create: Game_TournamentGroupingFieldNestedCreateInput;
}

export interface Game_TournamentGroupingFieldCreateInput {
  connect?: TournamentGroupingWhereUniqueInput[] | null;
  connectOrCreate?: Game_TournamentGroupingFieldConnectOrCreateInput[] | null;
  create?: Game_TournamentGroupingFieldNestedCreateInput[] | null;
}

export interface Game_TournamentGroupingFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  coverImage?: string | null;
  description?: string | null;
  groupId: number;
  values?: TournamentGrouping_ValuesFieldCreateInput | null;
  group?: TournamentGrouping_GroupFieldCreateInput | null;
}

export interface Game_TournamentGroupingFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  coverImage?: string | null;
  description?: string | null;
  groupId?: number | null;
  values?: TournamentGrouping_ValuesFieldUpdateInput | null;
  group?: TournamentGrouping_GroupFieldUpdateInput | null;
}

export interface Game_TournamentGroupingFieldNestedUpdateInput {
  where: TournamentGroupingWhereUniqueInput;
  data: Game_TournamentGroupingFieldNestedUpdateDataInput;
}

export interface Game_TournamentGroupingFieldNestedUpsertInput {
  create: Game_TournamentGroupingFieldNestedCreateInput;
  update: Game_TournamentGroupingFieldNestedUpdateDataInput;
  where: TournamentGroupingWhereUniqueInput;
}

export interface Game_TournamentGroupingFieldUpdateInput {
  connect?: TournamentGroupingWhereUniqueInput[] | null;
  connectOrCreate?: Game_TournamentGroupingFieldConnectOrCreateInput[] | null;
  create?: Game_TournamentGroupingFieldNestedCreateInput[] | null;
  delete?: TournamentGroupingWhereUniqueInput[] | null;
  deleteMany?: TournamentGroupingWhereScalarInput | null;
  disconnect?: TournamentGroupingWhereUniqueInput[] | null;
  set?: TournamentGroupingWhereUniqueInput[] | null;
  update?: Game_TournamentGroupingFieldNestedUpdateInput[] | null;
  upsert?: Game_TournamentGroupingFieldNestedUpsertInput[] | null;
}

export interface Game_TournamentsFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: Game_TournamentsFieldNestedCreateInput;
}

export interface Game_TournamentsFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: Game_TournamentsFieldConnectOrCreateInput[] | null;
  create?: Game_TournamentsFieldNestedCreateInput[] | null;
}

export interface Game_TournamentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface Game_TournamentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface Game_TournamentsFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: Game_TournamentsFieldNestedUpdateDataInput;
}

export interface Game_TournamentsFieldNestedUpsertInput {
  create: Game_TournamentsFieldNestedCreateInput;
  update: Game_TournamentsFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface Game_TournamentsFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: Game_TournamentsFieldConnectOrCreateInput[] | null;
  create?: Game_TournamentsFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: Game_TournamentsFieldNestedUpdateInput[] | null;
  upsert?: Game_TournamentsFieldNestedUpsertInput[] | null;
}

export interface GenerateTournamentMatchGroupsInput {
  bucketsCount: number;
  stageId: number;
  tournamentId: number;
  groups?: TournamentMatchGroupFormatInput[] | null;
  crossMatchesCount: number;
  dateStart: any;
}

export interface GuestCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  nickname?: string | null;
  photo?: string | null;
  addressId?: number | null;
  mediaId?: number | null;
  media?: Guest_MediaFieldCreateInput | null;
  address?: Guest_AddressFieldCreateInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldCreateInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldCreateInput | null;
}

export interface GuestOrderByInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  firstName?: SortOrder | null;
  lastName?: SortOrder | null;
  nickname?: SortOrder | null;
  photo?: SortOrder | null;
  addressId?: SortOrder | null;
  mediaId?: SortOrder | null;
  media?: MediaOrderByInput | null;
  address?: AddressOrderByInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldOrderByInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldOrderByInput | null;
}

export interface GuestUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName?: string | null;
  lastName?: string | null;
  nickname?: string | null;
  photo?: string | null;
  addressId?: number | null;
  mediaId?: number | null;
  media?: Guest_MediaFieldUpdateInput | null;
  address?: Guest_AddressFieldUpdateInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldUpdateInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldUpdateInput | null;
}

export interface GuestWhereInput {
  OR?: (GuestWhereInput | null)[] | null;
  AND?: (GuestWhereInput | null)[] | null;
  NOT?: (GuestWhereInput | null)[] | null;
  id?: Guest_IdFieldWhereInput | null;
  createdAt?: Guest_CreatedAtFieldWhereInput | null;
  updatedAt?: Guest_UpdatedAtFieldWhereInput | null;
  firstName?: Guest_FirstNameFieldWhereInput | null;
  lastName?: Guest_LastNameFieldWhereInput | null;
  nickname?: Guest_NicknameFieldWhereInput | null;
  photo?: Guest_PhotoFieldWhereInput | null;
  addressId?: Guest_AddressIdFieldWhereInput | null;
  mediaId?: Guest_MediaIdFieldWhereInput | null;
  media?: MediaWhereInput | null;
  address?: AddressWhereInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldWhereInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldWhereInput | null;
}

export interface GuestWhereScalarInput {
  OR?: (GuestWhereScalarInput | null)[] | null;
  AND?: (GuestWhereScalarInput | null)[] | null;
  NOT?: (GuestWhereScalarInput | null)[] | null;
  id?: Guest_IdFieldWhereScalarInput | null;
  createdAt?: Guest_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Guest_UpdatedAtFieldWhereScalarInput | null;
  firstName?: Guest_FirstNameFieldWhereScalarInput | null;
  lastName?: Guest_LastNameFieldWhereScalarInput | null;
  nickname?: Guest_NicknameFieldWhereScalarInput | null;
  photo?: Guest_PhotoFieldWhereScalarInput | null;
  addressId?: Guest_AddressIdFieldWhereScalarInput | null;
  mediaId?: Guest_MediaIdFieldWhereScalarInput | null;
}

export interface GuestWhereUniqueInput {
  id?: number | null;
  addressId?: number | null;
  mediaId?: number | null;
}

export interface Guest_AddressFieldConnectOrCreateInput {
  where: AddressWhereUniqueInput;
  create: Guest_AddressFieldNestedCreateInput;
}

export interface Guest_AddressFieldCreateInput {
  connect?: AddressWhereUniqueInput | null;
  connectOrCreate?: Guest_AddressFieldConnectOrCreateInput | null;
  create?: Guest_AddressFieldNestedCreateInput | null;
}

export interface Guest_AddressFieldNestedCreateInput {
  id?: number | null;
  street?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export interface Guest_AddressFieldNestedUpdateInput {
  id?: number | null;
  street?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export interface Guest_AddressFieldNestedUpsertInput {
  create: Guest_AddressFieldNestedCreateInput;
  update: Guest_AddressFieldNestedUpdateInput;
}

export interface Guest_AddressFieldUpdateInput {
  connect?: AddressWhereUniqueInput | null;
  connectOrCreate?: Guest_AddressFieldConnectOrCreateInput | null;
  create?: Guest_AddressFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Guest_AddressFieldNestedUpdateInput | null;
  upsert?: Guest_AddressFieldNestedUpsertInput | null;
}

export interface Guest_AddressIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_AddressIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Guest_AddressIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_AddressIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Guest_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Guest_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Guest_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Guest_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Guest_FirstNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_FirstNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_FirstNameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_FirstNameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Guest_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Guest_LastNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_LastNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_LastNameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_LastNameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_MediaFieldConnectOrCreateInput {
  where: MediaWhereUniqueInput;
  create: Guest_MediaFieldNestedCreateInput;
}

export interface Guest_MediaFieldCreateInput {
  connect?: MediaWhereUniqueInput | null;
  connectOrCreate?: Guest_MediaFieldConnectOrCreateInput | null;
  create?: Guest_MediaFieldNestedCreateInput | null;
}

export interface Guest_MediaFieldNestedCreateInput {
  id?: number | null;
  web?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  twitch?: string | null;
  twitter?: string | null;
}

export interface Guest_MediaFieldNestedUpdateInput {
  id?: number | null;
  web?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  twitch?: string | null;
  twitter?: string | null;
}

export interface Guest_MediaFieldNestedUpsertInput {
  create: Guest_MediaFieldNestedCreateInput;
  update: Guest_MediaFieldNestedUpdateInput;
}

export interface Guest_MediaFieldUpdateInput {
  connect?: MediaWhereUniqueInput | null;
  connectOrCreate?: Guest_MediaFieldConnectOrCreateInput | null;
  create?: Guest_MediaFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Guest_MediaFieldNestedUpdateInput | null;
  upsert?: Guest_MediaFieldNestedUpsertInput | null;
}

export interface Guest_MediaIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_MediaIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Guest_MediaIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Guest_MediaIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Guest_NicknameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_NicknameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_NicknameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_NicknameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_PhotoFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_PhotoFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_PhotoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Guest_PhotoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Guest_ProjectGuestsSectionFieldConnectOrCreateInput {
  where: ProjectGuestsSectionWhereUniqueInput;
  create: Guest_ProjectGuestsSectionFieldNestedCreateInput;
}

export interface Guest_ProjectGuestsSectionFieldCreateInput {
  connect?: ProjectGuestsSectionWhereUniqueInput[] | null;
  connectOrCreate?: Guest_ProjectGuestsSectionFieldConnectOrCreateInput[] | null;
  create?: Guest_ProjectGuestsSectionFieldNestedCreateInput[] | null;
}

export interface Guest_ProjectGuestsSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectSectionId: number;
  projectSection?: ProjectGuestsSection_ProjectSectionFieldCreateInput | null;
}

export interface Guest_ProjectGuestsSectionFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectSectionId?: number | null;
  projectSection?: ProjectGuestsSection_ProjectSectionFieldUpdateInput | null;
}

export interface Guest_ProjectGuestsSectionFieldNestedUpdateInput {
  where: ProjectGuestsSectionWhereUniqueInput;
  data: Guest_ProjectGuestsSectionFieldNestedUpdateDataInput;
}

export interface Guest_ProjectGuestsSectionFieldNestedUpsertInput {
  create: Guest_ProjectGuestsSectionFieldNestedCreateInput;
  update: Guest_ProjectGuestsSectionFieldNestedUpdateDataInput;
  where: ProjectGuestsSectionWhereUniqueInput;
}

export interface Guest_ProjectGuestsSectionFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface Guest_ProjectGuestsSectionFieldUpdateInput {
  connect?: ProjectGuestsSectionWhereUniqueInput[] | null;
  connectOrCreate?: Guest_ProjectGuestsSectionFieldConnectOrCreateInput[] | null;
  create?: Guest_ProjectGuestsSectionFieldNestedCreateInput[] | null;
  delete?: ProjectGuestsSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectGuestsSectionWhereScalarInput | null;
  disconnect?: ProjectGuestsSectionWhereUniqueInput[] | null;
  set?: ProjectGuestsSectionWhereUniqueInput[] | null;
  update?: Guest_ProjectGuestsSectionFieldNestedUpdateInput[] | null;
  upsert?: Guest_ProjectGuestsSectionFieldNestedUpsertInput[] | null;
}

export interface Guest_ProjectGuestsSectionFieldWhereInput {
  every?: ProjectGuestsSectionWhereInput | null;
  none?: ProjectGuestsSectionWhereInput | null;
  some?: ProjectGuestsSectionWhereInput | null;
}

export interface Guest_ProjectProgramsSectionsFieldConnectOrCreateInput {
  where: ProjectProgramSectionWhereUniqueInput;
  create: Guest_ProjectProgramsSectionsFieldNestedCreateInput;
}

export interface Guest_ProjectProgramsSectionsFieldCreateInput {
  connect?: ProjectProgramSectionWhereUniqueInput[] | null;
  connectOrCreate?: Guest_ProjectProgramsSectionsFieldConnectOrCreateInput[] | null;
  create?: Guest_ProjectProgramsSectionsFieldNestedCreateInput[] | null;
}

export interface Guest_ProjectProgramsSectionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  date: any;
  duration: number;
  name: string;
  description: string;
  place: string;
  streamUrl?: string | null;
  order?: number | null;
  projectSectionId: number;
  projectSection?: ProjectProgramSection_ProjectSectionFieldCreateInput | null;
}

export interface Guest_ProjectProgramsSectionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  date?: any | null;
  duration?: number | null;
  name?: string | null;
  description?: string | null;
  place?: string | null;
  streamUrl?: string | null;
  order?: number | null;
  projectSectionId?: number | null;
  projectSection?: ProjectProgramSection_ProjectSectionFieldUpdateInput | null;
}

export interface Guest_ProjectProgramsSectionsFieldNestedUpdateInput {
  where: ProjectProgramSectionWhereUniqueInput;
  data: Guest_ProjectProgramsSectionsFieldNestedUpdateDataInput;
}

export interface Guest_ProjectProgramsSectionsFieldNestedUpsertInput {
  create: Guest_ProjectProgramsSectionsFieldNestedCreateInput;
  update: Guest_ProjectProgramsSectionsFieldNestedUpdateDataInput;
  where: ProjectProgramSectionWhereUniqueInput;
}

export interface Guest_ProjectProgramsSectionsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface Guest_ProjectProgramsSectionsFieldUpdateInput {
  connect?: ProjectProgramSectionWhereUniqueInput[] | null;
  connectOrCreate?: Guest_ProjectProgramsSectionsFieldConnectOrCreateInput[] | null;
  create?: Guest_ProjectProgramsSectionsFieldNestedCreateInput[] | null;
  delete?: ProjectProgramSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectProgramSectionWhereScalarInput | null;
  disconnect?: ProjectProgramSectionWhereUniqueInput[] | null;
  set?: ProjectProgramSectionWhereUniqueInput[] | null;
  update?: Guest_ProjectProgramsSectionsFieldNestedUpdateInput[] | null;
  upsert?: Guest_ProjectProgramsSectionsFieldNestedUpsertInput[] | null;
}

export interface Guest_ProjectProgramsSectionsFieldWhereInput {
  every?: ProjectProgramSectionWhereInput | null;
  none?: ProjectProgramSectionWhereInput | null;
  some?: ProjectProgramSectionWhereInput | null;
}

export interface Guest_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Guest_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Guest_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Guest_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HeaderLinkBackgroundColorFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkBackgroundColorFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkCreatedAtFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkCreatedAtFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkGroupCreatedAtFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkGroupCreatedAtFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkGroupIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HeaderLinkGroupIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface HeaderLinkGroupInput {
  pathMatch: string;
  name?: string | null;
  exact: boolean;
}

export interface HeaderLinkGroupLinksFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface HeaderLinkGroupNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkGroupNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkGroupOrderByInput {
  createdAt?: SortOrder | null;
  exact?: SortOrder | null;
  id?: SortOrder | null;
  links?: HeaderLinkGroupLinksFieldOrderByInput | null;
  name?: SortOrder | null;
  pathMatch?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface HeaderLinkGroupPathMatchFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkGroupPathMatchFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkGroupWhereInput {
  id?: HeaderLinkGroupIdFieldWhereInput | null;
  createdAt?: HeaderLinkGroupCreatedAtFieldWhereInput | null;
  exact?: boolean | null;
  name?: HeaderLinkGroupNameFieldWhereInput | null;
  pathMatch?: HeaderLinkGroupPathMatchFieldWhereInput | null;
  AND?: HeaderLinkGroupWhereLogicAndInput[] | null;
  NOT?: HeaderLinkGroupWhereLogicNotInput[] | null;
  OR?: HeaderLinkGroupWhereLogicOrInput[] | null;
}

export interface HeaderLinkGroupWhereLogicAndInput {
  id?: HeaderLinkGroupIdFieldWhereInput | null;
  createdAt?: HeaderLinkGroupCreatedAtFieldWhereInput | null;
  exact?: boolean | null;
  name?: HeaderLinkGroupNameFieldWhereInput | null;
  pathMatch?: HeaderLinkGroupPathMatchFieldWhereInput | null;
  AND?: HeaderLinkGroupWhereLogicAndInput[] | null;
  NOT?: HeaderLinkGroupWhereLogicNotInput[] | null;
  OR?: HeaderLinkGroupWhereLogicOrInput[] | null;
}

export interface HeaderLinkGroupWhereLogicNotInput {
  id?: HeaderLinkGroupIdFieldWhereInput | null;
  createdAt?: HeaderLinkGroupCreatedAtFieldWhereInput | null;
  exact?: boolean | null;
  name?: HeaderLinkGroupNameFieldWhereInput | null;
  pathMatch?: HeaderLinkGroupPathMatchFieldWhereInput | null;
  AND?: HeaderLinkGroupWhereLogicAndInput[] | null;
  NOT?: HeaderLinkGroupWhereLogicNotInput[] | null;
  OR?: HeaderLinkGroupWhereLogicOrInput[] | null;
}

export interface HeaderLinkGroupWhereLogicOrInput {
  id?: HeaderLinkGroupIdFieldWhereInput | null;
  createdAt?: HeaderLinkGroupCreatedAtFieldWhereInput | null;
  exact?: boolean | null;
  name?: HeaderLinkGroupNameFieldWhereInput | null;
  pathMatch?: HeaderLinkGroupPathMatchFieldWhereInput | null;
  AND?: HeaderLinkGroupWhereLogicAndInput[] | null;
  NOT?: HeaderLinkGroupWhereLogicNotInput[] | null;
  OR?: HeaderLinkGroupWhereLogicOrInput[] | null;
}

export interface HeaderLinkGroupWhereUniqueInput {
  id: number;
}

export interface HeaderLinkIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HeaderLinkIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface HeaderLinkInput {
  backgroundColor?: string | null;
  icon?: string | null;
  order: number;
  path: string;
  groupId: number;
  text: string;
}

export interface HeaderLinkOrderByInput {
  backgroundColor?: SortOrder | null;
  createdAt?: SortOrder | null;
  group?: HeaderLinkGroupOrderByInput | null;
  groupId?: SortOrder | null;
  icon?: SortOrder | null;
  id?: SortOrder | null;
  order?: SortOrder | null;
  path?: SortOrder | null;
  text?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface HeaderLinkOrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HeaderLinkOrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface HeaderLinkPathFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkPathFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HeaderLinkTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HeaderLinkWhereInput {
  id?: HeaderLinkIdFieldWhereInput | null;
  backgroundColor?: HeaderLinkBackgroundColorFieldWhereInput | null;
  createdAt?: HeaderLinkCreatedAtFieldWhereInput | null;
  order?: HeaderLinkOrderFieldWhereInput | null;
  text?: HeaderLinkTextFieldWhereInput | null;
  path?: HeaderLinkPathFieldWhereInput | null;
  AND?: HeaderLinkWhereLogicAndInput[] | null;
  NOT?: HeaderLinkWhereLogicNotInput[] | null;
  OR?: HeaderLinkWhereLogicOrInput[] | null;
}

export interface HeaderLinkWhereLogicAndInput {
  id?: HeaderLinkIdFieldWhereInput | null;
  backgroundColor?: HeaderLinkBackgroundColorFieldWhereInput | null;
  createdAt?: HeaderLinkCreatedAtFieldWhereInput | null;
  order?: HeaderLinkOrderFieldWhereInput | null;
  text?: HeaderLinkTextFieldWhereInput | null;
  path?: HeaderLinkPathFieldWhereInput | null;
  AND?: HeaderLinkWhereLogicAndInput[] | null;
  NOT?: HeaderLinkWhereLogicNotInput[] | null;
  OR?: HeaderLinkWhereLogicOrInput[] | null;
}

export interface HeaderLinkWhereLogicNotInput {
  id?: HeaderLinkIdFieldWhereInput | null;
  backgroundColor?: HeaderLinkBackgroundColorFieldWhereInput | null;
  createdAt?: HeaderLinkCreatedAtFieldWhereInput | null;
  order?: HeaderLinkOrderFieldWhereInput | null;
  text?: HeaderLinkTextFieldWhereInput | null;
  path?: HeaderLinkPathFieldWhereInput | null;
  AND?: HeaderLinkWhereLogicAndInput[] | null;
  NOT?: HeaderLinkWhereLogicNotInput[] | null;
  OR?: HeaderLinkWhereLogicOrInput[] | null;
}

export interface HeaderLinkWhereLogicOrInput {
  id?: HeaderLinkIdFieldWhereInput | null;
  backgroundColor?: HeaderLinkBackgroundColorFieldWhereInput | null;
  createdAt?: HeaderLinkCreatedAtFieldWhereInput | null;
  order?: HeaderLinkOrderFieldWhereInput | null;
  text?: HeaderLinkTextFieldWhereInput | null;
  path?: HeaderLinkPathFieldWhereInput | null;
  AND?: HeaderLinkWhereLogicAndInput[] | null;
  NOT?: HeaderLinkWhereLogicNotInput[] | null;
  OR?: HeaderLinkWhereLogicOrInput[] | null;
}

export interface HeaderLinkWhereUniqueInput {
  id: number;
}

export interface HomeArticleDateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: HomeArticleDateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HomeArticleIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HomeArticleIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface HomeArticleNoteFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HomeArticleNoteFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HomeArticleWhereInput {
  id?: HomeArticleIdFieldWhereInput | null;
  date?: HomeArticleDateFieldWhereInput | null;
  note?: HomeArticleNoteFieldWhereInput | null;
  AND?: HomeArticleWhereLogicAndInput[] | null;
  NOT?: HomeArticleWhereLogicNotInput[] | null;
  OR?: HomeArticleWhereLogicOrInput[] | null;
}

export interface HomeArticleWhereLogicAndInput {
  id?: HomeArticleIdFieldWhereInput | null;
  date?: HomeArticleDateFieldWhereInput | null;
  note?: HomeArticleNoteFieldWhereInput | null;
  AND?: HomeArticleWhereLogicAndInput[] | null;
  NOT?: HomeArticleWhereLogicNotInput[] | null;
  OR?: HomeArticleWhereLogicOrInput[] | null;
}

export interface HomeArticleWhereLogicNotInput {
  id?: HomeArticleIdFieldWhereInput | null;
  date?: HomeArticleDateFieldWhereInput | null;
  note?: HomeArticleNoteFieldWhereInput | null;
  AND?: HomeArticleWhereLogicAndInput[] | null;
  NOT?: HomeArticleWhereLogicNotInput[] | null;
  OR?: HomeArticleWhereLogicOrInput[] | null;
}

export interface HomeArticleWhereLogicOrInput {
  id?: HomeArticleIdFieldWhereInput | null;
  date?: HomeArticleDateFieldWhereInput | null;
  note?: HomeArticleNoteFieldWhereInput | null;
  AND?: HomeArticleWhereLogicAndInput[] | null;
  NOT?: HomeArticleWhereLogicNotInput[] | null;
  OR?: HomeArticleWhereLogicOrInput[] | null;
}

export interface HomeArticleWhereScalarInput {
  OR?: (HomeArticleWhereScalarInput | null)[] | null;
  AND?: (HomeArticleWhereScalarInput | null)[] | null;
  NOT?: (HomeArticleWhereScalarInput | null)[] | null;
  id?: HomeArticle_IdFieldWhereScalarInput | null;
  createdAt?: HomeArticle_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: HomeArticle_UpdatedAtFieldWhereScalarInput | null;
  articleId?: HomeArticle_ArticleIdFieldWhereScalarInput | null;
  note?: HomeArticle_NoteFieldWhereScalarInput | null;
  date?: HomeArticle_DateFieldWhereScalarInput | null;
}

export interface HomeArticleWhereUniqueInput {
  id: number;
}

export interface HomeArticle_ArticleIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HomeArticle_ArticleIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface HomeArticle_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: HomeArticle_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HomeArticle_DateFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: HomeArticle_DateFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HomeArticle_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HomeArticle_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface HomeArticle_NoteFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HomeArticle_NoteFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HomeArticle_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: HomeArticle_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HomeEventDateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: HomeEventDateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface HomeEventIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: HomeEventIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface HomeEventInput {
  date?: any | null;
  place?: string | null;
  title?: string | null;
  url?: string | null;
  note?: string | null;
  image?: string | null;
}

export interface HomeEventNoteFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HomeEventNoteFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HomeEventTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HomeEventTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HomeEventUrlFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: HomeEventUrlFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface HomeEventWhereInput {
  id?: HomeEventIdFieldWhereInput | null;
  date?: HomeEventDateFieldWhereInput | null;
  note?: HomeEventNoteFieldWhereInput | null;
  title?: HomeEventTitleFieldWhereInput | null;
  url?: HomeEventUrlFieldWhereInput | null;
  AND?: HomeEventWhereLogicAndInput[] | null;
  NOT?: HomeEventWhereLogicNotInput[] | null;
  OR?: HomeEventWhereLogicOrInput[] | null;
}

export interface HomeEventWhereLogicAndInput {
  id?: HomeEventIdFieldWhereInput | null;
  date?: HomeEventDateFieldWhereInput | null;
  note?: HomeEventNoteFieldWhereInput | null;
  title?: HomeEventTitleFieldWhereInput | null;
  url?: HomeEventUrlFieldWhereInput | null;
  AND?: HomeEventWhereLogicAndInput[] | null;
  NOT?: HomeEventWhereLogicNotInput[] | null;
  OR?: HomeEventWhereLogicOrInput[] | null;
}

export interface HomeEventWhereLogicNotInput {
  id?: HomeEventIdFieldWhereInput | null;
  date?: HomeEventDateFieldWhereInput | null;
  note?: HomeEventNoteFieldWhereInput | null;
  title?: HomeEventTitleFieldWhereInput | null;
  url?: HomeEventUrlFieldWhereInput | null;
  AND?: HomeEventWhereLogicAndInput[] | null;
  NOT?: HomeEventWhereLogicNotInput[] | null;
  OR?: HomeEventWhereLogicOrInput[] | null;
}

export interface HomeEventWhereLogicOrInput {
  id?: HomeEventIdFieldWhereInput | null;
  date?: HomeEventDateFieldWhereInput | null;
  note?: HomeEventNoteFieldWhereInput | null;
  title?: HomeEventTitleFieldWhereInput | null;
  url?: HomeEventUrlFieldWhereInput | null;
  AND?: HomeEventWhereLogicAndInput[] | null;
  NOT?: HomeEventWhereLogicNotInput[] | null;
  OR?: HomeEventWhereLogicOrInput[] | null;
}

export interface HomeEventWhereUniqueInput {
  id: number;
}

export interface LaptopInput {
  translatable: any;
  mainImage: string;
  gallery?: string[] | null;
  sellers?: (LaptopSellerInput | null)[] | null;
  performance?: (LaptopPerformanceInput | null)[] | null;
}

export interface LaptopPerformanceDescriptionInput {
  sk?: string | null;
  cz?: string | null;
  en?: string | null;
}

export interface LaptopPerformanceFpsStatsInput {
  avg?: string | null;
  max?: string | null;
  min?: string | null;
}

export interface LaptopPerformanceInput {
  description?: LaptopPerformanceDescriptionInput | null;
  fullhd?: LaptopPerformanceFpsStatsInput | null;
  hd?: LaptopPerformanceFpsStatsInput | null;
  hdplus?: LaptopPerformanceFpsStatsInput | null;
  quadhd?: LaptopPerformanceFpsStatsInput | null;
  wxga?: LaptopPerformanceFpsStatsInput | null;
  game?: string | null;
}

export interface LaptopSellerInput {
  name: string;
  value: string;
}

export interface MediaOrderByInput {
  id?: SortOrder | null;
  web?: SortOrder | null;
  facebook?: SortOrder | null;
  instagram?: SortOrder | null;
  youtube?: SortOrder | null;
  twitch?: SortOrder | null;
  twitter?: SortOrder | null;
  Guest?: GuestOrderByInput | null;
}

export interface MediaWhereInput {
  OR?: (MediaWhereInput | null)[] | null;
  AND?: (MediaWhereInput | null)[] | null;
  NOT?: (MediaWhereInput | null)[] | null;
  id?: Media_IdFieldWhereInput | null;
  web?: Media_WebFieldWhereInput | null;
  facebook?: Media_FacebookFieldWhereInput | null;
  instagram?: Media_InstagramFieldWhereInput | null;
  youtube?: Media_YoutubeFieldWhereInput | null;
  twitch?: Media_TwitchFieldWhereInput | null;
  twitter?: Media_TwitterFieldWhereInput | null;
  Guest?: GuestWhereInput | null;
}

export interface MediaWhereUniqueInput {
  id: number;
}

export interface Media_FacebookFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_FacebookFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Media_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Media_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Media_InstagramFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_InstagramFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Media_TwitchFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_TwitchFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Media_TwitterFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_TwitterFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Media_WebFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_WebFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Media_YoutubeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Media_YoutubeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface MerchantCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: MerchantCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface MerchantIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: MerchantIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface MerchantInput {
  name: string;
  type: MerchantType;
  userId?: number | null;
}

export interface MerchantNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: MerchantNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface MerchantWhereInput {
  id?: MerchantIdFieldWhereInput | null;
  createdAt?: MerchantCreatedAtFieldWhereInput | null;
  name?: MerchantNameFieldWhereInput | null;
  type?: MerchantType | null;
  AND?: MerchantWhereLogicAndInput[] | null;
  NOT?: MerchantWhereLogicNotInput[] | null;
  OR?: MerchantWhereLogicOrInput[] | null;
}

export interface MerchantWhereLogicAndInput {
  id?: MerchantIdFieldWhereInput | null;
  createdAt?: MerchantCreatedAtFieldWhereInput | null;
  name?: MerchantNameFieldWhereInput | null;
  type?: MerchantType | null;
  AND?: MerchantWhereLogicAndInput[] | null;
  NOT?: MerchantWhereLogicNotInput[] | null;
  OR?: MerchantWhereLogicOrInput[] | null;
}

export interface MerchantWhereLogicNotInput {
  id?: MerchantIdFieldWhereInput | null;
  createdAt?: MerchantCreatedAtFieldWhereInput | null;
  name?: MerchantNameFieldWhereInput | null;
  type?: MerchantType | null;
  AND?: MerchantWhereLogicAndInput[] | null;
  NOT?: MerchantWhereLogicNotInput[] | null;
  OR?: MerchantWhereLogicOrInput[] | null;
}

export interface MerchantWhereLogicOrInput {
  id?: MerchantIdFieldWhereInput | null;
  createdAt?: MerchantCreatedAtFieldWhereInput | null;
  name?: MerchantNameFieldWhereInput | null;
  type?: MerchantType | null;
  AND?: MerchantWhereLogicAndInput[] | null;
  NOT?: MerchantWhereLogicNotInput[] | null;
  OR?: MerchantWhereLogicOrInput[] | null;
}

export interface MerchantWhereScalarInput {
  OR?: (MerchantWhereScalarInput | null)[] | null;
  AND?: (MerchantWhereScalarInput | null)[] | null;
  NOT?: (MerchantWhereScalarInput | null)[] | null;
  id?: Merchant_IdFieldWhereScalarInput | null;
  createdAt?: Merchant_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Merchant_UpdatedAtFieldWhereScalarInput | null;
  name?: Merchant_NameFieldWhereScalarInput | null;
  userId?: Merchant_UserIdFieldWhereScalarInput | null;
  type?: Merchant_TypeFieldWhereScalarInput | null;
}

export interface MerchantWhereUniqueInput {
  id: number;
}

export interface Merchant_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Merchant_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Merchant_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Merchant_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Merchant_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Merchant_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Merchant_TournamentsFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: Merchant_TournamentsFieldNestedCreateInput;
}

export interface Merchant_TournamentsFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: Merchant_TournamentsFieldConnectOrCreateInput[] | null;
  create?: Merchant_TournamentsFieldNestedCreateInput[] | null;
}

export interface Merchant_TournamentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface Merchant_TournamentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface Merchant_TournamentsFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: Merchant_TournamentsFieldNestedUpdateDataInput;
}

export interface Merchant_TournamentsFieldNestedUpsertInput {
  create: Merchant_TournamentsFieldNestedCreateInput;
  update: Merchant_TournamentsFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface Merchant_TournamentsFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: Merchant_TournamentsFieldConnectOrCreateInput[] | null;
  create?: Merchant_TournamentsFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: Merchant_TournamentsFieldNestedUpdateInput[] | null;
  upsert?: Merchant_TournamentsFieldNestedUpsertInput[] | null;
}

export interface Merchant_TypeFieldWhereScalarInput {
  equals?: MerchantType | null;
  in?: MerchantType[] | null;
  not?: Merchant_TypeFieldWhereScalarInput | null;
  notIn?: MerchantType[] | null;
}

export interface Merchant_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Merchant_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Merchant_UserFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: Merchant_UserFieldNestedCreateInput;
}

export interface Merchant_UserFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Merchant_UserFieldConnectOrCreateInput | null;
  create?: Merchant_UserFieldNestedCreateInput | null;
}

export interface Merchant_UserFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  articles?: User_ArticlesFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface Merchant_UserFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  articles?: User_ArticlesFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface Merchant_UserFieldNestedUpsertInput {
  create: Merchant_UserFieldNestedCreateInput;
  update: Merchant_UserFieldNestedUpdateInput;
}

export interface Merchant_UserFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Merchant_UserFieldConnectOrCreateInput | null;
  create?: Merchant_UserFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Merchant_UserFieldNestedUpdateInput | null;
  upsert?: Merchant_UserFieldNestedUpsertInput | null;
}

export interface Merchant_UserIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Merchant_UserIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Merchant_WalletsFieldConnectOrCreateInput {
  where: WalletWhereUniqueInput;
  create: Merchant_WalletsFieldNestedCreateInput;
}

export interface Merchant_WalletsFieldCreateInput {
  connect?: WalletWhereUniqueInput[] | null;
  connectOrCreate?: Merchant_WalletsFieldConnectOrCreateInput[] | null;
  create?: Merchant_WalletsFieldNestedCreateInput[] | null;
}

export interface Merchant_WalletsFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  currencyId: number;
  currency?: Wallet_CurrencyFieldCreateInput | null;
  senderTransactions?: Wallet_SenderTransactionsFieldCreateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldCreateInput | null;
}

export interface Merchant_WalletsFieldNestedUpdateDataInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  currencyId?: number | null;
  currency?: Wallet_CurrencyFieldUpdateInput | null;
  senderTransactions?: Wallet_SenderTransactionsFieldUpdateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldUpdateInput | null;
}

export interface Merchant_WalletsFieldNestedUpdateInput {
  where: WalletWhereUniqueInput;
  data: Merchant_WalletsFieldNestedUpdateDataInput;
}

export interface Merchant_WalletsFieldNestedUpsertInput {
  create: Merchant_WalletsFieldNestedCreateInput;
  update: Merchant_WalletsFieldNestedUpdateDataInput;
  where: WalletWhereUniqueInput;
}

export interface Merchant_WalletsFieldUpdateInput {
  connect?: WalletWhereUniqueInput[] | null;
  connectOrCreate?: Merchant_WalletsFieldConnectOrCreateInput[] | null;
  create?: Merchant_WalletsFieldNestedCreateInput[] | null;
  delete?: WalletWhereUniqueInput[] | null;
  deleteMany?: WalletWhereScalarInput | null;
  disconnect?: WalletWhereUniqueInput[] | null;
  set?: WalletWhereUniqueInput[] | null;
  update?: Merchant_WalletsFieldNestedUpdateInput[] | null;
  upsert?: Merchant_WalletsFieldNestedUpsertInput[] | null;
}

export interface PartnerCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  projectPartnerTypes?: Partner_ProjectPartnerTypesFieldCreateInput | null;
  ProjectAttractionsSection?: Partner_ProjectAttractionsSectionFieldCreateInput | null;
}

export interface PartnerUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  projectPartnerTypes?: Partner_ProjectPartnerTypesFieldUpdateInput | null;
  ProjectAttractionsSection?: Partner_ProjectAttractionsSectionFieldUpdateInput | null;
}

export interface PartnerWhereInput {
  OR?: (PartnerWhereInput | null)[] | null;
  AND?: (PartnerWhereInput | null)[] | null;
  NOT?: (PartnerWhereInput | null)[] | null;
  id?: Partner_IdFieldWhereInput | null;
  createdAt?: Partner_CreatedAtFieldWhereInput | null;
  updatedAt?: Partner_UpdatedAtFieldWhereInput | null;
  name?: Partner_NameFieldWhereInput | null;
  logo?: Partner_LogoFieldWhereInput | null;
  orsr?: Partner_OrsrFieldWhereInput | null;
  ico?: Partner_IcoFieldWhereInput | null;
  dic?: Partner_DicFieldWhereInput | null;
  dph?: Partner_DphFieldWhereInput | null;
  media?: Partner_MediaFieldWhereInput | null;
  address?: Partner_AddressFieldWhereInput | null;
  projectPartnerTypes?: Partner_ProjectPartnerTypesFieldWhereInput | null;
  ProjectAttractionsSection?: Partner_ProjectAttractionsSectionFieldWhereInput | null;
}

export interface PartnerWhereScalarInput {
  OR?: (PartnerWhereScalarInput | null)[] | null;
  AND?: (PartnerWhereScalarInput | null)[] | null;
  NOT?: (PartnerWhereScalarInput | null)[] | null;
  id?: Partner_IdFieldWhereScalarInput | null;
  createdAt?: Partner_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Partner_UpdatedAtFieldWhereScalarInput | null;
  name?: Partner_NameFieldWhereScalarInput | null;
  logo?: Partner_LogoFieldWhereScalarInput | null;
  orsr?: Partner_OrsrFieldWhereScalarInput | null;
  ico?: Partner_IcoFieldWhereScalarInput | null;
  dic?: Partner_DicFieldWhereScalarInput | null;
  dph?: Partner_DphFieldWhereScalarInput | null;
  media?: Partner_MediaFieldWhereScalarInput | null;
  address?: Partner_AddressFieldWhereScalarInput | null;
}

export interface PartnerWhereUniqueInput {
  id: number;
}

export interface Partner_AddressFieldWhereInput {
  equals?: any | null;
  not?: Partner_AddressFieldWhereInput | null;
}

export interface Partner_AddressFieldWhereScalarInput {
  equals?: any | null;
  not?: Partner_AddressFieldWhereScalarInput | null;
}

export interface Partner_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Partner_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Partner_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Partner_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Partner_DicFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_DicFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_DicFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_DicFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_DphFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_DphFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_DphFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_DphFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_IcoFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_IcoFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_IcoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_IcoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Partner_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Partner_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Partner_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Partner_LogoFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_LogoFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_LogoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_LogoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_MediaFieldWhereInput {
  equals?: any | null;
  not?: Partner_MediaFieldWhereInput | null;
}

export interface Partner_MediaFieldWhereScalarInput {
  equals?: any | null;
  not?: Partner_MediaFieldWhereScalarInput | null;
}

export interface Partner_NameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_NameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_OrsrFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_OrsrFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_OrsrFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Partner_OrsrFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Partner_ProjectAttractionsSectionFieldConnectOrCreateInput {
  where: ProjectAttractionsSectionWhereUniqueInput;
  create: Partner_ProjectAttractionsSectionFieldNestedCreateInput;
}

export interface Partner_ProjectAttractionsSectionFieldCreateInput {
  connect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  connectOrCreate?: Partner_ProjectAttractionsSectionFieldConnectOrCreateInput[] | null;
  create?: Partner_ProjectAttractionsSectionFieldNestedCreateInput[] | null;
}

export interface Partner_ProjectAttractionsSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  priority: ProjectAttractionPriority;
  title: string;
  image?: string | null;
  description?: string | null;
  projectSectionId: number;
  projectSection?: ProjectAttractionsSection_ProjectSectionFieldCreateInput | null;
}

export interface Partner_ProjectAttractionsSectionFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  priority?: ProjectAttractionPriority | null;
  title?: string | null;
  image?: string | null;
  description?: string | null;
  projectSectionId?: number | null;
  projectSection?: ProjectAttractionsSection_ProjectSectionFieldUpdateInput | null;
}

export interface Partner_ProjectAttractionsSectionFieldNestedUpdateInput {
  where: ProjectAttractionsSectionWhereUniqueInput;
  data: Partner_ProjectAttractionsSectionFieldNestedUpdateDataInput;
}

export interface Partner_ProjectAttractionsSectionFieldNestedUpsertInput {
  create: Partner_ProjectAttractionsSectionFieldNestedCreateInput;
  update: Partner_ProjectAttractionsSectionFieldNestedUpdateDataInput;
  where: ProjectAttractionsSectionWhereUniqueInput;
}

export interface Partner_ProjectAttractionsSectionFieldUpdateInput {
  connect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  connectOrCreate?: Partner_ProjectAttractionsSectionFieldConnectOrCreateInput[] | null;
  create?: Partner_ProjectAttractionsSectionFieldNestedCreateInput[] | null;
  delete?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectAttractionsSectionWhereScalarInput | null;
  disconnect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  set?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  update?: Partner_ProjectAttractionsSectionFieldNestedUpdateInput[] | null;
  upsert?: Partner_ProjectAttractionsSectionFieldNestedUpsertInput[] | null;
}

export interface Partner_ProjectAttractionsSectionFieldWhereInput {
  every?: ProjectAttractionsSectionWhereInput | null;
  none?: ProjectAttractionsSectionWhereInput | null;
  some?: ProjectAttractionsSectionWhereInput | null;
}

export interface Partner_ProjectPartnerTypesFieldConnectOrCreateInput {
  where: ProjectPartnerTypeWhereUniqueInput;
  create: Partner_ProjectPartnerTypesFieldNestedCreateInput;
}

export interface Partner_ProjectPartnerTypesFieldCreateInput {
  connect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  connectOrCreate?: Partner_ProjectPartnerTypesFieldConnectOrCreateInput[] | null;
  create?: Partner_ProjectPartnerTypesFieldNestedCreateInput[] | null;
}

export interface Partner_ProjectPartnerTypesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  tilesPerRowMobile?: number | null;
  tilesPerRowDesktop?: number | null;
  name: string;
  projectSectionId: number;
  projectSection?: ProjectPartnerType_ProjectSectionFieldCreateInput | null;
}

export interface Partner_ProjectPartnerTypesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  tilesPerRowMobile?: number | null;
  tilesPerRowDesktop?: number | null;
  name?: string | null;
  projectSectionId?: number | null;
  projectSection?: ProjectPartnerType_ProjectSectionFieldUpdateInput | null;
}

export interface Partner_ProjectPartnerTypesFieldNestedUpdateInput {
  where: ProjectPartnerTypeWhereUniqueInput;
  data: Partner_ProjectPartnerTypesFieldNestedUpdateDataInput;
}

export interface Partner_ProjectPartnerTypesFieldNestedUpsertInput {
  create: Partner_ProjectPartnerTypesFieldNestedCreateInput;
  update: Partner_ProjectPartnerTypesFieldNestedUpdateDataInput;
  where: ProjectPartnerTypeWhereUniqueInput;
}

export interface Partner_ProjectPartnerTypesFieldUpdateInput {
  connect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  connectOrCreate?: Partner_ProjectPartnerTypesFieldConnectOrCreateInput[] | null;
  create?: Partner_ProjectPartnerTypesFieldNestedCreateInput[] | null;
  delete?: ProjectPartnerTypeWhereUniqueInput[] | null;
  deleteMany?: ProjectPartnerTypeWhereScalarInput | null;
  disconnect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  set?: ProjectPartnerTypeWhereUniqueInput[] | null;
  update?: Partner_ProjectPartnerTypesFieldNestedUpdateInput[] | null;
  upsert?: Partner_ProjectPartnerTypesFieldNestedUpsertInput[] | null;
}

export interface Partner_ProjectPartnerTypesFieldWhereInput {
  every?: ProjectPartnerTypeWhereInput | null;
  none?: ProjectPartnerTypeWhereInput | null;
  some?: ProjectPartnerTypeWhereInput | null;
}

export interface Partner_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Partner_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Partner_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Partner_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectArticlesSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  tags?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
}

export interface ProjectArticlesSectionWhereInput {
  OR?: (ProjectArticlesSectionWhereInput | null)[] | null;
  AND?: (ProjectArticlesSectionWhereInput | null)[] | null;
  NOT?: (ProjectArticlesSectionWhereInput | null)[] | null;
  id?: ProjectArticlesSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectArticlesSection_ProjectSectionIdFieldWhereInput | null;
  tags?: ProjectArticlesSection_TagsFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectArticlesSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectArticlesSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectArticlesSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectArticlesSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectArticlesSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectArticlesSection_TagsFieldWhereInput {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface ProjectAttractionsSectionWhereInput {
  OR?: (ProjectAttractionsSectionWhereInput | null)[] | null;
  AND?: (ProjectAttractionsSectionWhereInput | null)[] | null;
  NOT?: (ProjectAttractionsSectionWhereInput | null)[] | null;
  id?: ProjectAttractionsSection_IdFieldWhereInput | null;
  createdAt?: ProjectAttractionsSection_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectAttractionsSection_UpdatedAtFieldWhereInput | null;
  order?: ProjectAttractionsSection_OrderFieldWhereInput | null;
  priority?: ProjectAttractionsSection_PriorityFieldWhereInput | null;
  title?: ProjectAttractionsSection_TitleFieldWhereInput | null;
  image?: ProjectAttractionsSection_ImageFieldWhereInput | null;
  description?: ProjectAttractionsSection_DescriptionFieldWhereInput | null;
  partnerId?: ProjectAttractionsSection_PartnerIdFieldWhereInput | null;
  partner?: PartnerWhereInput | null;
  projectSectionId?: ProjectAttractionsSection_ProjectSectionIdFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectAttractionsSectionWhereScalarInput {
  OR?: (ProjectAttractionsSectionWhereScalarInput | null)[] | null;
  AND?: (ProjectAttractionsSectionWhereScalarInput | null)[] | null;
  NOT?: (ProjectAttractionsSectionWhereScalarInput | null)[] | null;
  id?: ProjectAttractionsSection_IdFieldWhereScalarInput | null;
  createdAt?: ProjectAttractionsSection_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectAttractionsSection_UpdatedAtFieldWhereScalarInput | null;
  order?: ProjectAttractionsSection_OrderFieldWhereScalarInput | null;
  priority?: ProjectAttractionsSection_PriorityFieldWhereScalarInput | null;
  title?: ProjectAttractionsSection_TitleFieldWhereScalarInput | null;
  image?: ProjectAttractionsSection_ImageFieldWhereScalarInput | null;
  description?: ProjectAttractionsSection_DescriptionFieldWhereScalarInput | null;
  partnerId?: ProjectAttractionsSection_PartnerIdFieldWhereScalarInput | null;
  projectSectionId?: ProjectAttractionsSection_ProjectSectionIdFieldWhereScalarInput | null;
}

export interface ProjectAttractionsSectionWhereUniqueInput {
  id: number;
}

export interface ProjectAttractionsSection_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectAttractionsSection_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectAttractionsSection_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectAttractionsSection_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectAttractionsSection_DescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_DescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_ImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_ImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_ImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_ImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_OrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_OrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_OrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_OrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_PartnerFieldConnectOrCreateInput {
  where: PartnerWhereUniqueInput;
  create: ProjectAttractionsSection_PartnerFieldNestedCreateInput;
}

export interface ProjectAttractionsSection_PartnerFieldCreateInput {
  connect?: PartnerWhereUniqueInput | null;
  connectOrCreate?: ProjectAttractionsSection_PartnerFieldConnectOrCreateInput | null;
  create?: ProjectAttractionsSection_PartnerFieldNestedCreateInput | null;
}

export interface ProjectAttractionsSection_PartnerFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  projectPartnerTypes?: Partner_ProjectPartnerTypesFieldCreateInput | null;
}

export interface ProjectAttractionsSection_PartnerFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  projectPartnerTypes?: Partner_ProjectPartnerTypesFieldUpdateInput | null;
}

export interface ProjectAttractionsSection_PartnerFieldNestedUpsertInput {
  create: ProjectAttractionsSection_PartnerFieldNestedCreateInput;
  update: ProjectAttractionsSection_PartnerFieldNestedUpdateInput;
}

export interface ProjectAttractionsSection_PartnerFieldUpdateInput {
  connect?: PartnerWhereUniqueInput | null;
  connectOrCreate?: ProjectAttractionsSection_PartnerFieldConnectOrCreateInput | null;
  create?: ProjectAttractionsSection_PartnerFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectAttractionsSection_PartnerFieldNestedUpdateInput | null;
  upsert?: ProjectAttractionsSection_PartnerFieldNestedUpsertInput | null;
}

export interface ProjectAttractionsSection_PartnerIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_PartnerIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_PartnerIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_PartnerIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_PriorityFieldWhereInput {
  equals?: ProjectAttractionPriority | null;
  in?: ProjectAttractionPriority[] | null;
  not?: ProjectAttractionsSection_PriorityFieldWhereInput | null;
  notIn?: ProjectAttractionPriority[] | null;
}

export interface ProjectAttractionsSection_PriorityFieldWhereScalarInput {
  equals?: ProjectAttractionPriority | null;
  in?: ProjectAttractionPriority[] | null;
  not?: ProjectAttractionsSection_PriorityFieldWhereScalarInput | null;
  notIn?: ProjectAttractionPriority[] | null;
}

export interface ProjectAttractionsSection_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectAttractionsSection_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectAttractionsSection_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectAttractionsSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectAttractionsSection_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectAttractionsSection_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectAttractionsSection_ProjectSectionFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectAttractionsSection_ProjectSectionFieldNestedUpsertInput {
  create: ProjectAttractionsSection_ProjectSectionFieldNestedCreateInput;
  update: ProjectAttractionsSection_ProjectSectionFieldNestedUpdateInput;
}

export interface ProjectAttractionsSection_ProjectSectionFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectAttractionsSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectAttractionsSection_ProjectSectionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectAttractionsSection_ProjectSectionFieldNestedUpdateInput | null;
  upsert?: ProjectAttractionsSection_ProjectSectionFieldNestedUpsertInput | null;
}

export interface ProjectAttractionsSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectAttractionsSection_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectAttractionsSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectAttractionsSection_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectAttractionsSection_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectAttractionsSection_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectAttractionsSection_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectAttractionsSection_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectCompetitionSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  title?: SortOrder | null;
  buttonLabel?: SortOrder | null;
  successMessage?: SortOrder | null;
  terms?: SortOrder | null;
  emailSubject?: SortOrder | null;
  emailText?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
  submissions?: ProjectCompetitionSection_SubmissionsFieldOrderByInput | null;
}

export interface ProjectCompetitionSectionSubmissionCreateInput {
  id?: number | null;
  projectCompetitionSectionId?: number | null;
  email: string;
  phone: string;
  projectCompetitionSection?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldCreateInput | null;
}

export interface ProjectCompetitionSectionSubmissionOrderByInput {
  id?: SortOrder | null;
  projectCompetitionSectionId?: SortOrder | null;
  email?: SortOrder | null;
  phone?: SortOrder | null;
  projectCompetitionSection?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldOrderByInput | null;
}

export interface ProjectCompetitionSectionSubmissionWhereInput {
  OR?: (ProjectCompetitionSectionSubmissionWhereInput | null)[] | null;
  AND?: (ProjectCompetitionSectionSubmissionWhereInput | null)[] | null;
  NOT?: (ProjectCompetitionSectionSubmissionWhereInput | null)[] | null;
  id?: ProjectCompetitionSectionSubmission_IdFieldWhereInput | null;
  projectCompetitionSectionId?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereInput | null;
  email?: ProjectCompetitionSectionSubmission_EmailFieldWhereInput | null;
  phone?: ProjectCompetitionSectionSubmission_PhoneFieldWhereInput | null;
  projectCompetitionSection?: ProjectCompetitionSectionWhereInput | null;
}

export interface ProjectCompetitionSectionSubmissionWhereScalarInput {
  OR?: (ProjectCompetitionSectionSubmissionWhereScalarInput | null)[] | null;
  AND?: (ProjectCompetitionSectionSubmissionWhereScalarInput | null)[] | null;
  NOT?: (ProjectCompetitionSectionSubmissionWhereScalarInput | null)[] | null;
  id?: ProjectCompetitionSectionSubmission_IdFieldWhereScalarInput | null;
  projectCompetitionSectionId?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereScalarInput | null;
  email?: ProjectCompetitionSectionSubmission_EmailFieldWhereScalarInput | null;
  phone?: ProjectCompetitionSectionSubmission_PhoneFieldWhereScalarInput | null;
}

export interface ProjectCompetitionSectionSubmissionWhereUniqueInput {
  id: number;
}

export interface ProjectCompetitionSectionSubmission_EmailFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSectionSubmission_EmailFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSectionSubmission_EmailFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSectionSubmission_EmailFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSectionSubmission_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSectionSubmission_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSectionSubmission_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSectionSubmission_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSectionSubmission_PhoneFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSectionSubmission_PhoneFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSectionSubmission_PhoneFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSectionSubmission_PhoneFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldConnectOrCreateInput {
  where: ProjectCompetitionSectionWhereUniqueInput;
  create: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldNestedCreateInput;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldCreateInput {
  connect?: ProjectCompetitionSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldConnectOrCreateInput | null;
  create?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldNestedCreateInput | null;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldNestedCreateInput {
  id?: number | null;
  projectSectionId: number;
  title: string;
  buttonLabel: string;
  successMessage: string;
  terms: string;
  emailSubject?: string | null;
  emailText?: string | null;
  projectSection?: ProjectCompetitionSection_ProjectSectionFieldCreateInput | null;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSectionSubmission_ProjectCompetitionSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSectionWhereInput {
  OR?: (ProjectCompetitionSectionWhereInput | null)[] | null;
  AND?: (ProjectCompetitionSectionWhereInput | null)[] | null;
  NOT?: (ProjectCompetitionSectionWhereInput | null)[] | null;
  id?: ProjectCompetitionSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectCompetitionSection_ProjectSectionIdFieldWhereInput | null;
  title?: ProjectCompetitionSection_TitleFieldWhereInput | null;
  buttonLabel?: ProjectCompetitionSection_ButtonLabelFieldWhereInput | null;
  successMessage?: ProjectCompetitionSection_SuccessMessageFieldWhereInput | null;
  terms?: ProjectCompetitionSection_TermsFieldWhereInput | null;
  emailSubject?: ProjectCompetitionSection_EmailSubjectFieldWhereInput | null;
  emailText?: ProjectCompetitionSection_EmailTextFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
  submissions?: ProjectCompetitionSection_SubmissionsFieldWhereInput | null;
}

export interface ProjectCompetitionSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectCompetitionSection_ButtonLabelFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_ButtonLabelFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSection_EmailSubjectFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_EmailSubjectFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSection_EmailTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_EmailTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSection_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectCompetitionSection_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectCompetitionSection_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectCompetitionSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectCompetitionSection_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectCompetitionSection_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
}

export interface ProjectCompetitionSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectCompetitionSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectCompetitionSection_SubmissionsFieldConnectOrCreateInput {
  where: ProjectCompetitionSectionSubmissionWhereUniqueInput;
  create: ProjectCompetitionSection_SubmissionsFieldNestedCreateInput;
}

export interface ProjectCompetitionSection_SubmissionsFieldCreateInput {
  connect?: ProjectCompetitionSectionSubmissionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectCompetitionSection_SubmissionsFieldConnectOrCreateInput[] | null;
  create?: ProjectCompetitionSection_SubmissionsFieldNestedCreateInput[] | null;
}

export interface ProjectCompetitionSection_SubmissionsFieldNestedCreateInput {
  id?: number | null;
  email: string;
  phone: string;
}

export interface ProjectCompetitionSection_SubmissionsFieldNestedUpdateDataInput {
  id?: number | null;
  email?: string | null;
  phone?: string | null;
}

export interface ProjectCompetitionSection_SubmissionsFieldNestedUpdateInput {
  where: ProjectCompetitionSectionSubmissionWhereUniqueInput;
  data: ProjectCompetitionSection_SubmissionsFieldNestedUpdateDataInput;
}

export interface ProjectCompetitionSection_SubmissionsFieldNestedUpsertInput {
  create: ProjectCompetitionSection_SubmissionsFieldNestedCreateInput;
  update: ProjectCompetitionSection_SubmissionsFieldNestedUpdateDataInput;
  where: ProjectCompetitionSectionSubmissionWhereUniqueInput;
}

export interface ProjectCompetitionSection_SubmissionsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectCompetitionSection_SubmissionsFieldUpdateInput {
  connect?: ProjectCompetitionSectionSubmissionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectCompetitionSection_SubmissionsFieldConnectOrCreateInput[] | null;
  create?: ProjectCompetitionSection_SubmissionsFieldNestedCreateInput[] | null;
  delete?: ProjectCompetitionSectionSubmissionWhereUniqueInput[] | null;
  deleteMany?: ProjectCompetitionSectionSubmissionWhereScalarInput | null;
  disconnect?: ProjectCompetitionSectionSubmissionWhereUniqueInput[] | null;
  set?: ProjectCompetitionSectionSubmissionWhereUniqueInput[] | null;
  update?: ProjectCompetitionSection_SubmissionsFieldNestedUpdateInput[] | null;
  upsert?: ProjectCompetitionSection_SubmissionsFieldNestedUpsertInput[] | null;
}

export interface ProjectCompetitionSection_SubmissionsFieldWhereInput {
  every?: ProjectCompetitionSectionSubmissionWhereInput | null;
  none?: ProjectCompetitionSectionSubmissionWhereInput | null;
  some?: ProjectCompetitionSectionSubmissionWhereInput | null;
}

export interface ProjectCompetitionSection_SuccessMessageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_SuccessMessageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSection_TermsFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_TermsFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectCompetitionSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectCompetitionSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectContactFormSubmissionCreateInput {
  id?: number | null;
  projectId?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payload?: any | null;
  project?: ProjectContactFormSubmission_ProjectFieldCreateInput | null;
}

export interface ProjectContactFormSubmissionWhereInput {
  OR?: (ProjectContactFormSubmissionWhereInput | null)[] | null;
  AND?: (ProjectContactFormSubmissionWhereInput | null)[] | null;
  NOT?: (ProjectContactFormSubmissionWhereInput | null)[] | null;
  id?: ProjectContactFormSubmission_IdFieldWhereInput | null;
  projectId?: ProjectContactFormSubmission_ProjectIdFieldWhereInput | null;
  createdAt?: ProjectContactFormSubmission_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectContactFormSubmission_UpdatedAtFieldWhereInput | null;
  payload?: ProjectContactFormSubmission_PayloadFieldWhereInput | null;
  project?: ProjectWhereInput | null;
}

export interface ProjectContactFormSubmissionWhereScalarInput {
  OR?: (ProjectContactFormSubmissionWhereScalarInput | null)[] | null;
  AND?: (ProjectContactFormSubmissionWhereScalarInput | null)[] | null;
  NOT?: (ProjectContactFormSubmissionWhereScalarInput | null)[] | null;
  id?: ProjectContactFormSubmission_IdFieldWhereScalarInput | null;
  projectId?: ProjectContactFormSubmission_ProjectIdFieldWhereScalarInput | null;
  createdAt?: ProjectContactFormSubmission_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectContactFormSubmission_UpdatedAtFieldWhereScalarInput | null;
  payload?: ProjectContactFormSubmission_PayloadFieldWhereScalarInput | null;
}

export interface ProjectContactFormSubmissionWhereUniqueInput {
  id: number;
}

export interface ProjectContactFormSubmission_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectContactFormSubmission_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectContactFormSubmission_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectContactFormSubmission_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectContactFormSubmission_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectContactFormSubmission_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectContactFormSubmission_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectContactFormSubmission_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectContactFormSubmission_PayloadFieldWhereInput {
  equals?: any | null;
  not?: ProjectContactFormSubmission_PayloadFieldWhereInput | null;
}

export interface ProjectContactFormSubmission_PayloadFieldWhereScalarInput {
  equals?: any | null;
  not?: ProjectContactFormSubmission_PayloadFieldWhereScalarInput | null;
}

export interface ProjectContactFormSubmission_ProjectFieldConnectOrCreateInput {
  where: ProjectWhereUniqueInput;
  create: ProjectContactFormSubmission_ProjectFieldNestedCreateInput;
}

export interface ProjectContactFormSubmission_ProjectFieldCreateInput {
  connect?: ProjectWhereUniqueInput | null;
  connectOrCreate?: ProjectContactFormSubmission_ProjectFieldConnectOrCreateInput | null;
  create?: ProjectContactFormSubmission_ProjectFieldNestedCreateInput | null;
}

export interface ProjectContactFormSubmission_ProjectFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  translations?: Project_TranslationsFieldCreateInput | null;
  projectSections?: Project_ProjectSectionsFieldCreateInput | null;
}

export interface ProjectContactFormSubmission_ProjectIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectContactFormSubmission_ProjectIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectContactFormSubmission_ProjectIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectContactFormSubmission_ProjectIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectContactFormSubmission_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectContactFormSubmission_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectContactFormSubmission_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectContactFormSubmission_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldCreateInput | null;
  translations?: Project_TranslationsFieldCreateInput | null;
  projectSections?: Project_ProjectSectionsFieldCreateInput | null;
}

export interface ProjectFigureSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  title?: SortOrder | null;
  caption?: SortOrder | null;
  image?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
}

export interface ProjectFigureSectionWhereInput {
  OR?: (ProjectFigureSectionWhereInput | null)[] | null;
  AND?: (ProjectFigureSectionWhereInput | null)[] | null;
  NOT?: (ProjectFigureSectionWhereInput | null)[] | null;
  id?: ProjectFigureSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectFigureSection_ProjectSectionIdFieldWhereInput | null;
  title?: ProjectFigureSection_TitleFieldWhereInput | null;
  caption?: ProjectFigureSection_CaptionFieldWhereInput | null;
  image?: ProjectFigureSection_ImageFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectFigureSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectFigureSection_CaptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectFigureSection_CaptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectFigureSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectFigureSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectFigureSection_ImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectFigureSection_ImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectFigureSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectFigureSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectFigureSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectFigureSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectGuestsSectionOrderByInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  guests?: ProjectGuestsSection_GuestsFieldOrderByInput | null;
  projectSectionId?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
}

export interface ProjectGuestsSectionWhereInput {
  OR?: (ProjectGuestsSectionWhereInput | null)[] | null;
  AND?: (ProjectGuestsSectionWhereInput | null)[] | null;
  NOT?: (ProjectGuestsSectionWhereInput | null)[] | null;
  id?: ProjectGuestsSection_IdFieldWhereInput | null;
  createdAt?: ProjectGuestsSection_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectGuestsSection_UpdatedAtFieldWhereInput | null;
  guests?: ProjectGuestsSection_GuestsFieldWhereInput | null;
  projectSectionId?: ProjectGuestsSection_ProjectSectionIdFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectGuestsSectionWhereScalarInput {
  OR?: (ProjectGuestsSectionWhereScalarInput | null)[] | null;
  AND?: (ProjectGuestsSectionWhereScalarInput | null)[] | null;
  NOT?: (ProjectGuestsSectionWhereScalarInput | null)[] | null;
  id?: ProjectGuestsSection_IdFieldWhereScalarInput | null;
  createdAt?: ProjectGuestsSection_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectGuestsSection_UpdatedAtFieldWhereScalarInput | null;
  projectSectionId?: ProjectGuestsSection_ProjectSectionIdFieldWhereScalarInput | null;
}

export interface ProjectGuestsSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectGuestsSection_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectGuestsSection_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectGuestsSection_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectGuestsSection_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectGuestsSection_GuestsFieldConnectOrCreateInput {
  where: GuestWhereUniqueInput;
  create: ProjectGuestsSection_GuestsFieldNestedCreateInput;
}

export interface ProjectGuestsSection_GuestsFieldCreateInput {
  connect?: GuestWhereUniqueInput[] | null;
  connectOrCreate?: ProjectGuestsSection_GuestsFieldConnectOrCreateInput[] | null;
  create?: ProjectGuestsSection_GuestsFieldNestedCreateInput[] | null;
}

export interface ProjectGuestsSection_GuestsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  nickname?: string | null;
  photo?: string | null;
  addressId: number;
  mediaId: number;
  media?: Guest_MediaFieldCreateInput | null;
  address?: Guest_AddressFieldCreateInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldCreateInput | null;
}

export interface ProjectGuestsSection_GuestsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName?: string | null;
  lastName?: string | null;
  nickname?: string | null;
  photo?: string | null;
  addressId?: number | null;
  mediaId?: number | null;
  media?: Guest_MediaFieldUpdateInput | null;
  address?: Guest_AddressFieldUpdateInput | null;
  projectProgramsSections?: Guest_ProjectProgramsSectionsFieldUpdateInput | null;
}

export interface ProjectGuestsSection_GuestsFieldNestedUpdateInput {
  where: GuestWhereUniqueInput;
  data: ProjectGuestsSection_GuestsFieldNestedUpdateDataInput;
}

export interface ProjectGuestsSection_GuestsFieldNestedUpsertInput {
  create: ProjectGuestsSection_GuestsFieldNestedCreateInput;
  update: ProjectGuestsSection_GuestsFieldNestedUpdateDataInput;
  where: GuestWhereUniqueInput;
}

export interface ProjectGuestsSection_GuestsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectGuestsSection_GuestsFieldUpdateInput {
  connect?: GuestWhereUniqueInput[] | null;
  connectOrCreate?: ProjectGuestsSection_GuestsFieldConnectOrCreateInput[] | null;
  create?: ProjectGuestsSection_GuestsFieldNestedCreateInput[] | null;
  delete?: GuestWhereUniqueInput[] | null;
  deleteMany?: GuestWhereScalarInput | null;
  disconnect?: GuestWhereUniqueInput[] | null;
  set?: GuestWhereUniqueInput[] | null;
  update?: ProjectGuestsSection_GuestsFieldNestedUpdateInput[] | null;
  upsert?: ProjectGuestsSection_GuestsFieldNestedUpsertInput[] | null;
}

export interface ProjectGuestsSection_GuestsFieldWhereInput {
  every?: GuestWhereInput | null;
  none?: GuestWhereInput | null;
  some?: GuestWhereInput | null;
}

export interface ProjectGuestsSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectGuestsSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectGuestsSection_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectGuestsSection_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectGuestsSection_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectGuestsSection_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectGuestsSection_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectGuestsSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectGuestsSection_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectGuestsSection_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectGuestsSection_ProjectSectionFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectGuestsSection_ProjectSectionFieldNestedUpsertInput {
  create: ProjectGuestsSection_ProjectSectionFieldNestedCreateInput;
  update: ProjectGuestsSection_ProjectSectionFieldNestedUpdateInput;
}

export interface ProjectGuestsSection_ProjectSectionFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectGuestsSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectGuestsSection_ProjectSectionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectGuestsSection_ProjectSectionFieldNestedUpdateInput | null;
  upsert?: ProjectGuestsSection_ProjectSectionFieldNestedUpsertInput | null;
}

export interface ProjectGuestsSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectGuestsSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectGuestsSection_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectGuestsSection_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectGuestsSection_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectGuestsSection_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectGuestsSection_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectGuestsSection_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectOrderByInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  published?: SortOrder | null;
  link?: SortOrder | null;
  contactEmail?: SortOrder | null;
  coverImage?: SortOrder | null;
  matchesDisplayCount?: SortOrder | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldOrderByInput | null;
  translations?: Project_TranslationsFieldOrderByInput | null;
  projectSections?: Project_ProjectSectionsFieldOrderByInput | null;
}

export interface ProjectPartnerTypeWhereInput {
  OR?: (ProjectPartnerTypeWhereInput | null)[] | null;
  AND?: (ProjectPartnerTypeWhereInput | null)[] | null;
  NOT?: (ProjectPartnerTypeWhereInput | null)[] | null;
  id?: ProjectPartnerType_IdFieldWhereInput | null;
  createdAt?: ProjectPartnerType_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectPartnerType_UpdatedAtFieldWhereInput | null;
  order?: ProjectPartnerType_OrderFieldWhereInput | null;
  tilesPerRowMobile?: ProjectPartnerType_TilesPerRowMobileFieldWhereInput | null;
  tilesPerRowDesktop?: ProjectPartnerType_TilesPerRowDesktopFieldWhereInput | null;
  name?: ProjectPartnerType_NameFieldWhereInput | null;
  projectSectionId?: ProjectPartnerType_ProjectSectionIdFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
  partners?: ProjectPartnerType_PartnersFieldWhereInput | null;
}

export interface ProjectPartnerTypeWhereScalarInput {
  OR?: (ProjectPartnerTypeWhereScalarInput | null)[] | null;
  AND?: (ProjectPartnerTypeWhereScalarInput | null)[] | null;
  NOT?: (ProjectPartnerTypeWhereScalarInput | null)[] | null;
  id?: ProjectPartnerType_IdFieldWhereScalarInput | null;
  createdAt?: ProjectPartnerType_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectPartnerType_UpdatedAtFieldWhereScalarInput | null;
  order?: ProjectPartnerType_OrderFieldWhereScalarInput | null;
  tilesPerRowMobile?: ProjectPartnerType_TilesPerRowMobileFieldWhereScalarInput | null;
  tilesPerRowDesktop?: ProjectPartnerType_TilesPerRowDesktopFieldWhereScalarInput | null;
  name?: ProjectPartnerType_NameFieldWhereScalarInput | null;
  projectSectionId?: ProjectPartnerType_ProjectSectionIdFieldWhereScalarInput | null;
}

export interface ProjectPartnerTypeWhereUniqueInput {
  id: number;
}

export interface ProjectPartnerType_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectPartnerType_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectPartnerType_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectPartnerType_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectPartnerType_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_NameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectPartnerType_NameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectPartnerType_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectPartnerType_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectPartnerType_OrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_OrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_OrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_OrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_PartnersFieldConnectOrCreateInput {
  where: PartnerWhereUniqueInput;
  create: ProjectPartnerType_PartnersFieldNestedCreateInput;
}

export interface ProjectPartnerType_PartnersFieldCreateInput {
  connect?: PartnerWhereUniqueInput[] | null;
  connectOrCreate?: ProjectPartnerType_PartnersFieldConnectOrCreateInput[] | null;
  create?: ProjectPartnerType_PartnersFieldNestedCreateInput[] | null;
}

export interface ProjectPartnerType_PartnersFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  ProjectAttractionsSection?: Partner_ProjectAttractionsSectionFieldCreateInput | null;
}

export interface ProjectPartnerType_PartnersFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  logo?: string | null;
  orsr?: string | null;
  ico?: string | null;
  dic?: string | null;
  dph?: string | null;
  media?: any | null;
  address?: any | null;
  ProjectAttractionsSection?: Partner_ProjectAttractionsSectionFieldUpdateInput | null;
}

export interface ProjectPartnerType_PartnersFieldNestedUpdateInput {
  where: PartnerWhereUniqueInput;
  data: ProjectPartnerType_PartnersFieldNestedUpdateDataInput;
}

export interface ProjectPartnerType_PartnersFieldNestedUpsertInput {
  create: ProjectPartnerType_PartnersFieldNestedCreateInput;
  update: ProjectPartnerType_PartnersFieldNestedUpdateDataInput;
  where: PartnerWhereUniqueInput;
}

export interface ProjectPartnerType_PartnersFieldUpdateInput {
  connect?: PartnerWhereUniqueInput[] | null;
  connectOrCreate?: ProjectPartnerType_PartnersFieldConnectOrCreateInput[] | null;
  create?: ProjectPartnerType_PartnersFieldNestedCreateInput[] | null;
  delete?: PartnerWhereUniqueInput[] | null;
  deleteMany?: PartnerWhereScalarInput | null;
  disconnect?: PartnerWhereUniqueInput[] | null;
  set?: PartnerWhereUniqueInput[] | null;
  update?: ProjectPartnerType_PartnersFieldNestedUpdateInput[] | null;
  upsert?: ProjectPartnerType_PartnersFieldNestedUpsertInput[] | null;
}

export interface ProjectPartnerType_PartnersFieldWhereInput {
  every?: PartnerWhereInput | null;
  none?: PartnerWhereInput | null;
  some?: PartnerWhereInput | null;
}

export interface ProjectPartnerType_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectPartnerType_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectPartnerType_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectPartnerType_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectPartnerType_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectPartnerType_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectPartnerType_ProjectSectionFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectPartnerType_ProjectSectionFieldNestedUpsertInput {
  create: ProjectPartnerType_ProjectSectionFieldNestedCreateInput;
  update: ProjectPartnerType_ProjectSectionFieldNestedUpdateInput;
}

export interface ProjectPartnerType_ProjectSectionFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectPartnerType_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectPartnerType_ProjectSectionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectPartnerType_ProjectSectionFieldNestedUpdateInput | null;
  upsert?: ProjectPartnerType_ProjectSectionFieldNestedUpsertInput | null;
}

export interface ProjectPartnerType_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_TilesPerRowDesktopFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_TilesPerRowDesktopFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_TilesPerRowDesktopFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_TilesPerRowDesktopFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_TilesPerRowMobileFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_TilesPerRowMobileFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_TilesPerRowMobileFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectPartnerType_TilesPerRowMobileFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectPartnerType_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectPartnerType_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectPartnerType_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectPartnerType_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSectionWhereInput {
  OR?: (ProjectProgramSectionWhereInput | null)[] | null;
  AND?: (ProjectProgramSectionWhereInput | null)[] | null;
  NOT?: (ProjectProgramSectionWhereInput | null)[] | null;
  id?: ProjectProgramSection_IdFieldWhereInput | null;
  createdAt?: ProjectProgramSection_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectProgramSection_UpdatedAtFieldWhereInput | null;
  date?: ProjectProgramSection_DateFieldWhereInput | null;
  duration?: ProjectProgramSection_DurationFieldWhereInput | null;
  name?: ProjectProgramSection_NameFieldWhereInput | null;
  description?: ProjectProgramSection_DescriptionFieldWhereInput | null;
  guests?: ProjectProgramSection_GuestsFieldWhereInput | null;
  place?: ProjectProgramSection_PlaceFieldWhereInput | null;
  streamUrl?: ProjectProgramSection_StreamUrlFieldWhereInput | null;
  order?: ProjectProgramSection_OrderFieldWhereInput | null;
  projectSectionId?: ProjectProgramSection_ProjectSectionIdFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectProgramSectionWhereScalarInput {
  OR?: (ProjectProgramSectionWhereScalarInput | null)[] | null;
  AND?: (ProjectProgramSectionWhereScalarInput | null)[] | null;
  NOT?: (ProjectProgramSectionWhereScalarInput | null)[] | null;
  id?: ProjectProgramSection_IdFieldWhereScalarInput | null;
  createdAt?: ProjectProgramSection_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectProgramSection_UpdatedAtFieldWhereScalarInput | null;
  date?: ProjectProgramSection_DateFieldWhereScalarInput | null;
  duration?: ProjectProgramSection_DurationFieldWhereScalarInput | null;
  name?: ProjectProgramSection_NameFieldWhereScalarInput | null;
  description?: ProjectProgramSection_DescriptionFieldWhereScalarInput | null;
  place?: ProjectProgramSection_PlaceFieldWhereScalarInput | null;
  streamUrl?: ProjectProgramSection_StreamUrlFieldWhereScalarInput | null;
  order?: ProjectProgramSection_OrderFieldWhereScalarInput | null;
  projectSectionId?: ProjectProgramSection_ProjectSectionIdFieldWhereScalarInput | null;
}

export interface ProjectProgramSectionWhereUniqueInput {
  id: number;
}

export interface ProjectProgramSection_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSection_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSection_DateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_DateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSection_DateFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_DateFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSection_DescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_DescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_DurationFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_DurationFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_DurationFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_DurationFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_GuestsFieldConnectOrCreateInput {
  where: GuestWhereUniqueInput;
  create: ProjectProgramSection_GuestsFieldNestedCreateInput;
}

export interface ProjectProgramSection_GuestsFieldCreateInput {
  connect?: GuestWhereUniqueInput[] | null;
  connectOrCreate?: ProjectProgramSection_GuestsFieldConnectOrCreateInput[] | null;
  create?: ProjectProgramSection_GuestsFieldNestedCreateInput[] | null;
}

export interface ProjectProgramSection_GuestsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  nickname?: string | null;
  photo?: string | null;
  addressId: number;
  mediaId: number;
  media?: Guest_MediaFieldCreateInput | null;
  address?: Guest_AddressFieldCreateInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldCreateInput | null;
}

export interface ProjectProgramSection_GuestsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName?: string | null;
  lastName?: string | null;
  nickname?: string | null;
  photo?: string | null;
  addressId?: number | null;
  mediaId?: number | null;
  media?: Guest_MediaFieldUpdateInput | null;
  address?: Guest_AddressFieldUpdateInput | null;
  projectGuestsSection?: Guest_ProjectGuestsSectionFieldUpdateInput | null;
}

export interface ProjectProgramSection_GuestsFieldNestedUpdateInput {
  where: GuestWhereUniqueInput;
  data: ProjectProgramSection_GuestsFieldNestedUpdateDataInput;
}

export interface ProjectProgramSection_GuestsFieldNestedUpsertInput {
  create: ProjectProgramSection_GuestsFieldNestedCreateInput;
  update: ProjectProgramSection_GuestsFieldNestedUpdateDataInput;
  where: GuestWhereUniqueInput;
}

export interface ProjectProgramSection_GuestsFieldUpdateInput {
  connect?: GuestWhereUniqueInput[] | null;
  connectOrCreate?: ProjectProgramSection_GuestsFieldConnectOrCreateInput[] | null;
  create?: ProjectProgramSection_GuestsFieldNestedCreateInput[] | null;
  delete?: GuestWhereUniqueInput[] | null;
  deleteMany?: GuestWhereScalarInput | null;
  disconnect?: GuestWhereUniqueInput[] | null;
  set?: GuestWhereUniqueInput[] | null;
  update?: ProjectProgramSection_GuestsFieldNestedUpdateInput[] | null;
  upsert?: ProjectProgramSection_GuestsFieldNestedUpsertInput[] | null;
}

export interface ProjectProgramSection_GuestsFieldWhereInput {
  every?: GuestWhereInput | null;
  none?: GuestWhereInput | null;
  some?: GuestWhereInput | null;
}

export interface ProjectProgramSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_NameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_NameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_OrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_OrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_OrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_OrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_PlaceFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_PlaceFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_PlaceFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_PlaceFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectProgramSection_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectProgramSection_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectProgramSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectProgramSection_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectProgramSection_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectProgramSection_ProjectSectionFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectProgramSection_ProjectSectionFieldNestedUpsertInput {
  create: ProjectProgramSection_ProjectSectionFieldNestedCreateInput;
  update: ProjectProgramSection_ProjectSectionFieldNestedUpdateInput;
}

export interface ProjectProgramSection_ProjectSectionFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectProgramSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectProgramSection_ProjectSectionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectProgramSection_ProjectSectionFieldNestedUpdateInput | null;
  upsert?: ProjectProgramSection_ProjectSectionFieldNestedUpsertInput | null;
}

export interface ProjectProgramSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectProgramSection_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectProgramSection_StreamUrlFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_StreamUrlFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_StreamUrlFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectProgramSection_StreamUrlFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectProgramSection_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectProgramSection_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectProgramSection_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectQuestionAndAnswerWhereInput {
  OR?: (ProjectQuestionAndAnswerWhereInput | null)[] | null;
  AND?: (ProjectQuestionAndAnswerWhereInput | null)[] | null;
  NOT?: (ProjectQuestionAndAnswerWhereInput | null)[] | null;
  id?: ProjectQuestionAndAnswer_IdFieldWhereInput | null;
  createdAt?: ProjectQuestionAndAnswer_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectQuestionAndAnswer_UpdatedAtFieldWhereInput | null;
  projectSectionId?: ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereInput | null;
  title?: ProjectQuestionAndAnswer_TitleFieldWhereInput | null;
  text?: ProjectQuestionAndAnswer_TextFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectQuestionAndAnswerWhereScalarInput {
  OR?: (ProjectQuestionAndAnswerWhereScalarInput | null)[] | null;
  AND?: (ProjectQuestionAndAnswerWhereScalarInput | null)[] | null;
  NOT?: (ProjectQuestionAndAnswerWhereScalarInput | null)[] | null;
  id?: ProjectQuestionAndAnswer_IdFieldWhereScalarInput | null;
  createdAt?: ProjectQuestionAndAnswer_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectQuestionAndAnswer_UpdatedAtFieldWhereScalarInput | null;
  projectSectionId?: ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereScalarInput | null;
  title?: ProjectQuestionAndAnswer_TitleFieldWhereScalarInput | null;
  text?: ProjectQuestionAndAnswer_TextFieldWhereScalarInput | null;
}

export interface ProjectQuestionAndAnswerWhereUniqueInput {
  id: number;
}

export interface ProjectQuestionAndAnswer_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectQuestionAndAnswer_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectQuestionAndAnswer_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectQuestionAndAnswer_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectQuestionAndAnswer_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectQuestionAndAnswer_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectQuestionAndAnswer_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectQuestionAndAnswer_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectQuestionAndAnswer_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectQuestionAndAnswer_TextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectQuestionAndAnswer_TextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectQuestionAndAnswer_TextFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectQuestionAndAnswer_TextFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectQuestionAndAnswer_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectQuestionAndAnswer_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectQuestionAndAnswer_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectQuestionAndAnswer_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectQuestionAndAnswer_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectQuestionAndAnswer_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectQuestionAndAnswer_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectQuestionAndAnswer_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectSectionCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectSectionOrderByInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  projectId?: SortOrder | null;
  locale?: SortOrder | null;
  type?: SortOrder | null;
  contentTableName?: SortOrder | null;
  icon?: SortOrder | null;
  addToContentTable?: SortOrder | null;
  project?: ProjectSection_ProjectFieldOrderByInput | null;
  translations?: ProjectSection_TranslationsFieldOrderByInput | null;
  video?: ProjectVideoSectionOrderByInput | null;
  figure?: ProjectFigureSectionOrderByInput | null;
  text?: ProjectTextSectionOrderByInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldOrderByInput | null;
  articles?: ProjectArticlesSectionOrderByInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldOrderByInput | null;
  guests?: ProjectGuestsSectionOrderByInput | null;
  tournaments?: ProjectSection_TournamentsFieldOrderByInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldOrderByInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldOrderByInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldOrderByInput | null;
  attractions?: ProjectSection_AttractionsFieldOrderByInput | null;
  programs?: ProjectSection_ProgramsFieldOrderByInput | null;
  voting?: ProjectVotingSectionOrderByInput | null;
  competition?: ProjectCompetitionSectionOrderByInput | null;
}

export interface ProjectSectionTranslationWhereInput {
  OR?: (ProjectSectionTranslationWhereInput | null)[] | null;
  AND?: (ProjectSectionTranslationWhereInput | null)[] | null;
  NOT?: (ProjectSectionTranslationWhereInput | null)[] | null;
  id?: ProjectSectionTranslation_IdFieldWhereInput | null;
  projectSectionId?: ProjectSectionTranslation_ProjectSectionIdFieldWhereInput | null;
  projectSections?: ProjectSectionWhereInput | null;
  locale?: ProjectSectionTranslation_LocaleFieldWhereInput | null;
  order?: ProjectSectionTranslation_OrderFieldWhereInput | null;
}

export interface ProjectSectionTranslationWhereScalarInput {
  OR?: (ProjectSectionTranslationWhereScalarInput | null)[] | null;
  AND?: (ProjectSectionTranslationWhereScalarInput | null)[] | null;
  NOT?: (ProjectSectionTranslationWhereScalarInput | null)[] | null;
  id?: ProjectSectionTranslation_IdFieldWhereScalarInput | null;
  projectSectionId?: ProjectSectionTranslation_ProjectSectionIdFieldWhereScalarInput | null;
  locale?: ProjectSectionTranslation_LocaleFieldWhereScalarInput | null;
  order?: ProjectSectionTranslation_OrderFieldWhereScalarInput | null;
}

export interface ProjectSectionTranslationWhereUniqueInput {
  id: number;
}

export interface ProjectSectionTranslation_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionTranslation_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionTranslation_LocaleFieldWhereInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectSectionTranslation_LocaleFieldWhereInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectSectionTranslation_LocaleFieldWhereScalarInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectSectionTranslation_LocaleFieldWhereScalarInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectSectionTranslation_OrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_OrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionTranslation_OrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_OrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionTranslation_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionTranslation_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSectionTranslation_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectSectionUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectSectionWhereInput {
  OR?: (ProjectSectionWhereInput | null)[] | null;
  AND?: (ProjectSectionWhereInput | null)[] | null;
  NOT?: (ProjectSectionWhereInput | null)[] | null;
  id?: ProjectSection_IdFieldWhereInput | null;
  createdAt?: ProjectSection_CreatedAtFieldWhereInput | null;
  updatedAt?: ProjectSection_UpdatedAtFieldWhereInput | null;
  projectId?: ProjectSection_ProjectIdFieldWhereInput | null;
  locale?: ProjectSection_LocaleFieldWhereInput | null;
  type?: ProjectSection_TypeFieldWhereInput | null;
  contentTableName?: ProjectSection_ContentTableNameFieldWhereInput | null;
  icon?: ProjectSection_IconFieldWhereInput | null;
  addToContentTable?: ProjectSection_AddToContentTableFieldWhereInput | null;
  project?: ProjectWhereInput | null;
  translations?: ProjectSection_TranslationsFieldWhereInput | null;
  video?: ProjectVideoSectionWhereInput | null;
  figure?: ProjectFigureSectionWhereInput | null;
  text?: ProjectTextSectionWhereInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldWhereInput | null;
  articles?: ProjectArticlesSectionWhereInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldWhereInput | null;
  guests?: ProjectGuestsSectionWhereInput | null;
  tournaments?: ProjectSection_TournamentsFieldWhereInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldWhereInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldWhereInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldWhereInput | null;
  attractions?: ProjectSection_AttractionsFieldWhereInput | null;
  programs?: ProjectSection_ProgramsFieldWhereInput | null;
  voting?: ProjectVotingSectionWhereInput | null;
  competition?: ProjectCompetitionSectionWhereInput | null;
}

export interface ProjectSectionWhereScalarInput {
  OR?: (ProjectSectionWhereScalarInput | null)[] | null;
  AND?: (ProjectSectionWhereScalarInput | null)[] | null;
  NOT?: (ProjectSectionWhereScalarInput | null)[] | null;
  id?: ProjectSection_IdFieldWhereScalarInput | null;
  createdAt?: ProjectSection_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: ProjectSection_UpdatedAtFieldWhereScalarInput | null;
  projectId?: ProjectSection_ProjectIdFieldWhereScalarInput | null;
  locale?: ProjectSection_LocaleFieldWhereScalarInput | null;
  type?: ProjectSection_TypeFieldWhereScalarInput | null;
  contentTableName?: ProjectSection_ContentTableNameFieldWhereScalarInput | null;
  icon?: ProjectSection_IconFieldWhereScalarInput | null;
  addToContentTable?: ProjectSection_AddToContentTableFieldWhereScalarInput | null;
}

export interface ProjectSectionWhereUniqueInput {
  id: number;
}

export interface ProjectSection_AddToContentTableFieldWhereInput {
  equals?: boolean | null;
  not?: ProjectSection_AddToContentTableFieldWhereInput | null;
}

export interface ProjectSection_AddToContentTableFieldWhereScalarInput {
  equals?: boolean | null;
  not?: ProjectSection_AddToContentTableFieldWhereScalarInput | null;
}

export interface ProjectSection_ArticlesFieldConnectOrCreateInput {
  where: ProjectArticlesSectionWhereUniqueInput;
  create: ProjectSection_ArticlesFieldNestedCreateInput;
}

export interface ProjectSection_ArticlesFieldCreateInput {
  connect?: ProjectArticlesSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_ArticlesFieldConnectOrCreateInput | null;
  create?: ProjectSection_ArticlesFieldNestedCreateInput | null;
}

export interface ProjectSection_ArticlesFieldNestedCreateInput {
  id?: number | null;
  tags: string[];
}

export interface ProjectSection_ArticlesFieldNestedUpdateInput {
  id?: number | null;
  tags?: string[] | null;
}

export interface ProjectSection_ArticlesFieldNestedUpsertInput {
  create: ProjectSection_ArticlesFieldNestedCreateInput;
  update: ProjectSection_ArticlesFieldNestedUpdateInput;
}

export interface ProjectSection_ArticlesFieldUpdateInput {
  connect?: ProjectArticlesSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_ArticlesFieldConnectOrCreateInput | null;
  create?: ProjectSection_ArticlesFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_ArticlesFieldNestedUpdateInput | null;
  upsert?: ProjectSection_ArticlesFieldNestedUpsertInput | null;
}

export interface ProjectSection_AttractionsFieldConnectOrCreateInput {
  where: ProjectAttractionsSectionWhereUniqueInput;
  create: ProjectSection_AttractionsFieldNestedCreateInput;
}

export interface ProjectSection_AttractionsFieldCreateInput {
  connect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_AttractionsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_AttractionsFieldNestedCreateInput[] | null;
}

export interface ProjectSection_AttractionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  priority: ProjectAttractionPriority;
  title: string;
  image?: string | null;
  description?: string | null;
  partnerId?: number | null;
  partner?: ProjectAttractionsSection_PartnerFieldCreateInput | null;
}

export interface ProjectSection_AttractionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  priority?: ProjectAttractionPriority | null;
  title?: string | null;
  image?: string | null;
  description?: string | null;
  partnerId?: number | null;
  partner?: ProjectAttractionsSection_PartnerFieldUpdateInput | null;
}

export interface ProjectSection_AttractionsFieldNestedUpdateInput {
  where: ProjectAttractionsSectionWhereUniqueInput;
  data: ProjectSection_AttractionsFieldNestedUpdateDataInput;
}

export interface ProjectSection_AttractionsFieldNestedUpsertInput {
  create: ProjectSection_AttractionsFieldNestedCreateInput;
  update: ProjectSection_AttractionsFieldNestedUpdateDataInput;
  where: ProjectAttractionsSectionWhereUniqueInput;
}

export interface ProjectSection_AttractionsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_AttractionsFieldUpdateInput {
  connect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_AttractionsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_AttractionsFieldNestedCreateInput[] | null;
  delete?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectAttractionsSectionWhereScalarInput | null;
  disconnect?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  set?: ProjectAttractionsSectionWhereUniqueInput[] | null;
  update?: ProjectSection_AttractionsFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_AttractionsFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_AttractionsFieldWhereInput {
  every?: ProjectAttractionsSectionWhereInput | null;
  none?: ProjectAttractionsSectionWhereInput | null;
  some?: ProjectAttractionsSectionWhereInput | null;
}

export interface ProjectSection_CompetitionFieldConnectOrCreateInput {
  where: ProjectCompetitionSectionWhereUniqueInput;
  create: ProjectSection_CompetitionFieldNestedCreateInput;
}

export interface ProjectSection_CompetitionFieldCreateInput {
  connect?: ProjectCompetitionSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_CompetitionFieldConnectOrCreateInput | null;
  create?: ProjectSection_CompetitionFieldNestedCreateInput | null;
}

export interface ProjectSection_CompetitionFieldNestedCreateInput {
  id?: number | null;
  title: string;
  buttonLabel: string;
  successMessage: string;
  terms: string;
  emailSubject?: string | null;
  emailText?: string | null;
  submissions?: ProjectCompetitionSection_SubmissionsFieldCreateInput | null;
}

export interface ProjectSection_CompetitionFieldNestedUpdateInput {
  id?: number | null;
  title?: string | null;
  buttonLabel?: string | null;
  successMessage?: string | null;
  terms?: string | null;
  emailSubject?: string | null;
  emailText?: string | null;
  submissions?: ProjectCompetitionSection_SubmissionsFieldUpdateInput | null;
}

export interface ProjectSection_CompetitionFieldNestedUpsertInput {
  create: ProjectSection_CompetitionFieldNestedCreateInput;
  update: ProjectSection_CompetitionFieldNestedUpdateInput;
}

export interface ProjectSection_CompetitionFieldUpdateInput {
  connect?: ProjectCompetitionSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_CompetitionFieldConnectOrCreateInput | null;
  create?: ProjectSection_CompetitionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_CompetitionFieldNestedUpdateInput | null;
  upsert?: ProjectSection_CompetitionFieldNestedUpsertInput | null;
}

export interface ProjectSection_ContentTableNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectSection_ContentTableNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectSection_ContentTableNameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectSection_ContentTableNameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectSection_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectSection_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectSection_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectSection_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectSection_FigureFieldConnectOrCreateInput {
  where: ProjectFigureSectionWhereUniqueInput;
  create: ProjectSection_FigureFieldNestedCreateInput;
}

export interface ProjectSection_FigureFieldCreateInput {
  connect?: ProjectFigureSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_FigureFieldConnectOrCreateInput | null;
  create?: ProjectSection_FigureFieldNestedCreateInput | null;
}

export interface ProjectSection_FigureFieldNestedCreateInput {
  id?: number | null;
  title?: string | null;
  caption?: string | null;
  image?: string | null;
}

export interface ProjectSection_FigureFieldNestedUpdateInput {
  id?: number | null;
  title?: string | null;
  caption?: string | null;
  image?: string | null;
}

export interface ProjectSection_FigureFieldNestedUpsertInput {
  create: ProjectSection_FigureFieldNestedCreateInput;
  update: ProjectSection_FigureFieldNestedUpdateInput;
}

export interface ProjectSection_FigureFieldUpdateInput {
  connect?: ProjectFigureSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_FigureFieldConnectOrCreateInput | null;
  create?: ProjectSection_FigureFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_FigureFieldNestedUpdateInput | null;
  upsert?: ProjectSection_FigureFieldNestedUpsertInput | null;
}

export interface ProjectSection_GuestsFieldConnectOrCreateInput {
  where: ProjectGuestsSectionWhereUniqueInput;
  create: ProjectSection_GuestsFieldNestedCreateInput;
}

export interface ProjectSection_GuestsFieldCreateInput {
  connect?: ProjectGuestsSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_GuestsFieldConnectOrCreateInput | null;
  create?: ProjectSection_GuestsFieldNestedCreateInput | null;
}

export interface ProjectSection_GuestsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  guests?: ProjectGuestsSection_GuestsFieldCreateInput | null;
}

export interface ProjectSection_GuestsFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  guests?: ProjectGuestsSection_GuestsFieldUpdateInput | null;
}

export interface ProjectSection_GuestsFieldNestedUpsertInput {
  create: ProjectSection_GuestsFieldNestedCreateInput;
  update: ProjectSection_GuestsFieldNestedUpdateInput;
}

export interface ProjectSection_GuestsFieldUpdateInput {
  connect?: ProjectGuestsSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_GuestsFieldConnectOrCreateInput | null;
  create?: ProjectSection_GuestsFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_GuestsFieldNestedUpdateInput | null;
  upsert?: ProjectSection_GuestsFieldNestedUpsertInput | null;
}

export interface ProjectSection_IconFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectSection_IconFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectSection_IconFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectSection_IconFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectSection_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSection_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectSection_LocaleFieldWhereInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectSection_LocaleFieldWhereInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectSection_LocaleFieldWhereScalarInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectSection_LocaleFieldWhereScalarInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectSection_PartnerTypesFieldConnectOrCreateInput {
  where: ProjectPartnerTypeWhereUniqueInput;
  create: ProjectSection_PartnerTypesFieldNestedCreateInput;
}

export interface ProjectSection_PartnerTypesFieldCreateInput {
  connect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_PartnerTypesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_PartnerTypesFieldNestedCreateInput[] | null;
}

export interface ProjectSection_PartnerTypesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  tilesPerRowMobile?: number | null;
  tilesPerRowDesktop?: number | null;
  name: string;
  partners?: ProjectPartnerType_PartnersFieldCreateInput | null;
}

export interface ProjectSection_PartnerTypesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  order?: number | null;
  tilesPerRowMobile?: number | null;
  tilesPerRowDesktop?: number | null;
  name?: string | null;
  partners?: ProjectPartnerType_PartnersFieldUpdateInput | null;
}

export interface ProjectSection_PartnerTypesFieldNestedUpdateInput {
  where: ProjectPartnerTypeWhereUniqueInput;
  data: ProjectSection_PartnerTypesFieldNestedUpdateDataInput;
}

export interface ProjectSection_PartnerTypesFieldNestedUpsertInput {
  create: ProjectSection_PartnerTypesFieldNestedCreateInput;
  update: ProjectSection_PartnerTypesFieldNestedUpdateDataInput;
  where: ProjectPartnerTypeWhereUniqueInput;
}

export interface ProjectSection_PartnerTypesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_PartnerTypesFieldUpdateInput {
  connect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_PartnerTypesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_PartnerTypesFieldNestedCreateInput[] | null;
  delete?: ProjectPartnerTypeWhereUniqueInput[] | null;
  deleteMany?: ProjectPartnerTypeWhereScalarInput | null;
  disconnect?: ProjectPartnerTypeWhereUniqueInput[] | null;
  set?: ProjectPartnerTypeWhereUniqueInput[] | null;
  update?: ProjectSection_PartnerTypesFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_PartnerTypesFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_PartnerTypesFieldWhereInput {
  every?: ProjectPartnerTypeWhereInput | null;
  none?: ProjectPartnerTypeWhereInput | null;
  some?: ProjectPartnerTypeWhereInput | null;
}

export interface ProjectSection_ProgramsFieldConnectOrCreateInput {
  where: ProjectProgramSectionWhereUniqueInput;
  create: ProjectSection_ProgramsFieldNestedCreateInput;
}

export interface ProjectSection_ProgramsFieldCreateInput {
  connect?: ProjectProgramSectionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_ProgramsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_ProgramsFieldNestedCreateInput[] | null;
}

export interface ProjectSection_ProgramsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  date: any;
  duration: number;
  name: string;
  description: string;
  guests?: ProjectProgramSection_GuestsFieldCreateInput | null;
  place: string;
  streamUrl?: string | null;
  order?: number | null;
}

export interface ProjectSection_ProgramsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  date?: any | null;
  duration?: number | null;
  name?: string | null;
  description?: string | null;
  guests?: ProjectProgramSection_GuestsFieldUpdateInput | null;
  place?: string | null;
  streamUrl?: string | null;
  order?: number | null;
}

export interface ProjectSection_ProgramsFieldNestedUpdateInput {
  where: ProjectProgramSectionWhereUniqueInput;
  data: ProjectSection_ProgramsFieldNestedUpdateDataInput;
}

export interface ProjectSection_ProgramsFieldNestedUpsertInput {
  create: ProjectSection_ProgramsFieldNestedCreateInput;
  update: ProjectSection_ProgramsFieldNestedUpdateDataInput;
  where: ProjectProgramSectionWhereUniqueInput;
}

export interface ProjectSection_ProgramsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_ProgramsFieldUpdateInput {
  connect?: ProjectProgramSectionWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_ProgramsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_ProgramsFieldNestedCreateInput[] | null;
  delete?: ProjectProgramSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectProgramSectionWhereScalarInput | null;
  disconnect?: ProjectProgramSectionWhereUniqueInput[] | null;
  set?: ProjectProgramSectionWhereUniqueInput[] | null;
  update?: ProjectSection_ProgramsFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_ProgramsFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_ProgramsFieldWhereInput {
  every?: ProjectProgramSectionWhereInput | null;
  none?: ProjectProgramSectionWhereInput | null;
  some?: ProjectProgramSectionWhereInput | null;
}

export interface ProjectSection_ProjectFieldConnectOrCreateInput {
  where: ProjectWhereUniqueInput;
  create: ProjectSection_ProjectFieldNestedCreateInput;
}

export interface ProjectSection_ProjectFieldCreateInput {
  connect?: ProjectWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_ProjectFieldConnectOrCreateInput | null;
  create?: ProjectSection_ProjectFieldNestedCreateInput | null;
}

export interface ProjectSection_ProjectFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldCreateInput | null;
  translations?: Project_TranslationsFieldCreateInput | null;
}

export interface ProjectSection_ProjectFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldUpdateInput | null;
  translations?: Project_TranslationsFieldUpdateInput | null;
}

export interface ProjectSection_ProjectFieldNestedUpsertInput {
  create: ProjectSection_ProjectFieldNestedCreateInput;
  update: ProjectSection_ProjectFieldNestedUpdateInput;
}

export interface ProjectSection_ProjectFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_ProjectFieldUpdateInput {
  connect?: ProjectWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_ProjectFieldConnectOrCreateInput | null;
  create?: ProjectSection_ProjectFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_ProjectFieldNestedUpdateInput | null;
  upsert?: ProjectSection_ProjectFieldNestedUpsertInput | null;
}

export interface ProjectSection_ProjectIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSection_ProjectIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectSection_ProjectIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectSection_ProjectIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldConnectOrCreateInput {
  where: ProjectQuestionAndAnswerWhereUniqueInput;
  create: ProjectSection_QuestionsAndAnswersFieldNestedCreateInput;
}

export interface ProjectSection_QuestionsAndAnswersFieldCreateInput {
  connect?: ProjectQuestionAndAnswerWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_QuestionsAndAnswersFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_QuestionsAndAnswersFieldNestedCreateInput[] | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  text?: string | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  text?: string | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldNestedUpdateInput {
  where: ProjectQuestionAndAnswerWhereUniqueInput;
  data: ProjectSection_QuestionsAndAnswersFieldNestedUpdateDataInput;
}

export interface ProjectSection_QuestionsAndAnswersFieldNestedUpsertInput {
  create: ProjectSection_QuestionsAndAnswersFieldNestedCreateInput;
  update: ProjectSection_QuestionsAndAnswersFieldNestedUpdateDataInput;
  where: ProjectQuestionAndAnswerWhereUniqueInput;
}

export interface ProjectSection_QuestionsAndAnswersFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldUpdateInput {
  connect?: ProjectQuestionAndAnswerWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_QuestionsAndAnswersFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_QuestionsAndAnswersFieldNestedCreateInput[] | null;
  delete?: ProjectQuestionAndAnswerWhereUniqueInput[] | null;
  deleteMany?: ProjectQuestionAndAnswerWhereScalarInput | null;
  disconnect?: ProjectQuestionAndAnswerWhereUniqueInput[] | null;
  set?: ProjectQuestionAndAnswerWhereUniqueInput[] | null;
  update?: ProjectSection_QuestionsAndAnswersFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_QuestionsAndAnswersFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_QuestionsAndAnswersFieldWhereInput {
  every?: ProjectQuestionAndAnswerWhereInput | null;
  none?: ProjectQuestionAndAnswerWhereInput | null;
  some?: ProjectQuestionAndAnswerWhereInput | null;
}

export interface ProjectSection_TextFieldConnectOrCreateInput {
  where: ProjectTextSectionWhereUniqueInput;
  create: ProjectSection_TextFieldNestedCreateInput;
}

export interface ProjectSection_TextFieldCreateInput {
  connect?: ProjectTextSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_TextFieldConnectOrCreateInput | null;
  create?: ProjectSection_TextFieldNestedCreateInput | null;
}

export interface ProjectSection_TextFieldNestedCreateInput {
  id?: number | null;
  title?: string | null;
  image?: string | null;
  imagePosition?: ProjectSectionImagePosition | null;
  buttonLink?: string | null;
  buttonText?: string | null;
  text?: string | null;
}

export interface ProjectSection_TextFieldNestedUpdateInput {
  id?: number | null;
  title?: string | null;
  image?: string | null;
  imagePosition?: ProjectSectionImagePosition | null;
  buttonLink?: string | null;
  buttonText?: string | null;
  text?: string | null;
}

export interface ProjectSection_TextFieldNestedUpsertInput {
  create: ProjectSection_TextFieldNestedCreateInput;
  update: ProjectSection_TextFieldNestedUpdateInput;
}

export interface ProjectSection_TextFieldUpdateInput {
  connect?: ProjectTextSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_TextFieldConnectOrCreateInput | null;
  create?: ProjectSection_TextFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_TextFieldNestedUpdateInput | null;
  upsert?: ProjectSection_TextFieldNestedUpsertInput | null;
}

export interface ProjectSection_TournamentsFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: ProjectSection_TournamentsFieldNestedCreateInput;
}

export interface ProjectSection_TournamentsFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_TournamentsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_TournamentsFieldNestedCreateInput[] | null;
}

export interface ProjectSection_TournamentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface ProjectSection_TournamentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface ProjectSection_TournamentsFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: ProjectSection_TournamentsFieldNestedUpdateDataInput;
}

export interface ProjectSection_TournamentsFieldNestedUpsertInput {
  create: ProjectSection_TournamentsFieldNestedCreateInput;
  update: ProjectSection_TournamentsFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface ProjectSection_TournamentsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_TournamentsFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_TournamentsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_TournamentsFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: ProjectSection_TournamentsFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_TournamentsFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_TournamentsFieldWhereInput {
  every?: TournamentWhereInput | null;
  none?: TournamentWhereInput | null;
  some?: TournamentWhereInput | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: ProjectSection_Tournaments_relation_matchesFieldNestedCreateInput;
}

export interface ProjectSection_Tournaments_relation_matchesFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_matchesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_matchesFieldNestedCreateInput[] | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: ProjectSection_Tournaments_relation_matchesFieldNestedUpdateDataInput;
}

export interface ProjectSection_Tournaments_relation_matchesFieldNestedUpsertInput {
  create: ProjectSection_Tournaments_relation_matchesFieldNestedCreateInput;
  update: ProjectSection_Tournaments_relation_matchesFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface ProjectSection_Tournaments_relation_matchesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_matchesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_matchesFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: ProjectSection_Tournaments_relation_matchesFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_Tournaments_relation_matchesFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_Tournaments_relation_matchesFieldWhereInput {
  every?: TournamentWhereInput | null;
  none?: TournamentWhereInput | null;
  some?: TournamentWhereInput | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: ProjectSection_Tournaments_relation_stagesFieldNestedCreateInput;
}

export interface ProjectSection_Tournaments_relation_stagesFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_stagesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_stagesFieldNestedCreateInput[] | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: ProjectSection_Tournaments_relation_stagesFieldNestedUpdateDataInput;
}

export interface ProjectSection_Tournaments_relation_stagesFieldNestedUpsertInput {
  create: ProjectSection_Tournaments_relation_stagesFieldNestedCreateInput;
  update: ProjectSection_Tournaments_relation_stagesFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface ProjectSection_Tournaments_relation_stagesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_stagesFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_stagesFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: ProjectSection_Tournaments_relation_stagesFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_Tournaments_relation_stagesFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_Tournaments_relation_stagesFieldWhereInput {
  every?: TournamentWhereInput | null;
  none?: TournamentWhereInput | null;
  some?: TournamentWhereInput | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: ProjectSection_Tournaments_relation_teamsFieldNestedCreateInput;
}

export interface ProjectSection_Tournaments_relation_teamsFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_teamsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_teamsFieldNestedCreateInput[] | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: ProjectSection_Tournaments_relation_teamsFieldNestedUpdateDataInput;
}

export interface ProjectSection_Tournaments_relation_teamsFieldNestedUpsertInput {
  create: ProjectSection_Tournaments_relation_teamsFieldNestedCreateInput;
  update: ProjectSection_Tournaments_relation_teamsFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface ProjectSection_Tournaments_relation_teamsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_Tournaments_relation_teamsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_Tournaments_relation_teamsFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: ProjectSection_Tournaments_relation_teamsFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_Tournaments_relation_teamsFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_Tournaments_relation_teamsFieldWhereInput {
  every?: TournamentWhereInput | null;
  none?: TournamentWhereInput | null;
  some?: TournamentWhereInput | null;
}

export interface ProjectSection_TranslationsFieldConnectOrCreateInput {
  where: ProjectSectionTranslationWhereUniqueInput;
  create: ProjectSection_TranslationsFieldNestedCreateInput;
}

export interface ProjectSection_TranslationsFieldCreateInput {
  connect?: ProjectSectionTranslationWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_TranslationsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_TranslationsFieldNestedCreateInput[] | null;
}

export interface ProjectSection_TranslationsFieldNestedCreateInput {
  id?: number | null;
  locale: Locale;
  order?: number | null;
}

export interface ProjectSection_TranslationsFieldNestedUpdateDataInput {
  id?: number | null;
  locale?: Locale | null;
  order?: number | null;
}

export interface ProjectSection_TranslationsFieldNestedUpdateInput {
  where: ProjectSectionTranslationWhereUniqueInput;
  data: ProjectSection_TranslationsFieldNestedUpdateDataInput;
}

export interface ProjectSection_TranslationsFieldNestedUpsertInput {
  create: ProjectSection_TranslationsFieldNestedCreateInput;
  update: ProjectSection_TranslationsFieldNestedUpdateDataInput;
  where: ProjectSectionTranslationWhereUniqueInput;
}

export interface ProjectSection_TranslationsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectSection_TranslationsFieldUpdateInput {
  connect?: ProjectSectionTranslationWhereUniqueInput[] | null;
  connectOrCreate?: ProjectSection_TranslationsFieldConnectOrCreateInput[] | null;
  create?: ProjectSection_TranslationsFieldNestedCreateInput[] | null;
  delete?: ProjectSectionTranslationWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionTranslationWhereScalarInput | null;
  disconnect?: ProjectSectionTranslationWhereUniqueInput[] | null;
  set?: ProjectSectionTranslationWhereUniqueInput[] | null;
  update?: ProjectSection_TranslationsFieldNestedUpdateInput[] | null;
  upsert?: ProjectSection_TranslationsFieldNestedUpsertInput[] | null;
}

export interface ProjectSection_TranslationsFieldWhereInput {
  every?: ProjectSectionTranslationWhereInput | null;
  none?: ProjectSectionTranslationWhereInput | null;
  some?: ProjectSectionTranslationWhereInput | null;
}

export interface ProjectSection_TypeFieldWhereInput {
  equals?: ProjectSectionType | null;
  in?: ProjectSectionType[] | null;
  not?: ProjectSection_TypeFieldWhereInput | null;
  notIn?: ProjectSectionType[] | null;
}

export interface ProjectSection_TypeFieldWhereScalarInput {
  equals?: ProjectSectionType | null;
  in?: ProjectSectionType[] | null;
  not?: ProjectSection_TypeFieldWhereScalarInput | null;
  notIn?: ProjectSectionType[] | null;
}

export interface ProjectSection_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectSection_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectSection_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: ProjectSection_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface ProjectSection_VideoFieldConnectOrCreateInput {
  where: ProjectVideoSectionWhereUniqueInput;
  create: ProjectSection_VideoFieldNestedCreateInput;
}

export interface ProjectSection_VideoFieldCreateInput {
  connect?: ProjectVideoSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_VideoFieldConnectOrCreateInput | null;
  create?: ProjectSection_VideoFieldNestedCreateInput | null;
}

export interface ProjectSection_VideoFieldNestedCreateInput {
  id?: number | null;
  title?: string | null;
  videosPerRow?: number | null;
  links: string[];
}

export interface ProjectSection_VideoFieldNestedUpdateInput {
  id?: number | null;
  title?: string | null;
  videosPerRow?: number | null;
  links?: string[] | null;
}

export interface ProjectSection_VideoFieldNestedUpsertInput {
  create: ProjectSection_VideoFieldNestedCreateInput;
  update: ProjectSection_VideoFieldNestedUpdateInput;
}

export interface ProjectSection_VideoFieldUpdateInput {
  connect?: ProjectVideoSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_VideoFieldConnectOrCreateInput | null;
  create?: ProjectSection_VideoFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_VideoFieldNestedUpdateInput | null;
  upsert?: ProjectSection_VideoFieldNestedUpsertInput | null;
}

export interface ProjectSection_VotingFieldConnectOrCreateInput {
  where: ProjectVotingSectionWhereUniqueInput;
  create: ProjectSection_VotingFieldNestedCreateInput;
}

export interface ProjectSection_VotingFieldCreateInput {
  connect?: ProjectVotingSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_VotingFieldConnectOrCreateInput | null;
  create?: ProjectSection_VotingFieldNestedCreateInput | null;
}

export interface ProjectSection_VotingFieldNestedCreateInput {
  id?: number | null;
  tournamentId: number;
  title: string;
  slotCount: number;
  emailSubject?: string | null;
  emailText?: string | null;
  tournament?: ProjectVotingSection_TournamentFieldCreateInput | null;
  votes?: ProjectVotingSection_VotesFieldCreateInput | null;
}

export interface ProjectSection_VotingFieldNestedUpdateInput {
  id?: number | null;
  tournamentId?: number | null;
  title?: string | null;
  slotCount?: number | null;
  emailSubject?: string | null;
  emailText?: string | null;
  tournament?: ProjectVotingSection_TournamentFieldUpdateInput | null;
  votes?: ProjectVotingSection_VotesFieldUpdateInput | null;
}

export interface ProjectSection_VotingFieldNestedUpsertInput {
  create: ProjectSection_VotingFieldNestedCreateInput;
  update: ProjectSection_VotingFieldNestedUpdateInput;
}

export interface ProjectSection_VotingFieldUpdateInput {
  connect?: ProjectVotingSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectSection_VotingFieldConnectOrCreateInput | null;
  create?: ProjectSection_VotingFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectSection_VotingFieldNestedUpdateInput | null;
  upsert?: ProjectSection_VotingFieldNestedUpsertInput | null;
}

export interface ProjectTextSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  title?: SortOrder | null;
  image?: SortOrder | null;
  imagePosition?: SortOrder | null;
  buttonLink?: SortOrder | null;
  buttonText?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
  text?: SortOrder | null;
}

export interface ProjectTextSectionWhereInput {
  OR?: (ProjectTextSectionWhereInput | null)[] | null;
  AND?: (ProjectTextSectionWhereInput | null)[] | null;
  NOT?: (ProjectTextSectionWhereInput | null)[] | null;
  id?: ProjectTextSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectTextSection_ProjectSectionIdFieldWhereInput | null;
  title?: ProjectTextSection_TitleFieldWhereInput | null;
  image?: ProjectTextSection_ImageFieldWhereInput | null;
  imagePosition?: ProjectTextSection_ImagePositionFieldWhereInput | null;
  buttonLink?: ProjectTextSection_ButtonLinkFieldWhereInput | null;
  buttonText?: ProjectTextSection_ButtonTextFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
  text?: ProjectTextSection_TextFieldWhereInput | null;
}

export interface ProjectTextSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectTextSection_ButtonLinkFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTextSection_ButtonLinkFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTextSection_ButtonTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTextSection_ButtonTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTextSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTextSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectTextSection_ImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTextSection_ImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTextSection_ImagePositionFieldWhereInput {
  equals?: ProjectSectionImagePosition | null;
  in?: ProjectSectionImagePosition[] | null;
  not?: ProjectTextSection_ImagePositionFieldWhereInput | null;
  notIn?: ProjectSectionImagePosition[] | null;
}

export interface ProjectTextSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTextSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectTextSection_TextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTextSection_TextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTextSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTextSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTranslationCreateInput {
  id?: number | null;
  projectId?: number | null;
  locale: Locale;
  name: string;
  project?: ProjectTranslation_ProjectFieldCreateInput | null;
}

export interface ProjectTranslationUpdateInput {
  id?: number | null;
  projectId?: number | null;
  locale?: Locale | null;
  name?: string | null;
  project?: ProjectTranslation_ProjectFieldUpdateInput | null;
}

export interface ProjectTranslationWhereInput {
  OR?: (ProjectTranslationWhereInput | null)[] | null;
  AND?: (ProjectTranslationWhereInput | null)[] | null;
  NOT?: (ProjectTranslationWhereInput | null)[] | null;
  id?: ProjectTranslation_IdFieldWhereInput | null;
  projectId?: ProjectTranslation_ProjectIdFieldWhereInput | null;
  locale?: ProjectTranslation_LocaleFieldWhereInput | null;
  name?: ProjectTranslation_NameFieldWhereInput | null;
  project?: ProjectWhereInput | null;
}

export interface ProjectTranslationWhereScalarInput {
  OR?: (ProjectTranslationWhereScalarInput | null)[] | null;
  AND?: (ProjectTranslationWhereScalarInput | null)[] | null;
  NOT?: (ProjectTranslationWhereScalarInput | null)[] | null;
  id?: ProjectTranslation_IdFieldWhereScalarInput | null;
  projectId?: ProjectTranslation_ProjectIdFieldWhereScalarInput | null;
  locale?: ProjectTranslation_LocaleFieldWhereScalarInput | null;
  name?: ProjectTranslation_NameFieldWhereScalarInput | null;
}

export interface ProjectTranslationWhereUniqueInput {
  id: number;
}

export interface ProjectTranslation_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTranslation_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectTranslation_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTranslation_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectTranslation_LocaleFieldWhereInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectTranslation_LocaleFieldWhereInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectTranslation_LocaleFieldWhereScalarInput {
  equals?: Locale | null;
  in?: Locale[] | null;
  not?: ProjectTranslation_LocaleFieldWhereScalarInput | null;
  notIn?: Locale[] | null;
}

export interface ProjectTranslation_NameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTranslation_NameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTranslation_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectTranslation_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectTranslation_ProjectFieldConnectOrCreateInput {
  where: ProjectWhereUniqueInput;
  create: ProjectTranslation_ProjectFieldNestedCreateInput;
}

export interface ProjectTranslation_ProjectFieldCreateInput {
  connect?: ProjectWhereUniqueInput | null;
  connectOrCreate?: ProjectTranslation_ProjectFieldConnectOrCreateInput | null;
  create?: ProjectTranslation_ProjectFieldNestedCreateInput | null;
}

export interface ProjectTranslation_ProjectFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldCreateInput | null;
  projectSections?: Project_ProjectSectionsFieldCreateInput | null;
}

export interface ProjectTranslation_ProjectFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldUpdateInput | null;
  projectSections?: Project_ProjectSectionsFieldUpdateInput | null;
}

export interface ProjectTranslation_ProjectFieldNestedUpsertInput {
  create: ProjectTranslation_ProjectFieldNestedCreateInput;
  update: ProjectTranslation_ProjectFieldNestedUpdateInput;
}

export interface ProjectTranslation_ProjectFieldUpdateInput {
  connect?: ProjectWhereUniqueInput | null;
  connectOrCreate?: ProjectTranslation_ProjectFieldConnectOrCreateInput | null;
  create?: ProjectTranslation_ProjectFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectTranslation_ProjectFieldNestedUpdateInput | null;
  upsert?: ProjectTranslation_ProjectFieldNestedUpsertInput | null;
}

export interface ProjectTranslation_ProjectIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTranslation_ProjectIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectTranslation_ProjectIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectTranslation_ProjectIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  published?: boolean | null;
  link?: string | null;
  contactEmail?: string | null;
  coverImage?: string | null;
  matchesDisplayCount?: number | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldUpdateInput | null;
  translations?: Project_TranslationsFieldUpdateInput | null;
  projectSections?: Project_ProjectSectionsFieldUpdateInput | null;
}

export interface ProjectVideoSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  title?: SortOrder | null;
  videosPerRow?: SortOrder | null;
  links?: SortOrder | null;
  projectSection?: ProjectSectionOrderByInput | null;
}

export interface ProjectVideoSectionWhereInput {
  OR?: (ProjectVideoSectionWhereInput | null)[] | null;
  AND?: (ProjectVideoSectionWhereInput | null)[] | null;
  NOT?: (ProjectVideoSectionWhereInput | null)[] | null;
  id?: ProjectVideoSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectVideoSection_ProjectSectionIdFieldWhereInput | null;
  title?: ProjectVideoSection_TitleFieldWhereInput | null;
  videosPerRow?: ProjectVideoSection_VideosPerRowFieldWhereInput | null;
  links?: ProjectVideoSection_LinksFieldWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
}

export interface ProjectVideoSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectVideoSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVideoSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVideoSection_LinksFieldWhereInput {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface ProjectVideoSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVideoSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVideoSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVideoSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVideoSection_VideosPerRowFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVideoSection_VideosPerRowFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSectionOrderByInput {
  id?: SortOrder | null;
  projectSectionId?: SortOrder | null;
  tournamentId?: SortOrder | null;
  title?: SortOrder | null;
  slotCount?: SortOrder | null;
  emailSubject?: SortOrder | null;
  emailText?: SortOrder | null;
  tournament?: ProjectVotingSection_TournamentFieldOrderByInput | null;
  projectSection?: ProjectSectionOrderByInput | null;
  votes?: ProjectVotingSection_VotesFieldOrderByInput | null;
}

export interface ProjectVotingSectionVoteCreateInput {
  id?: number | null;
  projectVotingSectionId?: number | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  teams: number[];
  projectVotingSection?: ProjectVotingSectionVote_ProjectVotingSectionFieldCreateInput | null;
}

export interface ProjectVotingSectionVoteOrderByInput {
  id?: SortOrder | null;
  projectVotingSectionId?: SortOrder | null;
  firstName?: SortOrder | null;
  lastName?: SortOrder | null;
  email?: SortOrder | null;
  phone?: SortOrder | null;
  teams?: SortOrder | null;
  projectVotingSection?: ProjectVotingSectionVote_ProjectVotingSectionFieldOrderByInput | null;
}

export interface ProjectVotingSectionVoteWhereInput {
  OR?: (ProjectVotingSectionVoteWhereInput | null)[] | null;
  AND?: (ProjectVotingSectionVoteWhereInput | null)[] | null;
  NOT?: (ProjectVotingSectionVoteWhereInput | null)[] | null;
  id?: ProjectVotingSectionVote_IdFieldWhereInput | null;
  projectVotingSectionId?: ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereInput | null;
  firstName?: ProjectVotingSectionVote_FirstNameFieldWhereInput | null;
  lastName?: ProjectVotingSectionVote_LastNameFieldWhereInput | null;
  email?: ProjectVotingSectionVote_EmailFieldWhereInput | null;
  phone?: ProjectVotingSectionVote_PhoneFieldWhereInput | null;
  teams?: ProjectVotingSectionVote_TeamsFieldWhereInput | null;
  projectVotingSection?: ProjectVotingSectionWhereInput | null;
}

export interface ProjectVotingSectionVoteWhereScalarInput {
  OR?: (ProjectVotingSectionVoteWhereScalarInput | null)[] | null;
  AND?: (ProjectVotingSectionVoteWhereScalarInput | null)[] | null;
  NOT?: (ProjectVotingSectionVoteWhereScalarInput | null)[] | null;
  id?: ProjectVotingSectionVote_IdFieldWhereScalarInput | null;
  projectVotingSectionId?: ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereScalarInput | null;
  firstName?: ProjectVotingSectionVote_FirstNameFieldWhereScalarInput | null;
  lastName?: ProjectVotingSectionVote_LastNameFieldWhereScalarInput | null;
  email?: ProjectVotingSectionVote_EmailFieldWhereScalarInput | null;
  phone?: ProjectVotingSectionVote_PhoneFieldWhereScalarInput | null;
  teams?: ProjectVotingSectionVote_TeamsFieldWhereScalarInput | null;
}

export interface ProjectVotingSectionVoteWhereUniqueInput {
  id: number;
}

export interface ProjectVotingSectionVote_EmailFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_EmailFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_EmailFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_EmailFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_FirstNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_FirstNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_FirstNameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_FirstNameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSectionVote_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSectionVote_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSectionVote_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSectionVote_LastNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_LastNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_LastNameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_LastNameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_PhoneFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_PhoneFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_PhoneFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSectionVote_PhoneFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionFieldConnectOrCreateInput {
  where: ProjectVotingSectionWhereUniqueInput;
  create: ProjectVotingSectionVote_ProjectVotingSectionFieldNestedCreateInput;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionFieldCreateInput {
  connect?: ProjectVotingSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectVotingSectionVote_ProjectVotingSectionFieldConnectOrCreateInput | null;
  create?: ProjectVotingSectionVote_ProjectVotingSectionFieldNestedCreateInput | null;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionFieldNestedCreateInput {
  id?: number | null;
  projectSectionId: number;
  tournamentId: number;
  title: string;
  slotCount: number;
  emailSubject?: string | null;
  emailText?: string | null;
  tournament?: ProjectVotingSection_TournamentFieldCreateInput | null;
  projectSection?: ProjectVotingSection_ProjectSectionFieldCreateInput | null;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSectionVote_ProjectVotingSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSectionVote_TeamsFieldWhereInput {
  equals?: number[] | null;
  has?: number | null;
  hasEvery?: number[] | null;
  hasSome?: number[] | null;
  isEmpty?: boolean | null;
}

export interface ProjectVotingSectionVote_TeamsFieldWhereScalarInput {
  equals?: number[] | null;
  has?: number | null;
  hasEvery?: number[] | null;
  hasSome?: number[] | null;
  isEmpty?: boolean | null;
}

export interface ProjectVotingSectionWhereInput {
  OR?: (ProjectVotingSectionWhereInput | null)[] | null;
  AND?: (ProjectVotingSectionWhereInput | null)[] | null;
  NOT?: (ProjectVotingSectionWhereInput | null)[] | null;
  id?: ProjectVotingSection_IdFieldWhereInput | null;
  projectSectionId?: ProjectVotingSection_ProjectSectionIdFieldWhereInput | null;
  tournamentId?: ProjectVotingSection_TournamentIdFieldWhereInput | null;
  title?: ProjectVotingSection_TitleFieldWhereInput | null;
  slotCount?: ProjectVotingSection_SlotCountFieldWhereInput | null;
  emailSubject?: ProjectVotingSection_EmailSubjectFieldWhereInput | null;
  emailText?: ProjectVotingSection_EmailTextFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  projectSection?: ProjectSectionWhereInput | null;
  votes?: ProjectVotingSection_VotesFieldWhereInput | null;
}

export interface ProjectVotingSectionWhereScalarInput {
  OR?: (ProjectVotingSectionWhereScalarInput | null)[] | null;
  AND?: (ProjectVotingSectionWhereScalarInput | null)[] | null;
  NOT?: (ProjectVotingSectionWhereScalarInput | null)[] | null;
  id?: ProjectVotingSection_IdFieldWhereScalarInput | null;
  projectSectionId?: ProjectVotingSection_ProjectSectionIdFieldWhereScalarInput | null;
  tournamentId?: ProjectVotingSection_TournamentIdFieldWhereScalarInput | null;
  title?: ProjectVotingSection_TitleFieldWhereScalarInput | null;
  slotCount?: ProjectVotingSection_SlotCountFieldWhereScalarInput | null;
  emailSubject?: ProjectVotingSection_EmailSubjectFieldWhereScalarInput | null;
  emailText?: ProjectVotingSection_EmailTextFieldWhereScalarInput | null;
}

export interface ProjectVotingSectionWhereUniqueInput {
  id?: number | null;
  projectSectionId?: number | null;
}

export interface ProjectVotingSection_EmailSubjectFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_EmailSubjectFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_EmailSubjectFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_EmailSubjectFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_EmailTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_EmailTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_EmailTextFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_EmailTextFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_ProjectSectionFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: ProjectVotingSection_ProjectSectionFieldNestedCreateInput;
}

export interface ProjectVotingSection_ProjectSectionFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectVotingSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectVotingSection_ProjectSectionFieldNestedCreateInput | null;
}

export interface ProjectVotingSection_ProjectSectionFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface ProjectVotingSection_ProjectSectionFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface ProjectVotingSection_ProjectSectionFieldNestedUpsertInput {
  create: ProjectVotingSection_ProjectSectionFieldNestedCreateInput;
  update: ProjectVotingSection_ProjectSectionFieldNestedUpdateInput;
}

export interface ProjectVotingSection_ProjectSectionFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput | null;
  connectOrCreate?: ProjectVotingSection_ProjectSectionFieldConnectOrCreateInput | null;
  create?: ProjectVotingSection_ProjectSectionFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectVotingSection_ProjectSectionFieldNestedUpdateInput | null;
  upsert?: ProjectVotingSection_ProjectSectionFieldNestedUpsertInput | null;
}

export interface ProjectVotingSection_ProjectSectionIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_ProjectSectionIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_ProjectSectionIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_ProjectSectionIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_SlotCountFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_SlotCountFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_SlotCountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_SlotCountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_TitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_TitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: ProjectVotingSection_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface ProjectVotingSection_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: ProjectVotingSection_TournamentFieldNestedCreateInput;
}

export interface ProjectVotingSection_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: ProjectVotingSection_TournamentFieldConnectOrCreateInput | null;
  create?: ProjectVotingSection_TournamentFieldNestedCreateInput | null;
}

export interface ProjectVotingSection_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
}

export interface ProjectVotingSection_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
}

export interface ProjectVotingSection_TournamentFieldNestedUpsertInput {
  create: ProjectVotingSection_TournamentFieldNestedCreateInput;
  update: ProjectVotingSection_TournamentFieldNestedUpdateInput;
}

export interface ProjectVotingSection_TournamentFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectVotingSection_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: ProjectVotingSection_TournamentFieldConnectOrCreateInput | null;
  create?: ProjectVotingSection_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ProjectVotingSection_TournamentFieldNestedUpdateInput | null;
  upsert?: ProjectVotingSection_TournamentFieldNestedUpsertInput | null;
}

export interface ProjectVotingSection_TournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_TournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: ProjectVotingSection_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface ProjectVotingSection_VotesFieldConnectOrCreateInput {
  where: ProjectVotingSectionVoteWhereUniqueInput;
  create: ProjectVotingSection_VotesFieldNestedCreateInput;
}

export interface ProjectVotingSection_VotesFieldCreateInput {
  connect?: ProjectVotingSectionVoteWhereUniqueInput[] | null;
  connectOrCreate?: ProjectVotingSection_VotesFieldConnectOrCreateInput[] | null;
  create?: ProjectVotingSection_VotesFieldNestedCreateInput[] | null;
}

export interface ProjectVotingSection_VotesFieldNestedCreateInput {
  id?: number | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  teams: number[];
}

export interface ProjectVotingSection_VotesFieldNestedUpdateDataInput {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  teams?: number[] | null;
}

export interface ProjectVotingSection_VotesFieldNestedUpdateInput {
  where: ProjectVotingSectionVoteWhereUniqueInput;
  data: ProjectVotingSection_VotesFieldNestedUpdateDataInput;
}

export interface ProjectVotingSection_VotesFieldNestedUpsertInput {
  create: ProjectVotingSection_VotesFieldNestedCreateInput;
  update: ProjectVotingSection_VotesFieldNestedUpdateDataInput;
  where: ProjectVotingSectionVoteWhereUniqueInput;
}

export interface ProjectVotingSection_VotesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface ProjectVotingSection_VotesFieldUpdateInput {
  connect?: ProjectVotingSectionVoteWhereUniqueInput[] | null;
  connectOrCreate?: ProjectVotingSection_VotesFieldConnectOrCreateInput[] | null;
  create?: ProjectVotingSection_VotesFieldNestedCreateInput[] | null;
  delete?: ProjectVotingSectionVoteWhereUniqueInput[] | null;
  deleteMany?: ProjectVotingSectionVoteWhereScalarInput | null;
  disconnect?: ProjectVotingSectionVoteWhereUniqueInput[] | null;
  set?: ProjectVotingSectionVoteWhereUniqueInput[] | null;
  update?: ProjectVotingSection_VotesFieldNestedUpdateInput[] | null;
  upsert?: ProjectVotingSection_VotesFieldNestedUpsertInput[] | null;
}

export interface ProjectVotingSection_VotesFieldWhereInput {
  every?: ProjectVotingSectionVoteWhereInput | null;
  none?: ProjectVotingSectionVoteWhereInput | null;
  some?: ProjectVotingSectionVoteWhereInput | null;
}

export interface ProjectWhereInput {
  OR?: (ProjectWhereInput | null)[] | null;
  AND?: (ProjectWhereInput | null)[] | null;
  NOT?: (ProjectWhereInput | null)[] | null;
  id?: Project_IdFieldWhereInput | null;
  createdAt?: Project_CreatedAtFieldWhereInput | null;
  updatedAt?: Project_UpdatedAtFieldWhereInput | null;
  published?: Project_PublishedFieldWhereInput | null;
  link?: Project_LinkFieldWhereInput | null;
  contactEmail?: Project_ContactEmailFieldWhereInput | null;
  coverImage?: Project_CoverImageFieldWhereInput | null;
  matchesDisplayCount?: Project_MatchesDisplayCountFieldWhereInput | null;
  contactFormSubmissions?: Project_ContactFormSubmissionsFieldWhereInput | null;
  translations?: Project_TranslationsFieldWhereInput | null;
  projectSections?: Project_ProjectSectionsFieldWhereInput | null;
}

export interface ProjectWhereUniqueInput {
  id?: number | null;
  link?: string | null;
}

export interface Project_ContactEmailFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Project_ContactEmailFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Project_ContactFormSubmissionsFieldConnectOrCreateInput {
  where: ProjectContactFormSubmissionWhereUniqueInput;
  create: Project_ContactFormSubmissionsFieldNestedCreateInput;
}

export interface Project_ContactFormSubmissionsFieldCreateInput {
  connect?: ProjectContactFormSubmissionWhereUniqueInput[] | null;
  connectOrCreate?: Project_ContactFormSubmissionsFieldConnectOrCreateInput[] | null;
  create?: Project_ContactFormSubmissionsFieldNestedCreateInput[] | null;
}

export interface Project_ContactFormSubmissionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payload?: any | null;
}

export interface Project_ContactFormSubmissionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payload?: any | null;
}

export interface Project_ContactFormSubmissionsFieldNestedUpdateInput {
  where: ProjectContactFormSubmissionWhereUniqueInput;
  data: Project_ContactFormSubmissionsFieldNestedUpdateDataInput;
}

export interface Project_ContactFormSubmissionsFieldNestedUpsertInput {
  create: Project_ContactFormSubmissionsFieldNestedCreateInput;
  update: Project_ContactFormSubmissionsFieldNestedUpdateDataInput;
  where: ProjectContactFormSubmissionWhereUniqueInput;
}

export interface Project_ContactFormSubmissionsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface Project_ContactFormSubmissionsFieldUpdateInput {
  connect?: ProjectContactFormSubmissionWhereUniqueInput[] | null;
  connectOrCreate?: Project_ContactFormSubmissionsFieldConnectOrCreateInput[] | null;
  create?: Project_ContactFormSubmissionsFieldNestedCreateInput[] | null;
  delete?: ProjectContactFormSubmissionWhereUniqueInput[] | null;
  deleteMany?: ProjectContactFormSubmissionWhereScalarInput | null;
  disconnect?: ProjectContactFormSubmissionWhereUniqueInput[] | null;
  set?: ProjectContactFormSubmissionWhereUniqueInput[] | null;
  update?: Project_ContactFormSubmissionsFieldNestedUpdateInput[] | null;
  upsert?: Project_ContactFormSubmissionsFieldNestedUpsertInput[] | null;
}

export interface Project_ContactFormSubmissionsFieldWhereInput {
  every?: ProjectContactFormSubmissionWhereInput | null;
  none?: ProjectContactFormSubmissionWhereInput | null;
  some?: ProjectContactFormSubmissionWhereInput | null;
}

export interface Project_CoverImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Project_CoverImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Project_CreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Project_CreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Project_IdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Project_IdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Project_LinkFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Project_LinkFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Project_MatchesDisplayCountFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Project_MatchesDisplayCountFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface Project_ProjectSectionsFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: Project_ProjectSectionsFieldNestedCreateInput;
}

export interface Project_ProjectSectionsFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Project_ProjectSectionsFieldConnectOrCreateInput[] | null;
  create?: Project_ProjectSectionsFieldNestedCreateInput[] | null;
}

export interface Project_ProjectSectionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments?: ProjectSection_TournamentsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface Project_ProjectSectionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments?: ProjectSection_TournamentsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface Project_ProjectSectionsFieldNestedUpdateInput {
  where: ProjectSectionWhereUniqueInput;
  data: Project_ProjectSectionsFieldNestedUpdateDataInput;
}

export interface Project_ProjectSectionsFieldNestedUpsertInput {
  create: Project_ProjectSectionsFieldNestedCreateInput;
  update: Project_ProjectSectionsFieldNestedUpdateDataInput;
  where: ProjectSectionWhereUniqueInput;
}

export interface Project_ProjectSectionsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface Project_ProjectSectionsFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Project_ProjectSectionsFieldConnectOrCreateInput[] | null;
  create?: Project_ProjectSectionsFieldNestedCreateInput[] | null;
  delete?: ProjectSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionWhereScalarInput | null;
  disconnect?: ProjectSectionWhereUniqueInput[] | null;
  set?: ProjectSectionWhereUniqueInput[] | null;
  update?: Project_ProjectSectionsFieldNestedUpdateInput[] | null;
  upsert?: Project_ProjectSectionsFieldNestedUpsertInput[] | null;
}

export interface Project_ProjectSectionsFieldWhereInput {
  every?: ProjectSectionWhereInput | null;
  none?: ProjectSectionWhereInput | null;
  some?: ProjectSectionWhereInput | null;
}

export interface Project_PublishedFieldWhereInput {
  equals?: boolean | null;
  not?: Project_PublishedFieldWhereInput | null;
}

export interface Project_TranslationsFieldConnectOrCreateInput {
  where: ProjectTranslationWhereUniqueInput;
  create: Project_TranslationsFieldNestedCreateInput;
}

export interface Project_TranslationsFieldCreateInput {
  connect?: ProjectTranslationWhereUniqueInput[] | null;
  connectOrCreate?: Project_TranslationsFieldConnectOrCreateInput[] | null;
  create?: Project_TranslationsFieldNestedCreateInput[] | null;
}

export interface Project_TranslationsFieldNestedCreateInput {
  id?: number | null;
  locale: Locale;
  name: string;
}

export interface Project_TranslationsFieldNestedUpdateDataInput {
  id?: number | null;
  locale?: Locale | null;
  name?: string | null;
}

export interface Project_TranslationsFieldNestedUpdateInput {
  where: ProjectTranslationWhereUniqueInput;
  data: Project_TranslationsFieldNestedUpdateDataInput;
}

export interface Project_TranslationsFieldNestedUpsertInput {
  create: Project_TranslationsFieldNestedCreateInput;
  update: Project_TranslationsFieldNestedUpdateDataInput;
  where: ProjectTranslationWhereUniqueInput;
}

export interface Project_TranslationsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface Project_TranslationsFieldUpdateInput {
  connect?: ProjectTranslationWhereUniqueInput[] | null;
  connectOrCreate?: Project_TranslationsFieldConnectOrCreateInput[] | null;
  create?: Project_TranslationsFieldNestedCreateInput[] | null;
  delete?: ProjectTranslationWhereUniqueInput[] | null;
  deleteMany?: ProjectTranslationWhereScalarInput | null;
  disconnect?: ProjectTranslationWhereUniqueInput[] | null;
  set?: ProjectTranslationWhereUniqueInput[] | null;
  update?: Project_TranslationsFieldNestedUpdateInput[] | null;
  upsert?: Project_TranslationsFieldNestedUpsertInput[] | null;
}

export interface Project_TranslationsFieldWhereInput {
  every?: ProjectTranslationWhereInput | null;
  none?: ProjectTranslationWhereInput | null;
  some?: ProjectTranslationWhereInput | null;
}

export interface Project_UpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Project_UpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface RedirectIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: RedirectIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface RedirectInput {
  url: string;
  redirectUrl: string;
}

export interface RedirectRedirectUrlFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: RedirectRedirectUrlFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface RedirectUrlFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: RedirectUrlFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface RedirectWhereInput {
  id?: RedirectIdFieldWhereInput | null;
  redirectUrl?: RedirectRedirectUrlFieldWhereInput | null;
  url?: RedirectUrlFieldWhereInput | null;
  AND?: RedirectWhereLogicAndInput[] | null;
  NOT?: RedirectWhereLogicNotInput[] | null;
  OR?: RedirectWhereLogicOrInput[] | null;
}

export interface RedirectWhereLogicAndInput {
  id?: RedirectIdFieldWhereInput | null;
  redirectUrl?: RedirectRedirectUrlFieldWhereInput | null;
  url?: RedirectUrlFieldWhereInput | null;
  AND?: RedirectWhereLogicAndInput[] | null;
  NOT?: RedirectWhereLogicNotInput[] | null;
  OR?: RedirectWhereLogicOrInput[] | null;
}

export interface RedirectWhereLogicNotInput {
  id?: RedirectIdFieldWhereInput | null;
  redirectUrl?: RedirectRedirectUrlFieldWhereInput | null;
  url?: RedirectUrlFieldWhereInput | null;
  AND?: RedirectWhereLogicAndInput[] | null;
  NOT?: RedirectWhereLogicNotInput[] | null;
  OR?: RedirectWhereLogicOrInput[] | null;
}

export interface RedirectWhereLogicOrInput {
  id?: RedirectIdFieldWhereInput | null;
  redirectUrl?: RedirectRedirectUrlFieldWhereInput | null;
  url?: RedirectUrlFieldWhereInput | null;
  AND?: RedirectWhereLogicAndInput[] | null;
  NOT?: RedirectWhereLogicNotInput[] | null;
  OR?: RedirectWhereLogicOrInput[] | null;
}

export interface RedirectWhereUniqueInput {
  id: number;
}

export interface ReorderProjectSectionInput {
  id: number;
  down: boolean;
  locale: Locale;
}

export interface TagCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TagCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TagIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TagIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TagInput {
  name?: string | null;
}

export interface TagNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TagNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TagOrderByInput {
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
}

export interface TagWhereInput {
  id?: TagIdFieldWhereInput | null;
  createdAt?: TagCreatedAtFieldWhereInput | null;
  name?: TagNameFieldWhereInput | null;
  AND?: TagWhereLogicAndInput[] | null;
  NOT?: TagWhereLogicNotInput[] | null;
  OR?: TagWhereLogicOrInput[] | null;
}

export interface TagWhereLogicAndInput {
  id?: TagIdFieldWhereInput | null;
  createdAt?: TagCreatedAtFieldWhereInput | null;
  name?: TagNameFieldWhereInput | null;
  AND?: TagWhereLogicAndInput[] | null;
  NOT?: TagWhereLogicNotInput[] | null;
  OR?: TagWhereLogicOrInput[] | null;
}

export interface TagWhereLogicNotInput {
  id?: TagIdFieldWhereInput | null;
  createdAt?: TagCreatedAtFieldWhereInput | null;
  name?: TagNameFieldWhereInput | null;
  AND?: TagWhereLogicAndInput[] | null;
  NOT?: TagWhereLogicNotInput[] | null;
  OR?: TagWhereLogicOrInput[] | null;
}

export interface TagWhereLogicOrInput {
  id?: TagIdFieldWhereInput | null;
  createdAt?: TagCreatedAtFieldWhereInput | null;
  name?: TagNameFieldWhereInput | null;
  AND?: TagWhereLogicAndInput[] | null;
  NOT?: TagWhereLogicNotInput[] | null;
  OR?: TagWhereLogicOrInput[] | null;
}

export interface TagWhereUniqueInput {
  id?: number | null;
  name?: string | null;
}

export interface TeamInput {
  id?: number | null;
  name: string;
  logo?: string | null;
  players?: any | null;
  gameId?: number | null;
}

export interface TeamInvitationWhereScalarInput {
  OR?: (TeamInvitationWhereScalarInput | null)[] | null;
  AND?: (TeamInvitationWhereScalarInput | null)[] | null;
  NOT?: (TeamInvitationWhereScalarInput | null)[] | null;
  id?: TeamInvitation_IdFieldWhereScalarInput | null;
  createdAt?: TeamInvitation_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TeamInvitation_UpdatedAtFieldWhereScalarInput | null;
  email?: TeamInvitation_EmailFieldWhereScalarInput | null;
  type?: TeamInvitation_TypeFieldWhereScalarInput | null;
  teamId?: TeamInvitation_TeamIdFieldWhereScalarInput | null;
  confirmed?: TeamInvitation_ConfirmedFieldWhereScalarInput | null;
}

export interface TeamInvitationWhereUniqueInput {
  id: number;
}

export interface TeamInvitation_ConfirmedFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TeamInvitation_ConfirmedFieldWhereScalarInput | null;
}

export interface TeamInvitation_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TeamInvitation_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TeamInvitation_EmailFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TeamInvitation_EmailFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TeamInvitation_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TeamInvitation_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TeamInvitation_TeamIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TeamInvitation_TeamIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TeamInvitation_TypeFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TeamInvitation_TypeFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TeamInvitation_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TeamInvitation_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TeamWhereScalarInput {
  OR?: (TeamWhereScalarInput | null)[] | null;
  AND?: (TeamWhereScalarInput | null)[] | null;
  NOT?: (TeamWhereScalarInput | null)[] | null;
  id?: Team_IdFieldWhereScalarInput | null;
  createdAt?: Team_CreatedAtFieldWhereScalarInput | null;
  gameId?: Team_GameIdFieldWhereScalarInput | null;
  name?: Team_NameFieldWhereScalarInput | null;
  ownerId?: Team_OwnerIdFieldWhereScalarInput | null;
  players?: Team_PlayersFieldWhereScalarInput | null;
  logo?: Team_LogoFieldWhereScalarInput | null;
  updatedAt?: Team_UpdatedAtFieldWhereScalarInput | null;
}

export interface TeamWhereUniqueInput {
  id: number;
}

export interface Team_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Team_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Team_GameFieldConnectOrCreateInput {
  where: GameWhereUniqueInput;
  create: Team_GameFieldNestedCreateInput;
}

export interface Team_GameFieldCreateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: Team_GameFieldConnectOrCreateInput | null;
  create?: Team_GameFieldNestedCreateInput | null;
}

export interface Team_GameFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags: string[];
  gameId?: Game_GameIdFieldCreateInput | null;
  maps?: Game_MapsFieldCreateInput | null;
  tournaments?: Game_TournamentsFieldCreateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldCreateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldCreateInput | null;
}

export interface Team_GameFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags?: string[] | null;
  gameId?: Game_GameIdFieldUpdateInput | null;
  maps?: Game_MapsFieldUpdateInput | null;
  tournaments?: Game_TournamentsFieldUpdateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldUpdateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldUpdateInput | null;
}

export interface Team_GameFieldNestedUpsertInput {
  create: Team_GameFieldNestedCreateInput;
  update: Team_GameFieldNestedUpdateInput;
}

export interface Team_GameFieldUpdateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: Team_GameFieldConnectOrCreateInput | null;
  create?: Team_GameFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Team_GameFieldNestedUpdateInput | null;
  upsert?: Team_GameFieldNestedUpsertInput | null;
}

export interface Team_GameIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Team_GameIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Team_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Team_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Team_InvitationsFieldConnectOrCreateInput {
  where: TeamInvitationWhereUniqueInput;
  create: Team_InvitationsFieldNestedCreateInput;
}

export interface Team_InvitationsFieldCreateInput {
  connect?: TeamInvitationWhereUniqueInput[] | null;
  connectOrCreate?: Team_InvitationsFieldConnectOrCreateInput[] | null;
  create?: Team_InvitationsFieldNestedCreateInput[] | null;
}

export interface Team_InvitationsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  type?: string | null;
  confirmed?: boolean | null;
}

export interface Team_InvitationsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  type?: string | null;
  confirmed?: boolean | null;
}

export interface Team_InvitationsFieldNestedUpdateInput {
  where: TeamInvitationWhereUniqueInput;
  data: Team_InvitationsFieldNestedUpdateDataInput;
}

export interface Team_InvitationsFieldNestedUpsertInput {
  create: Team_InvitationsFieldNestedCreateInput;
  update: Team_InvitationsFieldNestedUpdateDataInput;
  where: TeamInvitationWhereUniqueInput;
}

export interface Team_InvitationsFieldUpdateInput {
  connect?: TeamInvitationWhereUniqueInput[] | null;
  connectOrCreate?: Team_InvitationsFieldConnectOrCreateInput[] | null;
  create?: Team_InvitationsFieldNestedCreateInput[] | null;
  delete?: TeamInvitationWhereUniqueInput[] | null;
  deleteMany?: TeamInvitationWhereScalarInput | null;
  disconnect?: TeamInvitationWhereUniqueInput[] | null;
  set?: TeamInvitationWhereUniqueInput[] | null;
  update?: Team_InvitationsFieldNestedUpdateInput[] | null;
  upsert?: Team_InvitationsFieldNestedUpsertInput[] | null;
}

export interface Team_LogoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Team_LogoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Team_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Team_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Team_OwnerFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: Team_OwnerFieldNestedCreateInput;
}

export interface Team_OwnerFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Team_OwnerFieldConnectOrCreateInput | null;
  create?: Team_OwnerFieldNestedCreateInput | null;
}

export interface Team_OwnerFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  articles?: User_ArticlesFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface Team_OwnerFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  articles?: User_ArticlesFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface Team_OwnerFieldNestedUpsertInput {
  create: Team_OwnerFieldNestedCreateInput;
  update: Team_OwnerFieldNestedUpdateInput;
}

export interface Team_OwnerFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: Team_OwnerFieldConnectOrCreateInput | null;
  create?: Team_OwnerFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Team_OwnerFieldNestedUpdateInput | null;
  upsert?: Team_OwnerFieldNestedUpsertInput | null;
}

export interface Team_OwnerIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Team_OwnerIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Team_PlayersFieldWhereScalarInput {
  equals?: any | null;
  not?: Team_PlayersFieldWhereScalarInput | null;
}

export interface Team_TournamentTeamsFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: Team_TournamentTeamsFieldNestedCreateInput;
}

export interface Team_TournamentTeamsFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: Team_TournamentTeamsFieldConnectOrCreateInput[] | null;
  create?: Team_TournamentTeamsFieldNestedCreateInput[] | null;
}

export interface Team_TournamentTeamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface Team_TournamentTeamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface Team_TournamentTeamsFieldNestedUpdateInput {
  where: TournamentTeamWhereUniqueInput;
  data: Team_TournamentTeamsFieldNestedUpdateDataInput;
}

export interface Team_TournamentTeamsFieldNestedUpsertInput {
  create: Team_TournamentTeamsFieldNestedCreateInput;
  update: Team_TournamentTeamsFieldNestedUpdateDataInput;
  where: TournamentTeamWhereUniqueInput;
}

export interface Team_TournamentTeamsFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: Team_TournamentTeamsFieldConnectOrCreateInput[] | null;
  create?: Team_TournamentTeamsFieldNestedCreateInput[] | null;
  delete?: TournamentTeamWhereUniqueInput[] | null;
  deleteMany?: TournamentTeamWhereScalarInput | null;
  disconnect?: TournamentTeamWhereUniqueInput[] | null;
  set?: TournamentTeamWhereUniqueInput[] | null;
  update?: Team_TournamentTeamsFieldNestedUpdateInput[] | null;
  upsert?: Team_TournamentTeamsFieldNestedUpsertInput[] | null;
}

export interface Team_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Team_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCapMaxFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentCapMaxFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentCapMinFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentCapMinFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentCheckInEndTimeFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCheckInEndTimeFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCheckInEndTimePendingFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCheckInEndTimePendingFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCheckInStartTimeFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCheckInStartTimeFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCheckInStartTimePendingFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCheckInStartTimePendingFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentContactFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentContactFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCustomPrizeTypeCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentCustomPrizeTypeCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentCustomPrizeTypeIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentCustomPrizeTypeIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentCustomPrizeTypeNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentCustomPrizeTypeNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentCustomPrizeTypeWhereInput {
  id?: TournamentCustomPrizeTypeIdFieldWhereInput | null;
  name?: TournamentCustomPrizeTypeNameFieldWhereInput | null;
  createdAt?: TournamentCustomPrizeTypeCreatedAtFieldWhereInput | null;
  AND?: TournamentCustomPrizeTypeWhereLogicAndInput[] | null;
  NOT?: TournamentCustomPrizeTypeWhereLogicNotInput[] | null;
  OR?: TournamentCustomPrizeTypeWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface TournamentCustomPrizeTypeWhereLogicAndInput {
  id?: TournamentCustomPrizeTypeIdFieldWhereInput | null;
  name?: TournamentCustomPrizeTypeNameFieldWhereInput | null;
  createdAt?: TournamentCustomPrizeTypeCreatedAtFieldWhereInput | null;
  AND?: TournamentCustomPrizeTypeWhereLogicAndInput[] | null;
  NOT?: TournamentCustomPrizeTypeWhereLogicNotInput[] | null;
  OR?: TournamentCustomPrizeTypeWhereLogicOrInput[] | null;
}

export interface TournamentCustomPrizeTypeWhereLogicNotInput {
  id?: TournamentCustomPrizeTypeIdFieldWhereInput | null;
  name?: TournamentCustomPrizeTypeNameFieldWhereInput | null;
  createdAt?: TournamentCustomPrizeTypeCreatedAtFieldWhereInput | null;
  AND?: TournamentCustomPrizeTypeWhereLogicAndInput[] | null;
  NOT?: TournamentCustomPrizeTypeWhereLogicNotInput[] | null;
  OR?: TournamentCustomPrizeTypeWhereLogicOrInput[] | null;
}

export interface TournamentCustomPrizeTypeWhereLogicOrInput {
  id?: TournamentCustomPrizeTypeIdFieldWhereInput | null;
  name?: TournamentCustomPrizeTypeNameFieldWhereInput | null;
  createdAt?: TournamentCustomPrizeTypeCreatedAtFieldWhereInput | null;
  AND?: TournamentCustomPrizeTypeWhereLogicAndInput[] | null;
  NOT?: TournamentCustomPrizeTypeWhereLogicNotInput[] | null;
  OR?: TournamentCustomPrizeTypeWhereLogicOrInput[] | null;
}

export interface TournamentCustomPrizeTypeWhereUniqueInput {
  id: number;
}

export interface TournamentDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentEndDateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentEndDateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentExtrasCountFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentExtrasCountFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGameIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGameIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupGroupingsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentGroupGroupingsFieldWhereInput {
  every?: TournamentGroupingWhereInput | null;
  none?: TournamentGroupingWhereInput | null;
  some?: TournamentGroupingWhereInput | null;
}

export interface TournamentGroupIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupInput {
  title: string;
  coverImage?: string | null;
  description?: string | null;
  groupings?: TournamentGroupingCreateManyInput[] | null;
  tournamentIds: number[];
}

export interface TournamentGroupOrderByInput {
  coverImage?: SortOrder | null;
  createdAt?: SortOrder | null;
  description?: SortOrder | null;
  groupings?: TournamentGroupGroupingsFieldOrderByInput | null;
  id?: SortOrder | null;
  title?: SortOrder | null;
  tournaments?: TournamentGroupTournamentsFieldOrderByInput | null;
  updatedAt?: SortOrder | null;
}

export interface TournamentGroupTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupTournamentsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentGroupTournamentsFieldWhereInput {
  every?: TournamentWhereInput | null;
  none?: TournamentWhereInput | null;
  some?: TournamentWhereInput | null;
}

export interface TournamentGroupWhereInput {
  description?: TournamentGroupDescriptionFieldWhereInput | null;
  id?: TournamentGroupIdFieldWhereInput | null;
  title?: TournamentGroupTitleFieldWhereInput | null;
  createdAt?: TournamentGroupCreatedAtFieldWhereInput | null;
  tournaments?: TournamentGroupTournamentsFieldWhereInput | null;
  groupings?: TournamentGroupGroupingsFieldWhereInput | null;
  AND?: TournamentGroupWhereLogicAndInput[] | null;
  NOT?: TournamentGroupWhereLogicNotInput[] | null;
  OR?: TournamentGroupWhereLogicOrInput[] | null;
}

export interface TournamentGroupWhereLogicAndInput {
  description?: TournamentGroupDescriptionFieldWhereInput | null;
  id?: TournamentGroupIdFieldWhereInput | null;
  title?: TournamentGroupTitleFieldWhereInput | null;
  createdAt?: TournamentGroupCreatedAtFieldWhereInput | null;
  tournaments?: TournamentGroupTournamentsFieldWhereInput | null;
  groupings?: TournamentGroupGroupingsFieldWhereInput | null;
  AND?: TournamentGroupWhereLogicAndInput[] | null;
  NOT?: TournamentGroupWhereLogicNotInput[] | null;
  OR?: TournamentGroupWhereLogicOrInput[] | null;
}

export interface TournamentGroupWhereLogicNotInput {
  description?: TournamentGroupDescriptionFieldWhereInput | null;
  id?: TournamentGroupIdFieldWhereInput | null;
  title?: TournamentGroupTitleFieldWhereInput | null;
  createdAt?: TournamentGroupCreatedAtFieldWhereInput | null;
  tournaments?: TournamentGroupTournamentsFieldWhereInput | null;
  groupings?: TournamentGroupGroupingsFieldWhereInput | null;
  AND?: TournamentGroupWhereLogicAndInput[] | null;
  NOT?: TournamentGroupWhereLogicNotInput[] | null;
  OR?: TournamentGroupWhereLogicOrInput[] | null;
}

export interface TournamentGroupWhereLogicOrInput {
  description?: TournamentGroupDescriptionFieldWhereInput | null;
  id?: TournamentGroupIdFieldWhereInput | null;
  title?: TournamentGroupTitleFieldWhereInput | null;
  createdAt?: TournamentGroupCreatedAtFieldWhereInput | null;
  tournaments?: TournamentGroupTournamentsFieldWhereInput | null;
  groupings?: TournamentGroupGroupingsFieldWhereInput | null;
  AND?: TournamentGroupWhereLogicAndInput[] | null;
  NOT?: TournamentGroupWhereLogicNotInput[] | null;
  OR?: TournamentGroupWhereLogicOrInput[] | null;
}

export interface TournamentGroupWhereScalarInput {
  OR?: (TournamentGroupWhereScalarInput | null)[] | null;
  AND?: (TournamentGroupWhereScalarInput | null)[] | null;
  NOT?: (TournamentGroupWhereScalarInput | null)[] | null;
  id?: TournamentGroup_IdFieldWhereScalarInput | null;
  createdAt?: TournamentGroup_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentGroup_UpdatedAtFieldWhereScalarInput | null;
  title?: TournamentGroup_TitleFieldWhereScalarInput | null;
  coverImage?: TournamentGroup_CoverImageFieldWhereScalarInput | null;
  description?: TournamentGroup_DescriptionFieldWhereScalarInput | null;
}

export interface TournamentGroupWhereUniqueInput {
  id: number;
}

export interface TournamentGroup_CoverImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroup_CoverImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroup_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroup_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroup_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroup_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroup_GroupingsFieldConnectOrCreateInput {
  where: TournamentGroupingWhereUniqueInput;
  create: TournamentGroup_GroupingsFieldNestedCreateInput;
}

export interface TournamentGroup_GroupingsFieldCreateInput {
  connect?: TournamentGroupingWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGroup_GroupingsFieldConnectOrCreateInput[] | null;
  create?: TournamentGroup_GroupingsFieldNestedCreateInput[] | null;
}

export interface TournamentGroup_GroupingsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  coverImage?: string | null;
  description?: string | null;
  gameId?: number | null;
  values?: TournamentGrouping_ValuesFieldCreateInput | null;
  game?: TournamentGrouping_GameFieldCreateInput | null;
}

export interface TournamentGroup_GroupingsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  coverImage?: string | null;
  description?: string | null;
  gameId?: number | null;
  values?: TournamentGrouping_ValuesFieldUpdateInput | null;
  game?: TournamentGrouping_GameFieldUpdateInput | null;
}

export interface TournamentGroup_GroupingsFieldNestedUpdateInput {
  where: TournamentGroupingWhereUniqueInput;
  data: TournamentGroup_GroupingsFieldNestedUpdateDataInput;
}

export interface TournamentGroup_GroupingsFieldNestedUpsertInput {
  create: TournamentGroup_GroupingsFieldNestedCreateInput;
  update: TournamentGroup_GroupingsFieldNestedUpdateDataInput;
  where: TournamentGroupingWhereUniqueInput;
}

export interface TournamentGroup_GroupingsFieldUpdateInput {
  connect?: TournamentGroupingWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGroup_GroupingsFieldConnectOrCreateInput[] | null;
  create?: TournamentGroup_GroupingsFieldNestedCreateInput[] | null;
  delete?: TournamentGroupingWhereUniqueInput[] | null;
  deleteMany?: TournamentGroupingWhereScalarInput | null;
  disconnect?: TournamentGroupingWhereUniqueInput[] | null;
  set?: TournamentGroupingWhereUniqueInput[] | null;
  update?: TournamentGroup_GroupingsFieldNestedUpdateInput[] | null;
  upsert?: TournamentGroup_GroupingsFieldNestedUpsertInput[] | null;
}

export interface TournamentGroup_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroup_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroup_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroup_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroup_TournamentsFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentGroup_TournamentsFieldNestedCreateInput;
}

export interface TournamentGroup_TournamentsFieldCreateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGroup_TournamentsFieldConnectOrCreateInput[] | null;
  create?: TournamentGroup_TournamentsFieldNestedCreateInput[] | null;
}

export interface TournamentGroup_TournamentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentGroup_TournamentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentGroup_TournamentsFieldNestedUpdateInput {
  where: TournamentWhereUniqueInput;
  data: TournamentGroup_TournamentsFieldNestedUpdateDataInput;
}

export interface TournamentGroup_TournamentsFieldNestedUpsertInput {
  create: TournamentGroup_TournamentsFieldNestedCreateInput;
  update: TournamentGroup_TournamentsFieldNestedUpdateDataInput;
  where: TournamentWhereUniqueInput;
}

export interface TournamentGroup_TournamentsFieldUpdateInput {
  connect?: TournamentWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGroup_TournamentsFieldConnectOrCreateInput[] | null;
  create?: TournamentGroup_TournamentsFieldNestedCreateInput[] | null;
  delete?: TournamentWhereUniqueInput[] | null;
  deleteMany?: TournamentWhereScalarInput | null;
  disconnect?: TournamentWhereUniqueInput[] | null;
  set?: TournamentWhereUniqueInput[] | null;
  update?: TournamentGroup_TournamentsFieldNestedUpdateInput[] | null;
  upsert?: TournamentGroup_TournamentsFieldNestedUpsertInput[] | null;
}

export interface TournamentGroup_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroup_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingBelongingWhereInput {
  belongsToGrouping: boolean;
  exclude?: number[] | null;
  include?: number[] | null;
}

export interface TournamentGroupingCoverImageFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupingCoverImageFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupingCreateManyInput {
  title: string;
  coverImage?: string | null;
  description?: string | null;
  gameId?: number | null;
  values: TournamentGroupingValueCreateManyInput[];
}

export interface TournamentGroupingCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupingCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingDescriptionFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupingDescriptionFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupingGameIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingGameIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingGroupIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingGroupIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingOrderByInput {
  coverImage?: SortOrder | null;
  createdAt?: SortOrder | null;
  description?: SortOrder | null;
  game?: GameOrderByInput | null;
  gameId?: SortOrder | null;
  group?: TournamentGroupOrderByInput | null;
  groupId?: SortOrder | null;
  id?: SortOrder | null;
  title?: SortOrder | null;
  updatedAt?: SortOrder | null;
  values?: TournamentGroupingValuesFieldOrderByInput | null;
}

export interface TournamentGroupingTitleFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupingTitleFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupingUpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupingUpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingValueCreateManyInput {
  order?: number | null;
  value: string;
  tournamentId: number;
}

export interface TournamentGroupingValueCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupingValueCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingValueGroupingIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValueGroupingIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValueIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValueIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValueOrderByInput {
  createdAt?: SortOrder | null;
  grouping?: TournamentGroupingOrderByInput | null;
  groupingId?: SortOrder | null;
  id?: SortOrder | null;
  order?: SortOrder | null;
  tournament?: TournamentOrderByInput | null;
  tournamentId?: SortOrder | null;
  updatedAt?: SortOrder | null;
  value?: SortOrder | null;
}

export interface TournamentGroupingValueOrderFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValueOrderFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValueTournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValueTournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValueValueFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupingValueValueFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupingValueWhereInput {
  createdAt?: TournamentGroupingValueCreatedAtFieldWhereInput | null;
  id?: TournamentGroupingValueIdFieldWhereInput | null;
  order?: TournamentGroupingValueOrderFieldWhereInput | null;
  groupingId?: TournamentGroupingValueGroupingIdFieldWhereInput | null;
  tournamentId?: TournamentGroupingValueTournamentIdFieldWhereInput | null;
  value?: TournamentGroupingValueValueFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentGroupingValueWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingValueWhereLogicNotInput[] | null;
  OR?: TournamentGroupingValueWhereLogicOrInput[] | null;
}

export interface TournamentGroupingValueWhereLogicAndInput {
  createdAt?: TournamentGroupingValueCreatedAtFieldWhereInput | null;
  id?: TournamentGroupingValueIdFieldWhereInput | null;
  order?: TournamentGroupingValueOrderFieldWhereInput | null;
  groupingId?: TournamentGroupingValueGroupingIdFieldWhereInput | null;
  tournamentId?: TournamentGroupingValueTournamentIdFieldWhereInput | null;
  value?: TournamentGroupingValueValueFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentGroupingValueWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingValueWhereLogicNotInput[] | null;
  OR?: TournamentGroupingValueWhereLogicOrInput[] | null;
}

export interface TournamentGroupingValueWhereLogicNotInput {
  createdAt?: TournamentGroupingValueCreatedAtFieldWhereInput | null;
  id?: TournamentGroupingValueIdFieldWhereInput | null;
  order?: TournamentGroupingValueOrderFieldWhereInput | null;
  groupingId?: TournamentGroupingValueGroupingIdFieldWhereInput | null;
  tournamentId?: TournamentGroupingValueTournamentIdFieldWhereInput | null;
  value?: TournamentGroupingValueValueFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentGroupingValueWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingValueWhereLogicNotInput[] | null;
  OR?: TournamentGroupingValueWhereLogicOrInput[] | null;
}

export interface TournamentGroupingValueWhereLogicOrInput {
  createdAt?: TournamentGroupingValueCreatedAtFieldWhereInput | null;
  id?: TournamentGroupingValueIdFieldWhereInput | null;
  order?: TournamentGroupingValueOrderFieldWhereInput | null;
  groupingId?: TournamentGroupingValueGroupingIdFieldWhereInput | null;
  tournamentId?: TournamentGroupingValueTournamentIdFieldWhereInput | null;
  value?: TournamentGroupingValueValueFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentGroupingValueWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingValueWhereLogicNotInput[] | null;
  OR?: TournamentGroupingValueWhereLogicOrInput[] | null;
}

export interface TournamentGroupingValueWhereScalarInput {
  OR?: (TournamentGroupingValueWhereScalarInput | null)[] | null;
  AND?: (TournamentGroupingValueWhereScalarInput | null)[] | null;
  NOT?: (TournamentGroupingValueWhereScalarInput | null)[] | null;
  id?: TournamentGroupingValue_IdFieldWhereScalarInput | null;
  createdAt?: TournamentGroupingValue_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentGroupingValue_UpdatedAtFieldWhereScalarInput | null;
  value?: TournamentGroupingValue_ValueFieldWhereScalarInput | null;
  order?: TournamentGroupingValue_OrderFieldWhereScalarInput | null;
  groupingId?: TournamentGroupingValue_GroupingIdFieldWhereScalarInput | null;
  tournamentId?: TournamentGroupingValue_TournamentIdFieldWhereScalarInput | null;
}

export interface TournamentGroupingValueWhereUniqueInput {
  id?: number | null;
  tournamentId?: number | null;
}

export interface TournamentGroupingValue_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupingValue_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingValue_GroupingFieldConnectOrCreateInput {
  where: TournamentGroupingWhereUniqueInput;
  create: TournamentGroupingValue_GroupingFieldNestedCreateInput;
}

export interface TournamentGroupingValue_GroupingFieldCreateInput {
  connect?: TournamentGroupingWhereUniqueInput | null;
  connectOrCreate?: TournamentGroupingValue_GroupingFieldConnectOrCreateInput | null;
  create?: TournamentGroupingValue_GroupingFieldNestedCreateInput | null;
}

export interface TournamentGroupingValue_GroupingFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  coverImage?: string | null;
  description?: string | null;
  gameId?: number | null;
  groupId: number;
  game?: TournamentGrouping_GameFieldCreateInput | null;
  group?: TournamentGrouping_GroupFieldCreateInput | null;
}

export interface TournamentGroupingValue_GroupingFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  coverImage?: string | null;
  description?: string | null;
  gameId?: number | null;
  groupId?: number | null;
  game?: TournamentGrouping_GameFieldUpdateInput | null;
  group?: TournamentGrouping_GroupFieldUpdateInput | null;
}

export interface TournamentGroupingValue_GroupingFieldNestedUpsertInput {
  create: TournamentGroupingValue_GroupingFieldNestedCreateInput;
  update: TournamentGroupingValue_GroupingFieldNestedUpdateInput;
}

export interface TournamentGroupingValue_GroupingFieldUpdateInput {
  connect?: TournamentGroupingWhereUniqueInput | null;
  connectOrCreate?: TournamentGroupingValue_GroupingFieldConnectOrCreateInput | null;
  create?: TournamentGroupingValue_GroupingFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentGroupingValue_GroupingFieldNestedUpdateInput | null;
  upsert?: TournamentGroupingValue_GroupingFieldNestedUpsertInput | null;
}

export interface TournamentGroupingValue_GroupingIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValue_GroupingIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValue_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValue_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValue_OrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValue_OrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValue_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentGroupingValue_TournamentFieldNestedCreateInput;
}

export interface TournamentGroupingValue_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentGroupingValue_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentGroupingValue_TournamentFieldNestedCreateInput | null;
}

export interface TournamentGroupingValue_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentGroupingValue_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentGroupingValue_TournamentFieldNestedUpsertInput {
  create: TournamentGroupingValue_TournamentFieldNestedCreateInput;
  update: TournamentGroupingValue_TournamentFieldNestedUpdateInput;
}

export interface TournamentGroupingValue_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentGroupingValue_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentGroupingValue_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentGroupingValue_TournamentFieldNestedUpdateInput | null;
  upsert?: TournamentGroupingValue_TournamentFieldNestedUpsertInput | null;
}

export interface TournamentGroupingValue_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGroupingValue_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGroupingValue_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGroupingValue_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGroupingValue_ValueFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGroupingValue_ValueFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGroupingValuesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentGroupingValuesFieldWhereInput {
  every?: TournamentGroupingValueWhereInput | null;
  none?: TournamentGroupingValueWhereInput | null;
  some?: TournamentGroupingValueWhereInput | null;
}

export interface TournamentGroupingWhereInput {
  coverImage?: TournamentGroupingCoverImageFieldWhereInput | null;
  createdAt?: TournamentGroupingCreatedAtFieldWhereInput | null;
  description?: TournamentGroupingDescriptionFieldWhereInput | null;
  game?: GameWhereInput | null;
  gameId?: TournamentGroupingGameIdFieldWhereInput | null;
  group?: TournamentGroupWhereInput | null;
  groupId?: TournamentGroupingGroupIdFieldWhereInput | null;
  id?: TournamentGroupingIdFieldWhereInput | null;
  title?: TournamentGroupingTitleFieldWhereInput | null;
  updatedAt?: TournamentGroupingUpdatedAtFieldWhereInput | null;
  values?: TournamentGroupingValuesFieldWhereInput | null;
  AND?: TournamentGroupingWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingWhereLogicNotInput[] | null;
  OR?: TournamentGroupingWhereLogicOrInput[] | null;
}

export interface TournamentGroupingWhereLogicAndInput {
  coverImage?: TournamentGroupingCoverImageFieldWhereInput | null;
  createdAt?: TournamentGroupingCreatedAtFieldWhereInput | null;
  description?: TournamentGroupingDescriptionFieldWhereInput | null;
  game?: GameWhereInput | null;
  gameId?: TournamentGroupingGameIdFieldWhereInput | null;
  group?: TournamentGroupWhereInput | null;
  groupId?: TournamentGroupingGroupIdFieldWhereInput | null;
  id?: TournamentGroupingIdFieldWhereInput | null;
  title?: TournamentGroupingTitleFieldWhereInput | null;
  updatedAt?: TournamentGroupingUpdatedAtFieldWhereInput | null;
  values?: TournamentGroupingValuesFieldWhereInput | null;
  AND?: TournamentGroupingWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingWhereLogicNotInput[] | null;
  OR?: TournamentGroupingWhereLogicOrInput[] | null;
}

export interface TournamentGroupingWhereLogicNotInput {
  coverImage?: TournamentGroupingCoverImageFieldWhereInput | null;
  createdAt?: TournamentGroupingCreatedAtFieldWhereInput | null;
  description?: TournamentGroupingDescriptionFieldWhereInput | null;
  game?: GameWhereInput | null;
  gameId?: TournamentGroupingGameIdFieldWhereInput | null;
  group?: TournamentGroupWhereInput | null;
  groupId?: TournamentGroupingGroupIdFieldWhereInput | null;
  id?: TournamentGroupingIdFieldWhereInput | null;
  title?: TournamentGroupingTitleFieldWhereInput | null;
  updatedAt?: TournamentGroupingUpdatedAtFieldWhereInput | null;
  values?: TournamentGroupingValuesFieldWhereInput | null;
  AND?: TournamentGroupingWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingWhereLogicNotInput[] | null;
  OR?: TournamentGroupingWhereLogicOrInput[] | null;
}

export interface TournamentGroupingWhereLogicOrInput {
  coverImage?: TournamentGroupingCoverImageFieldWhereInput | null;
  createdAt?: TournamentGroupingCreatedAtFieldWhereInput | null;
  description?: TournamentGroupingDescriptionFieldWhereInput | null;
  game?: GameWhereInput | null;
  gameId?: TournamentGroupingGameIdFieldWhereInput | null;
  group?: TournamentGroupWhereInput | null;
  groupId?: TournamentGroupingGroupIdFieldWhereInput | null;
  id?: TournamentGroupingIdFieldWhereInput | null;
  title?: TournamentGroupingTitleFieldWhereInput | null;
  updatedAt?: TournamentGroupingUpdatedAtFieldWhereInput | null;
  values?: TournamentGroupingValuesFieldWhereInput | null;
  AND?: TournamentGroupingWhereLogicAndInput[] | null;
  NOT?: TournamentGroupingWhereLogicNotInput[] | null;
  OR?: TournamentGroupingWhereLogicOrInput[] | null;
}

export interface TournamentGroupingWhereScalarInput {
  OR?: (TournamentGroupingWhereScalarInput | null)[] | null;
  AND?: (TournamentGroupingWhereScalarInput | null)[] | null;
  NOT?: (TournamentGroupingWhereScalarInput | null)[] | null;
  id?: TournamentGrouping_IdFieldWhereScalarInput | null;
  createdAt?: TournamentGrouping_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentGrouping_UpdatedAtFieldWhereScalarInput | null;
  title?: TournamentGrouping_TitleFieldWhereScalarInput | null;
  coverImage?: TournamentGrouping_CoverImageFieldWhereScalarInput | null;
  description?: TournamentGrouping_DescriptionFieldWhereScalarInput | null;
  gameId?: TournamentGrouping_GameIdFieldWhereScalarInput | null;
  groupId?: TournamentGrouping_GroupIdFieldWhereScalarInput | null;
}

export interface TournamentGroupingWhereUniqueInput {
  id: number;
}

export interface TournamentGrouping_CoverImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGrouping_CoverImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGrouping_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGrouping_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGrouping_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGrouping_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGrouping_GameFieldConnectOrCreateInput {
  where: GameWhereUniqueInput;
  create: TournamentGrouping_GameFieldNestedCreateInput;
}

export interface TournamentGrouping_GameFieldCreateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: TournamentGrouping_GameFieldConnectOrCreateInput | null;
  create?: TournamentGrouping_GameFieldNestedCreateInput | null;
}

export interface TournamentGrouping_GameFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags: string[];
  gameId?: Game_GameIdFieldCreateInput | null;
  maps?: Game_MapsFieldCreateInput | null;
  tournaments?: Game_TournamentsFieldCreateInput | null;
  teams?: Game_TeamsFieldCreateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldCreateInput | null;
}

export interface TournamentGrouping_GameFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags?: string[] | null;
  gameId?: Game_GameIdFieldUpdateInput | null;
  maps?: Game_MapsFieldUpdateInput | null;
  tournaments?: Game_TournamentsFieldUpdateInput | null;
  teams?: Game_TeamsFieldUpdateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldUpdateInput | null;
}

export interface TournamentGrouping_GameFieldNestedUpsertInput {
  create: TournamentGrouping_GameFieldNestedCreateInput;
  update: TournamentGrouping_GameFieldNestedUpdateInput;
}

export interface TournamentGrouping_GameFieldUpdateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: TournamentGrouping_GameFieldConnectOrCreateInput | null;
  create?: TournamentGrouping_GameFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentGrouping_GameFieldNestedUpdateInput | null;
  upsert?: TournamentGrouping_GameFieldNestedUpsertInput | null;
}

export interface TournamentGrouping_GameIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGrouping_GameIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGrouping_GroupFieldConnectOrCreateInput {
  where: TournamentGroupWhereUniqueInput;
  create: TournamentGrouping_GroupFieldNestedCreateInput;
}

export interface TournamentGrouping_GroupFieldCreateInput {
  connect?: TournamentGroupWhereUniqueInput | null;
  connectOrCreate?: TournamentGrouping_GroupFieldConnectOrCreateInput | null;
  create?: TournamentGrouping_GroupFieldNestedCreateInput | null;
}

export interface TournamentGrouping_GroupFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  coverImage?: string | null;
  description?: string | null;
  tournaments?: TournamentGroup_TournamentsFieldCreateInput | null;
}

export interface TournamentGrouping_GroupFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  coverImage?: string | null;
  description?: string | null;
  tournaments?: TournamentGroup_TournamentsFieldUpdateInput | null;
}

export interface TournamentGrouping_GroupFieldNestedUpsertInput {
  create: TournamentGrouping_GroupFieldNestedCreateInput;
  update: TournamentGrouping_GroupFieldNestedUpdateInput;
}

export interface TournamentGrouping_GroupFieldUpdateInput {
  connect?: TournamentGroupWhereUniqueInput | null;
  connectOrCreate?: TournamentGrouping_GroupFieldConnectOrCreateInput | null;
  create?: TournamentGrouping_GroupFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentGrouping_GroupFieldNestedUpdateInput | null;
  upsert?: TournamentGrouping_GroupFieldNestedUpsertInput | null;
}

export interface TournamentGrouping_GroupIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGrouping_GroupIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGrouping_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentGrouping_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentGrouping_TitleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentGrouping_TitleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentGrouping_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentGrouping_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentGrouping_ValuesFieldConnectOrCreateInput {
  where: TournamentGroupingValueWhereUniqueInput;
  create: TournamentGrouping_ValuesFieldNestedCreateInput;
}

export interface TournamentGrouping_ValuesFieldCreateInput {
  connect?: TournamentGroupingValueWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGrouping_ValuesFieldConnectOrCreateInput[] | null;
  create?: TournamentGrouping_ValuesFieldNestedCreateInput[] | null;
}

export interface TournamentGrouping_ValuesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  order?: number | null;
  tournamentId: number;
  tournament?: TournamentGroupingValue_TournamentFieldCreateInput | null;
}

export interface TournamentGrouping_ValuesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value?: string | null;
  order?: number | null;
  tournamentId?: number | null;
  tournament?: TournamentGroupingValue_TournamentFieldUpdateInput | null;
}

export interface TournamentGrouping_ValuesFieldNestedUpdateInput {
  where: TournamentGroupingValueWhereUniqueInput;
  data: TournamentGrouping_ValuesFieldNestedUpdateDataInput;
}

export interface TournamentGrouping_ValuesFieldNestedUpsertInput {
  create: TournamentGrouping_ValuesFieldNestedCreateInput;
  update: TournamentGrouping_ValuesFieldNestedUpdateDataInput;
  where: TournamentGroupingValueWhereUniqueInput;
}

export interface TournamentGrouping_ValuesFieldUpdateInput {
  connect?: TournamentGroupingValueWhereUniqueInput[] | null;
  connectOrCreate?: TournamentGrouping_ValuesFieldConnectOrCreateInput[] | null;
  create?: TournamentGrouping_ValuesFieldNestedCreateInput[] | null;
  delete?: TournamentGroupingValueWhereUniqueInput[] | null;
  deleteMany?: TournamentGroupingValueWhereScalarInput | null;
  disconnect?: TournamentGroupingValueWhereUniqueInput[] | null;
  set?: TournamentGroupingValueWhereUniqueInput[] | null;
  update?: TournamentGrouping_ValuesFieldNestedUpdateInput[] | null;
  upsert?: TournamentGrouping_ValuesFieldNestedUpsertInput[] | null;
}

export interface TournamentGroupsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentGroupsFieldWhereInput {
  every?: TournamentGroupWhereInput | null;
  none?: TournamentGroupWhereInput | null;
  some?: TournamentGroupWhereInput | null;
}

export interface TournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentInput {
  adminsEmails?: string[] | null;
  name: string;
  type?: string | null;
  gameId: number;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  fakeRegistrations?: number | null;
  extrasCount?: number | null;
  contact?: string | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  partner?: string | null;
  partner2?: string | null;
  rules?: TournamentRulesInput[] | null;
  tournamentInfo?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  style?: string | null;
  embedStream?: string | null;
  embedTournament?: EmbedTournamentInput[] | null;
  mailText?: string | null;
  mailSubject?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  public?: boolean | null;
  customFields?: CustomFieldsInput[] | null;
}

export interface TournamentMailSubjectFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMailSubjectFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMailTextFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMailTextFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchBucketFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchBucketFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatchCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchDateEndFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatchDateEndFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchDateStartFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatchDateStartFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchDisputeByFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchDisputeByFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchEmbedFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchEmbedFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchFortunaLinkFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchFortunaLinkFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchGroupFormatInput {
  name?: string | null;
  teams?: number[] | null;
}

export interface TournamentMatchIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchInput {
  dateStart?: any | null;
  dateEnd?: any | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  bucket?: string | null;
  sibling?: number | null;
  child?: number | null;
  round?: number | null;
  active?: boolean | null;
  note?: string | null;
  printScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  stageId: number;
  fortunaLink?: string | null;
  rounds?: TournamentMatchRoundInput[] | null;
}

export interface TournamentMatchNoteFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchNoteFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchOrderByInput {
  active?: SortOrder | null;
  bucket?: SortOrder | null;
  childMatch?: TournamentMatchOrderByInput | null;
  childMatchId?: SortOrder | null;
  confirmed?: SortOrder | null;
  createdAt?: SortOrder | null;
  dateEnd?: SortOrder | null;
  dateStart?: SortOrder | null;
  disputeBy?: SortOrder | null;
  disputePrintScreen?: SortOrder | null;
  disputeScoreA?: SortOrder | null;
  disputeScoreB?: SortOrder | null;
  embed?: SortOrder | null;
  id?: SortOrder | null;
  loserMatch?: TournamentMatchOrderByInput | null;
  loserMatchId?: SortOrder | null;
  note?: SortOrder | null;
  ourRounds?: SortOrder | null;
  printScreen?: SortOrder | null;
  resultBy?: SortOrder | null;
  round?: SortOrder | null;
  rounds?: TournamentMatchRoundsFieldOrderByInput | null;
  siblingMatch?: TournamentMatchOrderByInput | null;
  siblingMatchId?: SortOrder | null;
  stage?: TournamentStageOrderByInput | null;
  stageId?: SortOrder | null;
  teamA?: TournamentTeamOrderByInput | null;
  teamB?: TournamentTeamOrderByInput | null;
  teamIdA?: SortOrder | null;
  teamIdB?: SortOrder | null;
  teamScoreA?: SortOrder | null;
  teamScoreB?: SortOrder | null;
  theirRounds?: SortOrder | null;
  tournament?: TournamentOrderByInput | null;
  tournamentId?: SortOrder | null;
  updatedAt?: SortOrder | null;
  fortunaLink?: SortOrder | null;
}

export interface TournamentMatchResultByFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatchResultByFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatchRoundDateInput {
  stageId: number;
  round: number;
  dateStart?: any | null;
}

export interface TournamentMatchRoundFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRoundFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRoundInput {
  id?: number | null;
  matchId: number;
  mapId?: number | null;
  teamScoreA: number;
  teamScoreB: number;
}

export interface TournamentMatchRoundWhereScalarInput {
  OR?: (TournamentMatchRoundWhereScalarInput | null)[] | null;
  AND?: (TournamentMatchRoundWhereScalarInput | null)[] | null;
  NOT?: (TournamentMatchRoundWhereScalarInput | null)[] | null;
  id?: TournamentMatchRound_IdFieldWhereScalarInput | null;
  createdAt?: TournamentMatchRound_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentMatchRound_UpdatedAtFieldWhereScalarInput | null;
  matchId?: TournamentMatchRound_MatchIdFieldWhereScalarInput | null;
  mapId?: TournamentMatchRound_MapIdFieldWhereScalarInput | null;
  teamScoreA?: TournamentMatchRound_TeamScoreAFieldWhereScalarInput | null;
  teamScoreB?: TournamentMatchRound_TeamScoreBFieldWhereScalarInput | null;
}

export interface TournamentMatchRoundWhereUniqueInput {
  id: number;
}

export interface TournamentMatchRound_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatchRound_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchRound_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRound_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRound_MapFieldConnectOrCreateInput {
  where: GameMapWhereUniqueInput;
  create: TournamentMatchRound_MapFieldNestedCreateInput;
}

export interface TournamentMatchRound_MapFieldCreateInput {
  connect?: GameMapWhereUniqueInput | null;
  connectOrCreate?: TournamentMatchRound_MapFieldConnectOrCreateInput | null;
  create?: TournamentMatchRound_MapFieldNestedCreateInput | null;
}

export interface TournamentMatchRound_MapFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  gameId: number;
  name: string;
  image?: string | null;
  game?: GameMap_GameFieldCreateInput | null;
}

export interface TournamentMatchRound_MapFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  gameId?: number | null;
  name?: string | null;
  image?: string | null;
  game?: GameMap_GameFieldUpdateInput | null;
}

export interface TournamentMatchRound_MapFieldNestedUpsertInput {
  create: TournamentMatchRound_MapFieldNestedCreateInput;
  update: TournamentMatchRound_MapFieldNestedUpdateInput;
}

export interface TournamentMatchRound_MapFieldUpdateInput {
  connect?: GameMapWhereUniqueInput | null;
  connectOrCreate?: TournamentMatchRound_MapFieldConnectOrCreateInput | null;
  create?: TournamentMatchRound_MapFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatchRound_MapFieldNestedUpdateInput | null;
  upsert?: TournamentMatchRound_MapFieldNestedUpsertInput | null;
}

export interface TournamentMatchRound_MapIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRound_MapIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRound_MatchFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatchRound_MatchFieldNestedCreateInput;
}

export interface TournamentMatchRound_MatchFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatchRound_MatchFieldConnectOrCreateInput | null;
  create?: TournamentMatchRound_MatchFieldNestedCreateInput | null;
}

export interface TournamentMatchRound_MatchFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatchRound_MatchFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatchRound_MatchFieldNestedUpsertInput {
  create: TournamentMatchRound_MatchFieldNestedCreateInput;
  update: TournamentMatchRound_MatchFieldNestedUpdateInput;
}

export interface TournamentMatchRound_MatchFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatchRound_MatchFieldConnectOrCreateInput | null;
  create?: TournamentMatchRound_MatchFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatchRound_MatchFieldNestedUpdateInput | null;
  upsert?: TournamentMatchRound_MatchFieldNestedUpsertInput | null;
}

export interface TournamentMatchRound_MatchIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRound_MatchIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRound_TeamScoreAFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRound_TeamScoreAFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRound_TeamScoreBFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchRound_TeamScoreBFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchRound_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatchRound_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchRoundsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentMatchScoreDisputeInput {
  disputeScoreA: number;
  disputeScoreB: number;
  disputePrintScreen?: string | null;
}

export interface TournamentMatchStageIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchStageIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchTeamIdAFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchTeamIdAFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchTeamIdBFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchTeamIdBFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchTeamScoreAFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchTeamScoreAFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchTeamScoreBFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchTeamScoreBFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchTournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatchTournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatchWhereInput {
  active?: boolean | null;
  bucket?: TournamentMatchBucketFieldWhereInput | null;
  confirmed?: boolean | null;
  createdAt?: TournamentMatchCreatedAtFieldWhereInput | null;
  dateEnd?: TournamentMatchDateEndFieldWhereInput | null;
  dateStart?: TournamentMatchDateStartFieldWhereInput | null;
  embed?: TournamentMatchEmbedFieldWhereInput | null;
  id?: TournamentMatchIdFieldWhereInput | null;
  note?: TournamentMatchNoteFieldWhereInput | null;
  round?: TournamentMatchRoundFieldWhereInput | null;
  resultBy?: TournamentMatchResultByFieldWhereInput | null;
  disputeBy?: TournamentMatchDisputeByFieldWhereInput | null;
  stageId?: TournamentMatchStageIdFieldWhereInput | null;
  teamIdA?: TournamentMatchTeamIdAFieldWhereInput | null;
  teamIdB?: TournamentMatchTeamIdBFieldWhereInput | null;
  teamScoreA?: TournamentMatchTeamScoreAFieldWhereInput | null;
  teamScoreB?: TournamentMatchTeamScoreBFieldWhereInput | null;
  tournamentId?: TournamentMatchTournamentIdFieldWhereInput | null;
  fortunaLink?: TournamentMatchFortunaLinkFieldWhereInput | null;
  AND?: TournamentMatchWhereLogicAndInput[] | null;
  NOT?: TournamentMatchWhereLogicNotInput[] | null;
  OR?: TournamentMatchWhereLogicOrInput[] | null;
}

export interface TournamentMatchWhereLogicAndInput {
  active?: boolean | null;
  bucket?: TournamentMatchBucketFieldWhereInput | null;
  confirmed?: boolean | null;
  createdAt?: TournamentMatchCreatedAtFieldWhereInput | null;
  dateEnd?: TournamentMatchDateEndFieldWhereInput | null;
  dateStart?: TournamentMatchDateStartFieldWhereInput | null;
  embed?: TournamentMatchEmbedFieldWhereInput | null;
  id?: TournamentMatchIdFieldWhereInput | null;
  note?: TournamentMatchNoteFieldWhereInput | null;
  round?: TournamentMatchRoundFieldWhereInput | null;
  resultBy?: TournamentMatchResultByFieldWhereInput | null;
  disputeBy?: TournamentMatchDisputeByFieldWhereInput | null;
  stageId?: TournamentMatchStageIdFieldWhereInput | null;
  teamIdA?: TournamentMatchTeamIdAFieldWhereInput | null;
  teamIdB?: TournamentMatchTeamIdBFieldWhereInput | null;
  teamScoreA?: TournamentMatchTeamScoreAFieldWhereInput | null;
  teamScoreB?: TournamentMatchTeamScoreBFieldWhereInput | null;
  tournamentId?: TournamentMatchTournamentIdFieldWhereInput | null;
  fortunaLink?: TournamentMatchFortunaLinkFieldWhereInput | null;
  AND?: TournamentMatchWhereLogicAndInput[] | null;
  NOT?: TournamentMatchWhereLogicNotInput[] | null;
  OR?: TournamentMatchWhereLogicOrInput[] | null;
}

export interface TournamentMatchWhereLogicNotInput {
  active?: boolean | null;
  bucket?: TournamentMatchBucketFieldWhereInput | null;
  confirmed?: boolean | null;
  createdAt?: TournamentMatchCreatedAtFieldWhereInput | null;
  dateEnd?: TournamentMatchDateEndFieldWhereInput | null;
  dateStart?: TournamentMatchDateStartFieldWhereInput | null;
  embed?: TournamentMatchEmbedFieldWhereInput | null;
  id?: TournamentMatchIdFieldWhereInput | null;
  note?: TournamentMatchNoteFieldWhereInput | null;
  round?: TournamentMatchRoundFieldWhereInput | null;
  resultBy?: TournamentMatchResultByFieldWhereInput | null;
  disputeBy?: TournamentMatchDisputeByFieldWhereInput | null;
  stageId?: TournamentMatchStageIdFieldWhereInput | null;
  teamIdA?: TournamentMatchTeamIdAFieldWhereInput | null;
  teamIdB?: TournamentMatchTeamIdBFieldWhereInput | null;
  teamScoreA?: TournamentMatchTeamScoreAFieldWhereInput | null;
  teamScoreB?: TournamentMatchTeamScoreBFieldWhereInput | null;
  tournamentId?: TournamentMatchTournamentIdFieldWhereInput | null;
  fortunaLink?: TournamentMatchFortunaLinkFieldWhereInput | null;
  AND?: TournamentMatchWhereLogicAndInput[] | null;
  NOT?: TournamentMatchWhereLogicNotInput[] | null;
  OR?: TournamentMatchWhereLogicOrInput[] | null;
}

export interface TournamentMatchWhereLogicOrInput {
  active?: boolean | null;
  bucket?: TournamentMatchBucketFieldWhereInput | null;
  confirmed?: boolean | null;
  createdAt?: TournamentMatchCreatedAtFieldWhereInput | null;
  dateEnd?: TournamentMatchDateEndFieldWhereInput | null;
  dateStart?: TournamentMatchDateStartFieldWhereInput | null;
  embed?: TournamentMatchEmbedFieldWhereInput | null;
  id?: TournamentMatchIdFieldWhereInput | null;
  note?: TournamentMatchNoteFieldWhereInput | null;
  round?: TournamentMatchRoundFieldWhereInput | null;
  resultBy?: TournamentMatchResultByFieldWhereInput | null;
  disputeBy?: TournamentMatchDisputeByFieldWhereInput | null;
  stageId?: TournamentMatchStageIdFieldWhereInput | null;
  teamIdA?: TournamentMatchTeamIdAFieldWhereInput | null;
  teamIdB?: TournamentMatchTeamIdBFieldWhereInput | null;
  teamScoreA?: TournamentMatchTeamScoreAFieldWhereInput | null;
  teamScoreB?: TournamentMatchTeamScoreBFieldWhereInput | null;
  tournamentId?: TournamentMatchTournamentIdFieldWhereInput | null;
  fortunaLink?: TournamentMatchFortunaLinkFieldWhereInput | null;
  AND?: TournamentMatchWhereLogicAndInput[] | null;
  NOT?: TournamentMatchWhereLogicNotInput[] | null;
  OR?: TournamentMatchWhereLogicOrInput[] | null;
}

export interface TournamentMatchWhereScalarInput {
  OR?: (TournamentMatchWhereScalarInput | null)[] | null;
  AND?: (TournamentMatchWhereScalarInput | null)[] | null;
  NOT?: (TournamentMatchWhereScalarInput | null)[] | null;
  id?: TournamentMatch_IdFieldWhereScalarInput | null;
  createdAt?: TournamentMatch_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentMatch_UpdatedAtFieldWhereScalarInput | null;
  tournamentId?: TournamentMatch_TournamentIdFieldWhereScalarInput | null;
  dateStart?: TournamentMatch_DateStartFieldWhereScalarInput | null;
  dateEnd?: TournamentMatch_DateEndFieldWhereScalarInput | null;
  result?: TournamentMatch_ResultFieldWhereScalarInput | null;
  bucket?: TournamentMatch_BucketFieldWhereScalarInput | null;
  siblingMatchId?: TournamentMatch_SiblingMatchIdFieldWhereScalarInput | null;
  childMatchId?: TournamentMatch_ChildMatchIdFieldWhereScalarInput | null;
  round?: TournamentMatch_RoundFieldWhereScalarInput | null;
  active?: TournamentMatch_ActiveFieldWhereScalarInput | null;
  stageId?: TournamentMatch_StageIdFieldWhereScalarInput | null;
  note?: TournamentMatch_NoteFieldWhereScalarInput | null;
  loserMatchId?: TournamentMatch_LoserMatchIdFieldWhereScalarInput | null;
  teamIdA?: TournamentMatch_TeamIdAFieldWhereScalarInput | null;
  teamIdB?: TournamentMatch_TeamIdBFieldWhereScalarInput | null;
  ourRounds?: TournamentMatch_OurRoundsFieldWhereScalarInput | null;
  theirRounds?: TournamentMatch_TheirRoundsFieldWhereScalarInput | null;
  printScreen?: TournamentMatch_PrintScreenFieldWhereScalarInput | null;
  teamScoreA?: TournamentMatch_TeamScoreAFieldWhereScalarInput | null;
  teamScoreB?: TournamentMatch_TeamScoreBFieldWhereScalarInput | null;
  confirmed?: TournamentMatch_ConfirmedFieldWhereScalarInput | null;
  resultBy?: TournamentMatch_ResultByFieldWhereScalarInput | null;
  disputeBy?: TournamentMatch_DisputeByFieldWhereScalarInput | null;
  disputeScoreA?: TournamentMatch_DisputeScoreAFieldWhereScalarInput | null;
  disputeScoreB?: TournamentMatch_DisputeScoreBFieldWhereScalarInput | null;
  disputePrintScreen?: TournamentMatch_DisputePrintScreenFieldWhereScalarInput | null;
  twitchLink?: TournamentMatch_TwitchLinkFieldWhereScalarInput | null;
  embed?: TournamentMatch_EmbedFieldWhereScalarInput | null;
  fortunaLink?: TournamentMatch_FortunaLinkFieldWhereScalarInput | null;
}

export interface TournamentMatchWhereUniqueInput {
  id: number;
}

export interface TournamentMatch_ActiveFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentMatch_ActiveFieldWhereScalarInput | null;
}

export interface TournamentMatch_BucketFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_BucketFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_ChildMatchFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_ChildMatchFieldNestedCreateInput;
}

export interface TournamentMatch_ChildMatchFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_ChildMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_ChildMatchFieldNestedCreateInput | null;
}

export interface TournamentMatch_ChildMatchFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_ChildMatchFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_ChildMatchFieldNestedUpsertInput {
  create: TournamentMatch_ChildMatchFieldNestedCreateInput;
  update: TournamentMatch_ChildMatchFieldNestedUpdateInput;
}

export interface TournamentMatch_ChildMatchFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_ChildMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_ChildMatchFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_ChildMatchFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_ChildMatchFieldNestedUpsertInput | null;
}

export interface TournamentMatch_ChildMatchIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_ChildMatchIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_ConfirmedFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentMatch_ConfirmedFieldWhereScalarInput | null;
}

export interface TournamentMatch_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatch_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatch_DateEndFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatch_DateEndFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatch_DateStartFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatch_DateStartFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatch_DisputeByFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_DisputeByFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_DisputePrintScreenFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_DisputePrintScreenFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_DisputeScoreAFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_DisputeScoreAFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_DisputeScoreBFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_DisputeScoreBFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_EmbedFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_EmbedFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_FortunaLinkFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_FortunaLinkFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_LoserMatchFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_LoserMatchFieldNestedCreateInput;
}

export interface TournamentMatch_LoserMatchFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_LoserMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_LoserMatchFieldNestedCreateInput | null;
}

export interface TournamentMatch_LoserMatchFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_LoserMatchFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_LoserMatchFieldNestedUpsertInput {
  create: TournamentMatch_LoserMatchFieldNestedCreateInput;
  update: TournamentMatch_LoserMatchFieldNestedUpdateInput;
}

export interface TournamentMatch_LoserMatchFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_LoserMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_LoserMatchFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_LoserMatchFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_LoserMatchFieldNestedUpsertInput | null;
}

export interface TournamentMatch_LoserMatchIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_LoserMatchIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_LoserMatchParentsFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_LoserMatchParentsFieldNestedCreateInput;
}

export interface TournamentMatch_LoserMatchParentsFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_LoserMatchParentsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_LoserMatchParentsFieldNestedCreateInput[] | null;
}

export interface TournamentMatch_LoserMatchParentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_LoserMatchParentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_LoserMatchParentsFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatch_LoserMatchParentsFieldNestedUpdateDataInput;
}

export interface TournamentMatch_LoserMatchParentsFieldNestedUpsertInput {
  create: TournamentMatch_LoserMatchParentsFieldNestedCreateInput;
  update: TournamentMatch_LoserMatchParentsFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentMatch_LoserMatchParentsFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_LoserMatchParentsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_LoserMatchParentsFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentMatch_LoserMatchParentsFieldNestedUpdateInput[] | null;
  upsert?: TournamentMatch_LoserMatchParentsFieldNestedUpsertInput[] | null;
}

export interface TournamentMatch_NoteFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_NoteFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_OurRoundsFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_OurRoundsFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_ParentMatchesFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_ParentMatchesFieldNestedCreateInput;
}

export interface TournamentMatch_ParentMatchesFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_ParentMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_ParentMatchesFieldNestedCreateInput[] | null;
}

export interface TournamentMatch_ParentMatchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_ParentMatchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_ParentMatchesFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatch_ParentMatchesFieldNestedUpdateDataInput;
}

export interface TournamentMatch_ParentMatchesFieldNestedUpsertInput {
  create: TournamentMatch_ParentMatchesFieldNestedCreateInput;
  update: TournamentMatch_ParentMatchesFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentMatch_ParentMatchesFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_ParentMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_ParentMatchesFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentMatch_ParentMatchesFieldNestedUpdateInput[] | null;
  upsert?: TournamentMatch_ParentMatchesFieldNestedUpsertInput[] | null;
}

export interface TournamentMatch_PrintScreenFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_PrintScreenFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_ResultByFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_ResultByFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_ResultFieldWhereScalarInput {
  equals?: any | null;
  not?: TournamentMatch_ResultFieldWhereScalarInput | null;
}

export interface TournamentMatch_RoundFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_RoundFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_RoundsFieldConnectOrCreateInput {
  where: TournamentMatchRoundWhereUniqueInput;
  create: TournamentMatch_RoundsFieldNestedCreateInput;
}

export interface TournamentMatch_RoundsFieldCreateInput {
  connect?: TournamentMatchRoundWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_RoundsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_RoundsFieldNestedCreateInput[] | null;
}

export interface TournamentMatch_RoundsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  mapId?: number | null;
  teamScoreA: number;
  teamScoreB: number;
  map?: TournamentMatchRound_MapFieldCreateInput | null;
}

export interface TournamentMatch_RoundsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  mapId?: number | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  map?: TournamentMatchRound_MapFieldUpdateInput | null;
}

export interface TournamentMatch_RoundsFieldNestedUpdateInput {
  where: TournamentMatchRoundWhereUniqueInput;
  data: TournamentMatch_RoundsFieldNestedUpdateDataInput;
}

export interface TournamentMatch_RoundsFieldNestedUpsertInput {
  create: TournamentMatch_RoundsFieldNestedCreateInput;
  update: TournamentMatch_RoundsFieldNestedUpdateDataInput;
  where: TournamentMatchRoundWhereUniqueInput;
}

export interface TournamentMatch_RoundsFieldUpdateInput {
  connect?: TournamentMatchRoundWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_RoundsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_RoundsFieldNestedCreateInput[] | null;
  delete?: TournamentMatchRoundWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchRoundWhereScalarInput | null;
  disconnect?: TournamentMatchRoundWhereUniqueInput[] | null;
  set?: TournamentMatchRoundWhereUniqueInput[] | null;
  update?: TournamentMatch_RoundsFieldNestedUpdateInput[] | null;
  upsert?: TournamentMatch_RoundsFieldNestedUpsertInput[] | null;
}

export interface TournamentMatch_SiblingMatchFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_SiblingMatchFieldNestedCreateInput;
}

export interface TournamentMatch_SiblingMatchFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_SiblingMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_SiblingMatchFieldNestedCreateInput | null;
}

export interface TournamentMatch_SiblingMatchFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_SiblingMatchFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_SiblingMatchFieldNestedUpsertInput {
  create: TournamentMatch_SiblingMatchFieldNestedCreateInput;
  update: TournamentMatch_SiblingMatchFieldNestedUpdateInput;
}

export interface TournamentMatch_SiblingMatchFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_SiblingMatchFieldConnectOrCreateInput | null;
  create?: TournamentMatch_SiblingMatchFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_SiblingMatchFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_SiblingMatchFieldNestedUpsertInput | null;
}

export interface TournamentMatch_SiblingMatchIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_SiblingMatchIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_SiblingMatchParentsFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentMatch_SiblingMatchParentsFieldNestedCreateInput;
}

export interface TournamentMatch_SiblingMatchParentsFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_SiblingMatchParentsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_SiblingMatchParentsFieldNestedCreateInput[] | null;
}

export interface TournamentMatch_SiblingMatchParentsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentMatch_SiblingMatchParentsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentMatch_SiblingMatchParentsFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentMatch_SiblingMatchParentsFieldNestedUpdateDataInput;
}

export interface TournamentMatch_SiblingMatchParentsFieldNestedUpsertInput {
  create: TournamentMatch_SiblingMatchParentsFieldNestedCreateInput;
  update: TournamentMatch_SiblingMatchParentsFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentMatch_SiblingMatchParentsFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentMatch_SiblingMatchParentsFieldConnectOrCreateInput[] | null;
  create?: TournamentMatch_SiblingMatchParentsFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentMatch_SiblingMatchParentsFieldNestedUpdateInput[] | null;
  upsert?: TournamentMatch_SiblingMatchParentsFieldNestedUpsertInput[] | null;
}

export interface TournamentMatch_StageFieldConnectOrCreateInput {
  where: TournamentStageWhereUniqueInput;
  create: TournamentMatch_StageFieldNestedCreateInput;
}

export interface TournamentMatch_StageFieldCreateInput {
  connect?: TournamentStageWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_StageFieldConnectOrCreateInput | null;
  create?: TournamentMatch_StageFieldNestedCreateInput | null;
}

export interface TournamentMatch_StageFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  tournament?: TournamentStage_TournamentFieldCreateInput | null;
  type: TournamentStageType;
  prizes?: TournamentStage_PrizesFieldCreateInput | null;
}

export interface TournamentMatch_StageFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  tournament?: TournamentStage_TournamentFieldUpdateInput | null;
  type?: TournamentStageType | null;
  prizes?: TournamentStage_PrizesFieldUpdateInput | null;
}

export interface TournamentMatch_StageFieldNestedUpsertInput {
  create: TournamentMatch_StageFieldNestedCreateInput;
  update: TournamentMatch_StageFieldNestedUpdateInput;
}

export interface TournamentMatch_StageFieldUpdateInput {
  connect?: TournamentStageWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_StageFieldConnectOrCreateInput | null;
  create?: TournamentMatch_StageFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_StageFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_StageFieldNestedUpsertInput | null;
}

export interface TournamentMatch_StageIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_StageIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TeamAFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: TournamentMatch_TeamAFieldNestedCreateInput;
}

export interface TournamentMatch_TeamAFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TeamAFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TeamAFieldNestedCreateInput | null;
}

export interface TournamentMatch_TeamAFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface TournamentMatch_TeamAFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface TournamentMatch_TeamAFieldNestedUpsertInput {
  create: TournamentMatch_TeamAFieldNestedCreateInput;
  update: TournamentMatch_TeamAFieldNestedUpdateInput;
}

export interface TournamentMatch_TeamAFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TeamAFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TeamAFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_TeamAFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_TeamAFieldNestedUpsertInput | null;
}

export interface TournamentMatch_TeamBFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: TournamentMatch_TeamBFieldNestedCreateInput;
}

export interface TournamentMatch_TeamBFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TeamBFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TeamBFieldNestedCreateInput | null;
}

export interface TournamentMatch_TeamBFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface TournamentMatch_TeamBFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface TournamentMatch_TeamBFieldNestedUpsertInput {
  create: TournamentMatch_TeamBFieldNestedCreateInput;
  update: TournamentMatch_TeamBFieldNestedUpdateInput;
}

export interface TournamentMatch_TeamBFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TeamBFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TeamBFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_TeamBFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_TeamBFieldNestedUpsertInput | null;
}

export interface TournamentMatch_TeamIdAFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TeamIdAFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TeamIdBFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TeamIdBFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TeamScoreAFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TeamScoreAFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TeamScoreBFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TeamScoreBFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TheirRoundsFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TheirRoundsFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentMatch_TournamentFieldNestedCreateInput;
}

export interface TournamentMatch_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TournamentFieldNestedCreateInput | null;
}

export interface TournamentMatch_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentMatch_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentMatch_TournamentFieldNestedUpsertInput {
  create: TournamentMatch_TournamentFieldNestedCreateInput;
  update: TournamentMatch_TournamentFieldNestedUpdateInput;
}

export interface TournamentMatch_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentMatch_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentMatch_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentMatch_TournamentFieldNestedUpdateInput | null;
  upsert?: TournamentMatch_TournamentFieldNestedUpsertInput | null;
}

export interface TournamentMatch_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentMatch_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentMatch_TwitchLinkFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentMatch_TwitchLinkFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentMatch_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentMatch_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentMatchesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentMatchesFieldWhereInput {
  every?: TournamentMatchWhereInput | null;
  none?: TournamentMatchWhereInput | null;
  some?: TournamentMatchWhereInput | null;
}

export interface TournamentNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentOrderByInput {
  capMax?: SortOrder | null;
  capMin?: SortOrder | null;
  checkInEndTime?: SortOrder | null;
  contact?: SortOrder | null;
  createdAt?: SortOrder | null;
  startDate?: SortOrder | null;
  endDate?: SortOrder | null;
  description?: SortOrder | null;
  game?: GameOrderByInput | null;
  gameId?: SortOrder | null;
  groupingValue?: TournamentGroupingValueOrderByInput | null;
  groups?: TournamentGroupsFieldOrderByInput | null;
  id?: SortOrder | null;
  mailSubject?: SortOrder | null;
  mailText?: SortOrder | null;
  matches?: TournamentMatchesFieldOrderByInput | null;
  name?: SortOrder | null;
  prizes?: TournamentPrizesFieldOrderByInput | null;
  updatedAt?: SortOrder | null;
}

export interface TournamentPrizeActionWhereScalarInput {
  OR?: (TournamentPrizeActionWhereScalarInput | null)[] | null;
  AND?: (TournamentPrizeActionWhereScalarInput | null)[] | null;
  NOT?: (TournamentPrizeActionWhereScalarInput | null)[] | null;
  id?: TournamentPrizeAction_IdFieldWhereScalarInput | null;
  createdAt?: TournamentPrizeAction_CreatedAtFieldWhereScalarInput | null;
  prizeId?: TournamentPrizeAction_PrizeIdFieldWhereScalarInput | null;
  teamId?: TournamentPrizeAction_TeamIdFieldWhereScalarInput | null;
  state?: TournamentPrizeAction_StateFieldWhereScalarInput | null;
  authorId?: TournamentPrizeAction_AuthorIdFieldWhereScalarInput | null;
}

export interface TournamentPrizeActionWhereUniqueInput {
  id: number;
}

export interface TournamentPrizeAction_AuthorFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: TournamentPrizeAction_AuthorFieldNestedCreateInput;
}

export interface TournamentPrizeAction_AuthorFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_AuthorFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_AuthorFieldNestedCreateInput | null;
}

export interface TournamentPrizeAction_AuthorFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  articles?: User_ArticlesFieldCreateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface TournamentPrizeAction_AuthorFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  articles?: User_ArticlesFieldUpdateInput | null;
  tournamentTeams?: User_TournamentTeamsFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface TournamentPrizeAction_AuthorFieldNestedUpsertInput {
  create: TournamentPrizeAction_AuthorFieldNestedCreateInput;
  update: TournamentPrizeAction_AuthorFieldNestedUpdateInput;
}

export interface TournamentPrizeAction_AuthorFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_AuthorFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_AuthorFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrizeAction_AuthorFieldNestedUpdateInput | null;
  upsert?: TournamentPrizeAction_AuthorFieldNestedUpsertInput | null;
}

export interface TournamentPrizeAction_AuthorIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeAction_AuthorIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeAction_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentPrizeAction_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentPrizeAction_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeAction_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeAction_PrizeFieldConnectOrCreateInput {
  where: TournamentPrizeWhereUniqueInput;
  create: TournamentPrizeAction_PrizeFieldNestedCreateInput;
}

export interface TournamentPrizeAction_PrizeFieldCreateInput {
  connect?: TournamentPrizeWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_PrizeFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_PrizeFieldNestedCreateInput | null;
}

export interface TournamentPrizeAction_PrizeFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldCreateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldCreateInput | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  quantity: number;
  placement: number[];
  state?: TournamentPrizeState | null;
  tournamentId: number;
  tournament?: TournamentPrize_TournamentFieldCreateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldCreateInput | null;
  winners?: TournamentPrize_WinnersFieldCreateInput | null;
}

export interface TournamentPrizeAction_PrizeFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldUpdateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldUpdateInput | null;
  type?: TournamentPrizeType | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: number | null;
  placement?: number[] | null;
  state?: TournamentPrizeState | null;
  tournamentId?: number | null;
  tournament?: TournamentPrize_TournamentFieldUpdateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldUpdateInput | null;
  winners?: TournamentPrize_WinnersFieldUpdateInput | null;
}

export interface TournamentPrizeAction_PrizeFieldNestedUpsertInput {
  create: TournamentPrizeAction_PrizeFieldNestedCreateInput;
  update: TournamentPrizeAction_PrizeFieldNestedUpdateInput;
}

export interface TournamentPrizeAction_PrizeFieldUpdateInput {
  connect?: TournamentPrizeWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_PrizeFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_PrizeFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrizeAction_PrizeFieldNestedUpdateInput | null;
  upsert?: TournamentPrizeAction_PrizeFieldNestedUpsertInput | null;
}

export interface TournamentPrizeAction_PrizeIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeAction_PrizeIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeAction_StateFieldWhereScalarInput {
  equals?: TournamentPrizeState | null;
  in?: TournamentPrizeState[] | null;
  not?: TournamentPrizeAction_StateFieldWhereScalarInput | null;
  notIn?: TournamentPrizeState[] | null;
}

export interface TournamentPrizeAction_TeamFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: TournamentPrizeAction_TeamFieldNestedCreateInput;
}

export interface TournamentPrizeAction_TeamFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_TeamFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_TeamFieldNestedCreateInput | null;
}

export interface TournamentPrizeAction_TeamFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
}

export interface TournamentPrizeAction_TeamFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
}

export interface TournamentPrizeAction_TeamFieldNestedUpsertInput {
  create: TournamentPrizeAction_TeamFieldNestedCreateInput;
  update: TournamentPrizeAction_TeamFieldNestedUpdateInput;
}

export interface TournamentPrizeAction_TeamFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput | null;
  connectOrCreate?: TournamentPrizeAction_TeamFieldConnectOrCreateInput | null;
  create?: TournamentPrizeAction_TeamFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrizeAction_TeamFieldNestedUpdateInput | null;
  upsert?: TournamentPrizeAction_TeamFieldNestedUpsertInput | null;
}

export interface TournamentPrizeAction_TeamIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeAction_TeamIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentPrizeCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentPrizeCurrencyIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeCurrencyIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeCustomTypeIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeCustomTypeIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeInput {
  customTypeName?: string | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  currencyId?: number | null;
  quantity: number;
  placement: number[];
  tournamentId: number;
  tournamentStageId?: number | null;
}

export interface TournamentPrizeQuantityFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeQuantityFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeTournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeTournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeTournamentStageIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrizeTournamentStageIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrizeWhereInput {
  createdAt?: TournamentPrizeCreatedAtFieldWhereInput | null;
  currencyId?: TournamentPrizeCurrencyIdFieldWhereInput | null;
  customType?: TournamentCustomPrizeTypeWhereInput | null;
  customTypeId?: TournamentPrizeCustomTypeIdFieldWhereInput | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: TournamentPrizeQuantityFieldWhereInput | null;
  state?: TournamentPrizeState | null;
  tournamentId?: TournamentPrizeTournamentIdFieldWhereInput | null;
  id?: TournamentPrizeIdFieldWhereInput | null;
  tournamentStageId?: TournamentPrizeTournamentStageIdFieldWhereInput | null;
  type?: TournamentPrizeType | null;
  AND?: TournamentPrizeWhereLogicAndInput[] | null;
  NOT?: TournamentPrizeWhereLogicNotInput[] | null;
  OR?: TournamentPrizeWhereLogicOrInput[] | null;
}

export interface TournamentPrizeWhereLogicAndInput {
  createdAt?: TournamentPrizeCreatedAtFieldWhereInput | null;
  currencyId?: TournamentPrizeCurrencyIdFieldWhereInput | null;
  customType?: TournamentCustomPrizeTypeWhereInput | null;
  customTypeId?: TournamentPrizeCustomTypeIdFieldWhereInput | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: TournamentPrizeQuantityFieldWhereInput | null;
  state?: TournamentPrizeState | null;
  tournamentId?: TournamentPrizeTournamentIdFieldWhereInput | null;
  id?: TournamentPrizeIdFieldWhereInput | null;
  tournamentStageId?: TournamentPrizeTournamentStageIdFieldWhereInput | null;
  type?: TournamentPrizeType | null;
  AND?: TournamentPrizeWhereLogicAndInput[] | null;
  NOT?: TournamentPrizeWhereLogicNotInput[] | null;
  OR?: TournamentPrizeWhereLogicOrInput[] | null;
}

export interface TournamentPrizeWhereLogicNotInput {
  createdAt?: TournamentPrizeCreatedAtFieldWhereInput | null;
  currencyId?: TournamentPrizeCurrencyIdFieldWhereInput | null;
  customType?: TournamentCustomPrizeTypeWhereInput | null;
  customTypeId?: TournamentPrizeCustomTypeIdFieldWhereInput | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: TournamentPrizeQuantityFieldWhereInput | null;
  state?: TournamentPrizeState | null;
  tournamentId?: TournamentPrizeTournamentIdFieldWhereInput | null;
  id?: TournamentPrizeIdFieldWhereInput | null;
  tournamentStageId?: TournamentPrizeTournamentStageIdFieldWhereInput | null;
  type?: TournamentPrizeType | null;
  AND?: TournamentPrizeWhereLogicAndInput[] | null;
  NOT?: TournamentPrizeWhereLogicNotInput[] | null;
  OR?: TournamentPrizeWhereLogicOrInput[] | null;
}

export interface TournamentPrizeWhereLogicOrInput {
  createdAt?: TournamentPrizeCreatedAtFieldWhereInput | null;
  currencyId?: TournamentPrizeCurrencyIdFieldWhereInput | null;
  customType?: TournamentCustomPrizeTypeWhereInput | null;
  customTypeId?: TournamentPrizeCustomTypeIdFieldWhereInput | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: TournamentPrizeQuantityFieldWhereInput | null;
  state?: TournamentPrizeState | null;
  tournamentId?: TournamentPrizeTournamentIdFieldWhereInput | null;
  id?: TournamentPrizeIdFieldWhereInput | null;
  tournamentStageId?: TournamentPrizeTournamentStageIdFieldWhereInput | null;
  type?: TournamentPrizeType | null;
  AND?: TournamentPrizeWhereLogicAndInput[] | null;
  NOT?: TournamentPrizeWhereLogicNotInput[] | null;
  OR?: TournamentPrizeWhereLogicOrInput[] | null;
}

export interface TournamentPrizeWhereScalarInput {
  OR?: (TournamentPrizeWhereScalarInput | null)[] | null;
  AND?: (TournamentPrizeWhereScalarInput | null)[] | null;
  NOT?: (TournamentPrizeWhereScalarInput | null)[] | null;
  id?: TournamentPrize_IdFieldWhereScalarInput | null;
  createdAt?: TournamentPrize_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentPrize_UpdatedAtFieldWhereScalarInput | null;
  customTypeId?: TournamentPrize_CustomTypeIdFieldWhereScalarInput | null;
  currencyId?: TournamentPrize_CurrencyIdFieldWhereScalarInput | null;
  type?: TournamentPrize_TypeFieldWhereScalarInput | null;
  placementType?: TournamentPrize_PlacementTypeFieldWhereScalarInput | null;
  quantity?: TournamentPrize_QuantityFieldWhereScalarInput | null;
  placement?: TournamentPrize_PlacementFieldWhereScalarInput | null;
  state?: TournamentPrize_StateFieldWhereScalarInput | null;
  tournamentId?: TournamentPrize_TournamentIdFieldWhereScalarInput | null;
  tournamentStageId?: TournamentPrize_TournamentStageIdFieldWhereScalarInput | null;
}

export interface TournamentPrizeWhereUniqueInput {
  id: number;
}

export interface TournamentPrize_ActionHistoryFieldConnectOrCreateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  create: TournamentPrize_ActionHistoryFieldNestedCreateInput;
}

export interface TournamentPrize_ActionHistoryFieldCreateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: TournamentPrize_ActionHistoryFieldConnectOrCreateInput[] | null;
  create?: TournamentPrize_ActionHistoryFieldNestedCreateInput[] | null;
}

export interface TournamentPrize_ActionHistoryFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  teamId?: number | null;
  state: TournamentPrizeState;
  team?: TournamentPrizeAction_TeamFieldCreateInput | null;
  authorId?: number | null;
  author?: TournamentPrizeAction_AuthorFieldCreateInput | null;
}

export interface TournamentPrize_ActionHistoryFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  teamId?: number | null;
  state?: TournamentPrizeState | null;
  team?: TournamentPrizeAction_TeamFieldUpdateInput | null;
  authorId?: number | null;
  author?: TournamentPrizeAction_AuthorFieldUpdateInput | null;
}

export interface TournamentPrize_ActionHistoryFieldNestedUpdateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  data: TournamentPrize_ActionHistoryFieldNestedUpdateDataInput;
}

export interface TournamentPrize_ActionHistoryFieldNestedUpsertInput {
  create: TournamentPrize_ActionHistoryFieldNestedCreateInput;
  update: TournamentPrize_ActionHistoryFieldNestedUpdateDataInput;
  where: TournamentPrizeActionWhereUniqueInput;
}

export interface TournamentPrize_ActionHistoryFieldUpdateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: TournamentPrize_ActionHistoryFieldConnectOrCreateInput[] | null;
  create?: TournamentPrize_ActionHistoryFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeActionWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeActionWhereScalarInput | null;
  disconnect?: TournamentPrizeActionWhereUniqueInput[] | null;
  set?: TournamentPrizeActionWhereUniqueInput[] | null;
  update?: TournamentPrize_ActionHistoryFieldNestedUpdateInput[] | null;
  upsert?: TournamentPrize_ActionHistoryFieldNestedUpsertInput[] | null;
}

export interface TournamentPrize_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentPrize_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentPrize_CurrencyFieldConnectOrCreateInput {
  where: CurrencyWhereUniqueInput;
  create: TournamentPrize_CurrencyFieldNestedCreateInput;
}

export interface TournamentPrize_CurrencyFieldCreateInput {
  connect?: CurrencyWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_CurrencyFieldConnectOrCreateInput | null;
  create?: TournamentPrize_CurrencyFieldNestedCreateInput | null;
}

export interface TournamentPrize_CurrencyFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  code: string;
  icon?: string | null;
  wallets?: Currency_WalletsFieldCreateInput | null;
}

export interface TournamentPrize_CurrencyFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  code?: string | null;
  icon?: string | null;
  wallets?: Currency_WalletsFieldUpdateInput | null;
}

export interface TournamentPrize_CurrencyFieldNestedUpsertInput {
  create: TournamentPrize_CurrencyFieldNestedCreateInput;
  update: TournamentPrize_CurrencyFieldNestedUpdateInput;
}

export interface TournamentPrize_CurrencyFieldUpdateInput {
  connect?: CurrencyWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_CurrencyFieldConnectOrCreateInput | null;
  create?: TournamentPrize_CurrencyFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrize_CurrencyFieldNestedUpdateInput | null;
  upsert?: TournamentPrize_CurrencyFieldNestedUpsertInput | null;
}

export interface TournamentPrize_CurrencyIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_CurrencyIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_CustomTypeFieldConnectOrCreateInput {
  where: TournamentCustomPrizeTypeWhereUniqueInput;
  create: TournamentPrize_CustomTypeFieldNestedCreateInput;
}

export interface TournamentPrize_CustomTypeFieldCreateInput {
  connect?: TournamentCustomPrizeTypeWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_CustomTypeFieldConnectOrCreateInput | null;
  create?: TournamentPrize_CustomTypeFieldNestedCreateInput | null;
}

export interface TournamentPrize_CustomTypeFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
}

export interface TournamentPrize_CustomTypeFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
}

export interface TournamentPrize_CustomTypeFieldNestedUpsertInput {
  create: TournamentPrize_CustomTypeFieldNestedCreateInput;
  update: TournamentPrize_CustomTypeFieldNestedUpdateInput;
}

export interface TournamentPrize_CustomTypeFieldUpdateInput {
  connect?: TournamentCustomPrizeTypeWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_CustomTypeFieldConnectOrCreateInput | null;
  create?: TournamentPrize_CustomTypeFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrize_CustomTypeFieldNestedUpdateInput | null;
  upsert?: TournamentPrize_CustomTypeFieldNestedUpsertInput | null;
}

export interface TournamentPrize_CustomTypeIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_CustomTypeIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_PlacementFieldWhereScalarInput {
  equals?: number[] | null;
  has?: number | null;
  hasEvery?: number[] | null;
  hasSome?: number[] | null;
  isEmpty?: boolean | null;
}

export interface TournamentPrize_PlacementTypeFieldWhereScalarInput {
  equals?: TournamentPrizePlacementType | null;
  in?: TournamentPrizePlacementType[] | null;
  not?: TournamentPrize_PlacementTypeFieldWhereScalarInput | null;
  notIn?: TournamentPrizePlacementType[] | null;
}

export interface TournamentPrize_QuantityFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_QuantityFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_StateFieldWhereScalarInput {
  equals?: TournamentPrizeState | null;
  in?: TournamentPrizeState[] | null;
  not?: TournamentPrize_StateFieldWhereScalarInput | null;
  notIn?: TournamentPrizeState[] | null;
}

export interface TournamentPrize_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentPrize_TournamentFieldNestedCreateInput;
}

export interface TournamentPrize_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentPrize_TournamentFieldNestedCreateInput | null;
}

export interface TournamentPrize_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentPrize_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentPrize_TournamentFieldNestedUpsertInput {
  create: TournamentPrize_TournamentFieldNestedCreateInput;
  update: TournamentPrize_TournamentFieldNestedUpdateInput;
}

export interface TournamentPrize_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentPrize_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrize_TournamentFieldNestedUpdateInput | null;
  upsert?: TournamentPrize_TournamentFieldNestedUpsertInput | null;
}

export interface TournamentPrize_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_TournamentStageFieldConnectOrCreateInput {
  where: TournamentStageWhereUniqueInput;
  create: TournamentPrize_TournamentStageFieldNestedCreateInput;
}

export interface TournamentPrize_TournamentStageFieldCreateInput {
  connect?: TournamentStageWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_TournamentStageFieldConnectOrCreateInput | null;
  create?: TournamentPrize_TournamentStageFieldNestedCreateInput | null;
}

export interface TournamentPrize_TournamentStageFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  tournament?: TournamentStage_TournamentFieldCreateInput | null;
  type: TournamentStageType;
  matches?: TournamentStage_MatchesFieldCreateInput | null;
}

export interface TournamentPrize_TournamentStageFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  tournament?: TournamentStage_TournamentFieldUpdateInput | null;
  type?: TournamentStageType | null;
  matches?: TournamentStage_MatchesFieldUpdateInput | null;
}

export interface TournamentPrize_TournamentStageFieldNestedUpsertInput {
  create: TournamentPrize_TournamentStageFieldNestedCreateInput;
  update: TournamentPrize_TournamentStageFieldNestedUpdateInput;
}

export interface TournamentPrize_TournamentStageFieldUpdateInput {
  connect?: TournamentStageWhereUniqueInput | null;
  connectOrCreate?: TournamentPrize_TournamentStageFieldConnectOrCreateInput | null;
  create?: TournamentPrize_TournamentStageFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentPrize_TournamentStageFieldNestedUpdateInput | null;
  upsert?: TournamentPrize_TournamentStageFieldNestedUpsertInput | null;
}

export interface TournamentPrize_TournamentStageIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentPrize_TournamentStageIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentPrize_TypeFieldWhereScalarInput {
  equals?: TournamentPrizeType | null;
  in?: TournamentPrizeType[] | null;
  not?: TournamentPrize_TypeFieldWhereScalarInput | null;
  notIn?: TournamentPrizeType[] | null;
}

export interface TournamentPrize_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentPrize_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentPrize_WinnersFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: TournamentPrize_WinnersFieldNestedCreateInput;
}

export interface TournamentPrize_WinnersFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: TournamentPrize_WinnersFieldConnectOrCreateInput[] | null;
  create?: TournamentPrize_WinnersFieldNestedCreateInput[] | null;
}

export interface TournamentPrize_WinnersFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface TournamentPrize_WinnersFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface TournamentPrize_WinnersFieldNestedUpdateInput {
  where: TournamentTeamWhereUniqueInput;
  data: TournamentPrize_WinnersFieldNestedUpdateDataInput;
}

export interface TournamentPrize_WinnersFieldNestedUpsertInput {
  create: TournamentPrize_WinnersFieldNestedCreateInput;
  update: TournamentPrize_WinnersFieldNestedUpdateDataInput;
  where: TournamentTeamWhereUniqueInput;
}

export interface TournamentPrize_WinnersFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: TournamentPrize_WinnersFieldConnectOrCreateInput[] | null;
  create?: TournamentPrize_WinnersFieldNestedCreateInput[] | null;
  delete?: TournamentTeamWhereUniqueInput[] | null;
  deleteMany?: TournamentTeamWhereScalarInput | null;
  disconnect?: TournamentTeamWhereUniqueInput[] | null;
  set?: TournamentTeamWhereUniqueInput[] | null;
  update?: TournamentPrize_WinnersFieldNestedUpdateInput[] | null;
  upsert?: TournamentPrize_WinnersFieldNestedUpsertInput[] | null;
}

export interface TournamentPrizesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentRulesInput {
  title?: string | null;
  text?: string | null;
}

export interface TournamentStageDateEndFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStageDateEndFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStageDateStartFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStageDateStartFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStageEnteringFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStageEnteringFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentStageIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStageIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentStageInput {
  tournamentId: number;
  type: TournamentStageType;
  dateStart?: any | null;
  dateEnd?: any | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
}

export interface TournamentStageLeavingFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStageLeavingFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentStageNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentStageNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentStageOrderByInput {
  active?: SortOrder | null;
  dateEnd?: SortOrder | null;
  dateStart?: SortOrder | null;
  entering?: SortOrder | null;
  hasLoserMatch?: SortOrder | null;
  id?: SortOrder | null;
  leaving?: SortOrder | null;
  name?: SortOrder | null;
  prizes?: TournamentStagePrizesFieldOrderByInput | null;
  tournament?: TournamentOrderByInput | null;
  tournamentId?: SortOrder | null;
  type?: SortOrder | null;
}

export interface TournamentStagePrizesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface TournamentStageTournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStageTournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentStageTypeFieldWhereInput {
  equals?: TournamentStageType | null;
  in?: TournamentStageType[] | null;
  not?: TournamentStageTypeFieldWhereInput | null;
  notIn?: TournamentStageType[] | null;
}

export interface TournamentStageWhereInput {
  active?: boolean | null;
  dateEnd?: TournamentStageDateEndFieldWhereInput | null;
  dateStart?: TournamentStageDateStartFieldWhereInput | null;
  entering?: TournamentStageEnteringFieldWhereInput | null;
  hasLoserMatch?: boolean | null;
  id?: TournamentStageIdFieldWhereInput | null;
  leaving?: TournamentStageLeavingFieldWhereInput | null;
  name?: TournamentStageNameFieldWhereInput | null;
  type?: TournamentStageTypeFieldWhereInput | null;
  tournamentId?: TournamentStageTournamentIdFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentStageWhereLogicAndInput[] | null;
  NOT?: TournamentStageWhereLogicNotInput[] | null;
  OR?: TournamentStageWhereLogicOrInput[] | null;
}

export interface TournamentStageWhereLogicAndInput {
  active?: boolean | null;
  dateEnd?: TournamentStageDateEndFieldWhereInput | null;
  dateStart?: TournamentStageDateStartFieldWhereInput | null;
  entering?: TournamentStageEnteringFieldWhereInput | null;
  hasLoserMatch?: boolean | null;
  id?: TournamentStageIdFieldWhereInput | null;
  leaving?: TournamentStageLeavingFieldWhereInput | null;
  name?: TournamentStageNameFieldWhereInput | null;
  type?: TournamentStageTypeFieldWhereInput | null;
  tournamentId?: TournamentStageTournamentIdFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentStageWhereLogicAndInput[] | null;
  NOT?: TournamentStageWhereLogicNotInput[] | null;
  OR?: TournamentStageWhereLogicOrInput[] | null;
}

export interface TournamentStageWhereLogicNotInput {
  active?: boolean | null;
  dateEnd?: TournamentStageDateEndFieldWhereInput | null;
  dateStart?: TournamentStageDateStartFieldWhereInput | null;
  entering?: TournamentStageEnteringFieldWhereInput | null;
  hasLoserMatch?: boolean | null;
  id?: TournamentStageIdFieldWhereInput | null;
  leaving?: TournamentStageLeavingFieldWhereInput | null;
  name?: TournamentStageNameFieldWhereInput | null;
  type?: TournamentStageTypeFieldWhereInput | null;
  tournamentId?: TournamentStageTournamentIdFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentStageWhereLogicAndInput[] | null;
  NOT?: TournamentStageWhereLogicNotInput[] | null;
  OR?: TournamentStageWhereLogicOrInput[] | null;
}

export interface TournamentStageWhereLogicOrInput {
  active?: boolean | null;
  dateEnd?: TournamentStageDateEndFieldWhereInput | null;
  dateStart?: TournamentStageDateStartFieldWhereInput | null;
  entering?: TournamentStageEnteringFieldWhereInput | null;
  hasLoserMatch?: boolean | null;
  id?: TournamentStageIdFieldWhereInput | null;
  leaving?: TournamentStageLeavingFieldWhereInput | null;
  name?: TournamentStageNameFieldWhereInput | null;
  type?: TournamentStageTypeFieldWhereInput | null;
  tournamentId?: TournamentStageTournamentIdFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentStageWhereLogicAndInput[] | null;
  NOT?: TournamentStageWhereLogicNotInput[] | null;
  OR?: TournamentStageWhereLogicOrInput[] | null;
}

export interface TournamentStageWhereScalarInput {
  OR?: (TournamentStageWhereScalarInput | null)[] | null;
  AND?: (TournamentStageWhereScalarInput | null)[] | null;
  NOT?: (TournamentStageWhereScalarInput | null)[] | null;
  id?: TournamentStage_IdFieldWhereScalarInput | null;
  createdAt?: TournamentStage_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentStage_UpdatedAtFieldWhereScalarInput | null;
  tournamentId?: TournamentStage_TournamentIdFieldWhereScalarInput | null;
  dateStart?: TournamentStage_DateStartFieldWhereScalarInput | null;
  dateEnd?: TournamentStage_DateEndFieldWhereScalarInput | null;
  entering?: TournamentStage_EnteringFieldWhereScalarInput | null;
  leaving?: TournamentStage_LeavingFieldWhereScalarInput | null;
  active?: TournamentStage_ActiveFieldWhereScalarInput | null;
  name?: TournamentStage_NameFieldWhereScalarInput | null;
  hasLoserMatch?: TournamentStage_HasLoserMatchFieldWhereScalarInput | null;
  type?: TournamentStage_TypeFieldWhereScalarInput | null;
}

export interface TournamentStageWhereUniqueInput {
  id: number;
}

export interface TournamentStage_ActiveFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentStage_ActiveFieldWhereScalarInput | null;
}

export interface TournamentStage_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStage_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStage_DateEndFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStage_DateEndFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStage_DateStartFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStage_DateStartFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStage_EnteringFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStage_EnteringFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentStage_HasLoserMatchFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentStage_HasLoserMatchFieldWhereScalarInput | null;
}

export interface TournamentStage_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStage_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentStage_LeavingFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStage_LeavingFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentStage_MatchesFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentStage_MatchesFieldNestedCreateInput;
}

export interface TournamentStage_MatchesFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentStage_MatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentStage_MatchesFieldNestedCreateInput[] | null;
}

export interface TournamentStage_MatchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentStage_MatchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentStage_MatchesFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentStage_MatchesFieldNestedUpdateDataInput;
}

export interface TournamentStage_MatchesFieldNestedUpsertInput {
  create: TournamentStage_MatchesFieldNestedCreateInput;
  update: TournamentStage_MatchesFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentStage_MatchesFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentStage_MatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentStage_MatchesFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentStage_MatchesFieldNestedUpdateInput[] | null;
  upsert?: TournamentStage_MatchesFieldNestedUpsertInput[] | null;
}

export interface TournamentStage_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentStage_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentStage_PrizesFieldConnectOrCreateInput {
  where: TournamentPrizeWhereUniqueInput;
  create: TournamentStage_PrizesFieldNestedCreateInput;
}

export interface TournamentStage_PrizesFieldCreateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: TournamentStage_PrizesFieldConnectOrCreateInput[] | null;
  create?: TournamentStage_PrizesFieldNestedCreateInput[] | null;
}

export interface TournamentStage_PrizesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldCreateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldCreateInput | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  quantity: number;
  placement: number[];
  state?: TournamentPrizeState | null;
  tournamentId: number;
  tournament?: TournamentPrize_TournamentFieldCreateInput | null;
  winners?: TournamentPrize_WinnersFieldCreateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldCreateInput | null;
}

export interface TournamentStage_PrizesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldUpdateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldUpdateInput | null;
  type?: TournamentPrizeType | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: number | null;
  placement?: number[] | null;
  state?: TournamentPrizeState | null;
  tournamentId?: number | null;
  tournament?: TournamentPrize_TournamentFieldUpdateInput | null;
  winners?: TournamentPrize_WinnersFieldUpdateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldUpdateInput | null;
}

export interface TournamentStage_PrizesFieldNestedUpdateInput {
  where: TournamentPrizeWhereUniqueInput;
  data: TournamentStage_PrizesFieldNestedUpdateDataInput;
}

export interface TournamentStage_PrizesFieldNestedUpsertInput {
  create: TournamentStage_PrizesFieldNestedCreateInput;
  update: TournamentStage_PrizesFieldNestedUpdateDataInput;
  where: TournamentPrizeWhereUniqueInput;
}

export interface TournamentStage_PrizesFieldUpdateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: TournamentStage_PrizesFieldConnectOrCreateInput[] | null;
  create?: TournamentStage_PrizesFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeWhereScalarInput | null;
  disconnect?: TournamentPrizeWhereUniqueInput[] | null;
  set?: TournamentPrizeWhereUniqueInput[] | null;
  update?: TournamentStage_PrizesFieldNestedUpdateInput[] | null;
  upsert?: TournamentStage_PrizesFieldNestedUpsertInput[] | null;
}

export interface TournamentStage_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentStage_TournamentFieldNestedCreateInput;
}

export interface TournamentStage_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentStage_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentStage_TournamentFieldNestedCreateInput | null;
}

export interface TournamentStage_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  teams?: Tournament_TeamsFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentStage_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  teams?: Tournament_TeamsFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentStage_TournamentFieldNestedUpsertInput {
  create: TournamentStage_TournamentFieldNestedCreateInput;
  update: TournamentStage_TournamentFieldNestedUpdateInput;
}

export interface TournamentStage_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentStage_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentStage_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentStage_TournamentFieldNestedUpdateInput | null;
  upsert?: TournamentStage_TournamentFieldNestedUpsertInput | null;
}

export interface TournamentStage_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentStage_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentStage_TypeFieldWhereScalarInput {
  equals?: TournamentStageType | null;
  in?: TournamentStageType[] | null;
  not?: TournamentStage_TypeFieldWhereScalarInput | null;
  notIn?: TournamentStageType[] | null;
}

export interface TournamentStage_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStage_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentStartDateFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentStartDateFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentTeamContactFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeamContactFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeamCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentTeamCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentTeamDiscordFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeamDiscordFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeamIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeamIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeamInput {
  name: string;
  logo?: string | null;
  ownerId: number;
  isManager?: boolean | null;
  players: (any | null)[];
  custom?: any | null;
  teamId?: number | null;
  extra?: any | null;
  gameId?: number | null;
  manager?: any | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: string | null;
}

export interface TournamentTeamNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeamNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeamOrderByInput {
  confirmed?: SortOrder | null;
  contact?: SortOrder | null;
  createdAt?: SortOrder | null;
  discord?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
  ownerId?: SortOrder | null;
  state?: SortOrder | null;
  tournament?: TournamentOrderByInput | null;
  tournamentId?: SortOrder | null;
}

export interface TournamentTeamOwnerIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeamOwnerIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeamStateFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeamStateFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeamTournamentIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeamTournamentIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeamWhereInput {
  id?: TournamentTeamIdFieldWhereInput | null;
  confirmed?: boolean | null;
  contact?: TournamentTeamContactFieldWhereInput | null;
  name?: TournamentTeamNameFieldWhereInput | null;
  createdAt?: TournamentTeamCreatedAtFieldWhereInput | null;
  tournamentId?: TournamentTeamTournamentIdFieldWhereInput | null;
  discord?: TournamentTeamDiscordFieldWhereInput | null;
  ownerId?: TournamentTeamOwnerIdFieldWhereInput | null;
  pending?: boolean | null;
  state?: TournamentTeamStateFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentTeamWhereLogicAndInput[] | null;
  NOT?: TournamentTeamWhereLogicNotInput[] | null;
  OR?: TournamentTeamWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface TournamentTeamWhereLogicAndInput {
  id?: TournamentTeamIdFieldWhereInput | null;
  confirmed?: boolean | null;
  contact?: TournamentTeamContactFieldWhereInput | null;
  name?: TournamentTeamNameFieldWhereInput | null;
  createdAt?: TournamentTeamCreatedAtFieldWhereInput | null;
  tournamentId?: TournamentTeamTournamentIdFieldWhereInput | null;
  discord?: TournamentTeamDiscordFieldWhereInput | null;
  ownerId?: TournamentTeamOwnerIdFieldWhereInput | null;
  pending?: boolean | null;
  state?: TournamentTeamStateFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentTeamWhereLogicAndInput[] | null;
  NOT?: TournamentTeamWhereLogicNotInput[] | null;
  OR?: TournamentTeamWhereLogicOrInput[] | null;
}

export interface TournamentTeamWhereLogicNotInput {
  id?: TournamentTeamIdFieldWhereInput | null;
  confirmed?: boolean | null;
  contact?: TournamentTeamContactFieldWhereInput | null;
  name?: TournamentTeamNameFieldWhereInput | null;
  createdAt?: TournamentTeamCreatedAtFieldWhereInput | null;
  tournamentId?: TournamentTeamTournamentIdFieldWhereInput | null;
  discord?: TournamentTeamDiscordFieldWhereInput | null;
  ownerId?: TournamentTeamOwnerIdFieldWhereInput | null;
  pending?: boolean | null;
  state?: TournamentTeamStateFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentTeamWhereLogicAndInput[] | null;
  NOT?: TournamentTeamWhereLogicNotInput[] | null;
  OR?: TournamentTeamWhereLogicOrInput[] | null;
}

export interface TournamentTeamWhereLogicOrInput {
  id?: TournamentTeamIdFieldWhereInput | null;
  confirmed?: boolean | null;
  contact?: TournamentTeamContactFieldWhereInput | null;
  name?: TournamentTeamNameFieldWhereInput | null;
  createdAt?: TournamentTeamCreatedAtFieldWhereInput | null;
  tournamentId?: TournamentTeamTournamentIdFieldWhereInput | null;
  discord?: TournamentTeamDiscordFieldWhereInput | null;
  ownerId?: TournamentTeamOwnerIdFieldWhereInput | null;
  pending?: boolean | null;
  state?: TournamentTeamStateFieldWhereInput | null;
  tournament?: TournamentWhereInput | null;
  AND?: TournamentTeamWhereLogicAndInput[] | null;
  NOT?: TournamentTeamWhereLogicNotInput[] | null;
  OR?: TournamentTeamWhereLogicOrInput[] | null;
}

export interface TournamentTeamWhereScalarInput {
  OR?: (TournamentTeamWhereScalarInput | null)[] | null;
  AND?: (TournamentTeamWhereScalarInput | null)[] | null;
  NOT?: (TournamentTeamWhereScalarInput | null)[] | null;
  id?: TournamentTeam_IdFieldWhereScalarInput | null;
  createdAt?: TournamentTeam_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: TournamentTeam_UpdatedAtFieldWhereScalarInput | null;
  tournamentId?: TournamentTeam_TournamentIdFieldWhereScalarInput | null;
  name?: TournamentTeam_NameFieldWhereScalarInput | null;
  players?: TournamentTeam_PlayersFieldWhereScalarInput | null;
  contact?: TournamentTeam_ContactFieldWhereScalarInput | null;
  isManager?: TournamentTeam_IsManagerFieldWhereScalarInput | null;
  schedule?: TournamentTeam_ScheduleFieldWhereScalarInput | null;
  extra?: TournamentTeam_ExtraFieldWhereScalarInput | null;
  discord?: TournamentTeam_DiscordFieldWhereScalarInput | null;
  pending?: TournamentTeam_PendingFieldWhereScalarInput | null;
  state?: TournamentTeam_StateFieldWhereScalarInput | null;
  fixedOrder?: TournamentTeam_FixedOrderFieldWhereScalarInput | null;
  ownerId?: TournamentTeam_OwnerIdFieldWhereScalarInput | null;
  manager?: TournamentTeam_ManagerFieldWhereScalarInput | null;
  custom?: TournamentTeam_CustomFieldWhereScalarInput | null;
  teamId?: TournamentTeam_TeamIdFieldWhereScalarInput | null;
  fetched?: TournamentTeam_FetchedFieldWhereScalarInput | null;
  logo?: TournamentTeam_LogoFieldWhereScalarInput | null;
}

export interface TournamentTeamWhereUniqueInput {
  id: number;
}

export interface TournamentTeam_ClaimedPrizesFieldConnectOrCreateInput {
  where: TournamentPrizeWhereUniqueInput;
  create: TournamentTeam_ClaimedPrizesFieldNestedCreateInput;
}

export interface TournamentTeam_ClaimedPrizesFieldCreateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_ClaimedPrizesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_ClaimedPrizesFieldNestedCreateInput[] | null;
}

export interface TournamentTeam_ClaimedPrizesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldCreateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldCreateInput | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  quantity: number;
  placement: number[];
  state?: TournamentPrizeState | null;
  tournamentId: number;
  tournament?: TournamentPrize_TournamentFieldCreateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldCreateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldCreateInput | null;
}

export interface TournamentTeam_ClaimedPrizesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldUpdateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldUpdateInput | null;
  type?: TournamentPrizeType | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: number | null;
  placement?: number[] | null;
  state?: TournamentPrizeState | null;
  tournamentId?: number | null;
  tournament?: TournamentPrize_TournamentFieldUpdateInput | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldUpdateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldUpdateInput | null;
}

export interface TournamentTeam_ClaimedPrizesFieldNestedUpdateInput {
  where: TournamentPrizeWhereUniqueInput;
  data: TournamentTeam_ClaimedPrizesFieldNestedUpdateDataInput;
}

export interface TournamentTeam_ClaimedPrizesFieldNestedUpsertInput {
  create: TournamentTeam_ClaimedPrizesFieldNestedCreateInput;
  update: TournamentTeam_ClaimedPrizesFieldNestedUpdateDataInput;
  where: TournamentPrizeWhereUniqueInput;
}

export interface TournamentTeam_ClaimedPrizesFieldUpdateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_ClaimedPrizesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_ClaimedPrizesFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeWhereScalarInput | null;
  disconnect?: TournamentPrizeWhereUniqueInput[] | null;
  set?: TournamentPrizeWhereUniqueInput[] | null;
  update?: TournamentTeam_ClaimedPrizesFieldNestedUpdateInput[] | null;
  upsert?: TournamentTeam_ClaimedPrizesFieldNestedUpsertInput[] | null;
}

export interface TournamentTeam_ContactFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_ContactFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentTeam_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentTeam_CustomFieldWhereScalarInput {
  equals?: any | null;
  not?: TournamentTeam_CustomFieldWhereScalarInput | null;
}

export interface TournamentTeam_DiscordFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_DiscordFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_ExtraFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_ExtraFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_FetchedFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentTeam_FetchedFieldWhereScalarInput | null;
}

export interface TournamentTeam_FixedOrderFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_FixedOrderFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_IsManagerFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentTeam_IsManagerFieldWhereScalarInput | null;
}

export interface TournamentTeam_LogoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_LogoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_ManagerFieldWhereScalarInput {
  equals?: any | null;
  not?: TournamentTeam_ManagerFieldWhereScalarInput | null;
}

export interface TournamentTeam_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_OwnerFieldConnectOrCreateInput {
  where: UserWhereUniqueInput;
  create: TournamentTeam_OwnerFieldNestedCreateInput;
}

export interface TournamentTeam_OwnerFieldCreateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_OwnerFieldConnectOrCreateInput | null;
  create?: TournamentTeam_OwnerFieldNestedCreateInput | null;
}

export interface TournamentTeam_OwnerFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password: string;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldCreateInput | null;
  articles?: User_ArticlesFieldCreateInput | null;
  teams?: User_TeamsFieldCreateInput | null;
  roles?: User_RolesFieldCreateInput | null;
  prizeActions?: User_PrizeActionsFieldCreateInput | null;
  observedArticles?: User_ObservedArticlesFieldCreateInput | null;
  visits?: User_VisitsFieldCreateInput | null;
}

export interface TournamentTeam_OwnerFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  newsletter?: boolean | null;
  birthday?: any | null;
  fblink?: string | null;
  discord?: string | null;
  socialType?: string | null;
  nickname?: string | null;
  profilePicture?: string | null;
  merchants?: User_MerchantsFieldUpdateInput | null;
  articles?: User_ArticlesFieldUpdateInput | null;
  teams?: User_TeamsFieldUpdateInput | null;
  roles?: User_RolesFieldUpdateInput | null;
  prizeActions?: User_PrizeActionsFieldUpdateInput | null;
  observedArticles?: User_ObservedArticlesFieldUpdateInput | null;
  visits?: User_VisitsFieldUpdateInput | null;
}

export interface TournamentTeam_OwnerFieldNestedUpsertInput {
  create: TournamentTeam_OwnerFieldNestedCreateInput;
  update: TournamentTeam_OwnerFieldNestedUpdateInput;
}

export interface TournamentTeam_OwnerFieldUpdateInput {
  connect?: UserWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_OwnerFieldConnectOrCreateInput | null;
  create?: TournamentTeam_OwnerFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentTeam_OwnerFieldNestedUpdateInput | null;
  upsert?: TournamentTeam_OwnerFieldNestedUpsertInput | null;
}

export interface TournamentTeam_OwnerIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_OwnerIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_PendingFieldWhereScalarInput {
  equals?: boolean | null;
  not?: TournamentTeam_PendingFieldWhereScalarInput | null;
}

export interface TournamentTeam_PlayersFieldWhereScalarInput {
  equals?: any | null;
  not?: TournamentTeam_PlayersFieldWhereScalarInput | null;
}

export interface TournamentTeam_PrizeActionsFieldConnectOrCreateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  create: TournamentTeam_PrizeActionsFieldNestedCreateInput;
}

export interface TournamentTeam_PrizeActionsFieldCreateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_PrizeActionsFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_PrizeActionsFieldNestedCreateInput[] | null;
}

export interface TournamentTeam_PrizeActionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  prizeId: number;
  state: TournamentPrizeState;
  prize?: TournamentPrizeAction_PrizeFieldCreateInput | null;
  authorId?: number | null;
  author?: TournamentPrizeAction_AuthorFieldCreateInput | null;
}

export interface TournamentTeam_PrizeActionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  prizeId?: number | null;
  state?: TournamentPrizeState | null;
  prize?: TournamentPrizeAction_PrizeFieldUpdateInput | null;
  authorId?: number | null;
  author?: TournamentPrizeAction_AuthorFieldUpdateInput | null;
}

export interface TournamentTeam_PrizeActionsFieldNestedUpdateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  data: TournamentTeam_PrizeActionsFieldNestedUpdateDataInput;
}

export interface TournamentTeam_PrizeActionsFieldNestedUpsertInput {
  create: TournamentTeam_PrizeActionsFieldNestedCreateInput;
  update: TournamentTeam_PrizeActionsFieldNestedUpdateDataInput;
  where: TournamentPrizeActionWhereUniqueInput;
}

export interface TournamentTeam_PrizeActionsFieldUpdateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_PrizeActionsFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_PrizeActionsFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeActionWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeActionWhereScalarInput | null;
  disconnect?: TournamentPrizeActionWhereUniqueInput[] | null;
  set?: TournamentPrizeActionWhereUniqueInput[] | null;
  update?: TournamentTeam_PrizeActionsFieldNestedUpdateInput[] | null;
  upsert?: TournamentTeam_PrizeActionsFieldNestedUpsertInput[] | null;
}

export interface TournamentTeam_ScheduleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: TournamentTeam_ScheduleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TournamentTeam_StateFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_StateFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_TeamAMatchesFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentTeam_TeamAMatchesFieldNestedCreateInput;
}

export interface TournamentTeam_TeamAMatchesFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_TeamAMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_TeamAMatchesFieldNestedCreateInput[] | null;
}

export interface TournamentTeam_TeamAMatchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentTeam_TeamAMatchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentTeam_TeamAMatchesFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentTeam_TeamAMatchesFieldNestedUpdateDataInput;
}

export interface TournamentTeam_TeamAMatchesFieldNestedUpsertInput {
  create: TournamentTeam_TeamAMatchesFieldNestedCreateInput;
  update: TournamentTeam_TeamAMatchesFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentTeam_TeamAMatchesFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_TeamAMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_TeamAMatchesFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentTeam_TeamAMatchesFieldNestedUpdateInput[] | null;
  upsert?: TournamentTeam_TeamAMatchesFieldNestedUpsertInput[] | null;
}

export interface TournamentTeam_TeamBMatchesFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: TournamentTeam_TeamBMatchesFieldNestedCreateInput;
}

export interface TournamentTeam_TeamBMatchesFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_TeamBMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_TeamBMatchesFieldNestedCreateInput[] | null;
}

export interface TournamentTeam_TeamBMatchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldCreateInput | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface TournamentTeam_TeamBMatchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  tournament?: TournamentMatch_TournamentFieldUpdateInput | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface TournamentTeam_TeamBMatchesFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: TournamentTeam_TeamBMatchesFieldNestedUpdateDataInput;
}

export interface TournamentTeam_TeamBMatchesFieldNestedUpsertInput {
  create: TournamentTeam_TeamBMatchesFieldNestedCreateInput;
  update: TournamentTeam_TeamBMatchesFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface TournamentTeam_TeamBMatchesFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: TournamentTeam_TeamBMatchesFieldConnectOrCreateInput[] | null;
  create?: TournamentTeam_TeamBMatchesFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: TournamentTeam_TeamBMatchesFieldNestedUpdateInput[] | null;
  upsert?: TournamentTeam_TeamBMatchesFieldNestedUpsertInput[] | null;
}

export interface TournamentTeam_TeamFieldConnectOrCreateInput {
  where: TeamWhereUniqueInput;
  create: TournamentTeam_TeamFieldNestedCreateInput;
}

export interface TournamentTeam_TeamFieldCreateInput {
  connect?: TeamWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_TeamFieldConnectOrCreateInput | null;
  create?: TournamentTeam_TeamFieldNestedCreateInput | null;
}

export interface TournamentTeam_TeamFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  gameId?: number | null;
  name: string;
  ownerId?: number | null;
  players?: any | null;
  logo?: string | null;
  owner?: Team_OwnerFieldCreateInput | null;
  game?: Team_GameFieldCreateInput | null;
  invitations?: Team_InvitationsFieldCreateInput | null;
  updatedAt?: any | null;
}

export interface TournamentTeam_TeamFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  gameId?: number | null;
  name?: string | null;
  ownerId?: number | null;
  players?: any | null;
  logo?: string | null;
  owner?: Team_OwnerFieldUpdateInput | null;
  game?: Team_GameFieldUpdateInput | null;
  invitations?: Team_InvitationsFieldUpdateInput | null;
  updatedAt?: any | null;
}

export interface TournamentTeam_TeamFieldNestedUpsertInput {
  create: TournamentTeam_TeamFieldNestedCreateInput;
  update: TournamentTeam_TeamFieldNestedUpdateInput;
}

export interface TournamentTeam_TeamFieldUpdateInput {
  connect?: TeamWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_TeamFieldConnectOrCreateInput | null;
  create?: TournamentTeam_TeamFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentTeam_TeamFieldNestedUpdateInput | null;
  upsert?: TournamentTeam_TeamFieldNestedUpsertInput | null;
}

export interface TournamentTeam_TeamIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_TeamIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_TournamentFieldConnectOrCreateInput {
  where: TournamentWhereUniqueInput;
  create: TournamentTeam_TournamentFieldNestedCreateInput;
}

export interface TournamentTeam_TournamentFieldCreateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentTeam_TournamentFieldNestedCreateInput | null;
}

export interface TournamentTeam_TournamentFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails: string[];
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldCreateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldCreateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldCreateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldCreateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldCreateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldCreateInput | null;
  matches?: Tournament_MatchesFieldCreateInput | null;
  stages?: Tournament_StagesFieldCreateInput | null;
  prizes?: Tournament_PrizesFieldCreateInput | null;
  groupingValue?: Tournament_GroupingValueFieldCreateInput | null;
  groups?: Tournament_GroupsFieldCreateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldCreateInput | null;
}

export interface TournamentTeam_TournamentFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  description?: string | null;
  name?: string | null;
  type?: string | null;
  gameId?: number | null;
  capMin?: number | null;
  capMax?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  registrationStartTime?: any | null;
  registrationEndTime?: any | null;
  checkInStartTime?: any | null;
  checkInEndTime?: any | null;
  tournamentInfo?: string | null;
  embedStream?: string | null;
  partner?: string | null;
  partner2?: string | null;
  specialRegistration?: string | null;
  mailSubject?: string | null;
  mailText?: string | null;
  enablePending?: boolean | null;
  pendingMailSubject?: string | null;
  pendingMailText?: string | null;
  embedTournament?: any | null;
  checkInStartTimePending?: any | null;
  checkInEndTimePending?: any | null;
  public?: boolean | null;
  externalTournament?: string | null;
  publicMatches?: boolean | null;
  style?: string | null;
  coverImage?: string | null;
  banner?: string | null;
  rules?: any | null;
  playersCount?: number | null;
  playersRequiredCount?: number | null;
  contact?: string | null;
  adminsEmails?: string[] | null;
  customFields?: any | null;
  extrasCount?: number | null;
  fakeRegistrations?: number | null;
  game?: Tournament_GameFieldUpdateInput | null;
  merchantId?: number | null;
  merchant?: Tournament_MerchantFieldUpdateInput | null;
  projectSections?: Tournament_ProjectSectionsFieldUpdateInput | null;
  projectSections_relation_stages?: Tournament_ProjectSections_relation_stagesFieldUpdateInput | null;
  projectSections_relation_matches?: Tournament_ProjectSections_relation_matchesFieldUpdateInput | null;
  projectSections_relation_teams?: Tournament_ProjectSections_relation_teamsFieldUpdateInput | null;
  matches?: Tournament_MatchesFieldUpdateInput | null;
  stages?: Tournament_StagesFieldUpdateInput | null;
  prizes?: Tournament_PrizesFieldUpdateInput | null;
  groupingValue?: Tournament_GroupingValueFieldUpdateInput | null;
  groups?: Tournament_GroupsFieldUpdateInput | null;
  projectVotingSections?: Tournament_ProjectVotingSectionsFieldUpdateInput | null;
}

export interface TournamentTeam_TournamentFieldNestedUpsertInput {
  create: TournamentTeam_TournamentFieldNestedCreateInput;
  update: TournamentTeam_TournamentFieldNestedUpdateInput;
}

export interface TournamentTeam_TournamentFieldUpdateInput {
  connect?: TournamentWhereUniqueInput | null;
  connectOrCreate?: TournamentTeam_TournamentFieldConnectOrCreateInput | null;
  create?: TournamentTeam_TournamentFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TournamentTeam_TournamentFieldNestedUpdateInput | null;
  upsert?: TournamentTeam_TournamentFieldNestedUpsertInput | null;
}

export interface TournamentTeam_TournamentIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: TournamentTeam_TournamentIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface TournamentTeam_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: TournamentTeam_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TournamentWhereInput {
  id?: TournamentIdFieldWhereInput | null;
  gameId?: TournamentGameIdFieldWhereInput | null;
  startDate?: TournamentStartDateFieldWhereInput | null;
  endDate?: TournamentEndDateFieldWhereInput | null;
  name?: TournamentNameFieldWhereInput | null;
  contact?: TournamentContactFieldWhereInput | null;
  capMax?: TournamentCapMaxFieldWhereInput | null;
  capMin?: TournamentCapMinFieldWhereInput | null;
  checkInEndTime?: TournamentCheckInEndTimeFieldWhereInput | null;
  checkInEndTimePending?: TournamentCheckInEndTimePendingFieldWhereInput | null;
  checkInStartTime?: TournamentCheckInStartTimeFieldWhereInput | null;
  checkInStartTimePending?: TournamentCheckInStartTimePendingFieldWhereInput | null;
  createdAt?: TournamentCreatedAtFieldWhereInput | null;
  description?: TournamentDescriptionFieldWhereInput | null;
  extrasCount?: TournamentExtrasCountFieldWhereInput | null;
  game?: GameWhereInput | null;
  groupingValue?: TournamentGroupingValueWhereInput | null;
  groups?: TournamentGroupsFieldWhereInput | null;
  mailSubject?: TournamentMailSubjectFieldWhereInput | null;
  mailText?: TournamentMailTextFieldWhereInput | null;
  matches?: TournamentMatchesFieldWhereInput | null;
  public?: boolean | null;
  AND?: TournamentWhereLogicAndInput[] | null;
  NOT?: TournamentWhereLogicNotInput[] | null;
  OR?: TournamentWhereLogicOrInput[] | null;
  searchString?: string | null;
  groupingFilter?: TournamentGroupingBelongingWhereInput | null;
  status?: TournamentStatus | null;
}

export interface TournamentWhereLogicAndInput {
  id?: TournamentIdFieldWhereInput | null;
  gameId?: TournamentGameIdFieldWhereInput | null;
  startDate?: TournamentStartDateFieldWhereInput | null;
  endDate?: TournamentEndDateFieldWhereInput | null;
  name?: TournamentNameFieldWhereInput | null;
  contact?: TournamentContactFieldWhereInput | null;
  capMax?: TournamentCapMaxFieldWhereInput | null;
  capMin?: TournamentCapMinFieldWhereInput | null;
  checkInEndTime?: TournamentCheckInEndTimeFieldWhereInput | null;
  checkInEndTimePending?: TournamentCheckInEndTimePendingFieldWhereInput | null;
  checkInStartTime?: TournamentCheckInStartTimeFieldWhereInput | null;
  checkInStartTimePending?: TournamentCheckInStartTimePendingFieldWhereInput | null;
  createdAt?: TournamentCreatedAtFieldWhereInput | null;
  description?: TournamentDescriptionFieldWhereInput | null;
  extrasCount?: TournamentExtrasCountFieldWhereInput | null;
  game?: GameWhereInput | null;
  groupingValue?: TournamentGroupingValueWhereInput | null;
  groups?: TournamentGroupsFieldWhereInput | null;
  mailSubject?: TournamentMailSubjectFieldWhereInput | null;
  mailText?: TournamentMailTextFieldWhereInput | null;
  matches?: TournamentMatchesFieldWhereInput | null;
  public?: boolean | null;
  AND?: TournamentWhereLogicAndInput[] | null;
  NOT?: TournamentWhereLogicNotInput[] | null;
  OR?: TournamentWhereLogicOrInput[] | null;
}

export interface TournamentWhereLogicNotInput {
  id?: TournamentIdFieldWhereInput | null;
  gameId?: TournamentGameIdFieldWhereInput | null;
  startDate?: TournamentStartDateFieldWhereInput | null;
  endDate?: TournamentEndDateFieldWhereInput | null;
  name?: TournamentNameFieldWhereInput | null;
  contact?: TournamentContactFieldWhereInput | null;
  capMax?: TournamentCapMaxFieldWhereInput | null;
  capMin?: TournamentCapMinFieldWhereInput | null;
  checkInEndTime?: TournamentCheckInEndTimeFieldWhereInput | null;
  checkInEndTimePending?: TournamentCheckInEndTimePendingFieldWhereInput | null;
  checkInStartTime?: TournamentCheckInStartTimeFieldWhereInput | null;
  checkInStartTimePending?: TournamentCheckInStartTimePendingFieldWhereInput | null;
  createdAt?: TournamentCreatedAtFieldWhereInput | null;
  description?: TournamentDescriptionFieldWhereInput | null;
  extrasCount?: TournamentExtrasCountFieldWhereInput | null;
  game?: GameWhereInput | null;
  groupingValue?: TournamentGroupingValueWhereInput | null;
  groups?: TournamentGroupsFieldWhereInput | null;
  mailSubject?: TournamentMailSubjectFieldWhereInput | null;
  mailText?: TournamentMailTextFieldWhereInput | null;
  matches?: TournamentMatchesFieldWhereInput | null;
  public?: boolean | null;
  AND?: TournamentWhereLogicAndInput[] | null;
  NOT?: TournamentWhereLogicNotInput[] | null;
  OR?: TournamentWhereLogicOrInput[] | null;
}

export interface TournamentWhereLogicOrInput {
  id?: TournamentIdFieldWhereInput | null;
  gameId?: TournamentGameIdFieldWhereInput | null;
  startDate?: TournamentStartDateFieldWhereInput | null;
  endDate?: TournamentEndDateFieldWhereInput | null;
  name?: TournamentNameFieldWhereInput | null;
  contact?: TournamentContactFieldWhereInput | null;
  capMax?: TournamentCapMaxFieldWhereInput | null;
  capMin?: TournamentCapMinFieldWhereInput | null;
  checkInEndTime?: TournamentCheckInEndTimeFieldWhereInput | null;
  checkInEndTimePending?: TournamentCheckInEndTimePendingFieldWhereInput | null;
  checkInStartTime?: TournamentCheckInStartTimeFieldWhereInput | null;
  checkInStartTimePending?: TournamentCheckInStartTimePendingFieldWhereInput | null;
  createdAt?: TournamentCreatedAtFieldWhereInput | null;
  description?: TournamentDescriptionFieldWhereInput | null;
  extrasCount?: TournamentExtrasCountFieldWhereInput | null;
  game?: GameWhereInput | null;
  groupingValue?: TournamentGroupingValueWhereInput | null;
  groups?: TournamentGroupsFieldWhereInput | null;
  mailSubject?: TournamentMailSubjectFieldWhereInput | null;
  mailText?: TournamentMailTextFieldWhereInput | null;
  matches?: TournamentMatchesFieldWhereInput | null;
  public?: boolean | null;
  AND?: TournamentWhereLogicAndInput[] | null;
  NOT?: TournamentWhereLogicNotInput[] | null;
  OR?: TournamentWhereLogicOrInput[] | null;
}

export interface TournamentWhereScalarInput {
  OR?: (TournamentWhereScalarInput | null)[] | null;
  AND?: (TournamentWhereScalarInput | null)[] | null;
  NOT?: (TournamentWhereScalarInput | null)[] | null;
  id?: Tournament_IdFieldWhereScalarInput | null;
  createdAt?: Tournament_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Tournament_UpdatedAtFieldWhereScalarInput | null;
  description?: Tournament_DescriptionFieldWhereScalarInput | null;
  name?: Tournament_NameFieldWhereScalarInput | null;
  type?: Tournament_TypeFieldWhereScalarInput | null;
  gameId?: Tournament_GameIdFieldWhereScalarInput | null;
  capMin?: Tournament_CapMinFieldWhereScalarInput | null;
  capMax?: Tournament_CapMaxFieldWhereScalarInput | null;
  startDate?: Tournament_StartDateFieldWhereScalarInput | null;
  endDate?: Tournament_EndDateFieldWhereScalarInput | null;
  registrationStartTime?: Tournament_RegistrationStartTimeFieldWhereScalarInput | null;
  registrationEndTime?: Tournament_RegistrationEndTimeFieldWhereScalarInput | null;
  checkInStartTime?: Tournament_CheckInStartTimeFieldWhereScalarInput | null;
  checkInEndTime?: Tournament_CheckInEndTimeFieldWhereScalarInput | null;
  tournamentInfo?: Tournament_TournamentInfoFieldWhereScalarInput | null;
  embedStream?: Tournament_EmbedStreamFieldWhereScalarInput | null;
  partner?: Tournament_PartnerFieldWhereScalarInput | null;
  partner2?: Tournament_Partner2FieldWhereScalarInput | null;
  specialRegistration?: Tournament_SpecialRegistrationFieldWhereScalarInput | null;
  mailSubject?: Tournament_MailSubjectFieldWhereScalarInput | null;
  mailText?: Tournament_MailTextFieldWhereScalarInput | null;
  enablePending?: Tournament_EnablePendingFieldWhereScalarInput | null;
  pendingMailSubject?: Tournament_PendingMailSubjectFieldWhereScalarInput | null;
  pendingMailText?: Tournament_PendingMailTextFieldWhereScalarInput | null;
  embedTournament?: Tournament_EmbedTournamentFieldWhereScalarInput | null;
  checkInStartTimePending?: Tournament_CheckInStartTimePendingFieldWhereScalarInput | null;
  checkInEndTimePending?: Tournament_CheckInEndTimePendingFieldWhereScalarInput | null;
  public?: Tournament_PublicFieldWhereScalarInput | null;
  externalTournament?: Tournament_ExternalTournamentFieldWhereScalarInput | null;
  publicMatches?: Tournament_PublicMatchesFieldWhereScalarInput | null;
  style?: Tournament_StyleFieldWhereScalarInput | null;
  coverImage?: Tournament_CoverImageFieldWhereScalarInput | null;
  banner?: Tournament_BannerFieldWhereScalarInput | null;
  rules?: Tournament_RulesFieldWhereScalarInput | null;
  playersCount?: Tournament_PlayersCountFieldWhereScalarInput | null;
  playersRequiredCount?: Tournament_PlayersRequiredCountFieldWhereScalarInput | null;
  contact?: Tournament_ContactFieldWhereScalarInput | null;
  adminsEmails?: Tournament_AdminsEmailsFieldWhereScalarInput | null;
  customFields?: Tournament_CustomFieldsFieldWhereScalarInput | null;
  extrasCount?: Tournament_ExtrasCountFieldWhereScalarInput | null;
  fakeRegistrations?: Tournament_FakeRegistrationsFieldWhereScalarInput | null;
  merchantId?: Tournament_MerchantIdFieldWhereScalarInput | null;
}

export interface TournamentWhereUniqueInput {
  id: number;
}

export interface Tournament_AdminsEmailsFieldWhereScalarInput {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface Tournament_BannerFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_BannerFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_CapMaxFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_CapMaxFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_CapMinFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_CapMinFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_CheckInEndTimeFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_CheckInEndTimeFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_CheckInEndTimePendingFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_CheckInEndTimePendingFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_CheckInStartTimeFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_CheckInStartTimeFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_CheckInStartTimePendingFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_CheckInStartTimePendingFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_ContactFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_ContactFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_CoverImageFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_CoverImageFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_CustomFieldsFieldWhereScalarInput {
  equals?: any | null;
  not?: Tournament_CustomFieldsFieldWhereScalarInput | null;
}

export interface Tournament_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_EmbedStreamFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_EmbedStreamFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_EmbedTournamentFieldWhereScalarInput {
  equals?: any | null;
  not?: Tournament_EmbedTournamentFieldWhereScalarInput | null;
}

export interface Tournament_EnablePendingFieldWhereScalarInput {
  equals?: boolean | null;
  not?: Tournament_EnablePendingFieldWhereScalarInput | null;
}

export interface Tournament_EndDateFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_EndDateFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_ExternalTournamentFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_ExternalTournamentFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_ExtrasCountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_ExtrasCountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_FakeRegistrationsFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_FakeRegistrationsFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_GameFieldConnectOrCreateInput {
  where: GameWhereUniqueInput;
  create: Tournament_GameFieldNestedCreateInput;
}

export interface Tournament_GameFieldCreateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: Tournament_GameFieldConnectOrCreateInput | null;
  create?: Tournament_GameFieldNestedCreateInput | null;
}

export interface Tournament_GameFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags: string[];
  gameId?: Game_GameIdFieldCreateInput | null;
  maps?: Game_MapsFieldCreateInput | null;
  teams?: Game_TeamsFieldCreateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldCreateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldCreateInput | null;
}

export interface Tournament_GameFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  gameIdId?: number | null;
  type?: GameType | null;
  filterOrder?: number | null;
  coverImage?: string | null;
  tag?: string | null;
  tags?: string[] | null;
  gameId?: Game_GameIdFieldUpdateInput | null;
  maps?: Game_MapsFieldUpdateInput | null;
  teams?: Game_TeamsFieldUpdateInput | null;
  tournamentGrouping?: Game_TournamentGroupingFieldUpdateInput | null;
  gameGeneralRule?: Game_GameGeneralRuleFieldUpdateInput | null;
}

export interface Tournament_GameFieldNestedUpsertInput {
  create: Tournament_GameFieldNestedCreateInput;
  update: Tournament_GameFieldNestedUpdateInput;
}

export interface Tournament_GameFieldUpdateInput {
  connect?: GameWhereUniqueInput | null;
  connectOrCreate?: Tournament_GameFieldConnectOrCreateInput | null;
  create?: Tournament_GameFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Tournament_GameFieldNestedUpdateInput | null;
  upsert?: Tournament_GameFieldNestedUpsertInput | null;
}

export interface Tournament_GameIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_GameIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_GroupingValueFieldConnectOrCreateInput {
  where: TournamentGroupingValueWhereUniqueInput;
  create: Tournament_GroupingValueFieldNestedCreateInput;
}

export interface Tournament_GroupingValueFieldCreateInput {
  connect?: TournamentGroupingValueWhereUniqueInput | null;
  connectOrCreate?: Tournament_GroupingValueFieldConnectOrCreateInput | null;
  create?: Tournament_GroupingValueFieldNestedCreateInput | null;
}

export interface Tournament_GroupingValueFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  order?: number | null;
  groupingId: number;
  grouping?: TournamentGroupingValue_GroupingFieldCreateInput | null;
}

export interface Tournament_GroupingValueFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value?: string | null;
  order?: number | null;
  groupingId?: number | null;
  grouping?: TournamentGroupingValue_GroupingFieldUpdateInput | null;
}

export interface Tournament_GroupingValueFieldNestedUpsertInput {
  create: Tournament_GroupingValueFieldNestedCreateInput;
  update: Tournament_GroupingValueFieldNestedUpdateInput;
}

export interface Tournament_GroupingValueFieldUpdateInput {
  connect?: TournamentGroupingValueWhereUniqueInput | null;
  connectOrCreate?: Tournament_GroupingValueFieldConnectOrCreateInput | null;
  create?: Tournament_GroupingValueFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Tournament_GroupingValueFieldNestedUpdateInput | null;
  upsert?: Tournament_GroupingValueFieldNestedUpsertInput | null;
}

export interface Tournament_GroupsFieldConnectOrCreateInput {
  where: TournamentGroupWhereUniqueInput;
  create: Tournament_GroupsFieldNestedCreateInput;
}

export interface Tournament_GroupsFieldCreateInput {
  connect?: TournamentGroupWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_GroupsFieldConnectOrCreateInput[] | null;
  create?: Tournament_GroupsFieldNestedCreateInput[] | null;
}

export interface Tournament_GroupsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title: string;
  coverImage?: string | null;
  description?: string | null;
  groupings?: TournamentGroup_GroupingsFieldCreateInput | null;
}

export interface Tournament_GroupsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  coverImage?: string | null;
  description?: string | null;
  groupings?: TournamentGroup_GroupingsFieldUpdateInput | null;
}

export interface Tournament_GroupsFieldNestedUpdateInput {
  where: TournamentGroupWhereUniqueInput;
  data: Tournament_GroupsFieldNestedUpdateDataInput;
}

export interface Tournament_GroupsFieldNestedUpsertInput {
  create: Tournament_GroupsFieldNestedCreateInput;
  update: Tournament_GroupsFieldNestedUpdateDataInput;
  where: TournamentGroupWhereUniqueInput;
}

export interface Tournament_GroupsFieldUpdateInput {
  connect?: TournamentGroupWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_GroupsFieldConnectOrCreateInput[] | null;
  create?: Tournament_GroupsFieldNestedCreateInput[] | null;
  delete?: TournamentGroupWhereUniqueInput[] | null;
  deleteMany?: TournamentGroupWhereScalarInput | null;
  disconnect?: TournamentGroupWhereUniqueInput[] | null;
  set?: TournamentGroupWhereUniqueInput[] | null;
  update?: Tournament_GroupsFieldNestedUpdateInput[] | null;
  upsert?: Tournament_GroupsFieldNestedUpsertInput[] | null;
}

export interface Tournament_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_MailSubjectFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_MailSubjectFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_MailTextFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_MailTextFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_MatchesFieldConnectOrCreateInput {
  where: TournamentMatchWhereUniqueInput;
  create: Tournament_MatchesFieldNestedCreateInput;
}

export interface Tournament_MatchesFieldCreateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_MatchesFieldConnectOrCreateInput[] | null;
  create?: Tournament_MatchesFieldNestedCreateInput[] | null;
}

export interface Tournament_MatchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId: number;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  stage?: TournamentMatch_StageFieldCreateInput | null;
  rounds?: TournamentMatch_RoundsFieldCreateInput | null;
  teamA?: TournamentMatch_TeamAFieldCreateInput | null;
  teamB?: TournamentMatch_TeamBFieldCreateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldCreateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldCreateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldCreateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldCreateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldCreateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldCreateInput | null;
}

export interface Tournament_MatchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  result?: any | null;
  bucket?: string | null;
  siblingMatchId?: number | null;
  childMatchId?: number | null;
  round?: number | null;
  active?: boolean | null;
  stageId?: number | null;
  note?: string | null;
  loserMatchId?: number | null;
  teamIdA?: number | null;
  teamIdB?: number | null;
  ourRounds?: number | null;
  theirRounds?: number | null;
  printScreen?: string | null;
  teamScoreA?: number | null;
  teamScoreB?: number | null;
  confirmed?: boolean | null;
  resultBy?: string | null;
  disputeBy?: string | null;
  disputeScoreA?: number | null;
  disputeScoreB?: number | null;
  disputePrintScreen?: string | null;
  twitchLink?: string | null;
  embed?: string | null;
  fortunaLink?: string | null;
  stage?: TournamentMatch_StageFieldUpdateInput | null;
  rounds?: TournamentMatch_RoundsFieldUpdateInput | null;
  teamA?: TournamentMatch_TeamAFieldUpdateInput | null;
  teamB?: TournamentMatch_TeamBFieldUpdateInput | null;
  siblingMatch?: TournamentMatch_SiblingMatchFieldUpdateInput | null;
  childMatch?: TournamentMatch_ChildMatchFieldUpdateInput | null;
  loserMatch?: TournamentMatch_LoserMatchFieldUpdateInput | null;
  parentMatches?: TournamentMatch_ParentMatchesFieldUpdateInput | null;
  siblingMatchParents?: TournamentMatch_SiblingMatchParentsFieldUpdateInput | null;
  loserMatchParents?: TournamentMatch_LoserMatchParentsFieldUpdateInput | null;
}

export interface Tournament_MatchesFieldNestedUpdateInput {
  where: TournamentMatchWhereUniqueInput;
  data: Tournament_MatchesFieldNestedUpdateDataInput;
}

export interface Tournament_MatchesFieldNestedUpsertInput {
  create: Tournament_MatchesFieldNestedCreateInput;
  update: Tournament_MatchesFieldNestedUpdateDataInput;
  where: TournamentMatchWhereUniqueInput;
}

export interface Tournament_MatchesFieldUpdateInput {
  connect?: TournamentMatchWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_MatchesFieldConnectOrCreateInput[] | null;
  create?: Tournament_MatchesFieldNestedCreateInput[] | null;
  delete?: TournamentMatchWhereUniqueInput[] | null;
  deleteMany?: TournamentMatchWhereScalarInput | null;
  disconnect?: TournamentMatchWhereUniqueInput[] | null;
  set?: TournamentMatchWhereUniqueInput[] | null;
  update?: Tournament_MatchesFieldNestedUpdateInput[] | null;
  upsert?: Tournament_MatchesFieldNestedUpsertInput[] | null;
}

export interface Tournament_MerchantFieldConnectOrCreateInput {
  where: MerchantWhereUniqueInput;
  create: Tournament_MerchantFieldNestedCreateInput;
}

export interface Tournament_MerchantFieldCreateInput {
  connect?: MerchantWhereUniqueInput | null;
  connectOrCreate?: Tournament_MerchantFieldConnectOrCreateInput | null;
  create?: Tournament_MerchantFieldNestedCreateInput | null;
}

export interface Tournament_MerchantFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  userId?: number | null;
  user?: Merchant_UserFieldCreateInput | null;
  type: MerchantType;
  wallets?: Merchant_WalletsFieldCreateInput | null;
}

export interface Tournament_MerchantFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  userId?: number | null;
  user?: Merchant_UserFieldUpdateInput | null;
  type?: MerchantType | null;
  wallets?: Merchant_WalletsFieldUpdateInput | null;
}

export interface Tournament_MerchantFieldNestedUpsertInput {
  create: Tournament_MerchantFieldNestedCreateInput;
  update: Tournament_MerchantFieldNestedUpdateInput;
}

export interface Tournament_MerchantFieldUpdateInput {
  connect?: MerchantWhereUniqueInput | null;
  connectOrCreate?: Tournament_MerchantFieldConnectOrCreateInput | null;
  create?: Tournament_MerchantFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Tournament_MerchantFieldNestedUpdateInput | null;
  upsert?: Tournament_MerchantFieldNestedUpsertInput | null;
}

export interface Tournament_MerchantIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_MerchantIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_Partner2FieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_Partner2FieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_PartnerFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_PartnerFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_PendingMailSubjectFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_PendingMailSubjectFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_PendingMailTextFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_PendingMailTextFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_PlayersCountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_PlayersCountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_PlayersRequiredCountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Tournament_PlayersRequiredCountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Tournament_PrizesFieldConnectOrCreateInput {
  where: TournamentPrizeWhereUniqueInput;
  create: Tournament_PrizesFieldNestedCreateInput;
}

export interface Tournament_PrizesFieldCreateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_PrizesFieldConnectOrCreateInput[] | null;
  create?: Tournament_PrizesFieldNestedCreateInput[] | null;
}

export interface Tournament_PrizesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldCreateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldCreateInput | null;
  type: TournamentPrizeType;
  placementType: TournamentPrizePlacementType;
  quantity: number;
  placement: number[];
  state?: TournamentPrizeState | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldCreateInput | null;
  winners?: TournamentPrize_WinnersFieldCreateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldCreateInput | null;
}

export interface Tournament_PrizesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customTypeId?: number | null;
  customType?: TournamentPrize_CustomTypeFieldUpdateInput | null;
  currencyId?: number | null;
  currency?: TournamentPrize_CurrencyFieldUpdateInput | null;
  type?: TournamentPrizeType | null;
  placementType?: TournamentPrizePlacementType | null;
  quantity?: number | null;
  placement?: number[] | null;
  state?: TournamentPrizeState | null;
  tournamentStageId?: number | null;
  tournamentStage?: TournamentPrize_TournamentStageFieldUpdateInput | null;
  winners?: TournamentPrize_WinnersFieldUpdateInput | null;
  actionHistory?: TournamentPrize_ActionHistoryFieldUpdateInput | null;
}

export interface Tournament_PrizesFieldNestedUpdateInput {
  where: TournamentPrizeWhereUniqueInput;
  data: Tournament_PrizesFieldNestedUpdateDataInput;
}

export interface Tournament_PrizesFieldNestedUpsertInput {
  create: Tournament_PrizesFieldNestedCreateInput;
  update: Tournament_PrizesFieldNestedUpdateDataInput;
  where: TournamentPrizeWhereUniqueInput;
}

export interface Tournament_PrizesFieldUpdateInput {
  connect?: TournamentPrizeWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_PrizesFieldConnectOrCreateInput[] | null;
  create?: Tournament_PrizesFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeWhereScalarInput | null;
  disconnect?: TournamentPrizeWhereUniqueInput[] | null;
  set?: TournamentPrizeWhereUniqueInput[] | null;
  update?: Tournament_PrizesFieldNestedUpdateInput[] | null;
  upsert?: Tournament_PrizesFieldNestedUpsertInput[] | null;
}

export interface Tournament_ProjectSectionsFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: Tournament_ProjectSectionsFieldNestedCreateInput;
}

export interface Tournament_ProjectSectionsFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSectionsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSectionsFieldNestedCreateInput[] | null;
}

export interface Tournament_ProjectSectionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface Tournament_ProjectSectionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface Tournament_ProjectSectionsFieldNestedUpdateInput {
  where: ProjectSectionWhereUniqueInput;
  data: Tournament_ProjectSectionsFieldNestedUpdateDataInput;
}

export interface Tournament_ProjectSectionsFieldNestedUpsertInput {
  create: Tournament_ProjectSectionsFieldNestedCreateInput;
  update: Tournament_ProjectSectionsFieldNestedUpdateDataInput;
  where: ProjectSectionWhereUniqueInput;
}

export interface Tournament_ProjectSectionsFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSectionsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSectionsFieldNestedCreateInput[] | null;
  delete?: ProjectSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionWhereScalarInput | null;
  disconnect?: ProjectSectionWhereUniqueInput[] | null;
  set?: ProjectSectionWhereUniqueInput[] | null;
  update?: Tournament_ProjectSectionsFieldNestedUpdateInput[] | null;
  upsert?: Tournament_ProjectSectionsFieldNestedUpsertInput[] | null;
}

export interface Tournament_ProjectSections_relation_matchesFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: Tournament_ProjectSections_relation_matchesFieldNestedCreateInput;
}

export interface Tournament_ProjectSections_relation_matchesFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_matchesFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_matchesFieldNestedCreateInput[] | null;
}

export interface Tournament_ProjectSections_relation_matchesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface Tournament_ProjectSections_relation_matchesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface Tournament_ProjectSections_relation_matchesFieldNestedUpdateInput {
  where: ProjectSectionWhereUniqueInput;
  data: Tournament_ProjectSections_relation_matchesFieldNestedUpdateDataInput;
}

export interface Tournament_ProjectSections_relation_matchesFieldNestedUpsertInput {
  create: Tournament_ProjectSections_relation_matchesFieldNestedCreateInput;
  update: Tournament_ProjectSections_relation_matchesFieldNestedUpdateDataInput;
  where: ProjectSectionWhereUniqueInput;
}

export interface Tournament_ProjectSections_relation_matchesFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_matchesFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_matchesFieldNestedCreateInput[] | null;
  delete?: ProjectSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionWhereScalarInput | null;
  disconnect?: ProjectSectionWhereUniqueInput[] | null;
  set?: ProjectSectionWhereUniqueInput[] | null;
  update?: Tournament_ProjectSections_relation_matchesFieldNestedUpdateInput[] | null;
  upsert?: Tournament_ProjectSections_relation_matchesFieldNestedUpsertInput[] | null;
}

export interface Tournament_ProjectSections_relation_stagesFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: Tournament_ProjectSections_relation_stagesFieldNestedCreateInput;
}

export interface Tournament_ProjectSections_relation_stagesFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_stagesFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_stagesFieldNestedCreateInput[] | null;
}

export interface Tournament_ProjectSections_relation_stagesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface Tournament_ProjectSections_relation_stagesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface Tournament_ProjectSections_relation_stagesFieldNestedUpdateInput {
  where: ProjectSectionWhereUniqueInput;
  data: Tournament_ProjectSections_relation_stagesFieldNestedUpdateDataInput;
}

export interface Tournament_ProjectSections_relation_stagesFieldNestedUpsertInput {
  create: Tournament_ProjectSections_relation_stagesFieldNestedCreateInput;
  update: Tournament_ProjectSections_relation_stagesFieldNestedUpdateDataInput;
  where: ProjectSectionWhereUniqueInput;
}

export interface Tournament_ProjectSections_relation_stagesFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_stagesFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_stagesFieldNestedCreateInput[] | null;
  delete?: ProjectSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionWhereScalarInput | null;
  disconnect?: ProjectSectionWhereUniqueInput[] | null;
  set?: ProjectSectionWhereUniqueInput[] | null;
  update?: Tournament_ProjectSections_relation_stagesFieldNestedUpdateInput[] | null;
  upsert?: Tournament_ProjectSections_relation_stagesFieldNestedUpsertInput[] | null;
}

export interface Tournament_ProjectSections_relation_teamsFieldConnectOrCreateInput {
  where: ProjectSectionWhereUniqueInput;
  create: Tournament_ProjectSections_relation_teamsFieldNestedCreateInput;
}

export interface Tournament_ProjectSections_relation_teamsFieldCreateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_teamsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_teamsFieldNestedCreateInput[] | null;
}

export interface Tournament_ProjectSections_relation_teamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId: number;
  locale?: Locale | null;
  type: ProjectSectionType;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldCreateInput | null;
  translations?: ProjectSection_TranslationsFieldCreateInput | null;
  video?: ProjectSection_VideoFieldCreateInput | null;
  figure?: ProjectSection_FigureFieldCreateInput | null;
  text?: ProjectSection_TextFieldCreateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldCreateInput | null;
  articles?: ProjectSection_ArticlesFieldCreateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldCreateInput | null;
  guests?: ProjectSection_GuestsFieldCreateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldCreateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldCreateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldCreateInput | null;
  attractions?: ProjectSection_AttractionsFieldCreateInput | null;
  programs?: ProjectSection_ProgramsFieldCreateInput | null;
  voting?: ProjectSection_VotingFieldCreateInput | null;
  competition?: ProjectSection_CompetitionFieldCreateInput | null;
}

export interface Tournament_ProjectSections_relation_teamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  projectId?: number | null;
  locale?: Locale | null;
  type?: ProjectSectionType | null;
  contentTableName?: string | null;
  icon?: string | null;
  addToContentTable?: boolean | null;
  project?: ProjectSection_ProjectFieldUpdateInput | null;
  translations?: ProjectSection_TranslationsFieldUpdateInput | null;
  video?: ProjectSection_VideoFieldUpdateInput | null;
  figure?: ProjectSection_FigureFieldUpdateInput | null;
  text?: ProjectSection_TextFieldUpdateInput | null;
  questionsAndAnswers?: ProjectSection_QuestionsAndAnswersFieldUpdateInput | null;
  articles?: ProjectSection_ArticlesFieldUpdateInput | null;
  partnerTypes?: ProjectSection_PartnerTypesFieldUpdateInput | null;
  guests?: ProjectSection_GuestsFieldUpdateInput | null;
  tournaments_relation_teams?: ProjectSection_Tournaments_relation_teamsFieldUpdateInput | null;
  tournaments_relation_stages?: ProjectSection_Tournaments_relation_stagesFieldUpdateInput | null;
  tournaments_relation_matches?: ProjectSection_Tournaments_relation_matchesFieldUpdateInput | null;
  attractions?: ProjectSection_AttractionsFieldUpdateInput | null;
  programs?: ProjectSection_ProgramsFieldUpdateInput | null;
  voting?: ProjectSection_VotingFieldUpdateInput | null;
  competition?: ProjectSection_CompetitionFieldUpdateInput | null;
}

export interface Tournament_ProjectSections_relation_teamsFieldNestedUpdateInput {
  where: ProjectSectionWhereUniqueInput;
  data: Tournament_ProjectSections_relation_teamsFieldNestedUpdateDataInput;
}

export interface Tournament_ProjectSections_relation_teamsFieldNestedUpsertInput {
  create: Tournament_ProjectSections_relation_teamsFieldNestedCreateInput;
  update: Tournament_ProjectSections_relation_teamsFieldNestedUpdateDataInput;
  where: ProjectSectionWhereUniqueInput;
}

export interface Tournament_ProjectSections_relation_teamsFieldUpdateInput {
  connect?: ProjectSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectSections_relation_teamsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectSections_relation_teamsFieldNestedCreateInput[] | null;
  delete?: ProjectSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectSectionWhereScalarInput | null;
  disconnect?: ProjectSectionWhereUniqueInput[] | null;
  set?: ProjectSectionWhereUniqueInput[] | null;
  update?: Tournament_ProjectSections_relation_teamsFieldNestedUpdateInput[] | null;
  upsert?: Tournament_ProjectSections_relation_teamsFieldNestedUpsertInput[] | null;
}

export interface Tournament_ProjectVotingSectionsFieldConnectOrCreateInput {
  where: ProjectVotingSectionWhereUniqueInput;
  create: Tournament_ProjectVotingSectionsFieldNestedCreateInput;
}

export interface Tournament_ProjectVotingSectionsFieldCreateInput {
  connect?: ProjectVotingSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectVotingSectionsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectVotingSectionsFieldNestedCreateInput[] | null;
}

export interface Tournament_ProjectVotingSectionsFieldNestedCreateInput {
  id?: number | null;
  projectSectionId: number;
  title: string;
  slotCount: number;
  projectSection?: ProjectVotingSection_ProjectSectionFieldCreateInput | null;
  votes?: ProjectVotingSection_VotesFieldCreateInput | null;
}

export interface Tournament_ProjectVotingSectionsFieldNestedUpdateDataInput {
  id?: number | null;
  projectSectionId?: number | null;
  title?: string | null;
  slotCount?: number | null;
  projectSection?: ProjectVotingSection_ProjectSectionFieldUpdateInput | null;
  votes?: ProjectVotingSection_VotesFieldUpdateInput | null;
}

export interface Tournament_ProjectVotingSectionsFieldNestedUpdateInput {
  where: ProjectVotingSectionWhereUniqueInput;
  data: Tournament_ProjectVotingSectionsFieldNestedUpdateDataInput;
}

export interface Tournament_ProjectVotingSectionsFieldNestedUpsertInput {
  create: Tournament_ProjectVotingSectionsFieldNestedCreateInput;
  update: Tournament_ProjectVotingSectionsFieldNestedUpdateDataInput;
  where: ProjectVotingSectionWhereUniqueInput;
}

export interface Tournament_ProjectVotingSectionsFieldUpdateInput {
  connect?: ProjectVotingSectionWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_ProjectVotingSectionsFieldConnectOrCreateInput[] | null;
  create?: Tournament_ProjectVotingSectionsFieldNestedCreateInput[] | null;
  delete?: ProjectVotingSectionWhereUniqueInput[] | null;
  deleteMany?: ProjectVotingSectionWhereScalarInput | null;
  disconnect?: ProjectVotingSectionWhereUniqueInput[] | null;
  set?: ProjectVotingSectionWhereUniqueInput[] | null;
  update?: Tournament_ProjectVotingSectionsFieldNestedUpdateInput[] | null;
  upsert?: Tournament_ProjectVotingSectionsFieldNestedUpsertInput[] | null;
}

export interface Tournament_PublicFieldWhereScalarInput {
  equals?: boolean | null;
  not?: Tournament_PublicFieldWhereScalarInput | null;
}

export interface Tournament_PublicMatchesFieldWhereScalarInput {
  equals?: boolean | null;
  not?: Tournament_PublicMatchesFieldWhereScalarInput | null;
}

export interface Tournament_RegistrationEndTimeFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_RegistrationEndTimeFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_RegistrationStartTimeFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_RegistrationStartTimeFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_RulesFieldWhereScalarInput {
  equals?: any | null;
  not?: Tournament_RulesFieldWhereScalarInput | null;
}

export interface Tournament_SpecialRegistrationFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_SpecialRegistrationFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_StagesFieldConnectOrCreateInput {
  where: TournamentStageWhereUniqueInput;
  create: Tournament_StagesFieldNestedCreateInput;
}

export interface Tournament_StagesFieldCreateInput {
  connect?: TournamentStageWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_StagesFieldConnectOrCreateInput[] | null;
  create?: Tournament_StagesFieldNestedCreateInput[] | null;
}

export interface Tournament_StagesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  type: TournamentStageType;
  matches?: TournamentStage_MatchesFieldCreateInput | null;
  prizes?: TournamentStage_PrizesFieldCreateInput | null;
}

export interface Tournament_StagesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  entering?: number | null;
  leaving?: number | null;
  active?: boolean | null;
  name?: string | null;
  hasLoserMatch?: boolean | null;
  type?: TournamentStageType | null;
  matches?: TournamentStage_MatchesFieldUpdateInput | null;
  prizes?: TournamentStage_PrizesFieldUpdateInput | null;
}

export interface Tournament_StagesFieldNestedUpdateInput {
  where: TournamentStageWhereUniqueInput;
  data: Tournament_StagesFieldNestedUpdateDataInput;
}

export interface Tournament_StagesFieldNestedUpsertInput {
  create: Tournament_StagesFieldNestedCreateInput;
  update: Tournament_StagesFieldNestedUpdateDataInput;
  where: TournamentStageWhereUniqueInput;
}

export interface Tournament_StagesFieldUpdateInput {
  connect?: TournamentStageWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_StagesFieldConnectOrCreateInput[] | null;
  create?: Tournament_StagesFieldNestedCreateInput[] | null;
  delete?: TournamentStageWhereUniqueInput[] | null;
  deleteMany?: TournamentStageWhereScalarInput | null;
  disconnect?: TournamentStageWhereUniqueInput[] | null;
  set?: TournamentStageWhereUniqueInput[] | null;
  update?: Tournament_StagesFieldNestedUpdateInput[] | null;
  upsert?: Tournament_StagesFieldNestedUpsertInput[] | null;
}

export interface Tournament_StartDateFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_StartDateFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Tournament_StyleFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_StyleFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_TeamsFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: Tournament_TeamsFieldNestedCreateInput;
}

export interface Tournament_TeamsFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_TeamsFieldConnectOrCreateInput[] | null;
  create?: Tournament_TeamsFieldNestedCreateInput[] | null;
}

export interface Tournament_TeamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  owner?: TournamentTeam_OwnerFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface Tournament_TeamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  ownerId?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  owner?: TournamentTeam_OwnerFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface Tournament_TeamsFieldNestedUpdateInput {
  where: TournamentTeamWhereUniqueInput;
  data: Tournament_TeamsFieldNestedUpdateDataInput;
}

export interface Tournament_TeamsFieldNestedUpsertInput {
  create: Tournament_TeamsFieldNestedCreateInput;
  update: Tournament_TeamsFieldNestedUpdateDataInput;
  where: TournamentTeamWhereUniqueInput;
}

export interface Tournament_TeamsFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: Tournament_TeamsFieldConnectOrCreateInput[] | null;
  create?: Tournament_TeamsFieldNestedCreateInput[] | null;
  delete?: TournamentTeamWhereUniqueInput[] | null;
  deleteMany?: TournamentTeamWhereScalarInput | null;
  disconnect?: TournamentTeamWhereUniqueInput[] | null;
  set?: TournamentTeamWhereUniqueInput[] | null;
  update?: Tournament_TeamsFieldNestedUpdateInput[] | null;
  upsert?: Tournament_TeamsFieldNestedUpsertInput[] | null;
}

export interface Tournament_TournamentInfoFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_TournamentInfoFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_TypeFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Tournament_TypeFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Tournament_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Tournament_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface TransactionWhereScalarInput {
  OR?: (TransactionWhereScalarInput | null)[] | null;
  AND?: (TransactionWhereScalarInput | null)[] | null;
  NOT?: (TransactionWhereScalarInput | null)[] | null;
  id?: Transaction_IdFieldWhereScalarInput | null;
  createdAt?: Transaction_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Transaction_UpdatedAtFieldWhereScalarInput | null;
  senderId?: Transaction_SenderIdFieldWhereScalarInput | null;
  receiverId?: Transaction_ReceiverIdFieldWhereScalarInput | null;
  currencyId?: Transaction_CurrencyIdFieldWhereScalarInput | null;
  amount?: Transaction_AmountFieldWhereScalarInput | null;
  description?: Transaction_DescriptionFieldWhereScalarInput | null;
}

export interface TransactionWhereUniqueInput {
  id: number;
}

export interface Transaction_AmountFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Transaction_AmountFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Transaction_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Transaction_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Transaction_CurrencyIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Transaction_CurrencyIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Transaction_DescriptionFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: Transaction_DescriptionFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface Transaction_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Transaction_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Transaction_ReceiverFieldConnectOrCreateInput {
  where: WalletWhereUniqueInput;
  create: Transaction_ReceiverFieldNestedCreateInput;
}

export interface Transaction_ReceiverFieldCreateInput {
  connect?: WalletWhereUniqueInput | null;
  connectOrCreate?: Transaction_ReceiverFieldConnectOrCreateInput | null;
  create?: Transaction_ReceiverFieldNestedCreateInput | null;
}

export interface Transaction_ReceiverFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  merchantId: number;
  merchant?: Wallet_MerchantFieldCreateInput | null;
  currencyId: number;
  currency?: Wallet_CurrencyFieldCreateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldCreateInput | null;
}

export interface Transaction_ReceiverFieldNestedUpdateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  merchantId?: number | null;
  merchant?: Wallet_MerchantFieldUpdateInput | null;
  currencyId?: number | null;
  currency?: Wallet_CurrencyFieldUpdateInput | null;
  receiverTransactions?: Wallet_ReceiverTransactionsFieldUpdateInput | null;
}

export interface Transaction_ReceiverFieldNestedUpsertInput {
  create: Transaction_ReceiverFieldNestedCreateInput;
  update: Transaction_ReceiverFieldNestedUpdateInput;
}

export interface Transaction_ReceiverFieldUpdateInput {
  connect?: WalletWhereUniqueInput | null;
  connectOrCreate?: Transaction_ReceiverFieldConnectOrCreateInput | null;
  create?: Transaction_ReceiverFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Transaction_ReceiverFieldNestedUpdateInput | null;
  upsert?: Transaction_ReceiverFieldNestedUpsertInput | null;
}

export interface Transaction_ReceiverIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Transaction_ReceiverIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Transaction_SenderIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Transaction_SenderIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Transaction_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Transaction_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UpdateTournamentTeamInput {
  name?: string | null;
  isManager?: boolean | null;
  players: (any | null)[];
  custom?: any | null;
  manager?: any | null;
  extra?: any | null;
  discord?: string | null;
  logo?: string | null;
}

export interface UserAddressFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserAddressFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserBirthdayFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserBirthdayFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserCityFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserCityFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserConsentInput {
  userAgent?: string | null;
  visitorId?: string | null;
  cookies?: (string | null)[] | null;
}

export interface UserCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserDiscordFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserDiscordFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserEmailFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserEmailFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserFblinkFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserFblinkFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: UserIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface UserInput {
  name?: string | null;
  surname?: string | null;
  nickname?: string | null;
  address?: string | null;
  city?: string | null;
  zipcode?: string | null;
  phone?: string | null;
  fblink?: string | null;
  discord?: string | null;
}

export interface UserMerchantsFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface UserMerchantsFieldWhereInput {
  every?: MerchantWhereInput | null;
  none?: MerchantWhereInput | null;
  some?: MerchantWhereInput | null;
}

export interface UserNameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserNameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserNicknameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserNicknameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserOrderByInput {
  address?: SortOrder | null;
  birthday?: SortOrder | null;
  city?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  discord?: SortOrder | null;
  email?: SortOrder | null;
  fblink?: SortOrder | null;
  id?: SortOrder | null;
  merchants?: UserMerchantsFieldOrderByInput | null;
  name?: SortOrder | null;
  nickname?: SortOrder | null;
  phone?: SortOrder | null;
  roles?: UserRolesFieldOrderByInput | null;
  surname?: SortOrder | null;
  zipcode?: SortOrder | null;
}

export interface UserPhoneFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserPhoneFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserRoleCreatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserRoleCreatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserRoleUpdatedAtFieldWhereInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserRoleUpdatedAtFieldWhereInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserRoleUserIdFieldWhereInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: UserRoleUserIdFieldWhereInput | null;
  notIn?: number[] | null;
}

export interface UserRoleUserIdRoleCompositeFieldWhereInput {
  userId: number;
  role: Role;
}

export interface UserRoleWhereInput {
  createdAt?: UserRoleCreatedAtFieldWhereInput | null;
  role?: Role | null;
  updatedAt?: UserRoleUpdatedAtFieldWhereInput | null;
  userId?: UserRoleUserIdFieldWhereInput | null;
  AND?: UserRoleWhereLogicAndInput[] | null;
  NOT?: UserRoleWhereLogicNotInput[] | null;
  OR?: UserRoleWhereLogicOrInput[] | null;
}

export interface UserRoleWhereLogicAndInput {
  createdAt?: UserRoleCreatedAtFieldWhereInput | null;
  role?: Role | null;
  updatedAt?: UserRoleUpdatedAtFieldWhereInput | null;
  userId?: UserRoleUserIdFieldWhereInput | null;
  AND?: UserRoleWhereLogicAndInput[] | null;
  NOT?: UserRoleWhereLogicNotInput[] | null;
  OR?: UserRoleWhereLogicOrInput[] | null;
}

export interface UserRoleWhereLogicNotInput {
  createdAt?: UserRoleCreatedAtFieldWhereInput | null;
  role?: Role | null;
  updatedAt?: UserRoleUpdatedAtFieldWhereInput | null;
  userId?: UserRoleUserIdFieldWhereInput | null;
  AND?: UserRoleWhereLogicAndInput[] | null;
  NOT?: UserRoleWhereLogicNotInput[] | null;
  OR?: UserRoleWhereLogicOrInput[] | null;
}

export interface UserRoleWhereLogicOrInput {
  createdAt?: UserRoleCreatedAtFieldWhereInput | null;
  role?: Role | null;
  updatedAt?: UserRoleUpdatedAtFieldWhereInput | null;
  userId?: UserRoleUserIdFieldWhereInput | null;
  AND?: UserRoleWhereLogicAndInput[] | null;
  NOT?: UserRoleWhereLogicNotInput[] | null;
  OR?: UserRoleWhereLogicOrInput[] | null;
}

export interface UserRoleWhereScalarInput {
  OR?: (UserRoleWhereScalarInput | null)[] | null;
  AND?: (UserRoleWhereScalarInput | null)[] | null;
  NOT?: (UserRoleWhereScalarInput | null)[] | null;
  createdAt?: UserRole_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: UserRole_UpdatedAtFieldWhereScalarInput | null;
  userId?: UserRole_UserIdFieldWhereScalarInput | null;
  role?: UserRole_RoleFieldWhereScalarInput | null;
}

export interface UserRoleWhereUniqueInput {
  userId_role: UserRoleUserIdRoleCompositeFieldWhereInput;
}

export interface UserRole_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserRole_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserRole_RoleFieldWhereScalarInput {
  equals?: Role | null;
  in?: Role[] | null;
  not?: UserRole_RoleFieldWhereScalarInput | null;
  notIn?: Role[] | null;
}

export interface UserRole_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: UserRole_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface UserRole_UserIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: UserRole_UserIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface UserRolesFieldOrderByInput {
  _count?: SortOrder | null;
}

export interface UserRolesFieldWhereInput {
  every?: UserRoleWhereInput | null;
  none?: UserRoleWhereInput | null;
  some?: UserRoleWhereInput | null;
}

export interface UserSurnameFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserSurnameFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface UserWhereInput {
  address?: UserAddressFieldWhereInput | null;
  birthday?: UserBirthdayFieldWhereInput | null;
  city?: UserCityFieldWhereInput | null;
  createdAt?: UserCreatedAtFieldWhereInput | null;
  discord?: UserDiscordFieldWhereInput | null;
  email?: UserEmailFieldWhereInput | null;
  fblink?: UserFblinkFieldWhereInput | null;
  id?: UserIdFieldWhereInput | null;
  name?: UserNameFieldWhereInput | null;
  nickname?: UserNicknameFieldWhereInput | null;
  phone?: UserPhoneFieldWhereInput | null;
  surname?: UserSurnameFieldWhereInput | null;
  zipcode?: UserZipcodeFieldWhereInput | null;
  roles?: UserRolesFieldWhereInput | null;
  merchants?: UserMerchantsFieldWhereInput | null;
  AND?: UserWhereLogicAndInput[] | null;
  NOT?: UserWhereLogicNotInput[] | null;
  OR?: UserWhereLogicOrInput[] | null;
  searchString?: string | null;
}

export interface UserWhereLogicAndInput {
  address?: UserAddressFieldWhereInput | null;
  birthday?: UserBirthdayFieldWhereInput | null;
  city?: UserCityFieldWhereInput | null;
  createdAt?: UserCreatedAtFieldWhereInput | null;
  discord?: UserDiscordFieldWhereInput | null;
  email?: UserEmailFieldWhereInput | null;
  fblink?: UserFblinkFieldWhereInput | null;
  id?: UserIdFieldWhereInput | null;
  name?: UserNameFieldWhereInput | null;
  nickname?: UserNicknameFieldWhereInput | null;
  phone?: UserPhoneFieldWhereInput | null;
  surname?: UserSurnameFieldWhereInput | null;
  zipcode?: UserZipcodeFieldWhereInput | null;
  roles?: UserRolesFieldWhereInput | null;
  merchants?: UserMerchantsFieldWhereInput | null;
  AND?: UserWhereLogicAndInput[] | null;
  NOT?: UserWhereLogicNotInput[] | null;
  OR?: UserWhereLogicOrInput[] | null;
}

export interface UserWhereLogicNotInput {
  address?: UserAddressFieldWhereInput | null;
  birthday?: UserBirthdayFieldWhereInput | null;
  city?: UserCityFieldWhereInput | null;
  createdAt?: UserCreatedAtFieldWhereInput | null;
  discord?: UserDiscordFieldWhereInput | null;
  email?: UserEmailFieldWhereInput | null;
  fblink?: UserFblinkFieldWhereInput | null;
  id?: UserIdFieldWhereInput | null;
  name?: UserNameFieldWhereInput | null;
  nickname?: UserNicknameFieldWhereInput | null;
  phone?: UserPhoneFieldWhereInput | null;
  surname?: UserSurnameFieldWhereInput | null;
  zipcode?: UserZipcodeFieldWhereInput | null;
  roles?: UserRolesFieldWhereInput | null;
  merchants?: UserMerchantsFieldWhereInput | null;
  AND?: UserWhereLogicAndInput[] | null;
  NOT?: UserWhereLogicNotInput[] | null;
  OR?: UserWhereLogicOrInput[] | null;
}

export interface UserWhereLogicOrInput {
  address?: UserAddressFieldWhereInput | null;
  birthday?: UserBirthdayFieldWhereInput | null;
  city?: UserCityFieldWhereInput | null;
  createdAt?: UserCreatedAtFieldWhereInput | null;
  discord?: UserDiscordFieldWhereInput | null;
  email?: UserEmailFieldWhereInput | null;
  fblink?: UserFblinkFieldWhereInput | null;
  id?: UserIdFieldWhereInput | null;
  name?: UserNameFieldWhereInput | null;
  nickname?: UserNicknameFieldWhereInput | null;
  phone?: UserPhoneFieldWhereInput | null;
  surname?: UserSurnameFieldWhereInput | null;
  zipcode?: UserZipcodeFieldWhereInput | null;
  roles?: UserRolesFieldWhereInput | null;
  merchants?: UserMerchantsFieldWhereInput | null;
  AND?: UserWhereLogicAndInput[] | null;
  NOT?: UserWhereLogicNotInput[] | null;
  OR?: UserWhereLogicOrInput[] | null;
}

export interface UserWhereScalarInput {
  OR?: (UserWhereScalarInput | null)[] | null;
  AND?: (UserWhereScalarInput | null)[] | null;
  NOT?: (UserWhereScalarInput | null)[] | null;
  id?: User_IdFieldWhereScalarInput | null;
  createdAt?: User_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: User_UpdatedAtFieldWhereScalarInput | null;
  email?: User_EmailFieldWhereScalarInput | null;
  password?: User_PasswordFieldWhereScalarInput | null;
  name?: User_NameFieldWhereScalarInput | null;
  surname?: User_SurnameFieldWhereScalarInput | null;
  address?: User_AddressFieldWhereScalarInput | null;
  city?: User_CityFieldWhereScalarInput | null;
  zipcode?: User_ZipcodeFieldWhereScalarInput | null;
  phone?: User_PhoneFieldWhereScalarInput | null;
  newsletter?: User_NewsletterFieldWhereScalarInput | null;
  birthday?: User_BirthdayFieldWhereScalarInput | null;
  fblink?: User_FblinkFieldWhereScalarInput | null;
  discord?: User_DiscordFieldWhereScalarInput | null;
  socialType?: User_SocialTypeFieldWhereScalarInput | null;
  nickname?: User_NicknameFieldWhereScalarInput | null;
  profilePicture?: User_ProfilePictureFieldWhereScalarInput | null;
}

export interface UserWhereUniqueInput {
  id?: number | null;
  email?: string | null;
}

export interface UserZipcodeFieldWhereInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: UserZipcodeFieldWhereInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_AddressFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_AddressFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_ArticlesFieldConnectOrCreateInput {
  where: ArticleWhereUniqueInput;
  create: User_ArticlesFieldNestedCreateInput;
}

export interface User_ArticlesFieldCreateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  connectOrCreate?: User_ArticlesFieldConnectOrCreateInput[] | null;
  create?: User_ArticlesFieldNestedCreateInput[] | null;
}

export interface User_ArticlesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldCreateInput | null;
  subcategories?: Article_SubcategoriesFieldCreateInput | null;
  tags: string[];
  mainImageFile?: Article_MainImageFileFieldCreateInput | null;
  observers?: Article_ObserversFieldCreateInput | null;
  homeArticles?: Article_HomeArticlesFieldCreateInput | null;
  uniqueVisits?: Article_UniqueVisitsFieldCreateInput | null;
}

export interface User_ArticlesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldUpdateInput | null;
  subcategories?: Article_SubcategoriesFieldUpdateInput | null;
  tags?: string[] | null;
  mainImageFile?: Article_MainImageFileFieldUpdateInput | null;
  observers?: Article_ObserversFieldUpdateInput | null;
  homeArticles?: Article_HomeArticlesFieldUpdateInput | null;
  uniqueVisits?: Article_UniqueVisitsFieldUpdateInput | null;
}

export interface User_ArticlesFieldNestedUpdateInput {
  where: ArticleWhereUniqueInput;
  data: User_ArticlesFieldNestedUpdateDataInput;
}

export interface User_ArticlesFieldNestedUpsertInput {
  create: User_ArticlesFieldNestedCreateInput;
  update: User_ArticlesFieldNestedUpdateDataInput;
  where: ArticleWhereUniqueInput;
}

export interface User_ArticlesFieldUpdateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  connectOrCreate?: User_ArticlesFieldConnectOrCreateInput[] | null;
  create?: User_ArticlesFieldNestedCreateInput[] | null;
  delete?: ArticleWhereUniqueInput[] | null;
  deleteMany?: ArticleWhereScalarInput | null;
  disconnect?: ArticleWhereUniqueInput[] | null;
  set?: ArticleWhereUniqueInput[] | null;
  update?: User_ArticlesFieldNestedUpdateInput[] | null;
  upsert?: User_ArticlesFieldNestedUpsertInput[] | null;
}

export interface User_BirthdayFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: User_BirthdayFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface User_CityFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_CityFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: User_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface User_DiscordFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_DiscordFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_EmailFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_EmailFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_FblinkFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_FblinkFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_IdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: User_IdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface User_MerchantsFieldConnectOrCreateInput {
  where: MerchantWhereUniqueInput;
  create: User_MerchantsFieldNestedCreateInput;
}

export interface User_MerchantsFieldCreateInput {
  connect?: MerchantWhereUniqueInput[] | null;
  connectOrCreate?: User_MerchantsFieldConnectOrCreateInput[] | null;
  create?: User_MerchantsFieldNestedCreateInput[] | null;
}

export interface User_MerchantsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: MerchantType;
  wallets?: Merchant_WalletsFieldCreateInput | null;
  tournaments?: Merchant_TournamentsFieldCreateInput | null;
}

export interface User_MerchantsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  type?: MerchantType | null;
  wallets?: Merchant_WalletsFieldUpdateInput | null;
  tournaments?: Merchant_TournamentsFieldUpdateInput | null;
}

export interface User_MerchantsFieldNestedUpdateInput {
  where: MerchantWhereUniqueInput;
  data: User_MerchantsFieldNestedUpdateDataInput;
}

export interface User_MerchantsFieldNestedUpsertInput {
  create: User_MerchantsFieldNestedCreateInput;
  update: User_MerchantsFieldNestedUpdateDataInput;
  where: MerchantWhereUniqueInput;
}

export interface User_MerchantsFieldUpdateInput {
  connect?: MerchantWhereUniqueInput[] | null;
  connectOrCreate?: User_MerchantsFieldConnectOrCreateInput[] | null;
  create?: User_MerchantsFieldNestedCreateInput[] | null;
  delete?: MerchantWhereUniqueInput[] | null;
  deleteMany?: MerchantWhereScalarInput | null;
  disconnect?: MerchantWhereUniqueInput[] | null;
  set?: MerchantWhereUniqueInput[] | null;
  update?: User_MerchantsFieldNestedUpdateInput[] | null;
  upsert?: User_MerchantsFieldNestedUpsertInput[] | null;
}

export interface User_NameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_NameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_NewsletterFieldWhereScalarInput {
  equals?: boolean | null;
  not?: User_NewsletterFieldWhereScalarInput | null;
}

export interface User_NicknameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_NicknameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_ObservedArticlesFieldConnectOrCreateInput {
  where: ArticleWhereUniqueInput;
  create: User_ObservedArticlesFieldNestedCreateInput;
}

export interface User_ObservedArticlesFieldCreateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  connectOrCreate?: User_ObservedArticlesFieldConnectOrCreateInput[] | null;
  create?: User_ObservedArticlesFieldNestedCreateInput[] | null;
}

export interface User_ObservedArticlesFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldCreateInput | null;
  subcategories?: Article_SubcategoriesFieldCreateInput | null;
  tags: string[];
  mainImageFile?: Article_MainImageFileFieldCreateInput | null;
  observers?: Article_ObserversFieldCreateInput | null;
  homeArticles?: Article_HomeArticlesFieldCreateInput | null;
  uniqueVisits?: Article_UniqueVisitsFieldCreateInput | null;
}

export interface User_ObservedArticlesFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  publishedAt?: any | null;
  title?: string | null;
  subtitle?: string | null;
  mainImage?: string | null;
  text?: string | null;
  tag?: string | null;
  pageLoadCount?: number | null;
  categories?: Article_CategoriesFieldUpdateInput | null;
  subcategories?: Article_SubcategoriesFieldUpdateInput | null;
  tags?: string[] | null;
  mainImageFile?: Article_MainImageFileFieldUpdateInput | null;
  observers?: Article_ObserversFieldUpdateInput | null;
  homeArticles?: Article_HomeArticlesFieldUpdateInput | null;
  uniqueVisits?: Article_UniqueVisitsFieldUpdateInput | null;
}

export interface User_ObservedArticlesFieldNestedUpdateInput {
  where: ArticleWhereUniqueInput;
  data: User_ObservedArticlesFieldNestedUpdateDataInput;
}

export interface User_ObservedArticlesFieldNestedUpsertInput {
  create: User_ObservedArticlesFieldNestedCreateInput;
  update: User_ObservedArticlesFieldNestedUpdateDataInput;
  where: ArticleWhereUniqueInput;
}

export interface User_ObservedArticlesFieldUpdateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  connectOrCreate?: User_ObservedArticlesFieldConnectOrCreateInput[] | null;
  create?: User_ObservedArticlesFieldNestedCreateInput[] | null;
  delete?: ArticleWhereUniqueInput[] | null;
  deleteMany?: ArticleWhereScalarInput | null;
  disconnect?: ArticleWhereUniqueInput[] | null;
  set?: ArticleWhereUniqueInput[] | null;
  update?: User_ObservedArticlesFieldNestedUpdateInput[] | null;
  upsert?: User_ObservedArticlesFieldNestedUpsertInput[] | null;
}

export interface User_PasswordFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_PasswordFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_PhoneFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_PhoneFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_PrizeActionsFieldConnectOrCreateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  create: User_PrizeActionsFieldNestedCreateInput;
}

export interface User_PrizeActionsFieldCreateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: User_PrizeActionsFieldConnectOrCreateInput[] | null;
  create?: User_PrizeActionsFieldNestedCreateInput[] | null;
}

export interface User_PrizeActionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  prizeId: number;
  teamId?: number | null;
  state: TournamentPrizeState;
  prize?: TournamentPrizeAction_PrizeFieldCreateInput | null;
  team?: TournamentPrizeAction_TeamFieldCreateInput | null;
}

export interface User_PrizeActionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  prizeId?: number | null;
  teamId?: number | null;
  state?: TournamentPrizeState | null;
  prize?: TournamentPrizeAction_PrizeFieldUpdateInput | null;
  team?: TournamentPrizeAction_TeamFieldUpdateInput | null;
}

export interface User_PrizeActionsFieldNestedUpdateInput {
  where: TournamentPrizeActionWhereUniqueInput;
  data: User_PrizeActionsFieldNestedUpdateDataInput;
}

export interface User_PrizeActionsFieldNestedUpsertInput {
  create: User_PrizeActionsFieldNestedCreateInput;
  update: User_PrizeActionsFieldNestedUpdateDataInput;
  where: TournamentPrizeActionWhereUniqueInput;
}

export interface User_PrizeActionsFieldUpdateInput {
  connect?: TournamentPrizeActionWhereUniqueInput[] | null;
  connectOrCreate?: User_PrizeActionsFieldConnectOrCreateInput[] | null;
  create?: User_PrizeActionsFieldNestedCreateInput[] | null;
  delete?: TournamentPrizeActionWhereUniqueInput[] | null;
  deleteMany?: TournamentPrizeActionWhereScalarInput | null;
  disconnect?: TournamentPrizeActionWhereUniqueInput[] | null;
  set?: TournamentPrizeActionWhereUniqueInput[] | null;
  update?: User_PrizeActionsFieldNestedUpdateInput[] | null;
  upsert?: User_PrizeActionsFieldNestedUpsertInput[] | null;
}

export interface User_ProfilePictureFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_ProfilePictureFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_RolesFieldConnectOrCreateInput {
  where: UserRoleWhereUniqueInput;
  create: User_RolesFieldNestedCreateInput;
}

export interface User_RolesFieldCreateInput {
  connect?: UserRoleWhereUniqueInput[] | null;
  connectOrCreate?: User_RolesFieldConnectOrCreateInput[] | null;
  create?: User_RolesFieldNestedCreateInput[] | null;
}

export interface User_RolesFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  role: Role;
}

export interface User_RolesFieldNestedUpdateDataInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  role?: Role | null;
}

export interface User_RolesFieldNestedUpdateInput {
  where: UserRoleWhereUniqueInput;
  data: User_RolesFieldNestedUpdateDataInput;
}

export interface User_RolesFieldNestedUpsertInput {
  create: User_RolesFieldNestedCreateInput;
  update: User_RolesFieldNestedUpdateDataInput;
  where: UserRoleWhereUniqueInput;
}

export interface User_RolesFieldUpdateInput {
  connect?: UserRoleWhereUniqueInput[] | null;
  connectOrCreate?: User_RolesFieldConnectOrCreateInput[] | null;
  create?: User_RolesFieldNestedCreateInput[] | null;
  delete?: UserRoleWhereUniqueInput[] | null;
  deleteMany?: UserRoleWhereScalarInput | null;
  disconnect?: UserRoleWhereUniqueInput[] | null;
  set?: UserRoleWhereUniqueInput[] | null;
  update?: User_RolesFieldNestedUpdateInput[] | null;
  upsert?: User_RolesFieldNestedUpsertInput[] | null;
}

export interface User_SocialTypeFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_SocialTypeFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_SurnameFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_SurnameFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface User_TeamsFieldConnectOrCreateInput {
  where: TeamWhereUniqueInput;
  create: User_TeamsFieldNestedCreateInput;
}

export interface User_TeamsFieldCreateInput {
  connect?: TeamWhereUniqueInput[] | null;
  connectOrCreate?: User_TeamsFieldConnectOrCreateInput[] | null;
  create?: User_TeamsFieldNestedCreateInput[] | null;
}

export interface User_TeamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  gameId?: number | null;
  name: string;
  players?: any | null;
  logo?: string | null;
  tournamentTeams?: Team_TournamentTeamsFieldCreateInput | null;
  game?: Team_GameFieldCreateInput | null;
  invitations?: Team_InvitationsFieldCreateInput | null;
  updatedAt?: any | null;
}

export interface User_TeamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  gameId?: number | null;
  name?: string | null;
  players?: any | null;
  logo?: string | null;
  tournamentTeams?: Team_TournamentTeamsFieldUpdateInput | null;
  game?: Team_GameFieldUpdateInput | null;
  invitations?: Team_InvitationsFieldUpdateInput | null;
  updatedAt?: any | null;
}

export interface User_TeamsFieldNestedUpdateInput {
  where: TeamWhereUniqueInput;
  data: User_TeamsFieldNestedUpdateDataInput;
}

export interface User_TeamsFieldNestedUpsertInput {
  create: User_TeamsFieldNestedCreateInput;
  update: User_TeamsFieldNestedUpdateDataInput;
  where: TeamWhereUniqueInput;
}

export interface User_TeamsFieldUpdateInput {
  connect?: TeamWhereUniqueInput[] | null;
  connectOrCreate?: User_TeamsFieldConnectOrCreateInput[] | null;
  create?: User_TeamsFieldNestedCreateInput[] | null;
  delete?: TeamWhereUniqueInput[] | null;
  deleteMany?: TeamWhereScalarInput | null;
  disconnect?: TeamWhereUniqueInput[] | null;
  set?: TeamWhereUniqueInput[] | null;
  update?: User_TeamsFieldNestedUpdateInput[] | null;
  upsert?: User_TeamsFieldNestedUpsertInput[] | null;
}

export interface User_TournamentTeamsFieldConnectOrCreateInput {
  where: TournamentTeamWhereUniqueInput;
  create: User_TournamentTeamsFieldNestedCreateInput;
}

export interface User_TournamentTeamsFieldCreateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: User_TournamentTeamsFieldConnectOrCreateInput[] | null;
  create?: User_TournamentTeamsFieldNestedCreateInput[] | null;
}

export interface User_TournamentTeamsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId: number;
  name: string;
  players?: any | null;
  contact: string;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldCreateInput | null;
  team?: TournamentTeam_TeamFieldCreateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldCreateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldCreateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldCreateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldCreateInput | null;
}

export interface User_TournamentTeamsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  tournamentId?: number | null;
  name?: string | null;
  players?: any | null;
  contact?: string | null;
  isManager?: boolean | null;
  schedule?: string | null;
  extra?: string | null;
  discord?: string | null;
  pending?: boolean | null;
  state?: number | null;
  fixedOrder?: number | null;
  manager?: any | null;
  custom?: any | null;
  teamId?: number | null;
  fetched?: boolean | null;
  logo?: string | null;
  tournament?: TournamentTeam_TournamentFieldUpdateInput | null;
  team?: TournamentTeam_TeamFieldUpdateInput | null;
  teamAMatches?: TournamentTeam_TeamAMatchesFieldUpdateInput | null;
  teamBMatches?: TournamentTeam_TeamBMatchesFieldUpdateInput | null;
  claimedPrizes?: TournamentTeam_ClaimedPrizesFieldUpdateInput | null;
  prizeActions?: TournamentTeam_PrizeActionsFieldUpdateInput | null;
}

export interface User_TournamentTeamsFieldNestedUpdateInput {
  where: TournamentTeamWhereUniqueInput;
  data: User_TournamentTeamsFieldNestedUpdateDataInput;
}

export interface User_TournamentTeamsFieldNestedUpsertInput {
  create: User_TournamentTeamsFieldNestedCreateInput;
  update: User_TournamentTeamsFieldNestedUpdateDataInput;
  where: TournamentTeamWhereUniqueInput;
}

export interface User_TournamentTeamsFieldUpdateInput {
  connect?: TournamentTeamWhereUniqueInput[] | null;
  connectOrCreate?: User_TournamentTeamsFieldConnectOrCreateInput[] | null;
  create?: User_TournamentTeamsFieldNestedCreateInput[] | null;
  delete?: TournamentTeamWhereUniqueInput[] | null;
  deleteMany?: TournamentTeamWhereScalarInput | null;
  disconnect?: TournamentTeamWhereUniqueInput[] | null;
  set?: TournamentTeamWhereUniqueInput[] | null;
  update?: User_TournamentTeamsFieldNestedUpdateInput[] | null;
  upsert?: User_TournamentTeamsFieldNestedUpsertInput[] | null;
}

export interface User_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: User_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface User_VisitsFieldConnectOrCreateInput {
  where: ArticleVisitWhereUniqueInput;
  create: User_VisitsFieldNestedCreateInput;
}

export interface User_VisitsFieldCreateInput {
  connect?: ArticleVisitWhereUniqueInput[] | null;
  connectOrCreate?: User_VisitsFieldConnectOrCreateInput[] | null;
  create?: User_VisitsFieldNestedCreateInput[] | null;
}

export interface User_VisitsFieldNestedCreateInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  visitorId: string;
  articleId: number;
  article?: ArticleVisit_ArticleFieldCreateInput | null;
}

export interface User_VisitsFieldNestedUpdateDataInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  visitorId?: string | null;
  articleId?: number | null;
  article?: ArticleVisit_ArticleFieldUpdateInput | null;
}

export interface User_VisitsFieldNestedUpdateInput {
  where: ArticleVisitWhereUniqueInput;
  data: User_VisitsFieldNestedUpdateDataInput;
}

export interface User_VisitsFieldNestedUpsertInput {
  create: User_VisitsFieldNestedCreateInput;
  update: User_VisitsFieldNestedUpdateDataInput;
  where: ArticleVisitWhereUniqueInput;
}

export interface User_VisitsFieldUpdateInput {
  connect?: ArticleVisitWhereUniqueInput[] | null;
  connectOrCreate?: User_VisitsFieldConnectOrCreateInput[] | null;
  create?: User_VisitsFieldNestedCreateInput[] | null;
  delete?: ArticleVisitWhereUniqueInput[] | null;
  deleteMany?: ArticleVisitWhereScalarInput | null;
  disconnect?: ArticleVisitWhereUniqueInput[] | null;
  set?: ArticleVisitWhereUniqueInput[] | null;
  update?: User_VisitsFieldNestedUpdateInput[] | null;
  upsert?: User_VisitsFieldNestedUpsertInput[] | null;
}

export interface User_ZipcodeFieldWhereScalarInput {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: StringSearchMode | null;
  not?: User_ZipcodeFieldWhereScalarInput | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface WalletMerchantIdCurrencyIdCompositeFieldWhereInput {
  merchantId: number;
  currencyId: number;
}

export interface WalletWhereScalarInput {
  OR?: (WalletWhereScalarInput | null)[] | null;
  AND?: (WalletWhereScalarInput | null)[] | null;
  NOT?: (WalletWhereScalarInput | null)[] | null;
  createdAt?: Wallet_CreatedAtFieldWhereScalarInput | null;
  updatedAt?: Wallet_UpdatedAtFieldWhereScalarInput | null;
  merchantId?: Wallet_MerchantIdFieldWhereScalarInput | null;
  currencyId?: Wallet_CurrencyIdFieldWhereScalarInput | null;
}

export interface WalletWhereUniqueInput {
  merchantId_currencyId: WalletMerchantIdCurrencyIdCompositeFieldWhereInput;
}

export interface Wallet_CreatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Wallet_CreatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

export interface Wallet_CurrencyFieldConnectOrCreateInput {
  where: CurrencyWhereUniqueInput;
  create: Wallet_CurrencyFieldNestedCreateInput;
}

export interface Wallet_CurrencyFieldCreateInput {
  connect?: CurrencyWhereUniqueInput | null;
  connectOrCreate?: Wallet_CurrencyFieldConnectOrCreateInput | null;
  create?: Wallet_CurrencyFieldNestedCreateInput | null;
}

export interface Wallet_CurrencyFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  code: string;
  icon?: string | null;
  tournamentPrizes?: Currency_TournamentPrizesFieldCreateInput | null;
}

export interface Wallet_CurrencyFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  code?: string | null;
  icon?: string | null;
  tournamentPrizes?: Currency_TournamentPrizesFieldUpdateInput | null;
}

export interface Wallet_CurrencyFieldNestedUpsertInput {
  create: Wallet_CurrencyFieldNestedCreateInput;
  update: Wallet_CurrencyFieldNestedUpdateInput;
}

export interface Wallet_CurrencyFieldUpdateInput {
  connect?: CurrencyWhereUniqueInput | null;
  connectOrCreate?: Wallet_CurrencyFieldConnectOrCreateInput | null;
  create?: Wallet_CurrencyFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Wallet_CurrencyFieldNestedUpdateInput | null;
  upsert?: Wallet_CurrencyFieldNestedUpsertInput | null;
}

export interface Wallet_CurrencyIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Wallet_CurrencyIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Wallet_MerchantFieldConnectOrCreateInput {
  where: MerchantWhereUniqueInput;
  create: Wallet_MerchantFieldNestedCreateInput;
}

export interface Wallet_MerchantFieldCreateInput {
  connect?: MerchantWhereUniqueInput | null;
  connectOrCreate?: Wallet_MerchantFieldConnectOrCreateInput | null;
  create?: Wallet_MerchantFieldNestedCreateInput | null;
}

export interface Wallet_MerchantFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  userId?: number | null;
  user?: Merchant_UserFieldCreateInput | null;
  type: MerchantType;
  tournaments?: Merchant_TournamentsFieldCreateInput | null;
}

export interface Wallet_MerchantFieldNestedUpdateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  userId?: number | null;
  user?: Merchant_UserFieldUpdateInput | null;
  type?: MerchantType | null;
  tournaments?: Merchant_TournamentsFieldUpdateInput | null;
}

export interface Wallet_MerchantFieldNestedUpsertInput {
  create: Wallet_MerchantFieldNestedCreateInput;
  update: Wallet_MerchantFieldNestedUpdateInput;
}

export interface Wallet_MerchantFieldUpdateInput {
  connect?: MerchantWhereUniqueInput | null;
  connectOrCreate?: Wallet_MerchantFieldConnectOrCreateInput | null;
  create?: Wallet_MerchantFieldNestedCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: Wallet_MerchantFieldNestedUpdateInput | null;
  upsert?: Wallet_MerchantFieldNestedUpsertInput | null;
}

export interface Wallet_MerchantIdFieldWhereScalarInput {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: Wallet_MerchantIdFieldWhereScalarInput | null;
  notIn?: number[] | null;
}

export interface Wallet_ReceiverTransactionsFieldConnectOrCreateInput {
  where: TransactionWhereUniqueInput;
  create: Wallet_ReceiverTransactionsFieldNestedCreateInput;
}

export interface Wallet_ReceiverTransactionsFieldCreateInput {
  connect?: TransactionWhereUniqueInput[] | null;
  connectOrCreate?: Wallet_ReceiverTransactionsFieldConnectOrCreateInput[] | null;
  create?: Wallet_ReceiverTransactionsFieldNestedCreateInput[] | null;
}

export interface Wallet_ReceiverTransactionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  receiverId: number;
  amount: number;
  description?: string | null;
  receiver?: Transaction_ReceiverFieldCreateInput | null;
}

export interface Wallet_ReceiverTransactionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  receiverId?: number | null;
  amount?: number | null;
  description?: string | null;
  receiver?: Transaction_ReceiverFieldUpdateInput | null;
}

export interface Wallet_ReceiverTransactionsFieldNestedUpdateInput {
  where: TransactionWhereUniqueInput;
  data: Wallet_ReceiverTransactionsFieldNestedUpdateDataInput;
}

export interface Wallet_ReceiverTransactionsFieldNestedUpsertInput {
  create: Wallet_ReceiverTransactionsFieldNestedCreateInput;
  update: Wallet_ReceiverTransactionsFieldNestedUpdateDataInput;
  where: TransactionWhereUniqueInput;
}

export interface Wallet_ReceiverTransactionsFieldUpdateInput {
  connect?: TransactionWhereUniqueInput[] | null;
  connectOrCreate?: Wallet_ReceiverTransactionsFieldConnectOrCreateInput[] | null;
  create?: Wallet_ReceiverTransactionsFieldNestedCreateInput[] | null;
  delete?: TransactionWhereUniqueInput[] | null;
  deleteMany?: TransactionWhereScalarInput | null;
  disconnect?: TransactionWhereUniqueInput[] | null;
  set?: TransactionWhereUniqueInput[] | null;
  update?: Wallet_ReceiverTransactionsFieldNestedUpdateInput[] | null;
  upsert?: Wallet_ReceiverTransactionsFieldNestedUpsertInput[] | null;
}

export interface Wallet_SenderTransactionsFieldConnectOrCreateInput {
  where: TransactionWhereUniqueInput;
  create: Wallet_SenderTransactionsFieldNestedCreateInput;
}

export interface Wallet_SenderTransactionsFieldCreateInput {
  connect?: TransactionWhereUniqueInput[] | null;
  connectOrCreate?: Wallet_SenderTransactionsFieldConnectOrCreateInput[] | null;
  create?: Wallet_SenderTransactionsFieldNestedCreateInput[] | null;
}

export interface Wallet_SenderTransactionsFieldNestedCreateInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  receiverId: number;
  amount: number;
  description?: string | null;
  receiver?: Transaction_ReceiverFieldCreateInput | null;
}

export interface Wallet_SenderTransactionsFieldNestedUpdateDataInput {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  receiverId?: number | null;
  amount?: number | null;
  description?: string | null;
  receiver?: Transaction_ReceiverFieldUpdateInput | null;
}

export interface Wallet_SenderTransactionsFieldNestedUpdateInput {
  where: TransactionWhereUniqueInput;
  data: Wallet_SenderTransactionsFieldNestedUpdateDataInput;
}

export interface Wallet_SenderTransactionsFieldNestedUpsertInput {
  create: Wallet_SenderTransactionsFieldNestedCreateInput;
  update: Wallet_SenderTransactionsFieldNestedUpdateDataInput;
  where: TransactionWhereUniqueInput;
}

export interface Wallet_SenderTransactionsFieldUpdateInput {
  connect?: TransactionWhereUniqueInput[] | null;
  connectOrCreate?: Wallet_SenderTransactionsFieldConnectOrCreateInput[] | null;
  create?: Wallet_SenderTransactionsFieldNestedCreateInput[] | null;
  delete?: TransactionWhereUniqueInput[] | null;
  deleteMany?: TransactionWhereScalarInput | null;
  disconnect?: TransactionWhereUniqueInput[] | null;
  set?: TransactionWhereUniqueInput[] | null;
  update?: Wallet_SenderTransactionsFieldNestedUpdateInput[] | null;
  upsert?: Wallet_SenderTransactionsFieldNestedUpsertInput[] | null;
}

export interface Wallet_UpdatedAtFieldWhereScalarInput {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  not?: Wallet_UpdatedAtFieldWhereScalarInput | null;
  notIn?: any[] | null;
  lte?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
