/* eslint-disable import/named */
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { forwardRef, ButtonHTMLAttributes } from "react";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import { ClassNameArgument, Size, ThemeColor } from "types";
import styles from "./Button.module.scss";

type IconProp = FontAwesomeIconProps["icon"];
type HTMLButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonDataType =
  | "control"
  | "danger"
  | "danger-inverted"
  | "warning"
  | "warning-inverted"
  | "inline"
  | "success"
  | "success-inverted"
  | "action"
  | "action-inverted"
  | "info"
  | "info-inverted"
  | "neutral"
  | "neutral-inverted"
  | "ghost";

export type ButtonProps = Omit<HTMLButtonProps, "type"> & {
  type?: ButtonDataType | ButtonDataType[];
  backgroundColor?: ThemeColor;
  color?: ThemeColor;
  icon?: IconProp;
  iconColor?: ThemeColor;
  iconPosition?: "start" | "end";
  iconProps?: Omit<FontAwesomeIconProps, "icon" | "color">;
  className?: ClassNameArgument;
  loading?: boolean;
  size?: Size;
  htmlType?: HTMLButtonProps["type"];
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      "aria-label": ariaLabel,
      type: dataType,
      backgroundColor,
      color,
      children,
      icon,
      iconColor,
      iconPosition = "start",
      iconProps = {},
      className,
      onClick,
      loading = false,
      htmlType: type,
      title,
      size,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      aria-label={ariaLabel}
      className={classNames(styles.button, className)}
      data-background-color={backgroundColor}
      data-type={[dataType].flat().join(", ")}
      data-icon-position={iconPosition}
      onClick={onClick}
      type={type}
      title={title || ariaLabel}
      {...(size ? { "data-size": size } : {})}
      {...(color ? { "data-color": color } : {})}
      {...props}
    >
      {loading ? (
        <LoadingIndicatorInline />
      ) : (
        <>
          {icon && (
            <span className={styles.icon}>
              {typeof icon === "string" || Array.isArray(icon) ? (
                <FontAwesomeIcon
                  icon={icon as IconProp}
                  color={
                    iconColor
                      ? `hsl(var(--color-${iconColor}))`
                      : "currentColor"
                  }
                  {...iconProps}
                />
              ) : (
                icon
              )}
            </span>
          )}
          {children && <span className={styles.content}>{children}</span>}
        </>
      )}
    </button>
  )
);

export default Button;
