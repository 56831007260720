import { FC, LabelHTMLAttributes } from "react";
import styles from "./FormField.module.scss";

const FormLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  className = "",
  htmlFor,
  ...props
}) => (
  <label
    className={`${className} ${styles.label}`}
    htmlFor={htmlFor}
    {...props}
  >
    {children}
  </label>
);

export default FormLabel;
