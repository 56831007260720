import { kebabCase } from "lodash";
import StatusMessage from "base-components/StatusMessage/StatusMessage";
import { useStatusMessages } from "contexts/StatusMessages";
import styles from "./StatusMessages.module.scss";

const StatusMessages = () => {
  const { messages, removeMessage } = useStatusMessages();

  return (
    <section className={styles.statusMessages}>
      {messages.map((FormattedMessage) => (
        <StatusMessage
          key={`status-FormattedMessage-${FormattedMessage.type}-${kebabCase(
            FormattedMessage.id
          )}`}
          open
          onClose={() => removeMessage(FormattedMessage)}
          type={FormattedMessage.type}
        >
          {FormattedMessage.content}
        </StatusMessage>
      ))}
    </section>
  );
};

export default StatusMessages;
