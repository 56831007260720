import Link from "next/link";
import { FormattedMessage } from "react-intl";
import Button from "base-components/Button";

const LoginButton = () => (
  <Link href="/login">
    <a>
      <Button
        color="white"
        backgroundColor="cool-gray-800"
        type="control"
        htmlType="button"
      >
        <FormattedMessage defaultMessage="Log In" />
      </Button>
    </a>
  </Link>
);

export default LoginButton;
